import { analyticsLogEvent } from 'appFirebase/helperApi';
import { navBack } from 'misc/appHelperFunctions';
import { IconArrowLeft } from 'components/icons';
import TooltipContainer from 'components/TooltipContainer';
// Third party.
import React from 'react';
import { useHistory } from 'react-router-dom';

//--------------------
// BackButton
//--------------------
function BackButton(props) {
  const history = useHistory();

  const {
    className="",
    clickEventName="click_back_button",
    tooltipContent="Back",
    hoverEventName="hover_back_button",
  } = props;

  function handleClick() {
    navBack(history);
    analyticsLogEvent(clickEventName || 'click_back_button');
  }

  return (
    <TooltipContainer content={tooltipContent} hoverEventName={hoverEventName}>
      <div className={"round-button-container " + className} onClick={handleClick}>
        <IconArrowLeft size={1.5} />
      </div>
    </TooltipContainer>
  );
}

export default BackButton;

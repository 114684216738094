import { analyticsLogEvent } from 'appFirebase/helperApi';
import LoadingIndicator from 'components/LoadingIndicator';
// Third party.
import React, { Suspense } from 'react';

function LazyContainer(props) {
  const { importContent } = props;

  let Content;
  try {
    Content = React.lazy(importContent);
  } catch(error) {
    analyticsLogEvent('exception', { description: ('[LazyContainer]: ' + error.toString()) });
    console.log('Failed lazy loading content: ', error);
  }
  if (!Content) {
    return null;
  }

  return (
    <Suspense fallback={<LoadingIndicator delayInSeconds={1} className="mt-5" />}>
      <Content />
    </Suspense>
  );
}

export default LazyContainer;

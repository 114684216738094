import {
  feedCategoriesToSortedArray,
} from 'misc/appHelperFunctions';
// Third party.
import React from 'react';
import Button from 'react-bootstrap/Button';

function ClearAllButton(props) {
  const {
    categoryNames=[],
    setSelectedCategories,
  } = props;

  if (!categoryNames.length) {
    return null;
  }

  function handleClick() {
    if (setSelectedCategories) {
      setSelectedCategories({});
    }
  }

  return (
    <span
      className="text-nowrap border border-primary rounded text-primary px-1 ml-1 cursor-pointer"
      onClick={handleClick}
    >
      Clear All
    </span>
  );
}

function FooterButtons(props) {
  const {
    selectedCategories,
    setSelectedCategories,
    handleClickDone,
    // Redux states.
    feedPreference,
    // Redux actions.
    actionPersistSetFeedPreference,
  } = props;

  function handleClickCancel() {
    const nextFeedPreference = {
      ...feedPreference,
      show: false,
    };
    actionPersistSetFeedPreference(nextFeedPreference);
  }

  function hanleClickDone() {
    if (handleClickDone) {
      handleClickDone();
    }
  }


  let selectedCategoryNames = "All Categories";
  const categoryNames = feedCategoriesToSortedArray(selectedCategories);
  if (categoryNames.length) {
    selectedCategoryNames = categoryNames.join(', ');
  }

  return (
    <div className="px-3 fixed-bottom bg-white border-top mobile-py-2 desktop-py-4">
      <p className="text-center">
        Selected: { selectedCategoryNames } <ClearAllButton categoryNames={categoryNames} setSelectedCategories={setSelectedCategories}/>
      </p>
      <div className="d-flex justify-content-center mw-sm mx-auto">
        <Button
          block
          variant="outline-primary"
          className="mr-3 mt-0"
          onClick={handleClickCancel}
        >
          Cancel
        </Button>
        <Button
          block
          className="mt-0"
          onClick={hanleClickDone}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default FooterButtons;

// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function Account(props) {
  const { currentUser } = props;

  if (!currentUser) {
    return null;
  }

  const email = currentUser.email;

  return (
    <Container className="page-container py-4 mw-sm">
      <div>
        <h5>Email address</h5>
        <p>{ email }</p>
      </div>

      <div className="border p-3 mt-4 rounded-2">
        <p className="text-black-50 m-0">
          Your information is only visible to you and will not be shared with others.
        </p>
      </div>

      <div className="h-100px"/>
    </Container>
  );
}

export default Account;

import {
  adminCloseModals,
  adminApiDeleteFeedVideo,
  adminApiUpdateVideoUrlInfo,
} from 'appRedux/actions';
import DeleteVideo from './DeleteVideo';
import EditBaseClickCnt from './EditBaseClickCnt';
// Third party.
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

function ModalBody(props) {
  const {
    // Redux states.
    admin,
  } = props;


  if (admin.modalDeleteVideo.show) {
    return (<DeleteVideo {...props} />);
  }

  if (admin.modalEditBaseClickCnt.show) {
    return (<EditBaseClickCnt {...props} />);
  }

  return null;
}

function AdminModal(props) {
  const {
    // Redux states.
    admin,
    // Redux actions.
    actionAdminCloseModals,
  } = props;
  const show = admin.modalDeleteVideo.show || admin.modalEditBaseClickCnt.show;

  return (
    <Modal
      size="lg"
      show={show}
      onHide={actionAdminCloseModals}
      aria-labelledby="video-player-modal"
      centered
      scrollable={true}
    >
      <ModalBody {...props} />
    </Modal>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  actionAdminCloseModals: () => {
    dispatch(adminCloseModals());
  },
  actionAdminApiDeleteFeedVideo: (urlInfo) => {
    dispatch(adminApiDeleteFeedVideo(urlInfo));
  },
  actionAdminApiUpdateVideoUrlInfo: (urlInfo) => {
    dispatch(adminApiUpdateVideoUrlInfo(urlInfo));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminModal);

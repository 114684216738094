const positions = (state = {
  collection: {
    scrollY: 0,
  },
  feed: {
    scrollY: 0,
  },
  search: {
    scrollY: 0,
  },
}, action) => {
  switch (action.type) {
    case 'POSITIONS_UPDATE': {
      const { target, scrollY } = action;

      return {
        ...state,
        [target]: {
          scrollY,
        },
      };
    }
    default:
      return state
  }
};

export default positions;

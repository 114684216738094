import { analyticsLogEvent } from 'appFirebase/helperApi';
import { P_LOGIN } from 'misc/appConstants';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

function LogInButton(props) {
  const {
    clickEventName="click_log_in_button",
    onClick,
  } = props;

  function handleClick() {
    analyticsLogEvent(clickEventName);

    if (onClick) {
      onClick();
    }
  }

  const cleanProps = {
    ...props,
  }
  // Prevent warning:
  // React does not recognize the `clickEventName` prop on a DOM element.
  delete cleanProps.clickEventName;

  if (!onClick) {
    cleanProps.as = Link;
    cleanProps.to = P_LOGIN;
  }

  return (
    <Button
      {...cleanProps}
      variant="outline-primary"
      onClick={handleClick}
    >
      Log In
    </Button>
  );
}

export default LogInButton;
import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  isTouchDevice,
} from 'misc/appHelperFunctions';
// Third party.
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function TooltipContent(props) {
  const { content, hoverEventName="tooltip_displayed" } = props;

  analyticsLogEvent(hoverEventName);

  return content || "";
}

function TooltipContainer(props) {
  // Tooltip will show up whenever children is tapped on touch device, which
  // is undesired user experience. So Tooltip is explicitly disabled on touch
  // devices.
  if (isTouchDevice()) {
    return props.children;
  }

  const {
    children,
    placement="bottom",
    delay={ show: 1000, hide: 100 },
  } = props;

  return (
    <OverlayTrigger
      placement={placement}
      delay={delay}
      overlay={<Tooltip><TooltipContent {...props} /></Tooltip>}
    >
      { children }
    </OverlayTrigger>
  );
}

export default TooltipContainer;

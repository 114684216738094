import {
  APP_NAME,
} from 'misc/appConstants';
// Third party.
import React from 'react';

function CardWelcome() {
  return (
    <div className="mw-sm mx-auto">
      <h2 className="text-dark py-3">
        Welcome to { APP_NAME }
      </h2>

      <h5 className="text-dark py-4 text-display-line-break">
        { APP_NAME } is a place for you to save any porn video links, and get related recommendations of the most popular videos from all over the internet.
      </h5>

      <img
        src={'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2FSave%20content%20from%20any%20where.png?alt=media&token=3787878b-bb24-405c-a395-0402daf7deba'}
        alt="save videos to get recommendations"
        className="w-100 h-100 img-cover"
      />

      <div className="mobile-h-100px desktop-my-4"/>
    </div>
  );
}

export default CardWelcome;

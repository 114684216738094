import {
  getSortedPrivateCollections,
} from 'misc/appHelperFunctions';
import {
  IconX,
} from 'components/icons';
import Confirmation from './Confirmation';
import ChoosePlayList from './ChoosePlayList';
import CreatePlayList from './CreatePlayList';
// Third party.
import React, { useEffect, useState } from 'react';

function SaveItem(props) {
  const [confirmation, setConfirmation] = useState({
    show: false,
    collectionInfo: null,
  });

  const {
    // Redux state.
    collection,
  } = props;
  // Sort by collection name alphabetically.
  const sortedPrivateCollections =
      getSortedPrivateCollections(collection.privateCollections);
  const hasPrivateCollections = sortedPrivateCollections.length;

  // Hide create playlist and show choose playlist by default.
  const [showCreatePlaylist, setShowCreatePlaylist] = useState(false);

  // Change visibility of choose / create playlist whenever
  // hasPrivateCollections changes. It needs to be checked on component mount,
  // and when user log in / sign up.
  useEffect(() => {
    setShowCreatePlaylist(!hasPrivateCollections);
  }, [hasPrivateCollections, setShowCreatePlaylist]);

  const {
    closeModal,
    // Redux state.
    modal,
    // Redux actions.
    actionApiAddCollectionItem,
  } = props;

  const { urlInfo } = modal.saveItem;
  const thumbnailUrl = ((urlInfo.backupUrlInfo || {}).thumbnail || {}).url
      || (urlInfo.thumbnail || {}).url;

  const hideheader = confirmation.show;
  const hideChoosePlayList = showCreatePlaylist || confirmation.show;
  const hideCreatePlayList = !showCreatePlaylist || confirmation.show;

  function handleClickClose() {
    if (showCreatePlaylist && hasPrivateCollections) {
      setShowCreatePlaylist(false);
    } else {
      if (closeModal) {
        closeModal();
      }
    }
  }

  function handleSaveCollectionItem(collectionInfo) {
    if (actionApiAddCollectionItem &&
        urlInfo && urlInfo.url &&
        collectionInfo && collectionInfo.name
    ) {
      actionApiAddCollectionItem(/* urlInfo */urlInfo, collectionInfo);

      setConfirmation({
        show: true,
        collectionInfo,
      });

      if (closeModal) {
        closeModal(/* delayInSec */1);
      }
    } else if (closeModal) {
      closeModal();
    }
  }

  return (
    <div className="px-3 pb-3">
      {
        !hideheader &&
        <div className="d-flex align-items-start justify-content-between my-1">
          {/* Dummy div to center image */}
          <div className="px-3" />

          <img
            src={thumbnailUrl}
            alt={'No poster'}
            className="my-2 bg-secondary img-cover rounded-2"
            style={{ height: '5rem', width: '10rem' }}
          />

          <div className="round-button-container mr-n2" onClick={handleClickClose}>
            <IconX size={1.5} />
          </div>
        </div>
      }

      <ChoosePlayList
        {...props}
        hidden={hideChoosePlayList}
        setShowCreatePlaylist={setShowCreatePlaylist}
        sortedPrivateCollections={sortedPrivateCollections}
        handleSaveCollectionItem={handleSaveCollectionItem}
      />

      <CreatePlayList
        {...props}
        hidden={hideCreatePlayList}
        setShowCreatePlaylist={setShowCreatePlaylist}
        handleSaveCollectionItem={handleSaveCollectionItem}
      />

      <Confirmation
        confirmation={confirmation}
      />
    </div>
  );
}

export default SaveItem;
import { store, persistor } from 'appRedux/store';
import { PersistGate } from 'redux-persist/integration/react'
import {
  combinedClearCollection,
} from 'appRedux/actions';
import {
  APP_NAME,
  P_COLLECTION,
  P_CONTACT,
  P_HELP,
  P_HOME,
  P_PORNSTARS,
  P_PRIVACY,
  P_SITES,
  P_TERMS,
  P_VMARK,
  // Page titles.
  PAGE_TITLES,
} from 'misc/appConstants';
import {
  logScreenView,
} from 'misc/appHelperFunctions';
import { useAuthState } from 'components/auth/authHelperFunctions';
import AppNavbar from 'components/appNavbar';
import LoadingIndicator from 'components/LoadingIndicator';
import AuthRouter from 'AuthRouter';
import Collection from 'routes/collection';
import Contact from 'routes/contact';
import Help from 'routes/help';
import Pornstars from 'routes/pornstars';
import Privacy from 'routes/privacy';
import Sites from 'routes/sites';
import Terms from 'routes/terms';
import Vmark from 'routes/vmark';
// Third party.
import React from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  useAuthState();

  const { AppTopNavbar, AppBottomNavbar } = AppNavbar;

  return (
    <Provider store={store}>
      <PersistGate
        loading={<LoadingIndicator delayInSeconds={1} className="mt-5" />}
        persistor={persistor}
      >
        <BrowserRouter>
          <AppTopNavbar />
          <AppBottomNavbar />
          <div className="app-body-container">
            <Switch>
              <Route path={P_VMARK} render={() => {
                logScreenView(P_VMARK);
                document.title = PAGE_TITLES[P_VMARK] || APP_NAME;
                return (<Vmark />);
              }} />
              <Route path={P_COLLECTION} render={() => {
                logScreenView(P_COLLECTION);
                document.title = PAGE_TITLES[P_COLLECTION] || APP_NAME;
                return (<Collection />)
              }}/>
              <Route path={P_HOME} render={() => {
                store.dispatch(combinedClearCollection());
                return (
                  <Switch>
                    <Route exact path={P_PORNSTARS} render={() => {
                      logScreenView(P_PORNSTARS);
                      document.title = PAGE_TITLES[P_PORNSTARS] || APP_NAME;
                      return (<Pornstars />);
                    }}/>
                    <Route exact path={P_SITES} render={() => {
                      logScreenView(P_SITES);
                      document.title = PAGE_TITLES[P_SITES] || APP_NAME;
                      return (<Sites />);
                    }}/>
                    <Route exact path={P_PRIVACY} render={() => {
                      logScreenView(P_PRIVACY);
                      document.title = PAGE_TITLES[P_PRIVACY] || APP_NAME;
                      return (<Privacy />);
                    }}/>
                    <Route exact path={P_TERMS} render={() => {
                      logScreenView(P_TERMS);
                      document.title = PAGE_TITLES[P_TERMS] || APP_NAME;
                      return (<Terms />);
                    }} />
                    <Route exact path={P_HELP} render={() => {
                      logScreenView(P_HELP);
                      document.title = PAGE_TITLES[P_HELP] || APP_NAME;
                      return (<Help />);
                    }} />
                    <Route exact path={P_CONTACT} render={() => {
                      logScreenView(P_CONTACT);
                      document.title = PAGE_TITLES[P_CONTACT] || APP_NAME;
                      return (<Contact />);
                    }} />
                    <Route path={P_HOME} component={AuthRouter} />
                  </Switch>
                );
              }} />
            </Switch>
          </div>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;

export const playlistClearIsFetching = () => {
  return  (dispatch, getState) => {
    dispatch(playlistSetIsFetching(false));
    dispatch(playlistSetIsLoadingMore(false));
  };
};

export const playlistSetIsFetching = (isFetching) => ({
  type: 'PLAYLIST_SET_IS_FETCHING',
  isFetching,
});

export const playlistSetIsLoadingMore = (isLoadingMore) => ({
  type: 'PLAYLIST_SET_IS_LOADING_MORE',
  isLoadingMore,
});

export const playlistSetCurrentCollectionId = (collectionId) => ({
  type: 'PLAYLIST_SET_CURRENT_COLLECTION_ID',
  collectionId,
});

export const playlistSetCollectionVideos = (collectionId, videos) => ({
  type: 'PLAYLIST_SET_COLLECTION_VIDEOS',
  collectionId,
  videos,
});

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  delay,
  getAbsoluteUrl,
} from 'misc/appHelperFunctions';
import {
  IconPlus,
  IconSearch,
} from 'components/icons';
import ToastAutoHide from 'components/toastAutoHide/ToastAutoHide';
import TooltipContainer from 'components/TooltipContainer';
import InputBox from 'components/InputBox';
import EditCollectionButton from './EditCollectionButton';
// Third party.
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import isUrl from 'is-url';

const _SAVE_PLACEHOLDER = 'Save a URL https://...';
const _BUTTON_TITLE_SAVE = 'Save';
const _SEARCH_PLACEHOLDER = 'Search playlist...';
const _BUTTON_TITLE_SEARCH = 'Search';
const _ENTER_VALID_URL = 'Please enter a valid URL';

function SaveBox(props) {
  const [initialValue, setInitialValue] = useState('');

  useEffect(() => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      // Need a bit delay to let the input bar show up in Safari first.
      delay(0.01).then(() => {
        navigator.clipboard.readText()
            .then((text) => {
              if (isUrl(text)) {
                setInitialValue(text);
              }
            })
            .catch((error) => {
              // maybe user didn't grant access to read from clipboard
              console.log('Failed reading clipboard', error);
            });
      });
    }
  }, []);

  const {
    currentCollectionInfo,
    actionApiAddCollectionItem,
    actionNavbarUpdateSaveBox,
    toastRef,
  } = props;

  function cancelSave() {
    actionNavbarUpdateSaveBox(false);
  }

  function handleSave(urlString) {
    cancelSave();
    if (!urlString || !urlString.length) {
      return;
    }
    const abosluteUrlString = getAbsoluteUrl(urlString);
    if (abosluteUrlString) {
      const urlInfo = {
        url: abosluteUrlString,
      };
      const collectionInfo = {
        id: (currentCollectionInfo || {}).id,
      };
      actionApiAddCollectionItem(urlInfo, collectionInfo);
      analyticsLogEvent('save_valid_url');
    } else {
      toastRef.current.show(_ENTER_VALID_URL);
      analyticsLogEvent('save_invalid_url');
    }
  }

  return (
    <InputBox
      initialValue={initialValue}
      autoFocus={true}
      placeholder={_SAVE_PLACEHOLDER}
      buttonTitle={_BUTTON_TITLE_SAVE}
      cancelOnClick={cancelSave}
      submitOnClick={handleSave}
    />
  );
}

function SearchBox(props) {
  const {
    navbar,
    collectionFilter,
    updateCollectionFilter,
  } = props;

  function cancelSearch() {
    // Clear collection filter;
    updateCollectionFilter(null);
  }

  function handleSearch(searchString) {
    const nextFilter = {
      ...collectionFilter,
      search: searchString || '',
    }
    updateCollectionFilter(nextFilter);

    if (searchString) {
      analyticsLogEvent('search', { search_term: searchString });
      if (((collectionFilter || {}).tags || []).length) {
        analyticsLogEvent('search_w_selected_tags');
      }
    }
  }

  const initialValue = (collectionFilter || {}).search;

  return (
    <InputBox
      initialValue={initialValue}
      autoFocus={navbar.searchBox.autoFocus}
      placeholder={_SEARCH_PLACEHOLDER}
      buttonTitle={_BUTTON_TITLE_SEARCH}
      cancelOnClick={cancelSearch}
      submitOnClick={handleSearch}
    />
  );
}

function ToolbarBody(props) {
  const {
    navbar,
    currentCollectionInfo,
    actionNavbarUpdateSaveBox,
    updatePrivateCollectionInfo,
    // Redux actions.
    actionNavbarUpdateSearchBox,
  } = props;

  function enableSave() {
    actionNavbarUpdateSaveBox(true);
  }

  function enableSearch() {
    // Auto focus on search box if it is enabled by manually
    // clicking the search button.
    actionNavbarUpdateSearchBox(true, true);
  }

  function updateCollectionInfo(info) {
    if (updatePrivateCollectionInfo) {
      updatePrivateCollectionInfo(info);
    }
  }

  if (navbar.saveBox.enabled) {
    return (<SaveBox {...props} />);
  } else if (navbar.searchBox.enabled) {
    return (<SearchBox {...props} />);
  }

  return (
    <div className="mr-n2">
      <TooltipContainer content="Save a URL" hoverEventName="collection_hover_save_a_url_button">
        <Button variant="light" className="ml-1 round-button" onClick={enableSave}>
          <IconPlus size={1.5} />
        </Button>
      </TooltipContainer>

      <TooltipContainer content="Search" hoverEventName="collection_hover_search_button">
        <Button variant="light" className="ml-1 round-button" onClick={enableSearch}>
          <IconSearch size={1.5} />
        </Button>
      </TooltipContainer>

      <EditCollectionButton
        currentCollectionInfo={currentCollectionInfo}
        updateCollectionInfo={updateCollectionInfo}
      />
    </div>
  );
}

function ToolbarCollection(props) {
  const toastRef = useRef(null);

  return (
    <Container
      className="d-flex justify-content-end mw-md min-h-round-button-size"
    >
      <ToolbarBody {...props} toastRef={toastRef} />

      <ToastAutoHide
        ref={toastRef}
        isWarning
      />
    </Container>
  );
}

export default ToolbarCollection;

import {
  DEFAULT_SEARCH_INPUT,
} from 'appRedux/reducers/stateConstants';

export const searchClearIsFetching = () => {
  return  (dispatch, getState) => {
    dispatch(searchSetIsFetching(false));
    dispatch(searchSetIsLoadingMore(false));
  };
};

export const searchReset = () => {
  return (dispatch, getState) => {
    const nextCleanSearchInput = {
      ...DEFAULT_SEARCH_INPUT,
    };
    // Keep availableKeywords and externalKeywordsList, which is loaded
    // asynchronously once per page refresh.
    delete nextCleanSearchInput.availableKeywords;
    delete nextCleanSearchInput.externalKeywordsList;
    // Reset search input.
    dispatch(searchUpdateSearchInput(nextCleanSearchInput));
    // Reset search results.
    dispatch(searchSetVideos([]));
  };
};

export const searchSetIsFetching = (isFetching) => ({
  type: 'SEARCH_SET_IS_FETCHING',
  isFetching,
});

export const searchSetIsLoadingMore = (isLoadingMore) => ({
  type: 'SEARCH_SET_IS_LOADING_MORE',
  isLoadingMore,
});

export const searchSetVideos = (videos) => ({
  type: 'SEARCH_SET_VIDEOS',
  videos,
});

export const searchUpdateSearchInput = (searchInput) => ({
  type: 'SEARCH_UPDATE_SEARCH_INPUT',
  searchInput,
});

export const searchSetIsFetchingKeywordThumbnails = (isFetching) => ({
  type: 'SEARCH_SET_IS_FETCHING_KEYWORD_THUMBNAILS',
  isFetching,
});

export const searchSetKeywordThumbnails = (thumbnails) => ({
  type: 'SEARCH_SET_KEYWORD_THUMBNAILS',
  thumbnails,
});

import {
  usePlaylistQuery,
} from 'misc/queryHelpers';

function ToolbarPlaylist(props) {
  const {
    // Redux actions.
    actionApiFetchCollectionVidoes,
  } = props;

  usePlaylistQuery(({ collectionId }) => {
    actionApiFetchCollectionVidoes(collectionId);
  });

  return null;
}

export default ToolbarPlaylist;

import {
  MAX_TAG_LENGTH,
} from 'misc/appConstants';
import {
  isTouchDevice,
} from 'misc/appHelperFunctions';
// Third party.
import React, { forwardRef, useEffect, useRef, useState } from 'react';

const _INPUT_NAME = 'autosizeInput';
const _MIN_INPUT_MIN_WIDTH = 10;

// Inspired by github.com/JedWatson/react-input-autosize
function AutosizeInput(props, ref) {
  const sizerRef = useRef(null);
  const [inputMinWidth, setInputMinWidth] = useState(_MIN_INPUT_MIN_WIDTH);

  const { value } = props;

  useEffect(() => {
    // Make scrolling on focus (in modal) work on mobile.
    //
    // It is a hack for now to prevent auto focus on mobile, and user
    // tapping on mobile will trigger scrolling on focus successfully.
    //
    // Check if on mobile: https://stackoverflow.com/a/17089547
    if(!isTouchDevice()) {
      ref.current.focus();
    }
  }, [ref]);

  useEffect(() => {
    const nextInputMinWidth = _MIN_INPUT_MIN_WIDTH +
        ((sizerRef && sizerRef.current) ? sizerRef.current.scrollWidth : 0);
    setInputMinWidth(nextInputMinWidth);
  }, [sizerRef, value]);

  return (
    <div className="d-flex flex-stretch" style={{ minWidth: inputMinWidth }}>
      <input
        {...props}
        ref={ref}
        type="text"
        name={_INPUT_NAME}
        autoComplete="off"
        className="border-0 outline-none text-dark flex-stretch min-w-0 input-no-clear"
        maxLength={MAX_TAG_LENGTH}
      />
      <div ref={sizerRef} className="autosize-input-sizer">
        {value}
      </div>
    </div>
  );
};

export default forwardRef(AutosizeInput);

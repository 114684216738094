import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_HOME,
  P_PLAYLIST,
  P_SEARCH,
} from 'misc/appConstants';
import {
  searchInputToQuery,
} from 'misc/queryHelpers';
import {
  IconLogo,
} from 'components/icons';
// Third party.
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function HomeTab(props) {
  const {
    currentPathName,
    classNames,
    // Redux states.
    searchInput,
    // Redux actions.
    actionApiSearchVideos,
    actionSearchReset,
  } = props;

  const {
    keyword:searchKeyword,
    filters:searchFilters,
    forceSearchOn,
  } = searchInput;

  // Convert searchKeyword and searchFilters to a single string so that hook
  // can detect the changes.
  const searchQueryString = searchInputToQuery(searchInput);

  useEffect(() => {
    // Do not proceed if the change is not intended for new search results.
    if (!searchKeyword && !forceSearchOn) {
      return;
    }
    window.scrollTo(0, 0);
    actionApiSearchVideos(/*isRefresh*/true);
  }, [searchQueryString, searchKeyword, searchFilters, forceSearchOn, actionApiSearchVideos]);

  const isTargetPageCurrent =
      (currentPathName === P_HOME) || currentPathName.includes(P_PLAYLIST);

  const {
    inactiveIconClassName,
    activeIconClassName,
    inactiveTextClassName,
    activeTextClassName,
  } = classNames;

  const iconClassName =
      isTargetPageCurrent ? activeIconClassName : inactiveIconClassName;
  const textClassName =
      isTargetPageCurrent ? activeTextClassName : inactiveTextClassName;

  function handleClick() {
    analyticsLogEvent("bottom_navbar-click-home");

    // If already on the target page, scroll to top and reset search input and
    // results.
    if (isTargetPageCurrent) {
      window.scrollTo(0, 0);
      actionSearchReset();
    }
  }

  // Resume to the last search results if search keyword exists.
  const targetLink = (isTargetPageCurrent || !searchInput.keyword)
      ? P_HOME
      : (P_SEARCH + searchQueryString);

  return (
    <div className="flex-grow-1 text-center">
      <Link
        to={targetLink}
        className="no-hover-effect"
        onClick={handleClick}
      >
        <IconLogo size={1.2} className={iconClassName} />
        <p className={textClassName}>
          Home
        </p>
      </Link>
    </div>
  );
}

export default HomeTab;

import {
  apiGetFeedVideos,
  persistSetFeedPreference,
  recommendationSetSelectedCategory,
} from 'appRedux/actions';
import {
  isAnyModalActive,
  numberWithCommas,
} from 'misc/appHelperFunctions';
import VideosGrid from 'components/videosGrid';
import useScrollToLastPosition from 'misc/useScrollToLastPosition';
import Onboarding from './onboarding';
import FeedFilters from './FeedFilters';
import RecommendFeedFilters from './RecommendFeedFilters';
import FeedPreference from './feedPreference';
// Third party.
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';

const _ADD_VIDEO_FREQ = 15; // // Add one video every 5 minutes.

function VideosGridHeader(props) {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const date = now.getDate();
  const day = now.getDay();
  const addedCnt = 200 + (hours * 60 / _ADD_VIDEO_FREQ) +
      Math.floor(minutes / _ADD_VIDEO_FREQ) + date + day * 6;
  const prettyAddedCnt = numberWithCommas(addedCnt);

  return (
    <Container className="px-0 pb-3 mw-md">
      <div className="text-center">
        <div className="mb-2">
          <h6 className="text-secondary">Today added: { prettyAddedCnt } videos</h6>
        </div>

        <h6 className="mb-0 pt-2 text-dark">
          Recommended For You
        </h6>
      </div>
    </Container>
  );
}

function Feed(props) {
  useScrollToLastPosition({ positionTarget: 'feed' });

  const {
    // Redux states.
    modal,
    recommendation,
    // Redux actions.
    actionApiGetFeedVideos,
  } = props;

  const {
    isFetchingFeedVideos: isFetchingVideos,
    isLoadingMoreFeedVideos: isLoadingMoreVideos,
    feedVideos: videos,
  } = recommendation;

  const hasFeedVideos = videos.length > 0;

  useEffect(() => {
    // Only fetch feed videos on mount for the very first time when there's no
    // feed videos fetched at all.
    if (!hasFeedVideos) {
      window.scrollTo(0, 0);
      actionApiGetFeedVideos(/*isRefresh*/true);
    }
  }, [hasFeedVideos, actionApiGetFeedVideos]);

  const {
    // Redux states.
    feedPreference,
    isAdmin,
    // Redux actions.
    actionPersistSetFeedPreference,
  } = props;

  function onEndReached() {
    actionApiGetFeedVideos(/*isRefresh*/false);
  }

  if (props.user.isOnboarding) {
    return (
      <Onboarding />
    );
  }

  const videosGridContainerClassName = feedPreference.show ? "overflow-hidden" : "";
  const titleFetching = "We are adding new videos for you";
  // Prevents the page from scrolling when any modal is active. This is
  // especially necessary when video is playing in full screen mode, and user
  // drags the video progress bar on mobile device horizontally.
  const containerClassName = isAnyModalActive(modal) ? "overflow-hidden" : "";

  return (
    <div className={"page-container " + containerClassName}>
      <div className={"h-100 " + videosGridContainerClassName}>
        <FeedFilters {...props} />
        <RecommendFeedFilters {...props} />
        <VideosGrid
          eventPrefix="feed"
          isFetchingVideos={isFetchingVideos}
          isLoadingMoreVideos={isLoadingMoreVideos}
          videos={videos}
          titleFetching={titleFetching}
          titleComponent={VideosGridHeader}
          footerTitle={null}
          className="mobile-mt-2 desktop-mt-3"
          onEndReached={onEndReached}
          shouldUpdateClickCnt
          enableAdminButton={isAdmin}
        />
      </div>

      {
        feedPreference.show &&
        <FeedPreference
          feedPreference={feedPreference}
          actionPersistSetFeedPreference={actionPersistSetFeedPreference}
        />
      }
    </div>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  isAdmin: state.admin.isAdmin,
  feedPreference: state.persist.feedPreference,
  modal: state.modal,
  recommendation: state.recommendation,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  actionApiGetFeedVideos: (isRefresh) => {
    dispatch(apiGetFeedVideos(isRefresh));
  },
  actionPersistSetFeedPreference: (feedPreference) => {
    dispatch(persistSetFeedPreference(feedPreference));
  },
  actionRecommendationSetSelectedCategory: (category) => {
    dispatch(recommendationSetSelectedCategory(category));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Feed);

// Third party.
import React from 'react';

export function IconArrowDown(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 256 256" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093"/>
    </svg>
  );
}

export function IconArrowLeft(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="nonzero" d="M6.414 11h14.639c1.263 0 1.263 2 0 2H6.414l6.293 6.293c.943.943-.471 2.357-1.414 1.414l-8-8A.945.945 0 0 1 3.006 12c0-.285.095-.52.287-.707l8-8c.943-.943 2.357.471 1.414 1.414L6.414 11z"></path>
    </svg>
  );
}

export function IconArrowRight(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 451.846 451.847" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="nonzero" d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744 L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284 c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
    </svg>
  );
}

export function IconArrowUpRight(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.9283,1 C3.6273,1 2.5713,2.054 2.5713,3.357 C2.5713,4.66 3.6273,5.714 4.9283,5.714 L14.9523,5.714 L1.6893,18.976 C0.7703,19.896 0.7703,21.389 1.6893,22.31 C2.1503,22.771 2.7533,23 3.3573,23 C3.9603,23 4.5633,22.771 5.0243,22.31 L18.2853,9.047 L18.2853,19.071 C18.2853,20.374 19.3413,21.429 20.6433,21.429 C21.9443,21.429 23.0003,20.374 23.0003,19.071 L23.0003,1 L4.9283,1 Z"></path>
    </svg>
  );
}

export function IconArrowUp(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 256 256" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <polygon points="128,48.907 0,176.907 30.187,207.093 128,109.28 225.813,207.093 256,176.907"/>
    </svg>
  );
}

export function IconBalloons(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 512.005 512.005" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect x="202.598" y="419.167" transform="matrix(0.4472 -0.8944 0.8944 0.4472 -213.3717 501.7335)" fill="#CFD8DC" width="193.254" height="8.643"/>
        <rect x="199.815" y="306.573" transform="matrix(0.8779 -0.4788 0.4788 0.8779 -173.7213 148.388)" fill="#CFD8DC" width="8.634" height="216.585"/>
        <rect x="251.683" y="285.22" fill="#CFD8DC" width="8.643" height="224.72"/>
      </g>
      <circle fill="#35D4B6" cx="377.003" cy="190.15" r="129.64"/>
      <circle fill="#FFCC02" cx="135.003" cy="181.5" r="129.64"/>
      <path fill="#E1524A" d="M230.076,302.503l17.398-43.465l5.964,0.112c0.588,0,1.158,0.052,1.729,0.095h1.729
        c0.57,0,1.141-0.086,1.729-0.095l5.964-0.112l17.346,43.465H230.076z"/>
      <circle fill="#F96B47" cx="256.003" cy="129.64" r="129.64"/>
      <path fill="#FFCC02" d="M135.003,311.362l7.865-0.467c3.889-0.242,7.779-0.691,11.59-1.279l4.321-0.648l28.081,28.107
        l-53.301,17.649L135.003,311.362z"/>
      <path fill="#35D4B6" d="M307.863,345.718l31.469-31.478l4.788,1.253c2.826,0.743,5.687,1.392,8.565,1.936l7.027,1.331v44.243
        L307.863,345.718z"/>
    </svg>
  );
}

export function IconCheck(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M 22.59375 3.5 L 8.0625 18.1875 L 1.40625 11.5625 L 0 13 L 8.0625 21 L 24 4.9375 Z"/>
    </svg>
  );
}

export function IconCopy(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 469.333 469.333" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M330.667,0h-256C51.093,0,32,19.093,32,42.667v298.667h42.667V42.667h256V0z"/>
      <path d="M394.667,85.333H160c-23.573,0-42.667,19.093-42.667,42.667v298.667c0,23.573,19.093,42.667,42.667,42.667h234.667 c23.573,0,42.667-19.093,42.667-42.667V128C437.333,104.427,418.24,85.333,394.667,85.333z M394.667,426.667H160V128h234.667 V426.667z"/>
    </svg>
  );
}

export function IconExplore(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 612 612" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M306,0C137.272,0,0,137.27,0,305.997C0,474.727,137.272,612,306,612s306-137.273,306-306.003C612,137.27,474.73,0,306,0z
         M306,576.657c-149.24,0-270.657-121.418-270.657-270.66C35.343,156.759,156.76,35.343,306,35.343
        s270.657,121.416,270.657,270.655C576.657,455.24,455.24,576.657,306,576.657z"/>
      <path d="M474.47,144.734c-4.623-8.599-15.336-11.818-23.933-7.197L251.64,244.485c-3.053,1.642-5.556,4.144-7.197,7.198
        L137.538,450.536c-3.782,7.035-2.309,15.489,3.063,20.861c1.191,1.193,2.574,2.232,4.137,3.07
        c5.313,2.857,11.436,2.71,16.428,0.155l0.003,0.004l0.075-0.04c0.072-0.037,0.143-0.077,0.214-0.115l198.995-106.87
        c3.054-1.64,5.556-4.142,7.2-7.197l106.477-198.361c0.233-0.401,0.455-0.806,0.655-1.222l0.144-0.267l-0.018-0.006
        C477.174,155.671,477.211,149.833,474.47,144.734z M196.403,415.684l67.933-126.361l58.418,58.42L196.403,415.684z
         M347.76,322.764l-58.421-58.421l126.351-67.94"/>
    </svg>
  );
}

export function IconEye(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 511.999 511.999" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <path d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035
            c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201
            C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418
            c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418
            C447.361,287.923,358.746,385.406,255.997,385.406z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275
            s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516
            s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"/>
        </g>
      </g>
    </svg>
  );
}

export function IconHeart(props) {
  const size = (props.size || 1) + "em";
  const className = (props.className || "icon-black-50");
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 477.534 477.534" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909
            l-8.431-8.909C181.284,5.762,98.662,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778
            c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654
            c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z M413.787,234.226h-0.017
            L238.802,418.768L63.818,234.226c-39.78-42.916-39.78-109.233,0-152.149c36.125-39.154,97.152-41.609,136.306-5.484
            c1.901,1.754,3.73,3.583,5.484,5.484l20.804,21.948c6.856,6.812,17.925,6.812,24.781,0l20.804-21.931
            c36.125-39.154,97.152-41.609,136.306-5.484c1.901,1.754,3.73,3.583,5.484,5.484C453.913,125.078,454.207,191.516,413.787,234.226
            z"/>
        </g>
    </svg>
  );
}

export function IconHeartFill(props) {
  const size = (props.size || 1) + "em";
  const className = (props.className || "icon-black-50");
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 60 53" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="m59 17.108c0-8.896-7.164-16.108-16-16.108-4.405 0-8.386 1.798-11.276 4.7-.96.964-2.487.964-3.447 0-2.891-2.902-6.872-4.7-11.277-4.7-8.836 0-16 7.212-16 16.108 0 4.455 1.797 8.487 4.701 11.404l22.629 22.782c.934.94 2.456.94 3.389-.001l5.413-5.455 14.628-14.743 2.578-2.598-.012-.013c2.888-2.913 4.674-6.934 4.674-11.376z" />
    </svg>
  );
}

export function IconLoading(props) {
  const size = (props.size || 1) + "em";
  const className = props.className;
  const pathClassName = props.pathClassName || "icon-black-50";

  return (
    <svg className={className} width={size} height={size} viewBox="0 0 202 202" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-727.000000, -63.000000)">
          <g transform="translate(728.000000, 64.000000)">
            <circle stroke="#FFFFFF" strokeWidth="0" cx="100" cy="100" r="100"></circle>
            <path className={pathClassName} d="M200,100 C200,155.228475 155.228475,200 100,200 C44.771525,200 0,155.228475 0,100 C0,73.7385168 10.1231177,49.8414144 26.6800255,31.9980205 C31.2207458,36.8955177 36.2453686,41.3337403 41.6777942,45.2405474 C28.2355231,59.5511458 20,78.8138182 20,100 C20,144.18278 55.81722,180 100,180 C144.18278,180 180,144.18278 180,100 C180,78.8138182 171.764477,59.5511458 158.320118,45.2386705 C163.754631,41.3337403 168.779254,36.8955177 173.319975,32.0019795 C189.876882,49.8414144 200,73.7385168 200,100 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function IconLock(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";

  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9069372 8 4 8.9069372 4 10 L 4 20 C 4 21.093063 4.9069372 22 6 22 L 18 22 C 19.093063 22 20 21.093063 20 20 L 20 10 C 20 8.9069372 19.093063 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 6 10 L 18 10 L 18 20 L 6 20 L 6 10 z M 12 13 C 10.9 13 10 13.9 10 15 C 10 16.1 10.9 17 12 17 C 13.1 17 14 16.1 14 15 C 14 13.9 13.1 13 12 13 z"/>
    </svg>
  );
}


export function IconLogo(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-primary";

  // #4785EC is primary color.
  // #adb5bd is black-50 color in dark mode.
  let fillColor = '#4785EC';
  if (className.includes('icon-black-50')) {
    fillColor = '#adb5bd';
  }

  return (
    <svg className={className} width={size} height={size} viewBox="0 0 200 200" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" fill={fillColor} strokeWidth="1" transform="translate(-359.000000, -203.000000)" d="M543,203 C551.836556,203 559,210.163444 559,219 L559,403 L461.948194,364.526191 C460.054421,363.775451 457.945579,363.775451 456.051806,364.526191 L359,403 L359,219 C359,210.163444 366.163444,203 375,203 L543,203 Z M439,255.358984 L439,324.641016 L499,290 L439,255.358984 Z"></path>
    </svg>
  );
}

export function IconPencil(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M17.428 1.705a3.003 3.003 0 0 0-4.242 0L2.773 12.12a3.013 3.013 0 0 0-.854 1.735l-.897 6.88a2 2 0 0 0 2.242 2.245l6.882-.9a2.984 2.984 0 0 0 1.733-.855l10.414-10.41a3.002 3.002 0 0 0 0-4.245l-4.864-4.865zM14.6 3.12a1 1 0 0 1 1.415 0l4.863 4.865c.391.39.391 1.025 0 1.415l-1.724 1.725-6.278-6.28L14.6 3.12zm-3.139 3.14l-7.274 7.275a1 1 0 0 0-.284.575l-.897 6.885 6.881-.9a.98.98 0 0 0 .579-.28l7.274-7.275-6.278-6.28z"></path>
    </svg>
  );
}

export function IconPlay(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 477.886 477.886" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M476.091,231.332c-1.654-3.318-4.343-6.008-7.662-7.662L24.695,1.804C16.264-2.41,6.013,1.01,1.8,9.442 c-1.185,2.371-1.801,4.986-1.8,7.637v443.733c-0.004,9.426,7.633,17.07,17.059,17.075c2.651,0.001,5.266-0.615,7.637-1.8 L468.429,254.22C476.865,250.015,480.295,239.768,476.091,231.332z"/>
    </svg>
  );
}

export function IconPlus(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M13 2c0-.55-.447-1-1-1-.553 0-1 .45-1 1v9H2c-.552 0-1 .45-1 1s.448 1 1 1h9v9c0 .55.447 1 1 1 .553 0 1-.45 1-1v-9h9c.552 0 1-.45 1-1s-.448-1-1-1h-9V2z"></path>
    </svg>
  );
}

export function IconProfile(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Zm0,22a10.17,10.17,0,0,1-2.66-.37,3,3,0,0,1,5.32,0A10.17,10.17,0,0,1,16,26Zm4.52-1.09a5,5,0,0,0-9,0,10,10,0,1,1,9,0ZM16,12a4,4,0,1,0,4,4A4,4,0,0,0,16,12Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,16,18Z"></path>
    </svg>
  );
}

export function IconQuestion(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.34 21.2c0 1.57-1.19 2.8-2.78 2.8-1.58 0-2.77-1.23-2.77-2.8 0-1.57 1.19-2.8 2.77-2.8 1.59 0 2.78 1.23 2.78 2.8m-2.53-10.88c1.41-1.23 3.3-2.05 3.3-3.87 0-1.45-1.24-2.36-2.95-2.36-2.12 0-3.52 1.48-3.55 3.43H3.75C3.88 3.3 6.96 0 12.34 0c4.86 0 7.91 2.52 7.91 6.32 0 2.36-1.09 3.81-2.3 4.78-1.68 1.33-2.84 1.89-3.49 2.61-.5.57-.62 1.04-.65 1.95H9.45c0-2.64.65-3.9 2.36-5.34"></path>
    </svg>
  );
}

export function IconSearch(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="nonzero" d="M11 21C5.477 21 1 16.523 1 11S5.477 1 11 1s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm11.71 3.293a1 1 0 0 1-1.414 1.414l-5.003-5a1 1 0 0 1 1.414-1.414l5.002 5z"></path>
    </svg>
  );
}

export function IconStar(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="nonzero" d="M12 2.236L8.893 8.447a1 1 0 0 1-.832.551l-5.9.369 4.574 4.955a1 1 0 0 1 .241.895L5.462 22.03l6.048-3.402a1 1 0 0 1 .98 0l6.048 3.402-1.515-6.813a1 1 0 0 1 .242-.895l4.574-4.955-5.901-.369a1 1 0 0 1-.832-.55L12 2.235zM7.36 7.038l2.85-5.697a2 2 0 0 1 3.578 0l2.848 5.697 5.326.333a2 2 0 0 1 1.345 3.353l-4.219 4.57 1.401 6.302a2 2 0 0 1-2.933 2.177L12 20.647l-5.557 3.126a2 2 0 0 1-2.933-2.177l1.4-6.302-4.219-4.57A2 2 0 0 1 2.036 7.37l5.325-.333z"></path>
    </svg>
  );
}

export function IconStarFill(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 512.07 512.07" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="m509.759 194.639c-6.152-18.858-22.945-31.043-42.781-31.043h-127.918l-40.282-122.33c-6.192-18.805-22.95-30.926-42.729-30.926-.063 0-.128 0-.191.001-19.86.078-36.611 12.349-42.674 31.262l-39.108 121.993h-128.983c-19.886 0-36.692 12.226-42.814 31.146-6.123 18.92.335 38.674 16.453 50.324l104.361 75.434-40.17 121.991c-6.217 18.88.133 38.662 16.177 50.396 8.058 5.894 17.307 8.842 26.562 8.842 9.171-.001 18.347-2.897 26.365-8.693l104.542-75.563 103.3 75.436c16.026 11.704 36.781 11.76 52.873.147s22.575-31.328 16.518-50.227l-39.218-122.329 103.474-75.563c16.02-11.698 22.396-31.441 16.243-50.298z"/>
    </svg>
  );
}

export function IconTag(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="nonzero" d="M1.99999969,2 L1.99999969,9.171 C1.99979125,9.43654132 2.10520544,9.69126231 2.293,9.879 L11.293,18.877 C11.6834999,19.2673819 12.3165001,19.2673819 12.707,18.877 L18.879,12.707 C19.2693819,12.3165001 19.2693819,11.6834999 18.879,11.293 L9.879,2.293 C9.69150727,2.10545063 9.43719481,2 9.172,2 L1.99999969,2 Z M20.293,9.879 C21.4641458,11.0504996 21.4641458,12.9495004 20.293,14.121 L14.121,20.291 C12.9495004,21.4621458 11.0504996,21.4621458 9.879,20.291 L0.879,11.293 C0.316106825,10.7302768 -2.15520686e-08,9.96693097 -2.15520686e-08,9.171 L-2.15520686e-08,1 C-2.15520686e-08,0.44771525 0.44771525,0 0.999999978,0 L9.172,0 C9.96723788,0.000435018374 10.7297669,0.316596796 11.292,0.879 L20.293,9.879 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"></path>
    </svg>
  );
}

export function IconTagPlus(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="nonzero" d="M2 4v7.171a1 1 0 0 0 .293.708l9 8.998a1 1 0 0 0 1.414 0l6.172-6.17a1 1 0 0 0 0-1.414l-9-9A1 1 0 0 0 9.172 4H2zm19-1h2a1 1 0 0 1 0 2h-2v2a1 1 0 0 1-2 0V5h-2a1 1 0 0 1 0-2h2V1a1 1 0 0 1 2 0v2zm-.707 8.879a3 3 0 0 1 0 4.242l-6.172 6.17a3 3 0 0 1-4.242 0l-9-8.998A3 3 0 0 1 0 11.171V3a1 1 0 0 1 1-1h8.172a3 3 0 0 1 2.12.879l9.001 9zM7 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
    </svg>
  );
}

export function IconTrash(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="nonzero" d="M2 6a1 1 0 1 1 0-2h20a1 1 0 0 1 0 2H2zm3 0v15a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6H5zM3 21V4h18v17a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3zM8 4a1 1 0 1 1-2 0V3a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v1a1 1 0 0 1-2 0V3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v1z"></path>
    </svg>
  );
}

export function IconThreeDots(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="nonzero" d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path>
    </svg>
  );
}

export function IconVideo(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 512 512" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill="#CFD8DC" d="M117.333,42.667h277.333C459.468,42.667,512,95.199,512,160v192
        c0,64.801-52.532,117.333-117.333,117.333H117.333C52.532,469.333,0,416.801,0,352V160C0,95.199,52.532,42.667,117.333,42.667z"/>
      <path fill="#EF3A58" d="M357.781,247.04l-149.333-96c-4.951-3.193-11.552-1.768-14.745,3.183
        c-1.111,1.722-1.702,3.728-1.703,5.777v192c0.002,5.891,4.78,10.665,10.671,10.663c2.049-0.001,4.055-0.592,5.777-1.703l149.333-96
        c4.954-3.187,6.387-9.787,3.2-14.742c-0.825-1.283-1.917-2.374-3.2-3.2V247.04z"/>
      <path fill="#CFD8DC" d="M394.667,469.333H117.333C52.566,469.251,0.082,416.767,0,352V160C0.082,95.233,52.566,42.749,117.333,42.667h277.333
        C459.434,42.749,511.918,95.233,512,160v192C511.918,416.767,459.434,469.251,394.667,469.333z M117.333,64
        c-53.019,0-96,42.981-96,96v192c0,53.019,42.981,96,96,96h277.333c53.019,0,96-42.981,96-96V160c0-53.019-42.981-96-96-96H117.333z"
        />
      <path fill="#EF3A58" d="M202.667,362.667c-5.891,0-10.667-4.776-10.667-10.667V160c-0.01-5.891,4.758-10.674,10.649-10.684
        c2.057-0.003,4.07,0.588,5.799,1.703l149.333,96c4.96,3.178,6.405,9.776,3.227,14.736c-0.83,1.296-1.931,2.397-3.227,3.227
        l-149.333,96C206.723,362.087,204.716,362.672,202.667,362.667z M213.333,179.541v152.917L332.267,256L213.333,179.541z"/>
    </svg>
  );
}

export function IconWebsite(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 477.73 477.73" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M433.562,100.983c-0.008-0.154-0.025-0.308-0.051-0.461c-0.597-0.853-1.331-1.587-1.946-2.423
          c-4.46-6.076-9.182-11.924-14.165-17.545c-1.365-1.536-2.731-3.055-4.13-4.557c-5.131-5.495-10.513-10.735-16.145-15.718
          c-1.041-0.922-2.014-1.877-3.055-2.782c-13.88-11.891-29.078-22.153-45.295-30.583c-0.649-0.341-1.331-0.631-1.997-0.973
          c-7.222-3.67-14.627-6.969-22.187-9.882c-1.707-0.614-3.26-1.195-4.881-1.707c-6.724-2.423-13.551-4.545-20.48-6.366
          c-2.048-0.546-4.096-1.109-6.178-1.587c-6.827-1.587-13.653-2.799-20.634-3.789c-2.116-0.307-4.198-0.717-6.332-0.973
          c-17.979-2.185-36.156-2.185-54.136,0c-2.133,0.256-4.215,0.666-6.332,0.973c-6.98,0.99-13.875,2.202-20.634,3.789
          c-2.082,0.478-4.13,1.041-6.178,1.587c-6.975,1.82-13.801,3.942-20.48,6.366c-1.707,0.58-3.26,1.161-4.881,1.707
          c-7.559,2.913-14.965,6.211-22.187,9.882c-0.666,0.341-1.348,0.631-1.997,0.973c-16.271,8.421-31.52,18.682-45.449,30.583
          c-1.041,0.904-2.014,1.86-3.055,2.782c-5.689,5.006-11.071,10.246-16.145,15.718c-1.399,1.502-2.765,3.021-4.13,4.557
          c-4.995,5.609-9.717,11.457-14.165,17.544c-0.614,0.836-1.348,1.57-1.946,2.423c-0.078,0.149-0.147,0.303-0.205,0.461
          c-58.866,82.497-58.866,193.267,0,275.763c0.058,0.158,0.126,0.312,0.205,0.461c0.597,0.853,1.331,1.587,1.946,2.423
          c4.449,6.076,9.17,11.924,14.165,17.545c1.365,1.536,2.731,3.055,4.13,4.557c5.143,5.495,10.524,10.735,16.145,15.718
          c1.041,0.922,2.014,1.877,3.055,2.782c13.88,11.891,29.078,22.153,45.295,30.583c0.649,0.341,1.331,0.631,1.997,0.973
          c7.222,3.67,14.627,6.969,22.187,9.882c1.707,0.614,3.26,1.195,4.881,1.707c6.724,2.423,13.551,4.545,20.48,6.366
          c2.048,0.546,4.096,1.109,6.178,1.587c6.827,1.587,13.653,2.799,20.634,3.789c2.116,0.307,4.198,0.717,6.332,0.973
          c17.979,2.185,36.156,2.185,54.136,0c2.133-0.256,4.215-0.666,6.332-0.973c6.98-0.99,13.875-2.202,20.634-3.789
          c2.082-0.478,4.13-1.041,6.178-1.587c6.986-1.82,13.813-3.942,20.48-6.366c1.707-0.58,3.26-1.161,4.881-1.707
          c7.559-2.913,14.965-6.211,22.187-9.882c0.666-0.341,1.348-0.631,1.997-0.973c16.217-8.431,31.415-18.692,45.295-30.583
          c1.041-0.905,2.014-1.86,3.055-2.782c5.689-4.995,11.071-10.234,16.145-15.718c1.399-1.502,2.765-3.021,4.13-4.557
          c4.995-5.621,9.717-11.469,14.165-17.545c0.614-0.836,1.348-1.57,1.946-2.423c0.078-0.149,0.147-0.303,0.205-0.461
          C492.428,294.25,492.428,183.48,433.562,100.983z M414.089,133.274c16.322,26.881,26.178,57.185,28.791,88.525H340.651
          c-1.252-20.336-4.452-40.504-9.557-60.228C359.971,156.163,387.922,146.633,414.089,133.274z M282.368,38.775
          c0.956,0.222,1.877,0.529,2.833,0.751c6.11,1.434,12.169,3.072,18.091,5.12c0.905,0.307,1.792,0.666,2.68,0.99
          c5.871,2.048,11.656,4.318,17.323,6.827c0.99,0.461,1.963,0.973,2.953,1.434c5.427,2.583,10.729,5.376,15.906,8.38l3.413,2.065
          c4.915,3.004,9.694,6.218,14.336,9.643c1.195,0.87,2.389,1.707,3.567,2.662c4.551,3.413,8.909,7.071,13.073,10.974
          c1.092,0.99,2.219,1.963,3.294,2.987c4.369,4.147,8.533,8.533,12.561,13.073c0.512,0.597,1.058,1.143,1.57,1.707
          c-23.109,11.013-47.59,18.877-72.789,23.381c-11.674-32.092-27.095-62.694-45.943-91.17
          C277.606,38.025,280.03,38.264,282.368,38.775z M171.298,221.798c1.346-18.466,4.49-36.757,9.387-54.613
          c19.337,2.297,38.793,3.436,58.266,3.413c19.491-0.006,38.965-1.174,58.317-3.499c4.888,17.885,8.015,36.205,9.335,54.699H171.298
          z M306.603,255.932c-1.346,18.466-4.49,36.757-9.387,54.613c-19.337-2.297-38.793-3.436-58.266-3.413
          c-19.49-0.022-38.963,1.117-58.317,3.413c-4.883-17.857-8.009-36.148-9.336-54.613H306.603z M238.95,45.193
          c19.422,27.527,35.396,57.332,47.565,88.747c-15.799,1.678-31.676,2.521-47.565,2.526c-15.871-0.019-31.731-0.867-47.514-2.543
          C203.62,102.529,219.575,72.734,238.95,45.193z M85.521,103.663c4.011-4.54,8.192-8.926,12.561-13.073
          c1.075-1.024,2.202-1.997,3.294-2.987c4.21-3.834,8.568-7.492,13.073-10.974c1.178-0.905,2.372-1.707,3.567-2.662
          c4.642-3.413,9.421-6.628,14.336-9.643l3.413-2.065c5.177-3.026,10.479-5.82,15.906-8.38c0.99-0.461,1.963-0.973,2.953-1.434
          c5.666-2.56,11.452-4.83,17.323-6.827c0.887-0.324,1.707-0.683,2.679-0.99c5.922-1.98,11.947-3.618,18.091-5.12
          c0.956-0.222,1.877-0.529,2.85-0.734c2.338-0.512,4.762-0.751,7.134-1.178c-18.856,28.481-34.282,59.089-45.961,91.187
          c-25.199-4.504-49.681-12.368-72.789-23.381C84.463,104.806,85.009,104.26,85.521,103.663z M63.812,133.274
          c26.161,13.358,54.106,22.888,82.978,28.297c-5.099,19.725-8.294,39.893-9.54,60.228H35.021
          C37.635,190.459,47.491,160.155,63.812,133.274z M63.812,344.457c-16.322-26.881-26.178-57.185-28.791-88.525H137.25
          c1.252,20.336,4.452,40.504,9.557,60.228C117.93,321.567,89.979,331.097,63.812,344.457z M195.533,438.955
          c-0.956-0.222-1.877-0.529-2.833-0.751c-6.11-1.434-12.169-3.072-18.091-5.12c-0.905-0.307-1.792-0.666-2.68-0.99
          c-5.871-2.048-11.656-4.318-17.323-6.827c-0.99-0.461-1.963-0.973-2.953-1.434c-5.427-2.583-10.729-5.376-15.906-8.38
          l-3.413-2.065c-4.915-3.004-9.694-6.218-14.336-9.643c-1.195-0.87-2.389-1.707-3.567-2.662
          c-4.551-3.413-8.909-7.071-13.073-10.974c-1.092-0.99-2.219-1.963-3.294-2.987c-4.369-4.147-8.533-8.533-12.561-13.073
          c-0.512-0.597-1.058-1.143-1.57-1.707c23.109-11.013,47.59-18.877,72.789-23.381c11.674,32.092,27.095,62.694,45.943,91.17
          C200.294,439.706,197.871,439.467,195.533,438.955z M238.95,432.538c-19.422-27.527-35.396-57.332-47.565-88.747
          c31.607-3.402,63.488-3.402,95.095,0l-0.017,0.017C274.281,375.201,258.326,404.996,238.95,432.538z M392.38,374.067
          c-4.011,4.54-8.192,8.926-12.561,13.073c-1.075,1.024-2.202,1.997-3.294,2.987c-4.21,3.846-8.568,7.504-13.073,10.974
          c-1.178,0.904-2.372,1.792-3.567,2.662c-4.642,3.413-9.421,6.628-14.336,9.643l-3.413,2.065
          c-5.166,3.015-10.468,5.808-15.906,8.38c-0.99,0.461-1.963,0.973-2.953,1.434c-5.666,2.56-11.452,4.83-17.323,6.827
          c-0.887,0.324-1.707,0.683-2.679,0.99c-5.922,1.98-11.947,3.618-18.091,5.12c-0.956,0.222-1.877,0.529-2.85,0.734
          c-2.338,0.512-4.762,0.751-7.134,1.178c18.848-28.476,34.27-59.078,45.943-91.17c25.199,4.504,49.681,12.368,72.789,23.381
          C393.438,372.924,392.892,373.47,392.38,374.067z M414.089,344.457c-26.161-13.358-54.106-22.888-82.978-28.297
          c5.099-19.725,8.294-39.893,9.54-60.228H442.88C440.266,287.271,430.41,317.575,414.089,344.457z"/>
    </svg>
  );
}

export function IconX(props) {
  const size = (props.size || 1) + "em";
  const className = props.className || "icon-black-50";
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="nonzero" d="M12 13.414l-7.293 7.293c-.943.943-2.357-.471-1.414-1.414L10.586 12 3.293 4.707c-.943-.943.471-2.357 1.414-1.414L12 10.586l7.293-7.293c.943-.943 2.357.471 1.414 1.414L13.414 12l7.293 7.293c.943.943-.471 2.357-1.414 1.414L12 13.414z"></path>
    </svg>
  );
}

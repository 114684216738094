import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  IconX,
} from 'components/icons';
import {
  isCollectionFilterActive,
} from 'misc/appHelperFunctions';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function ClearFilterTagsButton(props) {
  const { hidden, onClick, filterSearchString } = props;

  if (hidden) {
    return null;
  }

  function handleClick() {
    onClick && onClick();
    analyticsLogEvent('collection_filter_clear_selected_tags');
    if (filterSearchString) {
      analyticsLogEvent('collection_filter_clear_selected_tags_w_search');
    }
  }

  return (
    <div
      className="mr-2 tag-light"
      onClick={handleClick}
    >
      <IconX size={1} />
    </div>
  );
}

function TagButton(props) {
  const { tag, isSelected, addTagToFilter, removeTagFromFilter, filterSearchString } = props;

  const className = "mr-2 mb-2 " + (isSelected ? "tag-primary" : "tag-light");

  function handleClick() {
    if (isSelected) {
      removeTagFromFilter(tag);
      analyticsLogEvent('collection_filter_unselect_tag');
      if (filterSearchString) {
        analyticsLogEvent('collection_filter_unselect_tag_w_search');
      }
    } else {
      addTagToFilter(tag);
      analyticsLogEvent('collection_filter_select_tag');
      if (filterSearchString) {
        analyticsLogEvent('collection_filter_select_tag_w_search');
      }
    }
  };

  return (
    <span className={className} onClick={handleClick}>
      {tag}
    </span>
  );
}

function Filter(props) {
  const {
    collectionTags,
    filter,
    updateCollectionFilter,
  } = props;

  if (!isCollectionFilterActive(filter)) {
    return null;
  }

  // Merge filter tags and filtered collection tags, and generate
  // a sorted tags list to display.
  const filterTagsList = filter.tags || [];
  const filterTags = filterTagsList.reduce(
    (accumulator, tag) => ({ ...accumulator, [tag]: true }),
    {}
  );

  const tagsListToDisplay = Object.keys({
    ...collectionTags,
    ...filterTags,
  }).sort();
  if (tagsListToDisplay.length === 0) {
    return null;
  }

  function addTagToFilter(tag) {
    if (updateCollectionFilter) {
      const nextFilterTags = [...filter.tags, tag];
      const nextFilter = {
        ...filter,
        tags: nextFilterTags,
      };
      updateCollectionFilter(nextFilter);
    }
  }

  function removeTagFromFilter(tag) {
    if (updateCollectionFilter) {
      const nextFilterTags = filter.tags.filter((value) => {
        return (value !== tag);
      });
      const nextFilter = {
        ...filter,
        tags: nextFilterTags,
      };
      updateCollectionFilter(nextFilter);
    }
  }

  function clearFilterTags() {
    if (updateCollectionFilter) {
      const nextFilter = {
        ...filter,
        tags: [],
      };
      updateCollectionFilter(nextFilter);
    }
  }

  const tagButtons = tagsListToDisplay.map((tag) => {
    const isSelected = filterTags[tag];
    return (
      <TagButton
        key={tag}
        tag={tag}
        isSelected={isSelected}
        addTagToFilter={addTagToFilter}
        removeTagFromFilter={removeTagFromFilter}
        filterSearchString={filter.search}
      />
     );
  });

  return (
    <div className="pt-3 bg-white sticky-top top-navbar-height ">
      <Container className="px-0 border-bottom mw-md">
        <div className="pb-1 overflow-auto mh-7rem">
          <ClearFilterTagsButton
            hidden={filterTagsList.length === 0}
            onClick={clearFilterTags}
            filterSearchString={filter.search}
          />

          {tagButtons}
        </div>
      </Container>
    </div>
  );
}

export default Filter;

import { APP_NAME } from 'misc/appConstants';
import { IconLogo } from 'components/icons';
// Third party.
import React from 'react';

function LogoButton(props) {
  const { shouldHideName } = props;

  return (
    <div className="round-button-container position-relative">
      <IconLogo size={1.2} />
      {
        !shouldHideName &&
        <h4 className="m-0 text-black-50 position-absolute left-48px">{ APP_NAME }</h4>
      }
    </div>
  );
}

export default LogoButton;

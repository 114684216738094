import LoadingCover from './LoadingCover';
// Third party.
import React, { useState } from 'react';

function VideoPlayer(props) {
  const [loaded, setLoaded] = useState(false);

  const { src } = props;

  function handleCanPlay() {
    setLoaded(true);
  }

  function handleError() {
    if (props.onError) {
      props.onError();
    }
  }

  const videoClassName =
      'mw-100 mh-media-container ' + (loaded ? '' : 'w-100 transparent');

  return (
    <div className="position-relative text-center">
      <video
        controls autoPlay muted playsInline
        className={videoClassName}
        src={src}
        onCanPlay={handleCanPlay}
        onError={handleError}
      />

      <LoadingCover hidden={loaded} />
    </div>
  );
}

export default VideoPlayer;

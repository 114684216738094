import VideosGrid from 'components/videosGrid';
// Third party.
import React from 'react';

function RelatedVideos(props) {
  const {
    urlInfo={},
    // Redux states.
    recommendation,
    // Redux actions.
    fetchRelatedVideos,
  } = props;

  const isFetchingVideos= recommendation.isFetchingUrlRelatedVideos[urlInfo.url];
  const videos = recommendation.urlRelatedVideos[urlInfo.url];

  function fetchVideos() {
    if (fetchRelatedVideos) {
      fetchRelatedVideos(urlInfo);
    }
  }

  return (
    <div className="page-container">
      <VideosGrid
        eventPrefix="vmark-related-video"
        fetchVideos={fetchVideos}
        isFetchingVideos={isFetchingVideos}
        videos={videos}
        titleFetching="We are adding related videos"
        title="More like this"
      />
    </div>
  );
}

export default RelatedVideos;

import {
  apiCreatePrivateCollection,
  apiFetchRelatedVideos,
  userSetIsOnboarding,
} from 'appRedux/actions';
import {
  ONBOARDNIG_URL_INFO,
} from 'misc/appConstants';
import CardWelcome from './CardWelcome';
import CardCreatePlaylist from './CardCreatePlaylist';
import CardSaveVideos from './CardSaveVideos';
import CardSummary from './CardSummary';
// Third party.
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

const _TOTAL_CARDS = 4;
const _LAST_CARD_INDEX = _TOTAL_CARDS - 1;

const _CREATE_PLAYLIST_INDEX = 1;
const _SAVE_VIDEOS_INDEX = 2;

const _MIN_REQUIRED_SAVED_VIDEOS = 3;

function NavButtons(props) {
  const {
    setIsOnboarding,
    cardIndex,
    handleClickNext,
    // Redux states
    collection,
  } = props;

  if (cardIndex === _CREATE_PLAYLIST_INDEX) {
    return null;
  }

  function handleClickDone() {
    setIsOnboarding(false);
  }

  const buttonTitle = (cardIndex !== _LAST_CARD_INDEX) ? "Next" : "Done";
  const onClick = (cardIndex !== _LAST_CARD_INDEX) ? handleClickNext : handleClickDone;

  // Special case for save videos card.
  const isSaveVideos = (cardIndex === _SAVE_VIDEOS_INDEX);
  const savedVideosCnt =
      (collection.itemIds[(collection.currentCollectionInfo || {}).id] || []).length;
  const savedVideosCntNote =
      savedVideosCnt + " video" + ((savedVideosCnt > 1) ? "s" : "") + " saved";
  const disabled = isSaveVideos && (savedVideosCnt < _MIN_REQUIRED_SAVED_VIDEOS);

  return (
    <div className="mt-2">
      {
        isSaveVideos &&
        <h6 className="text-center">
          { savedVideosCntNote }
        </h6>
      }
      <Button
        variant='primary'
        block
        onClick={onClick}
        disabled={disabled}
      >
        { buttonTitle }
      </Button>
    </div>
  );
}

function Onboarding(props) {
  const [cardIndex, setCardIndex] = useState(0);

  const { fetchRelatedVideos } = props;

  useEffect(() => {
    fetchRelatedVideos(ONBOARDNIG_URL_INFO);
  }, [fetchRelatedVideos]);

  function handleClickNext() {
    const nextIndex = Math.min(_LAST_CARD_INDEX, cardIndex + 1);
    setCardIndex(nextIndex);
  }

  function handleCreatePlaylist(name) {
    if (!name) {
      return;
    }

    if (props.createPrivateCollection) {
      props.createPrivateCollection(name);
    }

    handleClickNext();
  }

  let card = null;
  let additionalClassName = "";
  switch(cardIndex) {
    case 0:
      card = (<CardWelcome />);
      break;
    case 1:
      card = (<CardCreatePlaylist onSubmit={handleCreatePlaylist} />);
      break;
    case 2:
      card = (<CardSaveVideos {...props} minRequiredSavedVideos={_MIN_REQUIRED_SAVED_VIDEOS}/>);
      additionalClassName = " fixed-bottom w-100 mw-100";
      break;
    case 3:
      card = (<CardSummary />);
      break;
    default:
      card = (<CardWelcome />);
  }

  return (
    <Container fluid className="py-4 page-container">
      { card }

      <Container className={"bg-white mobile-fixed-bottom" + additionalClassName}>
        <div className="mw-sm mx-auto">
          <NavButtons
            {...props}
            cardIndex={cardIndex}
            handleClickNext={handleClickNext}
          />

          <h6 className="text-center my-2 text-black-50">
            {cardIndex + 1}/{_TOTAL_CARDS}
          </h6>
        </div>
      </Container>
    </Container>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  collection: state.collection,
  recommendation: state.recommendation,
});

const mapDispatchToProps = (dispatch) => ({
  createPrivateCollection: (name) => {
    dispatch(apiCreatePrivateCollection(name));
  },
  fetchRelatedVideos: (urlInfo) => {
    dispatch(apiFetchRelatedVideos(urlInfo));
  },
  setIsOnboarding: (isOnboarding) => {
    dispatch(userSetIsOnboarding(isOnboarding));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Onboarding);

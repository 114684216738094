import LoadingIndicator from 'components/LoadingIndicator';
// Third party.
import React from 'react';

function LoadingCover(props) {
  const { hidden } = props;

  if (hidden) {
    return null;
  }

  return (
    <div className="position-absolute w-100 h-100 bg-white d-flex
                    justify-content-center align-items-center top-0">
      <LoadingIndicator delayInSeconds={1} />
    </div>
  );
}

export default LoadingCover;

import { functions } from 'appFirebase';
import {
  playlistClearIsFetching,
  playlistSetIsFetching,
  playlistSetCurrentCollectionId,
  playlistSetCollectionVideos,
} from '../playlist';

/*
 * Search videos.
 */
export const apiFetchCollectionVidoes = (collectionId) => {
  return async (dispatch, getState) => {
    if (!collectionId) {
      return;
    }

    // Do not fetch again if it is actively fetching.
    if (getState().playlist.isFetching) {
      return;
    }
    // Do not fetch again if the collection videos is already in cache.
    if (getState().playlist.collectionVideos[collectionId]) {
      dispatch(playlistSetCurrentCollectionId(collectionId));
      return ;
    }

    dispatch(playlistSetCurrentCollectionId(collectionId));
    dispatch(playlistSetIsFetching(true));

    // Call cloud function to get keyword thumbnails.
    const getCollectionVidoesById = functions.httpsCallable('getCollectionVidoesById');

        // Get response.
    let hasError = false;
    const res = await getCollectionVidoesById({
          collectionId,
          filters: {},
          count: 40,
        })
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error("Error fetching collection videos: ", error);
          hasError = true;
        });

    if (hasError) {
      dispatch(playlistClearIsFetching());
      return;
    }

    dispatch(playlistSetCollectionVideos(collectionId, res));
  };
};

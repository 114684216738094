import {
  IconStarFill,
} from 'components/icons';
import { APP_NAME } from 'misc/appConstants';
import SectionWrapper from '../SectionWrapper';
// Third party.
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function FiveStars(props) {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(<IconStarFill key={i} size={1.5} className="icon-primary mr-3" />);
  }

  return (
    <div className="text-center mb-3">
      { stars }
    </div>
  );
}

function Review(props) {
  const { comments } = props;
  return (
    <Col sm={4} className="p-4">
      <FiveStars />
      <h5>{ comments }</h5>
    </Col>
  );
}

function SocialProof(props) {
  return (
    <SectionWrapper className="bg-white" innerClassName="p-4">
      <h1 className="mb-4 text-center">Hear from { APP_NAME } users</h1>
      <Row className="no-gutters">
        <Review
          comments={'"The best way to save the porn I like and rewatch them whenever I want."'}
        />
        <Review
          comments={'"I love saving porn to ' + APP_NAME + '. This allows me to go back to the porn I enjoyed from previous months and discover newer ones."'}
        />
        <Review
          comments={'"I never worry about others finding my stash of secrets anymore."'}
        />
      </Row>
    </SectionWrapper>
  );
}

export default SocialProof;

const admin = (state = {
  isAdmin: false,
  modalDeleteVideo: {
    show: false,
    urlInfo: null,
  },
  modalEditBaseClickCnt: {
    show: false,
    urlInfo: null,
  },
}, action) => {
  switch (action.type) {
    case 'ADMIN_SET_IS_ADMIN': {
      const { isAdmin } = action;

      return {
        ...state,
        isAdmin,
      };
    }
    case 'ADMIN_SHOW_MODAL_DELETE_VIDEO': {
      const { show, urlInfo } = action;
      const nextModalDeleteVideo = {
        show,
        urlInfo: show ? urlInfo : null,
      };

      return {
        ...state,
        modalDeleteVideo: nextModalDeleteVideo,
      };
    }
    case 'ADMIN_SHOW_MODAL_EDIT_BASE_CLICK_CNT': {
      const { show, urlInfo } = action;
      const nextModalEditBaseClickCnt = {
        show,
        urlInfo: show ? urlInfo : null,
      };

      return {
        ...state,
        modalEditBaseClickCnt: nextModalEditBaseClickCnt,
      };
    }
    default:
      return state
  }
};

export default admin;

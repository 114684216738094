const playlist = (state = {
  isFetching: false,
  isLoadingMore: false,
  currentCollectionId: null,
  collectionVideos: {
    /**
     * $collectionId,
     * ...
     */
  },
}, action) => {
  switch (action.type) {
    case 'PLAYLIST_SET_IS_FETCHING': {
      const { isFetching } = action;

      return {
        ...state,
        isFetching,
      };
    }
    case 'PLAYLIST_SET_IS_LOADING_MORE': {
      const { isLoadingMore } = action;

      return {
        ...state,
        isLoadingMore,
      };
    }
    case 'PLAYLIST_SET_CURRENT_COLLECTION_ID': {
      const { collectionId } = action;

      return {
        ...state,
        currentCollectionId: collectionId,
      };
    }
    case 'PLAYLIST_SET_COLLECTION_VIDEOS': {
      const { collectionId, videos } = action;

      if (!collectionId) {
        return state;
      }

      const nextCollectionVideos = {
        ...state.collectionVideos,
        [collectionId]: videos || [],
      };

      return {
        ...state,
        isFetching: false,
        isLoadingMore: false,
        collectionVideos: nextCollectionVideos,
      };
    }
    default:
      return state
  }
};

export default playlist;

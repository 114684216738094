import {
  collectionUpdateCurrentItemId,
  combinedClearCollection,
  combinedSetCollectionFilterToTags,
  combinedUpdateCollectionFilter,
  modalClose,
  modalShowDeleteItem,
  modalShowTagsEditor,
  modalShowDetailsEditor,
  modalShowPriceEditor,
  modalShowSaveItem,
  navbarHideInputBox,
  navbarUpdateSaveBox,
} from 'appRedux/actions';
import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  ESCAPE_KEY_CODE,
  P_COLLECTION,
  P_COLLECTION_TAGS,
  P_SAVED,
  P_TAGS,
} from 'misc/appConstants';
import {
  isCollectionFilterActive,
  isNavbarInputBoxEnabled,
  navBack,
} from 'misc/appHelperFunctions';
import LoadingIndicator from 'components/LoadingIndicator';
import ModalItemEditor from 'components/modalItemEditor';
import PageFooter from 'components/PageFooter';
import CollectionContent from './collectionContent';
import CollectionTags from './collectionTags';
// Third party.
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

function Collection(props) {
  const history = useHistory();
  const match = useRouteMatch();

  const {
    navbar,
    collection,
    hideNavbarInputBox,
    updateCollectionFilter,
    clearCollection,
    modal,
    closeModal,
  } = props;
  const collectionFilter = collection.filter;

  useEffect(() => {
    function HandleKeyDown(event) {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        if (history.location.pathname === P_COLLECTION_TAGS) {
          navBack(history);
          return;
        }

        if (modal.tagsEditor.show || modal.deleteItem.show) {
          closeModal();
          return;
        }

        const isInputBoxEnabled = isNavbarInputBoxEnabled(navbar);
        const isFilterActive = isCollectionFilterActive(collectionFilter);
        if (isInputBoxEnabled || isFilterActive) {
          if (isInputBoxEnabled) {
            hideNavbarInputBox()
          }
          if (isFilterActive) {
            updateCollectionFilter(null);
          }
          return;
        }

        clearCollection();
      }
    }

    // Listen to key events.
    window.addEventListener('keydown', HandleKeyDown);

    return () => {
      window.removeEventListener('keydown', HandleKeyDown);
    };
  }, [
    history,
    navbar,
    collectionFilter,
    hideNavbarInputBox,
    updateCollectionFilter,
    clearCollection,
    modal,
    closeModal,
  ]);

  const {
    currentCollectionInfo,
    isFetchingCurrentCollectionInfo,
    isFetchingItems,
  } = collection;

  if (isFetchingCurrentCollectionInfo || isFetchingItems) {
    return (
      <LoadingIndicator delayInSeconds={1} className="mt-5" />
    );
  }

  if (!currentCollectionInfo) {
    return (
      <Redirect to={P_SAVED} />
    );
  }

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.path}${P_TAGS}`} render={() => {
          analyticsLogEvent('screen_view', { screen_name: P_COLLECTION_TAGS });
          return (<CollectionTags {...props} />);
        }} />
        <Route path={match.path} render={() => {
          analyticsLogEvent('screen_view', { screen_name: P_COLLECTION });
          return (<CollectionContent {...props} />);
        }} />
      </Switch>

      <PageFooter />

      <ModalItemEditor eventPrefix="collection" />
    </div>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  collection: state.collection,
  modal: state.modal,
  navbar: state.navbar,
  tags: state.tags,
  isAdmin: state.admin.isAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  clearCollection: () => {
    dispatch(combinedClearCollection());
  },
  closeModal: () => {
    dispatch(modalClose());
  },
  hideNavbarInputBox: () => {
    dispatch(navbarHideInputBox());
  },
  setCollectionFilterToTags: (tags) => {
    dispatch(combinedSetCollectionFilterToTags(tags));
  },
  showModalDeleteItem: (show, itemId, redirectPath) => {
    dispatch(modalShowDeleteItem(show, itemId, redirectPath));
  },
  showModalTagsEditor: (show, itemId) => {
    dispatch(modalShowTagsEditor(show, itemId));
  },
  showModalDetailsEditor: (show, itemId) => {
    dispatch(modalShowDetailsEditor(show, itemId));
  },
  showModalPriceEditor: (show, itemId) => {
    dispatch(modalShowPriceEditor(show, itemId));
  },
  actionModalShowSaveItem: (show, urlInfo) => {
   dispatch(modalShowSaveItem(show, urlInfo));
  },
  updateCollectionCurrentItemId: (itemId) => {
    dispatch(collectionUpdateCurrentItemId(itemId));
  },
  updateCollectionFilter: (filter) => {
    dispatch(combinedUpdateCollectionFilter(filter));
  },
  updateNavbarSaveBox: (enabled) => {
    dispatch(navbarUpdateSaveBox(enabled));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Collection);

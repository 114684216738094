import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_COLLECTION_TAGS,
} from 'misc/appConstants';
import {
  IconTag,
} from 'components/icons';
import TooltipContainer from 'components/TooltipContainer';
import useScrollToLastPosition from 'misc/useScrollToLastPosition';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

function TitleSection(props) {
  useScrollToLastPosition({ positionTarget: 'collection' });

  const {
    currentCollectionInfo={},
  } = props;

  return (
    <h4 className="m-0 text-dont-break-out">{ currentCollectionInfo.name }</h4>
  );
}

function StatsSummary(props) {
  const {
    collectionItemIds,
    collectionTags,
    titleHidden,
    hideNavbarInputBox,
  } = props;
  const itemCount = collectionItemIds ? collectionItemIds.length : 0;
  const itemCountSummary = (itemCount > 1)
      ? (itemCount + ' videos')
      : (itemCount + ' video');
  const tagCount = collectionTags ? Object.keys(collectionTags).length : 0;
  const tagCountSummary = (tagCount > 1)
      ? (tagCount + ' tags')
      : (tagCount + ' tag');
  const statsSummary = itemCountSummary + ' · ' + tagCountSummary;

  function handleClickTags() {
    if (hideNavbarInputBox) {
      hideNavbarInputBox();
    }
    analyticsLogEvent('collection_click_view_all_tags');
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <span className="text-black-50">{statsSummary}</span>

      {
        !titleHidden &&
        <div className="mr-n2">
          <TooltipContainer content="See all tags" hoverEventName="collection_hover_all_tags_button">
            <Link
              to={P_COLLECTION_TAGS}
              className="no-hover-effect round-button-container"
              onClick={handleClickTags}
            >
              <IconTag size={1.5} />
            </Link>
          </TooltipContainer>
        </div>
      }
    </div>
  );
}

function Header(props) {
  const {
    titleHidden,
    currentCollectionInfo,
    collectionItemIds,
    collectionTags,
    hideNavbarInputBox,
  } = props;

  return (
    <Container className="pt-4 px-0 mw-md">
      {
        !titleHidden &&
        <TitleSection
          currentCollectionInfo={currentCollectionInfo}
        />
      }

      <StatsSummary
        collectionItemIds={collectionItemIds}
        collectionTags={collectionTags}
        titleHidden={titleHidden}
        hideNavbarInputBox={hideNavbarInputBox}
      />
    </Container>
  );
}

export default Header;

import {
  ARROW_DOWN_KEY_CODE,
  ARROW_UP_KEY_CODE,
  ENTER_KEY_CODE,
} from 'misc/appConstants';
import {
  IconCheck,
  IconSearch,
  IconX,
} from 'components/icons';
// Third party.
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const _BUTTON_TITLE_CANCEL = 'Cancel';
const _BUTTON_TITLE_CLEAR = 'Clear';

function Buttons(props) {
  const {
    handleCancel,
    hideCancelButton,
    buttonTitle,
    inputString,
  } = props;

  // Clear button is always visible as long as the input string is valid.
  const showCancelButton = !hideCancelButton || inputString;

  return (
    <InputGroup.Append>
      {
        showCancelButton &&
        <Button
          variant='light'
          type='reset'
          onClick={handleCancel}
          className="rounded ml-2"
        >
          <span className="hide-on-mobile">
            { inputString ? _BUTTON_TITLE_CLEAR : _BUTTON_TITLE_CANCEL }
          </span>
          <IconX
            size={1.2}
            className="show-on-mobile icon-black-50 mt-n1"
          />
        </Button>
      }

      <Button
        variant='primary'
        type='submit'
        className="rounded ml-2"
      >
        <span className="hide-on-mobile">
          { buttonTitle }
        </span>
        {
          buttonTitle.toLowerCase().includes('search')
          ? <IconSearch
              size={1.2}
              className="show-on-mobile icon-white mt-n1"
            />
          : <IconCheck
              size={1.2}
              className="show-on-mobile icon-white mt-n1"
            />
        }
      </Button>
    </InputGroup.Append>
  );
}

function InputBox(props) {
  const initialValue = props.initialValue || '';

  const [inputString, setInputString] = useState(initialValue);

  const inputRef = useRef(null);

  useEffect(() => {
    setInputString(initialValue);
  }, [ initialValue ]);

  const { autoFocus, onChange } = props;

  function updateInputString(str) {
    setInputString(str);
    if (onChange) {
      onChange(str);
    }
  }

  function handleChange(event) {
    if (props.disabled) {
      return;
    }

    switch (event.target.name) {
      case 'input':
        updateInputString(event.target.value);
        break;
      default:
        console.log('Input name is invalid.');
    }
  }

  function handleCancel() {
    if (inputString) {
      // Clear inputString if not empty.
      updateInputString('');
      // Keep the input focused.
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    } else if (props.cancelOnClick) {
      props.cancelOnClick();
    }
  }

  function handleSubmit(event) {
    if (props.submitOnClick) {
      const inputStringTrimmed = (inputString || '').trim();
      setInputString(inputStringTrimmed);
      props.submitOnClick(inputStringTrimmed);
    }
    event.preventDefault();
  }

  // Hide keyboard on mobile devices after pressing enter key.
  function blurInput(event) {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.target.blur();
      handleSubmit(event);
    }
    // Save arrow-up and arrow-down key events for AutoSuggest.
    else if (event.keyCode === ARROW_DOWN_KEY_CODE ||
        event.keyCode === ARROW_UP_KEY_CODE) {
      event.preventDefault();
    }
  }

  const { placeholder, buttonTitle, hideCancelButton } = props;
  const inputType = buttonTitle.toLowerCase().includes('search')
      ? 'search' : 'text';

  return (
    <Form onSubmit={handleSubmit} className="form-inline w-100">
      <InputGroup className="flex-grow-1 my-auto">
        <Form.Control
          ref={inputRef}
          type={inputType}
          placeholder={placeholder}
          name={'input'}
          value={inputString}
          onChange={handleChange}
          autoFocus={autoFocus}
          className="rounded w-100"
          autoComplete="off"
          onKeyDown={blurInput}
        />

        <Buttons
          handleCancel={handleCancel}
          hideCancelButton={hideCancelButton}
          buttonTitle={buttonTitle}
          inputString={inputString}
        />
      </InputGroup>
    </Form>
  );
}

export default InputBox;

import {
  canShowVideoPlayer,
  isPremiumVideo,
  parseDurationInSecToClockFormat,
} from 'misc/appHelperFunctions';
import {
  IconEye,
  IconPlay,
} from 'components/icons';
import PremiumBadge from 'components/PremiumBadge';
// Third party.
import React from 'react';

function Badges(props) {
  const {
    isHD,
    canShowPreview,
  } = props;
  const urlInfo = props.urlInfo || {};

  const video = urlInfo.video || {};
  const formattedDuration = parseDurationInSecToClockFormat(video.durationInSec);

  return (
    <div>
      {
        isPremiumVideo(urlInfo) &&
        <div className="position-absolute top-0 right-1">
          <PremiumBadge />
        </div>
      }
      <div className="position-absolute left-1 bottom-1">
        {
          canShowVideoPlayer(urlInfo) &&
          <small className="mr-1 badge badge-dark px-2 text-xs bg-primary-transparent-80">
            <IconPlay size={0.6} className="icon-white align-middle pb-0p2 mr-n0p5" />
          </small>
        }
        {
          canShowPreview &&
          <small className="mr-1 badge badge-dark text-xs bg-primary-transparent-80">
            <IconEye size={1} className="icon-white align-middle pb-0p2 my-n1" />
          </small>
        }
      </div>
      <div className="position-absolute right-1 bottom-1">
        {
          isHD &&
          <small className="ml-1 badge badge-dark text-xs bg-dark-transparent-80">HD</small>
        }
        <small className="ml-1 badge badge-dark text-xs bg-dark-transparent-80">
         {formattedDuration}
        </small>
      </div>
    </div>
  );
}

export default Badges;

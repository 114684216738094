import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_CONTACT,
  P_HELP,
  P_PRIVACY,
  P_TERMS,
} from 'misc/appConstants';
import SectionWrapper from './SectionWrapper';
// Third party.
import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Top(props) {
  const ref = useRef();

  const { eventNameSuffix } = props;

  useEffect(() => {
    // Log sroll to bottom event.
    function handleScroll() {
      let isInViewport = false;
      const offset = 0;
      const bound = ref && ref.current ? ref.current.getBoundingClientRect() : null;
      if (bound) {
        const top = bound.top;
        isInViewport = (top + offset) >= 0 && (top - offset) <= window.innerHeight;
      }
      if (isInViewport) {
        const eventName = 'landing_page_scroll_to_bottom' +
            (eventNameSuffix ? ('_' + eventNameSuffix) : '') ;
        analyticsLogEvent(eventName);
        // Only need to log scroll to bottom event once per page view.
        window.removeEventListener('scroll', handleScroll);
      }
    }

    // Listen to scroll events.
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [eventNameSuffix]);

  return (
    <div ref={ref} />
  );
}

function LinkItem(props) {
  const { target, name } = props;

  return (
    <Col sm={6} md={3}>
      <Link to={target} target="_blank" className="text-dark">
        { name }
      </Link>
    </Col>
  );
}

function Footer(props) {
  return (
    <SectionWrapper className="bg-secondary" innerClassName="p-4 text-center">
      <Top eventNameSuffix={props.eventNameSuffix} />

      <Row>
        <LinkItem
          target={P_TERMS}
          name="Terms of Service"
        />
        <LinkItem
          target={P_PRIVACY}
          name="Privacy Policy"
        />
        <LinkItem
          target={P_HELP}
          name="How It Works"
        />
        <LinkItem
          target={P_CONTACT}
          name="Contact Us"
        />
      </Row>
    </SectionWrapper>
  );
}

export default Footer;

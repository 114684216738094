import { store } from 'appRedux/store';
import {
  apiPostLogIn,
  combinedLogOut,
} from 'appRedux/actions';
import { auth } from 'appFirebase';
import { analyticsLogEvent } from 'appFirebase/helperApi';
// Third party.
import { useEffect } from 'react';

const _EMAIL_FORMAT = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const _PASSWORD_MIN_LENGTH = 6;

//--------------------
// Constants
//--------------------
export const ERROR_CODE = {
  // createUserWithEmailAndPassword error codes
  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#createuserwithemailandpassword
  EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
  OPERTION_NOT_ALLOWED: 'auth/operation-not-allowed',
  WEAK_PASSWORD: 'auth/weak-password',
  // signInWithEmailAndPassword error codes
  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#sign-inwith-email-and-password
  USER_DISABLED: 'auth/user-disabled',
  WRONG_PASSWORD: 'auth/wrong-password',
  // sendPasswordResetEmail error codes
  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#send-password-reset-email
  MISSING_ANDROID_PKG_NAME: 'auth/missing-android-pkg-name',
  MISSING_CONTINUE_URI: 'auth/missing-continue-uri',
  MISSING_IOS_BUNDLE_ID: 'auth/missing-ios-bundle-id',
  INVALID_CONTINUE_URI: 'auth/invalid-continue-uri',
  UNAUTHORIZED_CONTINUE_URI: 'auth/unauthorized-continue-uri',
  // Shared error codes by createUserWithEmailAndPassword,
  // signInWithEmailAndPassword and sendPasswordResetEmail
  INVALID_EMAIL: 'auth/invalid-email',
  // Shared error codes by signInWithEmailAndPassword and sendPasswordResetEmail
  USER_NOT_FOUND: 'auth/user-not-found',
}

export const ERROR_MESSAGE = {
  [ERROR_CODE.EMAIL_ALREADY_IN_USE]: "This email address is already registered",
  [ERROR_CODE.WEAK_PASSWORD]: "Please enter " + _PASSWORD_MIN_LENGTH + " characters or more for your password",
  [ERROR_CODE.USER_NOT_FOUND]: "The email is not registered. Please double-check and try again",
  [ERROR_CODE.WRONG_PASSWORD]: "Hmm...that password isn't right",
  [ERROR_CODE.INVALID_EMAIL]: "Please enter a valid email address",
}

//--------------------
// Functions
//--------------------
export function isEmailValid(email) {
  if (!email || email.length === 0) {
    return false;
  }
  return _EMAIL_FORMAT.test(email);
}

// Firebase weak password: less than 6 chars
// https://firebase.google.com/docs/reference/android/com/google/firebase/auth/FirebaseAuthWeakPasswordException
export function isPasswordValid(password) {
  return (password && password.length >= _PASSWORD_MIN_LENGTH);
}

export function logOut() {
  analyticsLogEvent('logout');
  auth.signOut()
      .catch(function(error) {
        analyticsLogEvent('exception', { description: ('[logOut]: ' + error.toString()) });
        console.log('Log out error: ', error);
      });
}

//--------------------
// Custom hooks
//--------------------
export function useAuthState() {
  useEffect(() => {
    const unsubscribeOnAuthStateChanged =
        auth.onAuthStateChanged((user) => {
          if (user) {
            store.dispatch(apiPostLogIn());
          } else {
            // user === null indicates user is signed out.
            store.dispatch(combinedLogOut());
          }
        });

    return () => {
      if (unsubscribeOnAuthStateChanged) {
        unsubscribeOnAuthStateChanged();
      }
    };
  }, []);
}

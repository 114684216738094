import MediaContent from 'components/mediaContent';
import VideoInformation from 'components/VideoInformation';
import RelatedVideos from './RelatedVideos';
// Third party.
import React from 'react';

const _COPY_CONFIRMATION = 'Link copied';

function VmarkContent(props) {
  const {
    // Redux states.
    collection,
    recommendation,
    // Redux actions.
    fetchRelatedVideos,
    toastShowMessage,
  } = props;
  const { currentItemId } = collection;
  const itemData = collection.items[currentItemId];
  if (!itemData || !itemData.urlInfo || !itemData.urlInfo.url) {
    return null;
  }

  const { tags, urlInfo } = itemData;

  function handleClickTag(tag) {
    props.handleClickTag && props.handleClickTag(tag);
  }

  function onCopyLink() {
    toastShowMessage(_COPY_CONFIRMATION);
  }

  return (
    <div>
      <div className="mx-auto mw-16by9-media-container">
        <MediaContent
          urlInfo={urlInfo}
        />
      </div>

      <VideoInformation
        tags={tags}
        urlInfo={urlInfo}
        onCopyLink={onCopyLink}
        handleClickTag={handleClickTag}
      />

      <RelatedVideos
        urlInfo={urlInfo}
        recommendation={recommendation}
        fetchRelatedVideos={fetchRelatedVideos}
      />
    </div>
  );
}

export default VmarkContent;

import { store } from 'appRedux/store';
import {
  positionsUpdate,
} from 'appRedux/actions';
// Third party.
import { useEffect } from 'react';

const useScrollToLastPosition = ({
  positionTarget,
} = {}) => {
  useEffect(() => {
    const safePositionState = store.getState().positions[positionTarget] || {};

    if (safePositionState.scrollY >= 0) {
      window.scrollTo(0, safePositionState.scrollY);
    }

    let scrollY = 0;

    function handleScroll() {
      scrollY = window.scrollY;
    }

    // Listen to scroll events.
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

      // Record the last scroll position before unmounting.
      store.dispatch(positionsUpdate(positionTarget, scrollY));
    };
  }, [positionTarget]);
};

export default useScrollToLastPosition;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import CreateCollectionForm from 'components/buttons/createCollectionButton/CreateCollectionForm';
import Auth from 'components/auth';
import LogInButton from 'components/buttons/LogInButton';
import SignUpButton from 'components/buttons/SignUpButton';
// Third party.
import React, { useState } from 'react';

function AuthButtons(props) {
  const {
    setAuthCard,
    showAuthRequestNote,
    hidden,
    eventPrefix,
  } = props;

  if (hidden) {
    return null;
  }

  function handleClickLogIn() {
    setAuthCard({
      show: true,
      isLogIn: true,
    });
  }

  function handleClickSignUp() {
    setAuthCard({
      show: true,
      isLogIn: false,
    });
  }

  return (
    <div>
      <hr className="mt-4 mb-3" />

      {
        showAuthRequestNote
        ? <h5 className="mb-3 text-info">
            Please sign up or log in first to save this video.
          </h5>
        : <h6 className="mb-3 text-dark text-center">
            Sign up to save this video and get personalized recommendations!
          </h6>
      }

      <div className="d-flex justify-content-center">
        <LogInButton
          block
          className="mr-3 mt-0"
          clickEventName={eventPrefix + "-click-log_in-button"}
          onClick={handleClickLogIn}
        />
        <SignUpButton
          block
          className="mt-0"
          clickEventName={eventPrefix + "-click-sign_up-button"}
          onClick={handleClickSignUp}
        />
      </div>
    </div>
  );
}

function CreatePlayListCard(props) {
  const [showAuthRequestNote, setShowAuthRequestNote] = useState(false);

  const {
    eventPrefix,
    handleSaveCollectionItem,
    setAuthCard,
    // Redux state.
    user,
  } = props;

  function handleSubmit(name) {
    if (user.isCurrentUserFresh && user.currentUser) {
      if (handleSaveCollectionItem) {
        handleSaveCollectionItem({ name });
      }
      analyticsLogEvent(eventPrefix + '-modal_save_item-submit_create_playlist');
    } else {
      setShowAuthRequestNote(true);
      analyticsLogEvent(eventPrefix + '-modal_save_item-submit_create_playlist_as_guest');
    }
  }

  return (
    <div>
      <h5 className="mt-2 mb-3 text-center">
        Create playlist
      </h5>

      <CreateCollectionForm onSubmit={handleSubmit} />

      <AuthButtons
        {...props}
        setAuthCard={setAuthCard}
        showAuthRequestNote={showAuthRequestNote}
      />
    </div>
  );
}

function Body(props) {
  const [authCard, setAuthCard] = useState({
    show: false,
    isLogIn: false,
  });

  const {
    // Redux state.
    user,
  } = props;

  // Hide from auth-not-fresh or login users.
  const hideAuth = !user.isCurrentUserFresh || user.currentUser;

  if (authCard.show && !hideAuth) {
    return (
      <div className="mt-2">
        <Auth logIn={authCard.isLogIn} />
      </div>
    );
  }

  return (
    <CreatePlayListCard
      {...props}
      setAuthCard={setAuthCard}
      hidden={hideAuth}
    />
  );
}

function CreatePlayList(props) {
  if (props.hidden) {
    return null;
  }

  return (
    <Body {...props} />
  );
}

export default CreatePlayList;

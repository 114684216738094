import { analyticsLogEvent } from 'appFirebase/helperApi';
import { APP_NAME } from 'misc/appConstants';
import SignUpButton from 'components/buttons/SignUpButton';
import InfoSection from '../InfoSection';
import SectionWrapper from '../SectionWrapper';
import Footer from '../Footer';
import BannerContent from './BannerContent';
import SocialProof from './SocialProof';
// Third party.
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';

function Explore(props) {
  useEffect(() => {
    analyticsLogEvent('screen_view', { screen_name: 'welcome' });
  }, []);

  const { eventNamePrefix="explore" } = props;

  function eventNameForClickSignUp(sectionNumber) {
    const eventName = eventNamePrefix + "_click_sign_up_button_" + sectionNumber;
    return eventName;
  }

  return (
    <Container fluid className="p-0">
      <InfoSection
        className="bg-primary"
        imageSrc="https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2FYour%20private%20lists.png?alt=media&token=3ce02d29-0607-4354-b567-7daaeb781c71"
        imageAlt="Your Private Playlists for Porn"
      >
        <BannerContent
          eventNameForClickSignUp={eventNameForClickSignUp(1)}
        />
      </InfoSection>

      <InfoSection
        className="bg-secondary"
        contentTitle="How it works"
        contentDescription={[
          "Save porn video links you enjoyed watching to " + APP_NAME + " from anywhere.",
          "We collect popular porn video links from 200+ best free porn sites everyday.",
          "Our powerful recommendation engine will find related videos you may like from our high quality collections.",
          "The more video links you save, the better recommendations you will get.",
        ]}
        imageSrc="https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2FSave%20content%20from%20any%20where.png?alt=media&token=3787878b-bb24-405c-a395-0402daf7deba"
        isReverseOrder
        showSignUpButton
        eventNameForClickSignUp={eventNameForClickSignUp(2)}
      />

      <InfoSection
        className="bg-white"
        contentTitle={"Why " + APP_NAME + "?"}
        contentDescription={[
          "Awesome porn videos are all over the web, but it's just hard to find them.",
          "The days when you had to Google using keywords and switch from site to site to look for porn you may enjoy, are over.",
          APP_NAME + " is the only place that collects popular porn videos from the best porn sites, and provide recommendations based on your personal tastes.",
          "It is the most effortless way to find the best fap material online.",
        ]}
        imageSrc="https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Frelated-videos.png?alt=media&token=0409383e-d639-48e0-af84-3226bc6ec4ae"
        showSignUpButton
        eventNameForClickSignUp={eventNameForClickSignUp(3)}
      />

      <SocialProof />

      <SectionWrapper className="bg-primary" innerClassName="p-4 text-center">
        <h1 className="text-white">Get started with { APP_NAME }</h1>
        <SignUpButton
          title="Sign Up for Free"
          className="text-primary mt-5 mobile-block"
          size="lg"
          variant="light"
          clickEventName={eventNameForClickSignUp(4)}
        />
      </SectionWrapper>

      <Footer eventNameSuffix={eventNamePrefix} />
    </Container>
  );
}

export default Explore;

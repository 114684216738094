import {
  canShowEmbedVideo,
  canShowVideoContent,
  openUrlInNewTab,
} from 'misc/appHelperFunctions';
import Frame from './Frame';
import VideoPlayer from './VideoPlayer';
// Third party.
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

function MediaContent(props) {
  const [videoPlayerError, setVideoPlayerError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [backupImageError, setBackupImageError] = useState(false);

  const urlInfo = props.urlInfo || {};
  const { url } = urlInfo;
  const thumbnail = urlInfo.thumbnail || {};
  const video = urlInfo.video || {};
  const backupThumbnailUrl = ((urlInfo.backupUrlInfo || {}).thumbnail || {}).url;

  function handleVideoPlayerError() {
    setVideoPlayerError(true);
  }

  function handleImageError() {
    setImageError(true);
  }

  function handleBackupImageError() {
    setBackupImageError(true);
  }

  function handleClickThumbnail() {
    openUrlInNewTab(url, 'click_MediaContent_thumbnail');
  }

  if (canShowEmbedVideo(urlInfo)) {
    return (
      <Frame
        src={video.embedUrl}
        backgroundImageUrl={thumbnail.url}
      />
    );
  }

  if (canShowVideoContent(urlInfo) && !videoPlayerError) {
    return (
      <VideoPlayer
        src={video.contentUrl}
        onError={handleVideoPlayerError}
      />
    );
  }

  if (thumbnail.url && !imageError) {
    return (
      <div className="pt-4 px-content-container">
        <Container className="p-0 text-center mw-md">
          <Image
            src={thumbnail.url}
            className="mw-100 mh-media-container rounded-2 cursor-pointer"
            onError={handleImageError}
            onClick={handleClickThumbnail}
          />
        </Container>
      </div>
    );
  }

  if (backupThumbnailUrl && !backupImageError) {
    return (
      <div className="pt-4 px-content-container">
        <Container className="p-0 text-center mw-md">
          <Image
            src={backupThumbnailUrl}
            className="mw-100 mh-media-container rounded-2 cursor-pointer"
            onError={handleBackupImageError}
            onClick={handleClickThumbnail}
          />
        </Container>
      </div>
    );
  }

  return null;

  // return (
  //   <Frame
  //     src={url}
  //     backgroundImageUrl={thumbnail.url}
  //   />
  // );
}

export default MediaContent;

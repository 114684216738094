import {
  IconCheck,
} from 'components/icons';
// Third party.
import React from 'react';

function Confirmation(props) {
  const confirmation = props.confirmation || {};

  if (!confirmation.show) {
    return null;
  }

  const playlistName = (confirmation.collectionInfo || {}).name || 'Your Playlist';

  return (
    <div className="pt-4 pb-3">
      <div className="d-flex align-items-center justify-content-center">
        <div className="mr-2 bg-primary round-container-sm">
          <IconCheck size={0.8} className="icon-white" />
        </div>
        <h4 className="text-dark m-0">
          Saved
        </h4>
      </div>

      <h4 className="text-center text-dark mt-3 mb-0">
        <b>{ playlistName }</b>
      </h4>
    </div>
  );
}

export default Confirmation;

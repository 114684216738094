import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  getDomain,
  openUrlInNewTab,
} from 'misc/appHelperFunctions';
import AutoSuggest from 'components/AutoSuggest';
import InputBox from 'components/InputBox';
// Third party.
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';

const _SEARCH_PLACEHOLDER = 'Search best porn sites...';
const _BUTTON_TITLE_SEARCH = 'Search';

function SearchBox(props) {
  const [initialValue, setInitialValue] = useState('');
  const [targetKeyword, setTargetKeyword] = useState('');
  const [highlightedCandidateKeyword, setHighlightedCandidateKeyword] = useState('');
  const [availableKeywordsList, setAvailableKeywordsList] = useState([]);
  const [urls, setUrls] = useState({});

  useEffect(() => {
    import("misc/dataConstants/BEST_SITES").then((constants) => {
      const { BEST_SITES } = constants;

      const allDomains = [];
      const allUrls = {};

      for (let i = 0; i < BEST_SITES.length; i++) {
        const category = BEST_SITES[i];
        const sites = category.sites;
        for (let j = 0; j < sites.length; j++) {
          const url = sites[j].url;
          const domain = getDomain(url);
          if (domain) {
            allDomains.push(domain);
            allUrls[domain] = url;
          }
        }
      }

      setAvailableKeywordsList(allDomains);
      setUrls(allUrls);
    });
  }, []);

  function handleSearch(searchString) {
    const rawKeyword = searchString || '';
    const targetUrl = urls[rawKeyword];

    if (!targetUrl) {
      return;
    }

    openUrlInNewTab(targetUrl);
    setInitialValue(rawKeyword);

    // Hide AutoSuggest.
    // When user taps on or search the same keyword as the current one,
    // auto suggest still needs to be dismissed.
    setTargetKeyword('');

    analyticsLogEvent('sites-search=' + searchString);
  }

  function handleSubmit(inputString) {
    handleSearch(highlightedCandidateKeyword || inputString);

    const eventName = highlightedCandidateKeyword
        ? ('sites-search-submit-suggested=' + highlightedCandidateKeyword)
        : ('sites-search-submit-original=' + inputString);
    analyticsLogEvent(eventName);
  }

  function handleClickCandidateTag(tag) {
    handleSearch(tag);

    analyticsLogEvent('sites-search-click-suggested=' + tag);
  }

  return (
    <div className="w-100 position-relative">
      <InputBox
        initialValue={initialValue}
        placeholder={_SEARCH_PLACEHOLDER}
        buttonTitle={_BUTTON_TITLE_SEARCH}
        hideCancelButton
        submitOnClick={handleSubmit}
        onChange={setTargetKeyword}
      />

      <AutoSuggest
        className="position-absolute mt-1"
        targetTag={targetKeyword}
        candidateTags={availableKeywordsList}
        onClickCandidateTag={handleClickCandidateTag}
        onHighlightedCandidateTagChange={setHighlightedCandidateKeyword}
        enableKeyEvents={true}
      />
    </div>
  );
}

function ToolbarSites(props) {
  return (
    <Container
      className="mw-md min-h-round-button-size"
    >
      <SearchBox {...props} />
    </Container>
  );
}

export default ToolbarSites;

import {
  adminShowModalDeleteVideo,
  adminShowModalEditBaseClickCnt,
} from 'appRedux/actions';
import {
  IconPencil,
  IconThreeDots,
  IconTrash,
} from 'components/icons';
import CustomToggleButton from 'components/buttons/CustomToggleButton';
// Third party.
import React from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';

function AdminDropDownButton(props) {
  const {
    urlInfo,
    // Redux actions.
    actionAdminShowModalDeleteVideo,
    actionAdminShowModalEditBaseClickCnt,
  } = props;

  function showModalDeleteVideo() {
    actionAdminShowModalDeleteVideo(true, urlInfo);
  }

  function showModalEditBaseClickCnt() {
    actionAdminShowModalEditBaseClickCnt(true, urlInfo);
  }

  // Note that alignRight and drop="up" cannot be used here since Dropdown.Menu
  // will still occupy bottom right space, thus force page extends on right and
  // bottom on mobile (which is a visual bug).
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggleButton}>
        <IconThreeDots size={1} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-up">
        <Dropdown.Item
          as="div"
          className="d-flex align-items-center"
          onClick={showModalEditBaseClickCnt}
        >
          <IconPencil size={1.45} className="mr-2 icon-dark" />
          <span>Edit Base Click</span>
        </Dropdown.Item>
        <Dropdown.Item
          as="div"
          className="d-flex align-items-center"
          onClick={showModalDeleteVideo}
        >
          <IconTrash size={1.45} className="mr-2 icon-dark" />
          <span>Delete</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  actionAdminShowModalDeleteVideo: (show, urlInfo) => {
    dispatch(adminShowModalDeleteVideo(show, urlInfo));
  },
  actionAdminShowModalEditBaseClickCnt: (show, urlInfo) => {
    dispatch(adminShowModalEditBaseClickCnt(show, urlInfo));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminDropDownButton);

import { auth } from 'appFirebase';
import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  apiPostSignUp,
} from 'appRedux/actions';
import ToastAutoHide from 'components/toastAutoHide/ToastAutoHide';
import {
  P_FORGOT,
  P_LOGIN,
  P_SIGNUP,
} from 'misc/appConstants';
import {
  goTo,
} from 'misc/appHelperFunctions';
import {
  ERROR_CODE,
  ERROR_MESSAGE,
  isEmailValid,
  isPasswordValid,
} from './authHelperFunctions';
import ForgotPassword from './ForgotPassword';
import EmailAndPasswordForm from './EmailAndPasswordForm';
// Third party.
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

function Auth(props) {
  const { forgotPassword=false, logIn=false } = props;

  const [isForgotPassword, setIsForgotPassword] = useState(forgotPassword);
  const [isLogIn, setIsLogIn] = useState(logIn);
  const [isLoading, setIsLoading] = useState(false);
  const toastRef = useRef(null);
  const history = useHistory();

  async function handleSubmitEmailAndPassword({ email, password }) {
    if (!isEmailValid(email)) {
      toastRef.current.show(ERROR_MESSAGE[ERROR_CODE.INVALID_EMAIL]);
      return;
    }
    if (!isPasswordValid(password)) {
      toastRef.current.show(ERROR_MESSAGE[
        isLogIn ? ERROR_CODE.WRONG_PASSWORD : ERROR_CODE.WEAK_PASSWORD]);
      return;
    }

    setIsLoading(true);

    let hasError = false;
    if (isLogIn) {
      await auth.signInWithEmailAndPassword(email, password)
          .then(() => {
            analyticsLogEvent('login', { method: 'Email/Password' });
          })
          .catch((error) => {
            hasError = true;
            const { code, message } = error;
            toastRef.current.show(ERROR_MESSAGE[code] || message);
          });
    } else {
      await auth.createUserWithEmailAndPassword(email, password)
          .then(() => {
            analyticsLogEvent('sign_up', { method: 'Email/Password' });
            props.postSignUp();
          })
          .catch((error) => {
             hasError = true;
            const { code, message } = error;
            toastRef.current.show(ERROR_MESSAGE[code] || message);
          });
    }

    // Only reset isLoading when error occurs to avoid React warning:
    // "Can't perform a React state update on an unmounted component."
    // because Auth will be unmounted once login/signup successfully.
    if (hasError) {
      setIsLoading(false);
    }
  }

  async function handleSubmitResetPassword({ email }) {
    if (!isEmailValid(email)) {
      toastRef.current.show(ERROR_MESSAGE[ERROR_CODE.INVALID_EMAIL]);
      return false;
    }

    setIsLoading(true);

    const isSuccessful = await auth.sendPasswordResetEmail(email)
        .then(() => {
          analyticsLogEvent('reset_password');
          return true;
        })
        .catch((error) => {
          const { code, message } = error;
          toastRef.current.show(ERROR_MESSAGE[code] || message);
          return false;
        });

    setIsLoading(false);

    return isSuccessful;
  }

  function enableForgotPassword() {
    if (props.shouldRedirectOnSwitch) {
      goTo(history, P_FORGOT);
    } else {
      setIsForgotPassword(true);
    }
  }

  function toggleIsLogIn() {
    if (props.shouldRedirectOnSwitch) {
      if (isLogIn) {
        goTo(history, P_SIGNUP);
      } else {
        goTo(history, P_LOGIN);
      }
    } else {
      setIsLogIn(isLogIn => !isLogIn);
    }
  }

  return (
    <div className={props.className}>
      {
        isForgotPassword
        ? <ForgotPassword
            setIsForgotPassword={setIsForgotPassword}
            setIsLogIn={setIsLogIn}
            handleSubmit={handleSubmitResetPassword}
            isLoading={isLoading}
            shouldRedirectOnSwitch={props.shouldRedirectOnSwitch}
          />
        : <EmailAndPasswordForm
            isLogIn={isLogIn}
            handleSubmit={handleSubmitEmailAndPassword}
            toggleIsLogIn={toggleIsLogIn}
            enableForgotPassword={enableForgotPassword}
            isLoading={isLoading}
          />
      }

      <ToastAutoHide
        ref={toastRef}
        isPositionAbsolute={true}
        isWarning
      />
    </div>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  postSignUp: () => {
    dispatch(apiPostSignUp());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Auth);

import {
  APP_NAME,
  P_ACCOUNT,
  P_EXPLORE,
  P_FEED,
  P_FORGOT,
  P_HOME,
  P_LOGIN,
  P_PLAYLIST,
  P_SAVED,
  P_SAVE,
  P_SIGNUP,
  // Page titles.
  PAGE_TITLES,
} from 'misc/appConstants';
import {
  logScreenView,
} from 'misc/appHelperFunctions';
import {
  composeQuery,
  useQuery,
  QUERY_ROUTE,
} from 'misc/queryHelpers';
import LoadingIndicator from 'components/LoadingIndicator';
import Account from 'routes/account';
import Explore from 'routes/landing/explore';
import Fallback from 'routes/fallback';
import Feed from 'routes/feed';
import ForgotPassword from 'routes/forgotPassword';
import Home from 'routes/home';
import LogIn from 'routes/logIn';
import Playlist from 'routes/playlist';
import PrivateCollections from'routes/privateCollections';
import Save from 'routes/landing/save';
import SignUp from 'routes/signUp';
// Third party.
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

function AuthRouter(props) {
  const query = useQuery();

  const { currentUser, isCurrentUserFresh } = props.user;

  if (!isCurrentUserFresh) {
    return (
      <LoadingIndicator delayInSeconds={1} className="mt-5" />
    );
  }

  return (
    <Switch>
      {/**
        * Pages to display to both login and guest users.
        */}
      <Route exact path={P_PLAYLIST} render={() => {
        logScreenView(P_PLAYLIST);
        document.title = PAGE_TITLES[P_PLAYLIST] || APP_NAME;
        return (<Playlist />);
      }}/>
      <Route exact path={P_FEED} render={() => {
        logScreenView(P_FEED);
        document.title = PAGE_TITLES[P_FEED] || APP_NAME;
        return (<Feed />);
      }}/>
      {/**
        * Pages to display with auth
        */}
      <Route exact path={P_SAVED} render={() => {
        if (!currentUser) {
          return (<Redirect to={ P_LOGIN + composeQuery({ [QUERY_ROUTE]: P_SAVED }) } />);
        }
        logScreenView(P_SAVED);
        document.title = PAGE_TITLES[P_SAVED] || APP_NAME;
        return (<PrivateCollections />);
      }} />
      <Route exact path={P_ACCOUNT} render={() => {
        if (!currentUser) {
          return (<Redirect to={P_LOGIN + composeQuery({ [QUERY_ROUTE]: P_ACCOUNT }) } />);
        }
        logScreenView(P_ACCOUNT);
        document.title = PAGE_TITLES[P_ACCOUNT] || APP_NAME;
        return (<Account currentUser={currentUser} />);
      }} />
      {/**
        * Pages to display without auth
        */}
      <Route exact path={P_FORGOT} render={() => {
        if (currentUser) {
          return (<Redirect to={query.get(QUERY_ROUTE) || P_HOME} />);
        }
        logScreenView(P_FORGOT);
        document.title = PAGE_TITLES[P_FORGOT] || APP_NAME;
        return (<ForgotPassword {...props} />);
      }}/>
      <Route exact path={P_LOGIN} render={() => {
        if (currentUser) {
          return (<Redirect to={query.get(QUERY_ROUTE) || P_HOME} />);
        }
        logScreenView(P_LOGIN);
        document.title = PAGE_TITLES[P_LOGIN] || APP_NAME;
        return (<LogIn {...props} />);
      }}/>
      <Route exact path={P_SIGNUP} render={() => {
        if (currentUser) {
          return (<Redirect to={query.get(QUERY_ROUTE) || P_HOME} />);
        }
        logScreenView(P_SIGNUP);
        document.title = PAGE_TITLES[P_SIGNUP] || APP_NAME;
        return (<SignUp {...props} />);
      }}/>
      <Route exact path={P_EXPLORE} render={() => {
        // Landing page experiment.
        if (currentUser) {
          return (<Redirect to={P_SAVED} />);
        }
        logScreenView(P_EXPLORE);
        document.title = PAGE_TITLES[P_EXPLORE] || APP_NAME;
        return (<Explore />);
      }}/>
      <Route exact path={P_SAVE} render={() => {
        // Landing page experiment.
        if (currentUser) {
          return (<Redirect to={P_SAVED} />);
        }
        logScreenView(P_SAVE);
        document.title = PAGE_TITLES[P_SAVE] || APP_NAME;
        return (<Save />);
      }}/>
      <Route exact path={P_HOME} render={() => {
        return (<Home />);
      }}/>
      {/**
        * Pages to display anyway
        */}
      <Route path={P_HOME} render={() => {
        logScreenView('/fallback');
        document.title = PAGE_TITLES['/fallback'] || APP_NAME;
        return (<Fallback />);
      }} />
    </Switch>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  null,
)(AuthRouter);

import {
  IconCheck,
} from 'components/icons';
// Third party.
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const _CATEGORIES = [
  'Japanese',
  'Anal',
  'Amateur',
  'Lesbian',
  'MILF',
  'Teen',
  'Big Tits',
  'Babe',
  'Masturbation',
  'Big Ass',
  'Big Dick',
  'Cosplay',
  //'Gay',
];

const _IMAGES = {
  'Amateur': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-amateur.jpg?alt=media&token=4a7249ec-087b-4196-a68b-22aa10b9a7a7',
  'Anal': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-anal.jpg?alt=media&token=f1a6edad-ca45-4661-84b9-ad4d125aab3e',
  'Babe': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-babe.jpg?alt=media&token=646570b9-6f45-48e7-bca1-dd9a485fe112',
  'Big Ass': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-big%20ass.jpg?alt=media&token=42f45b5b-80fe-409e-b633-f38aee421662',
  'Big Dick': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-big%20dick.jpg?alt=media&token=68dc1f7e-06f8-4939-9374-82611c72a8c2',
  'Big Tits': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-big%20tits.jpg?alt=media&token=4fc7e6ca-b2e0-4a39-a1a2-b4131c171221',
  'Cosplay': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-cosplay.jpg?alt=media&token=c379f6b3-7209-49e0-b5e2-70c5707bf080',
  'Japanese': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-japanese.png?alt=media&token=09a484f8-8211-44c3-966e-9654bfcb4352',
  'Masturbation': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-masturbation.jpg?alt=media&token=af2191bd-c437-4753-9461-6281d8ff3db2',
  'MILF': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-milf.jpg?alt=media&token=db8f904b-cca5-4e32-95b5-dce41d7f4d59',
  'Teen': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-teen.jpg?alt=media&token=a0e99f3c-ee57-4a4f-ae06-cdb36d45c8cc',
  'Gay': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-gay.png?alt=media&token=cb5f16ca-4d12-41f4-b269-9a1b696fa1b2',
  'Lesbian': 'https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fcategory-lesbian.jpg?alt=media&token=827a437c-019f-486a-b49f-cda6f47832f1',
};

function SelectedSign(props) {
  if (!props.isSelected) {
    return null;
  }

  return (
    <div className="bg-primary position-absolute top-2 left-2 round-container-sm">
      <IconCheck size={0.8} className="icon-white" />
    </div>
  );
}

function CategoriesGrid(props) {
  const {
    selectedCategories,
    setSelectedCategories,
  } = props;

  const categoryItems = _CATEGORIES.map((name) => {
    function handleClick() {
      const nextSelectedCategories = {
        ...selectedCategories,
        [name]: !selectedCategories[name],
      }
      setSelectedCategories(nextSelectedCategories);
    }

    const isSelected = selectedCategories[name];
    const imageCoverClassName = isSelected ? "transparent-50" : "transparent";

    return (
      <Col
        key={name}
        xs={6} sm={4}
        className="mobile-px-gutter"
      >
        <div
          className="cursor-pointer mb-2"
          onClick={handleClick}
        >
          <div
            className="bg-secondary thumbnail-image-container-75 rounded-2"
            style={{ backgroundImage:`url("${_IMAGES[name]}")` }}
          >
            <div className={"w-100 h-100 bg-dark position-absolute top-0 left-0 rounded-2 " + imageCoverClassName} />
            <SelectedSign isSelected={isSelected} />
          </div>
          <h5 className="mt-1 mb-3">
            { name }
          </h5>
        </div>
      </Col>
    );
  });

  return (
    <div className="text-center mx-auto mw-md px-content-container">
      <Row>
        { categoryItems }
      </Row>

      <div className="mobile-h-150px desktop-h-200px" />
    </div>
  );
}

export default CategoriesGrid;

import {
  toastSetShowMessage,
} from 'appRedux/actions';
import ToastAutoHide from './ToastAutoHide';
// Third party.
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

function ToastAutoHideContainer(props) {
  const toastRef = useRef(null);

  const {
    id,
    toast,
    toastShowMessage,
  } = props;
  const showMessage = toast.showMessage[id];

  useEffect(() => {
    if (showMessage && toastRef) {
      toastRef.current.show(showMessage);
    }
  }, [showMessage, toastRef]);

  function onClose() {
    toastShowMessage(id, null);
  }

  return (
    <ToastAutoHide
      {...props}
      ref={toastRef}
      onClose={onClose}
    />
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  toast: state.toast,
});

const mapDispatchToProps = (dispatch) => ({
  toastShowMessage: (id, message) => {
    dispatch(toastSetShowMessage(id, message));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToastAutoHideContainer);

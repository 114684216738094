import {
  P_LOGIN,
  P_SIGNUP,
} from 'misc/appConstants';
import {
  getMaskedEmail,
  goTo,
} from 'misc/appHelperFunctions';
import ButtonWithLoadingState from 'components/buttons/ButtonWithLoadingState';
// Third party.
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const _EMAIL = 'email';
const _EMAIL_PLACEHOLDER = 'Email';

function Footer(props) {
  const history = useHistory();

  const { setIsForgotPassword, setIsLogIn, shouldRedirectOnSwitch } = props;

  function handleClickSignUp() {
    if (shouldRedirectOnSwitch) {
      goTo(history, P_SIGNUP);
    } else {
      setIsForgotPassword(false);
      setIsLogIn(false);
    }
  }

  function handleClickLogIn() {
    if (shouldRedirectOnSwitch) {
      goTo(history, P_LOGIN);
    } else {
      setIsForgotPassword(false);
      setIsLogIn(true);
    }
  }

  return (
    <div>
      <hr className="my-5" />

      <div className="d-flex justify-content-center">
        <h6
          className="mr-3 text-dark cursor-pointer form-bottom-link"
          onClick={handleClickSignUp}
        >
          Sign up
        </h6>
        <h6
          className="text-dark cursor-pointer form-bottom-link"
          onClick={handleClickLogIn}
        >
          Log in
        </h6>
      </div>
    </div>
  );
}

function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  const [isSuccessfull, setIsSuccessful] = useState(false);

  const { isLoading } = props;

  function handleChange(event) {
    // Do not change if it's already loading.
    if (isLoading) {
      return;
    }

    switch (event.target.name) {
      case _EMAIL:
        setEmail(event.target.value);
        break;
      default:
        console.log('Input name is invalid.');
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    // Do not submit again if it's already loading.
    if (isLoading) {
      return;
    }

    if (props.handleSubmit) {
      props.handleSubmit({ email })
          .then((isSuccessful) => {
            if (isSuccessful) {
              setIsSuccessful(true);
            }
          });
    }
  }

  if (isSuccessfull) {
    const maskedEmail = getMaskedEmail(email);

    return (
      <div>
        <p className="text-dark">
          We've sent an email to {maskedEmail}. Click the link in the email to reset your password.
        </p>
        <p className="text-dark">
          If you don't see the email, check other places it might be, like your junk, spam, social, or other folders.
        </p>

        <Footer {...props} />
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-dark text-center mb-4">Reset password</h2>
      <p className="text-dark text-center ">
        Please enter your email and we’ll send you instructions to reset your password.
      </p>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId='formBasicEmail'>
          <Form.Control
            type={_EMAIL}
            placeholder={_EMAIL_PLACEHOLDER}
            name={_EMAIL}
            value={email}
            onChange={handleChange}
            autoFocus={true}
          />
        </Form.Group>

        <Form.Group controlId='formBasicSubmitButton'>
          <ButtonWithLoadingState
            variant='primary'
            type='submit'
            block
            isLoading={isLoading}
          >
            Send reset link
          </ButtonWithLoadingState>
        </Form.Group>
      </Form>

      <Footer {...props} />
    </div>
  );
}

export default ForgotPassword;

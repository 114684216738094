export const APP_NAME = 'Videomarx';

export const ADMIN_ACCOUNTS = {
  "videomarxapp@gmail.com": true,
  "videomarx.collection@gmail.com": true,
  "xxbest1989@gmail.com": true,
};

export const DISABLE_LOG_EVENT_ACCOUNTS = {
  ...ADMIN_ACCOUNTS,
  "nebulashine.song@gmail.com": true,
};

// Keys.
export const ARROW_DOWN_KEY_CODE = 40;
export const ARROW_UP_KEY_CODE = 38;
export const BACK_SPACE_KEY_CODE = 8;
export const ENTER_KEY_CODE = 13;
export const ESCAPE_KEY_CODE = 27;

// Route paths.
export const P_HOME = '/';
export const P_ACCOUNT = '/account';
export const P_SITES = '/sites';
export const P_COLLECTION = '/collection';
export const P_CONTACT = '/contact';
export const P_FEED = '/feed';
export const P_FORGOT = '/forgot';
export const P_HELP = '/help';
export const P_LOGIN = '/login';
export const P_PLAYLIST = '/playlist';
export const P_PORNSTARS = '/pornstars';
export const P_PRIVACY = '/privacy';
export const P_SAVED = '/saved';
export const P_SEARCH = '/';      // currently search results is displayed in home page.
export const P_SIGNUP = '/signup';
export const P_TERMS = '/terms';
export const P_VMARK = '/vmark';

export const P_TAGS = '/tags';
export const P_COLLECTION_TAGS = P_COLLECTION + P_TAGS;

// Landing page experiments.
export const P_EXPLORE = '/explore';
export const P_SAVE = '/save';

// Page titles.
function pageTitle(title) {
  if (!title) {
    return APP_NAME + ": best porn videos loved by Asian users";
  }
  return title + " - " + APP_NAME + ".com";
}

export const PAGE_TITLES = {
  [P_HOME]: pageTitle(),
  [P_ACCOUNT]: pageTitle("Account"),
  [P_SITES]: pageTitle("Best porn sites"),
  [P_COLLECTION]: pageTitle("My playlist"),
  [P_CONTACT]: pageTitle("Contact"),
  [P_FEED]: pageTitle(),
  [P_FORGOT]: pageTitle("Reset password"),
  [P_HELP]: pageTitle("Help"),
  [P_LOGIN]: pageTitle("Log in"),
  [P_PLAYLIST]: pageTitle("Playlist"),
  [P_PORNSTARS]: pageTitle("Pornstars"),
  [P_PRIVACY]: pageTitle("Privacy"),
  [P_SAVED]: pageTitle("My playlists"),
  [P_SIGNUP]: pageTitle("Sign up"),
  [P_TERMS]: pageTitle("Terms"),
  [P_VMARK]: pageTitle("Video"),
  [P_EXPLORE]: pageTitle(),
  [P_SAVE]: pageTitle(),
  "/fallback": pageTitle("Page not found"),
};

// Others
export const CONTACT_EMAIL = "contact@videomarx.com";
export const MAX_TAG_LENGTH = 26;
export const ONBOARDNIG_URL_INFO = {
  url: 'onboarding',
};

export const DEFAULT_FEED_CATEGORY = 'All recommendations';
export const FEED_CATEGORIES = [
  {
    name: DEFAULT_FEED_CATEGORY,
    keywords: [],
  },
  {
    name: 'Chinese',
    keywords: ['国产', '中国', '麻豆', '探花', 'swag', "性感"],
  },
  {
    name: 'Japanese',
    keywords: ['japanese'],
  },
  {
    name: 'Korean',
    keywords: ['korean'],
  },
  {
    name: 'Western',
    keywords: ['blonde teen', 'ebony teen', 'euro babe', 'french babe',
      'latina babe', 'latina teen', 'russian babe', 'russian teen',
      'step sister'],
  },
];

export const AVAIABLE_RECOMMEND_KEYWORDS = [
  '国产', 'swag', '麻豆', '探花', 'japanese teen', 'chinese-sub', 'rustudio',
  'japanese uniform', 'japanese censored', 'japanese uncensored', 'japanese sister',
  'japanese spring', 'japanese time stop', 'japanese sport', 'japanese teacher',
  'blonde teen', 'ebony teen', 'euro babe', 'french babe',
  'latina babe', 'latina teen', 'russian babe', 'russian teen', 'step sister',
  '中国', "性感",
];

// Configs
export const CONFIGS_DISPLAY_MEDIA = {
  'iyottube.com': {
    video: 'yes',
  },
  'javdoe.tv': {
    embed: 'no',
  },
  'javhihi.com': {
    video: 'yes',
  },
  'kaplog.com': {
    video: 'yes',
  },
  'liboggirls.net': {
    embed: 'no',
  },
  'motherless.com': {
    video: 'yes',
  },
  'paradisehill.cc': {
    video: 'yes',
  },
  'plusone8.com': {
    video: 'yes',
  },
  'porn300.com': {
    embed: 'no',
  },
  'redtube.com': {
    embed: 'no-mobile',
  },
  'sextvx.com': {
    video: 'yes',
  },
  'tube8.com': {
    embed: 'no-mobile',
  },
  'vikiporn.com': {
    video: 'yes',
  },
  'vqtube.com': {
    embed: 'no',
  },
  'xtapes.to': {
    embed: 'no',
  },
  'xxxstreams.eu': {
    embed: 'no-mobile',
  },
  'youporn.com': {
    embed: 'no',
  },
};

export const navbarHideInputBox = () => {
  return  (dispatch, getState) => {
    dispatch(navbarUpdateSaveBox(false));
    dispatch(navbarUpdateSearchBox(false));
  };
};

export const navbarUpdateSaveBox = (enabled) => ({
  type: 'NAVBAR_UPDATE_SAVE_BOX',
  enabled,
});

export const navbarUpdateSearchBox = (enabled, autoFocus=false) => ({
  type: 'NAVBAR_UPDATE_SEARCH_BOX',
  enabled,
  autoFocus,
});

import PageFooter from 'components/PageFooter';
import Categories from './Categories';
import Collections from './Collections';
import FullMovies from './FullMovies';
import Header from './Header';
import Stars from './Stars';
import TopSearches from './TopSearches';
import Introduction from './Introduction';
import BestPractise from './BestPractise';
import LatestVideos from './LatestVideos';
import WeeklyHotVideos from './WeeklyHotVideos';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function Shortcuts(props) {
  return (
    <Container className="p-0 mw-md">
      <Header />
      <Collections {...props} />
      <div className="page-container">
        <Stars {...props} />
        <TopSearches {...props} />
        <LatestVideos {...props}/>
        <WeeklyHotVideos {...props}/>
        <FullMovies {...props} />
        <Categories {...props} />
        {/*
        <TopSearches {...props} showAll />
        */}
        <br/>
        <Introduction/>
        <BestPractise/>
        <PageFooter />
      </div>
    </Container>
  );
}

export default Shortcuts;

// Third party.
import React from "react";

function PremiumBadge(props) {
  return (
    <p className="badge badge-info text-dark bg-info-transparent-80">
      Premium
    </p>
  );
}

export default PremiumBadge;

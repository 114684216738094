import {
  apiAddCollectionItem,
  apiCreatePrivateCollection,
  apiFetchCollectionVidoes,
  apiUpdatePrivateCollectionInfo,
  combinedUpdateCollectionFilter,
  modalShowDeleteItem,
  modalShowTagsEditor,
  navbarUpdateSaveBox,
  navbarUpdateSearchBox,
  searchReset,
  searchUpdateSearchInput,
  tagsUpdateFilter,
} from 'appRedux/actions';
import NavbarWithAuth from './NavbarWithAuth';
import NavbarWithoutAuth from './NavbarWithoutAuth';
// Third party.
import React from 'react';
import { connect } from 'react-redux';

function AppTopNavbar(props) {
  const { currentUser, isCurrentUserFresh, isOnboarding } = props.user || {};
  const isLoggedIn = isCurrentUserFresh && currentUser;

  if (isOnboarding) {
    return null;
  }

  if (isLoggedIn) {
    return (
      <NavbarWithAuth {...props} />
    );
  }

  return (
    <NavbarWithoutAuth
      {...props}
      isCurrentUserFresh={isCurrentUserFresh}
    />
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  navbar: state.navbar,
  currentCollectionInfo: state.collection.currentCollectionInfo,
  collectionCurrentItemId: state.collection.currentItemId,
  collectionFilter: state.collection.filter,
  searchInput: state.search.searchInput,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  actionApiAddCollectionItem: (urlInfo, collectionInfo) => {
    dispatch(apiAddCollectionItem(urlInfo, collectionInfo));
  },
  actionApiCreatePrivateCollection: (name) => {
    dispatch(apiCreatePrivateCollection(name));
  },
  actionApiFetchCollectionVidoes: (collectionId) => {
    dispatch(apiFetchCollectionVidoes(collectionId));
  },
  actionNavbarUpdateSearchBox: (enabled, autoFocus) => {
    dispatch(navbarUpdateSearchBox(enabled, autoFocus));
  },
  actionSearchUpdateSearchInput: (searchInput) => {
    dispatch(searchUpdateSearchInput(searchInput));
  },
  actionNavbarUpdateSaveBox: (enabled) => {
    dispatch(navbarUpdateSaveBox(enabled));
  },
  actionSearchReset: () => {
    dispatch(searchReset());
  },
  showModalDeleteItem: (show, itemId, redirectPath) => {
    dispatch(modalShowDeleteItem(show, itemId, redirectPath));
  },
  showModalTagsEditor: (show, itemId) => {
    dispatch(modalShowTagsEditor(show, itemId));
  },
  updateCollectionFilter: (filter) => {
    dispatch(combinedUpdateCollectionFilter(filter));
  },
  updatePrivateCollectionInfo: (info) => {
    dispatch(apiUpdatePrivateCollectionInfo(info));
  },
  updateTagsFilter: (filter) => {
    dispatch(tagsUpdateFilter(filter));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppTopNavbar);

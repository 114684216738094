export const videoPeeksSetIsFetchingLatestVideos = (isFetchingLatestVideos) => ({
  type: 'VIDEO_PEEKS_IS_FETCHING_LATEST_VIDEOS',
  isFetchingLatestVideos,
});

export const videoPeeksSetLatestVideos = (latestVideos) => ({
  type: 'VIDEO_PEEKS_SET_LATEST_VIDEOS',
  latestVideos,
});

export const videoPeeksSetIsFetchingWeeklyHotVideos = (isFetchingWeeklyHotVideos) => ({
  type: 'VIDEO_PEEKS_IS_FETCHING_WEEKLY_HOT_VIDEOS',
  isFetchingWeeklyHotVideos,
});

export const videoPeeksSetWeeklyHotVideos = (weeklyHotVideos) => ({
  type: 'VIDEO_PEEKS_SET_WEEKLY_HOT_VIDEOS',
  weeklyHotVideos,
});

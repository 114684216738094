import {
  openUrlInNewTab,
} from 'misc/appHelperFunctions';
import {
  IconArrowUpRight,
} from 'components/icons';
// Third party.
import React from 'react';

const _MAX_URL_LENGTH_TO_DISPLAY = 20;

function DomainInfo(props) {
  const {
    url="",
    domain,
    showArrow,
    clickEventName,
    isSmall,
    onClick,
    hideDomain,
  } = props;

  let validDomain = domain;
  if (!validDomain) {
    // If domain is not available, simply display the first few characters
    // to represent the doamin information.
    validDomain = url.substring(0, _MAX_URL_LENGTH_TO_DISPLAY);
    if (validDomain && validDomain.length < url.length) {
      validDomain += '...';
    }
  }

  if (!validDomain) {
    return null;
  }

  if (hideDomain) {
    validDomain = 'xxx.xxx'
  }

  function handleClick() {
    if (!hideDomain) {
      openUrlInNewTab(url, clickEventName || 'click_domain_info')

      if (onClick) {
        onClick();
      }
    }
  }

  if (isSmall) {
    return (
      <small
        className={"text-truncate text-primary link-container"}
        onClick={handleClick}
      >
        <small className="text-primary">{ validDomain }</small>

        {
          showArrow &&
          <IconArrowUpRight className="ml-2" size={0.75} />
        }
      </small>
    );
  }

  return (
    <span
      className={"text-truncate text-black-50 link-container"}
      onClick={handleClick}
    >
      <span>{ validDomain }</span>

      {
        showArrow &&
        <IconArrowUpRight className="ml-2" size={0.75} />
      }
    </span>
  );
}

export default DomainInfo;

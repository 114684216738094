import {
  IconX,
} from 'components/icons';

import TagsInput from './tagsInput';
// Third party.
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

const _BUTTON_TITLE = 'Save';

function Header(props) {
  const { handleClose } = props;

  return (
    <div className="d-flex align-items-center justify-content-between my-1">
      <h5 className="mb-0">Edit tags</h5>

      <div className="round-button-container mr-n2" onClick={handleClose}>
        <IconX size={1.5} />
      </div>
    </div>
  );
}

function TagsEditor(props) {
  const { itemId } = props.modal.tagsEditor;
  const itemData = props.collection.items[itemId] || {};
  const itemTags = itemData.tags || [];

  const [newTag, setNewTag] = useState('');
  const [tags, setTags] = useState([...itemTags]);
  const [tagSelected, setTagSelected] = useState({});

  function addNewTagToTags(optNewTag) {
    // Clear input value.
    setNewTag('');

    const newTagToAdd = optNewTag || newTag;

    if (!newTagToAdd || !newTagToAdd.trim().length) {
      return tags;
    }

    const normalizedNewTag = newTagToAdd.trim().toLowerCase();
    if (tags.includes(normalizedNewTag)) {
      return tags;
    }

    const tagsWithNewTag = [...tags, normalizedNewTag];
    setTags((tags) => tagsWithNewTag);

    return tagsWithNewTag;
  }

  function handleClose() {
    const { closeModal } = props;

    if (closeModal) {
      closeModal();
    }
  }

  function handleSave() {
    const { updateCollectionItemTags } = props;

    handleClose();

    if (updateCollectionItemTags) {
      const latestTags = addNewTagToTags();
      updateCollectionItemTags(itemId, latestTags);
    }
  }

  return (
    <div className="px-3 pb-3">
      <Header handleClose={handleClose} />

      <TagsInput
        {...props}
        newTag={newTag}
        setNewTag={setNewTag}
        tags={tags}
        setTags={setTags}
        addNewTagToTags={addNewTagToTags}
        tagSelected={tagSelected}
        setTagSelected={setTagSelected}
        handleSave={handleSave}
      />

      <Button variant='primary' block className="mt-3" onClick={handleSave}>
        { _BUTTON_TITLE }
      </Button>
    </div>
  );
}

export default TagsEditor;

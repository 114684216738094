import { auth, db, functions } from 'appFirebase';
import { getDomain } from 'misc/appHelperFunctions';
import {
  collectionAddItem,
  collectionBringItemToFront,
  collectionDeleteItem,
  collectionSetisFetchingCurrentCollectionInfo,
  collectionSetIsFetchingItems,
  collectionSetIsFetchingPrivateCollections,
  collectionSetPrivateCollections,
  collectionUpdateCurrentCollectionInfo,
  collectionUpdateItems,
  collectionUpdateItemTags,
  collectionUpdateItemDetails,
  collectionUpdateItemUrlInfo,
  collectionUpdatePrivateCollectionInfo,
  collectionUpdateItemPrice,
} from '../collection';
import {
  apiUpdateUserPreferredCategories,
} from './recommendationApi';

// Firestore option to not fetch cached data but only fetch data from server.
// https://firebase.google.com/docs/firestore/query-data/get-data#source_options
// https://firebase.google.com/docs/reference/js/firebase.firestore.GetOptions
// const _OPT_SOURCE_SERVER = { source: 'server' }

const _1_MINS_IN_MS = 60000; // 1 * 60 * 1000

/*
 * Add collection item.
 */
export const apiAddCollectionItem = (urlInfo, collectionInfo) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser ||
        !urlInfo || !urlInfo.url ||
        !collectionInfo) {
      return;
    }

    let collectionId = collectionInfo.id;

    // Create a new private collection if the specified collectionId is invalid,
    // but the specified collection name is valid.
    if (!collectionId && collectionInfo.name) {
      const newCollectionResult = await createPrivateCollection(
        dispatch,
        getState,
        /* userId */auth.currentUser.uid,
        /* name */collectionInfo.name
      );

      if (newCollectionResult.collectionId) {
        collectionId = newCollectionResult.collectionId;
      }
    }

    if (!collectionId) {
      return;
    }

    const url = urlInfo.url;

    const createdTimestampMs = new Date().getTime();

    // Check if url already exists in the collection to avoid
    // adding duplicate items with the same url.
    const collectionItemIds = getState().collection.itemIds[collectionId] || [];
    const itemsData = getState().collection.items;
    const itemIdForUrl = collectionItemIds.find((id) => {
      const itemData = itemsData[id] || {};
      return (
        itemData.urlInfo &&
        ((url === itemData.urlInfo.url) || (url === itemData.urlInfo.absoluteUrl))
      );
    });

    if (itemIdForUrl) {
      // Update UI immediately.
      dispatch(collectionBringItemToFront(collectionId, itemIdForUrl, createdTimestampMs));

      // Update the item in database with the new createdTimestampMs so it will
      // be fetched in the correct order in future.
      await db
        .collection('users').doc(auth.currentUser.uid)
        .collection('privateCollections').doc(collectionId)
        .collection('items').doc(itemIdForUrl)
        .update({
          createdTimestampMs,
        })
        .catch((error) => { console.error("Error updating collecton item createdTimestampMs: ", error) });

      return;
    }

    const userPrivateCollectionItemRef = db
        .collection('users').doc(auth.currentUser.uid)
        .collection('privateCollections').doc(collectionId)
        .collection('items').doc();
    const itemId = userPrivateCollectionItemRef.id;

    // Update UI immediately to indicate the item is added, and meta data is
    // actively being extracted.
    const itemData = {
      urlInfo: {
        url,
        domain: getDomain(url),
        // Client local property to indicate it's actively fetching url information.
        isFetching: true,
      },
      createdTimestampMs,
    };
    // Take the most adavantage of available information in urlInfo to diplay
    // during target html fetching and parsing.
    itemData.urlInfo = {
      ...itemData.urlInfo,
      ...urlInfo,
    };
    dispatch(collectionAddItem(collectionId, itemId, itemData));

    // Call cloud function to extract meta data of the given url and add
    // the new collection item in the database.
    const addCollectionItem = functions.httpsCallable('addCollectionItem');
    // Get response.
    const requestData = {
      // Nonnull.
      url,
      collectionId,
      itemId,
      // Nullable.
      createdTimestampMs,
    };

    // Add backupUrlInfo if valid fields exist.
    //
    // Prioritize the fields in urlInfo.backupUrlInfo over urlInfo.
    const flatUrlInfo = {
      ...urlInfo,
      ...urlInfo.backupUrlInfo,
    }
    // Construct backupUrlInfo.
    const backupUrlInfo = {};
    // For backupUrlInfo.title.
    if (flatUrlInfo.title) {
      backupUrlInfo.title = flatUrlInfo.title;
    }
    // For backupUrlInfo.thumbnail.url.
    if ((flatUrlInfo.thumbnail || {}).url) {
      backupUrlInfo.thumbnail = {
        url: flatUrlInfo.thumbnail.url,
      }
    }
    // For backupUrlInfo.video.durationInSec.
    if ((flatUrlInfo.video || {}).durationInSec) {
      backupUrlInfo.video = {
        durationInSec: flatUrlInfo.video.durationInSec,
      }
    }
    // Add optionalBackupUrlInfo if backupUrlInfo has valid field(s).
    if (Object.keys(backupUrlInfo).length) {
      requestData.optionalBackupUrlInfo = backupUrlInfo;
    }

    const res = await addCollectionItem(requestData)
        .then((result) => {
          return result.data;
        })
        .catch((error) => { console.error("Error adding collecton item: ", error) });

    // Update UI to reflect the latest meta data extracted and returned
    // by the clound function.
    if (res && res.itemData && res.itemData.urlInfo) {
      const urlInfo = res.itemData.urlInfo || {};
      dispatch(collectionUpdateItemUrlInfo(collectionId, itemId, urlInfo));
      // Update user preferred categories according to saved item.
      dispatch(apiUpdateUserPreferredCategories(urlInfo.categories, /*incAmount*/3));
    }
  };
};

/*
 * Delete collection item.
 */
export const apiDeleteCollectionItem = (itemId) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser ||
        !itemId) {
      return;
    }

    const collectionId = (getState().collection.currentCollectionInfo || {}).id;
    if (!collectionId) {
      return;
    }

    const isSuccessful = await db
        .collection('users').doc(auth.currentUser.uid)
        .collection('privateCollections').doc(collectionId)
        .collection('items').doc(itemId)
        .delete()
        .then(() => true)
        .catch((error) => { console.error("Error deleting collection item: ", error); });

    if (isSuccessful) {
      dispatch(collectionDeleteItem(collectionId, itemId));
    }
  };
};

/*
 * Fetch collection based on the collectionId and
 * replace the current collection with it.
 */
export const apiFetchCollection = (collectionInfo) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser ||
        !collectionInfo || !collectionInfo.id) {
      return;
    }

    //--------------------
    // Indicates it is fetching collection items.
    //--------------------
    dispatch(collectionSetisFetchingCurrentCollectionInfo(true));

    //--------------------
    // Update currentCollectionInfo.
    //--------------------
    const collectionId = collectionInfo.id;
    dispatch(collectionUpdateCurrentCollectionInfo({
      id: collectionId,
      name: collectionInfo.name,
    }));

    //--------------------
    // Fetch collection items
    //--------------------
    dispatch(collectionSetIsFetchingItems(true));

    const pendingItems = [];
    const currentTimestampMs = new Date().getTime();

    const collectionItems = await db
        .collection('users').doc(auth.currentUser.uid)
        .collection('privateCollections').doc(collectionId)
        .collection('items')
        .orderBy("createdTimestampMs", "desc")
        .get()
        .then(snapshot => {
          const items = {};
          if (!snapshot.empty) {
            snapshot.forEach(doc => {
              // doc.data() is never undefined for query doc snapshots.
              const itemData = doc.data();

              // Collect pending items.
              if (itemData && itemData.urlInfo && itemData.urlInfo.isFetching) {
                const createdTimestampMs = itemData.createdTimestampMs || 0;
                if ((currentTimestampMs - createdTimestampMs) > _1_MINS_IN_MS) {
                  // Simply turn off isFetching flag for items created a long
                  // time ago. If the item was created a long item ago and
                  // still has urlInfo.isFetching === true, it is likely to be
                  // an error caused during url info fetching / extraction.
                  itemData.urlInfo.isFetching = false;
                } else {
                  pendingItems.push({
                    id: doc.id,
                    itemData,
                  });
                }
              }

              // Add item data to items.
              items[doc.id] = itemData;
            });
          }
          return items;
        })
        .catch((error) => { console.error('Error fetching collection items', error); });

    dispatch(collectionUpdateItems(collectionId, collectionItems));

    //--------------------
    // Listen to changes for pending items whose urlInfo is actively being fetched.
    //--------------------
    pendingItems.forEach((item) => {
      const itemId = item.id;
      const unsubscribe = db
          .collection('users').doc(auth.currentUser.uid)
          .collection('privateCollections').doc(collectionId)
          .collection('items').doc(itemId)
          .onSnapshot((doc) => {
            const itemData = doc.data();
            if (!itemData || !itemData.urlInfo || !itemData.urlInfo.isFetching) {
              // Stop listening to changes when url info fetching is done.
              unsubscribe();
              // Update UI.
              if (itemData && itemData.urlInfo) {
                dispatch(collectionUpdateItemUrlInfo(collectionId, itemId, itemData.urlInfo));
              }
            }
          },
          (error) => {
            console.error('Error listening to item changes: ', error);
          });
    });
  };
};

export const apiFetchPrivateCollections = () => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser) {
      return;
    }

    //--------------------
    // Indicates it is fetching.
    //--------------------
    dispatch(collectionSetIsFetchingPrivateCollections(true));

    const privateCollections = await db
        .collection('users').doc(auth.currentUser.uid)
        .collection('privateCollections')
        .get()
        .then((querySnapshot) => {
          const collections = {};
          querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots.
              const collectionData = doc.data();
              const collectionId = doc.id;
              if (collectionId) {
                collections[collectionId] = {
                  id: collectionId,
                  name: collectionData.name || '',
                  createdTimestampMs: collectionData.createdTimestampMs || 0,
                };
              }
          });
          return collections;
        })
        .catch((error) => {
          console.error("Error fetching user collections: ", error);
          return {};
        });

    dispatch(collectionSetPrivateCollections(privateCollections));
  };
};

export const apiCreatePrivateCollection = (name) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser ||
        !name) {
      return;
    }

    const { collectionId, newPrivateCollection } = await createPrivateCollection(
        dispatch,
        getState,
        /* userId */auth.currentUser.uid,
        name
    );

    // Redirect to the new collection if creation is successful.
    if (collectionId && newPrivateCollection) {
      dispatch(apiFetchCollection({
        ...newPrivateCollection,
        id: collectionId,
      }));
    }
  };
};

export const apiUpdatePrivateCollectionInfo = (info) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser ||
        !info || !info.id || !info.name) {
      return;
    }

    dispatch(collectionUpdatePrivateCollectionInfo(info));

    const collectionId = info.id;
    // Do not save id field in the database.
    delete info['id'];

    await db
        .collection('users').doc(auth.currentUser.uid)
        .collection('privateCollections').doc(collectionId)
        .update({
          name: info.name,
        })
        .then(() => true)
        .catch((error) => {
          console.error("Error updating private collection info: ", error);
          return false;
        });
  };
};

/*
 * Update collection item tags.
 */
export const apiUpdateCollectionItemTags = (itemId, tags) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser ||
        !itemId) {
      return;
    }

    const collectionId = (getState().collection.currentCollectionInfo || {}).id;
    if (!collectionId) {
      return;
    }

    const sortedTags = [...tags].sort();

    const isSuccessful = await db
        .collection('users').doc(auth.currentUser.uid)
        .collection('privateCollections').doc(collectionId)
        .collection('items').doc(itemId)
        .set({ tags: sortedTags }, { merge: true })
        .then(() => true)
        .catch((error) => {
          console.error("Error updating collection item tags: ", error);
          return false;
        });

    if (isSuccessful) {
      dispatch(collectionUpdateItemTags(collectionId, itemId, sortedTags));
    }
  };
};

/*
 * Update collection item details.
 */
export const apiUpdateCollectionItemDetails = (itemId, title, description) => {
  return async (dispatch, getState) => {
    if (!title && !description) {
      return;
    }

    if (!auth || !auth.currentUser || !itemId) {
      return;
    }

    const collectionId = (getState().collection.currentCollectionInfo || {}).id;
    if (!collectionId) {
      return;
    }
    const data = {
      urlInfo:{}
    };
    if (title) {
      data.urlInfo.customizedTitle = title;
    }
    if (description) {
      data.urlInfo.customizedDescription = description;
    }

    const isSuccessful = await db
      .collection('users').doc(auth.currentUser.uid)
      .collection('privateCollections').doc(collectionId)
      .collection('items').doc(itemId)
      .set(data, { merge: true })
      .then(() => true)
      .catch((error) => {
        console.error("Error updating collection item title: ", error);
        return false;
      });

    if (isSuccessful) {
      dispatch(collectionUpdateItemDetails(collectionId, itemId, title, description));
    }
  };
};

/*
 * Update collection item price.
 */
export const apiUpdateCollectionItemPrice = (itemId, price) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser || !itemId) {
      return;
    }

    if (!price) {
      return;
    }

    const collectionId = (getState().collection.currentCollectionInfo || {}).id;
    if (!collectionId) {
      return;
    }

    const isSuccessful = await db
      .collection('users').doc(auth.currentUser.uid)
      .collection('privateCollections').doc(collectionId)
      .collection('items').doc(itemId)
      .set({
        urlInfo: {
          price: price,
        }
      }, { merge: true })
      .then(() => true)
      .catch((error) => {
        console.error("Error updating collection item title: ", error);
        return false;
      });

    if (isSuccessful) {
      dispatch(collectionUpdateItemPrice(collectionId, itemId, price));
    }
  };
};


//--------------------
// Private helper functions
//--------------------
const createPrivateCollection =
    async (dispatch, getState, userId, name) => {
  if (!dispatch || !getState ||
      !userId || !name) {
    return;
  }

  const userPrivateCollectionRef = db
      .collection('users').doc(userId)
      .collection('privateCollections').doc();

  // Compose new private collection info to save to the database.
  const collectionId = userPrivateCollectionRef.id;
  const createdTimestampMs = new Date().getTime();
  const newPrivateCollection = {
    name,
    createdTimestampMs,
  };

  // Update redux states for collection.privateCollections.
  const nextPrivateCollections = {
    ...getState().collection.privateCollections,
    [collectionId]:{
      ...newPrivateCollection,
      id: collectionId,
    }
  }
  dispatch(collectionSetPrivateCollections(nextPrivateCollections));

  // Update the database.
  const isSuccessful = await userPrivateCollectionRef
      .set(newPrivateCollection, { merge: true })
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.error("Error creating private collecton: ", error);
        return false;
      });

  return isSuccessful ? { collectionId, newPrivateCollection } : {};
}

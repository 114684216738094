import Auth from 'components/auth';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function LogIn(props) {
  return (
    <Container className="py-4">
      <Auth
        logIn
        shouldRedirectOnSwitch
        className="mx-auto mw-sm"
      />
    </Container>
  );
}

export default LogIn;

// Third party.
import React from "react";

const introductionList = [
  {
    title: 'Videomarx is the best porn site for Asian users',
    description: 'We collect the hottest, high quality porn videos from the best ' +
      'sites loved by Asian users using the latest technologies, and offer you ' +
      'the most effortless way to discover, search, and watch ' +
      'the best fap materials online! It is totally FREE and SAFE. ' +
      'Register and let us know your thoughts and help us make it better for ' +
      'both you and us!'
  },
  {
    title: 'Who made Videomarx?',
    description: 'We are a group of Asians spending a lot of time searching for ' +
      'the best free porn videos online. But we weren’t satisfied with any of ' +
      'the existing porn sites: they didn’t offer the exact content we were ' +
      'looking for, and many of them are filled with crazy ads. If you are ' +
      'suffering the same, this site is FOR YOU!'
  },
  {
    title: '',
    description: '你是否厌倦了满网站的广告？不知道网上最新，最火的视频有哪些？如果你也有' +
      '和我们一样的烦恼，请记住我们的网站。我们从亚洲用户的视角出发，利用最新的科技，在网上寻找整理最新、' +
      '最好看的视频，和最火热流行的关键词，给你也给我们自己提供全网最佳的看片体验！' +
      '我们保证网站的绝对安全，所有的视频免费，注册后体验更佳。'
  }
];

function Introduction() {
  let i = 1;
  const items = introductionList.map((item) => {
    const containerClassName = (i === introductionList.length) ? "" : "mb-4";
    return (
      <div key={i++} className={containerClassName}>
        <h3>
          { item.title }
        </h3>
        <h5 className="mt-3 text-black-50">
          { item.description }
        </h5>
      </div>
    )
  });

  return (
    <div className="mt-5 p-3 border border-primary rounded-2">
      { items }
    </div>
  )
}

export default Introduction;
import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  modalClose,
} from 'appRedux/actions';
import {
  IconCheck,
  IconX,
} from 'components/icons';
import Auth from 'components/auth';
import LogInButton from 'components/buttons/LogInButton';
import SignUpButton from 'components/buttons/SignUpButton';
// Third party.
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

function BenefitItem(props) {
  const { description } = props;

  return (
    <div className="mt-4 d-flex">
      <h5>
        <IconCheck size={1} className="icon-primary mr-2 mb-1" />
      </h5>
      <h5>
        { description }
      </h5>
    </div>
  );
}

function MemberBenefits(props) {
  const {
    setAuthCard,
  } = props;

  function handleClickLogIn() {
    setAuthCard({
      show: true,
      isLogIn: true,
    });
  }

  function handleClickSignUp() {
    setAuthCard({
      show: true,
      isLogIn: false,
    });
  }

  return (
    <div>
      <div className="pt-2">
        <h3 className="mb-5">Things you can do with a FREE account:</h3>

        <BenefitItem
          description="Watch staff picked premium videos"
        />
        <BenefitItem
          description="Create your own playlists"
        />
        <BenefitItem
          description="Save videos from any external sites"
        />
      </div>

      <hr className="my-4 pt-1 pb-2" />

      <div className="d-flex justify-content-center">
        <LogInButton
          block
          className="mr-3 mt-0"
          clickEventName={"ModalLoginRequest-click-log_in_button"}
          onClick={handleClickLogIn}
        />
        <SignUpButton
          block
          className="mt-0"
          clickEventName={"ModalLoginRequest-click-sign_up_button"}
          onClick={handleClickSignUp}
        />
      </div>
    </div>
  );
}

function ModalBody(props) {
  const [authCard, setAuthCard] = useState({
    show: false,
    isLogIn: false,
  });

  const {
    eventPrefix,
    // Redux actions.
    actionModalClose,
  } = props;

  useEffect(() => {
    analyticsLogEvent(eventPrefix + '-modal_login_request');
  }, [eventPrefix]);

  return (
    <div className="p-3">
      <div className="d-flex justify-content-end my-n3">
        <div className="round-button-container mr-n2" onClick={actionModalClose}>
          <IconX size={1.5} />
        </div>
      </div>

      {
        authCard.show
        ? <Auth logIn={authCard.isLogIn} />
        : <MemberBenefits setAuthCard={setAuthCard} />
      }
    </div>
  );
}

function ModalLoginRequest(props) {
  const {
    // Redux states.
    modal,
    user,
    // Redux actions.
    actionModalClose,
  } = props;

  const isGuestUser = user.isCurrentUserFresh && !user.currentUser;
  const show = modal.loginRequest.show && isGuestUser;

  return (
    <Modal
      show={show}
      onHide={actionModalClose}
      aria-labelledby="login-request-modal"
      centered
      scrollable={true}
    >
      <ModalBody {...props} />
    </Modal>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  modal: state.modal,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  actionModalClose: () => {
    dispatch(modalClose());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalLoginRequest);

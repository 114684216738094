import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  DEFAULT_SEARCH_INPUT,
} from 'appRedux/reducers/stateConstants';
import {
  searchInputToQuery,
} from 'misc/queryHelpers';
import {randomlyPickFromList} from "misc/appHelperFunctions";
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const _ITEMS = [
  {
    keyword: 'Japanese Full Movie',
    displayName: 'Japanese',
    thumbnail: 'https://cdn.javfree.sh/uploads/kawd-921.jpg',
    thumbnailPool: [
      'https://cdn.javfree.sh/uploads/kawd-921.jpg',
      'https://cdn.javfree.sh/uploads/abp-519.jpg',
      'https://cdn.javfree.sh/data/kwbd-276.jpg',
      'https://cdn.javfree.sh/uploads/ipz-794.jpg',
    ]
  },
  {
    keyword: 'Chinese Full Movie',
    displayName: 'Chinese',
    thumbnail: 'https://di.phncdn.com/videos/202007/22/335167102/original/(m=eaAaGwObaaaa)(mh=dIOSpFd1rPFqT5IT)8.jpg',
    thumbnailPool: [
      'https://di.phncdn.com/videos/202007/22/335167102/original/(m=eaAaGwObaaaa)(mh=dIOSpFd1rPFqT5IT)8.jpg',
      'https://ci.phncdn.com/videos/202005/04/310274771/original/(m=eaAaGwObaaaa)(mh=ideB0a_f3al_f6I0)6.jpg',
      'https://di.phncdn.com/videos/202006/20/325464522/original/(m=eaAaGwObaaaa)(mh=CePsYz5wOYxPfeFJ)10.jpg',

    ],
  },
  {
    keyword: 'Korean Full Movie',
    displayName: 'Korean',
    thumbnail: 'https://ci.phncdn.com/videos/201912/08/267195092/thumbs_10/(m=eaAaGwObaaaa)(mh=JBFgjwZcoItgP_fZ)8.jpg',
    thumbnailPool: [
      'https://ci.phncdn.com/videos/201912/08/267195092/thumbs_10/(m=eaAaGwObaaaa)(mh=JBFgjwZcoItgP_fZ)8.jpg',
      'https://ci.phncdn.com/videos/201908/24/243831261/original/(m=eaAaGwObaaaa)(mh=-sGZbki3gpX6GpeZ)16.jpg',
      'http://img-hw.xvideos-cdn.com/videos/thumbs169ll/26/ee/fd/26eefd5298dafc3fa8733e3d29d14acc/26eefd5298dafc3fa8733e3d29d14acc.27.jpg',
      'http://img-l3.xvideos-cdn.com/videos/thumbs169ll/ef/03/47/ef03477c9bc46adb29bc512db0616f17/ef03477c9bc46adb29bc512db0616f17.30.jpg',
      'http://img-hw.xvideos-cdn.com/videos/thumbs169ll/fc/0b/85/fc0b85c7a5dbf2b57482ca32ec7086dd/fc0b85c7a5dbf2b57482ca32ec7086dd.11.jpg',
    ],
  },
  {
    keyword: 'Full Movie',
    displayName: 'All Full Movies',
    thumbnail: 'https://ci.phncdn.com/videos/201908/12/241329041/original/(m=eaAaGwObaaaa)(mh=NONO3pPdkZna8hSg)10.jpg',
    thumbnailPool: [
      'https://ci.phncdn.com/videos/201908/12/241329041/original/(m=eaAaGwObaaaa)(mh=NONO3pPdkZna8hSg)10.jpg',
      'https://ci.phncdn.com/videos/202007/15/333072972/original/(m=eaAaGwObaaaa)(mh=pVduUZwr0fB3g6us)13.jpg',
      'http://img-l3.xvideos-cdn.com/videos/thumbs169ll/da/1b/5b/da1b5b087692dcf828cd50e0833b522d/da1b5b087692dcf828cd50e0833b522d.10.jpg',
      'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169ll/c1/af/22/c1af22eb9770d108483edf1e7a4befb7/c1af22eb9770d108483edf1e7a4befb7.17.jpg'
    ],
  },
];
const _THUMBNAIL_CHANGE_FREQUENCY = 5;

function Item(props) {
  const {
    item,
  } = props;

  const { keyword, displayName, thumbnail, thumbnailPool } = item;

  // Randomly pick a thumbnail based on date
  let chosenThumbnail = thumbnail;
  if (thumbnailPool && thumbnailPool.length > 0) {
    chosenThumbnail = randomlyPickFromList(
      thumbnailPool,
      _THUMBNAIL_CHANGE_FREQUENCY,
      1,
      1,
    );
  }

  const searchQuery = searchInputToQuery({
    keyword,
    filters: {
      ...DEFAULT_SEARCH_INPUT.filters,
      duration: 'long',
    },
  });

  function handleOnClick() {
    analyticsLogEvent('home-click-full_movie');
  }

  return (
    <Col
      as={Link}
      to={searchQuery}
      xs={6}
      className="mobile-px-gutter no-hover-effect"
      onClick={handleOnClick}
    >
      <div
        className="bg-secondary thumbnail-image-container rounded-2 cursor-pointer"
        style={{ backgroundImage:`url("${chosenThumbnail}")` }}
      />
      <p className="mt-1 mb-3 text-primary">
        { displayName }
      </p>
    </Col>
  );
}

function FullMovies(props) {
  let i = 0;
  const items = _ITEMS.map((item) => {
    i++;

    return (
      <Item
        {...props}
        key={i}
        item={item}
      />
    );
  });

  return (
    <Container fluid className="px-0 mt-5">
      <h5>Full Movies</h5>
      <Row className="mobile-mx-n1">
        { items }
      </Row>
    </Container>
  );
}

export default FullMovies;

const user = (state = {
  currentUser: null,
  isCurrentUserFresh: false,
  isOnboarding: false,
}, action) => {
  switch (action.type) {
    case 'USER_UPDATE_CURRENT_USER': {
      const { currentUser } = action;

      return {
        ...state,
        currentUser,
        isCurrentUserFresh: true,
      };
    }
    case 'USER_SET_IS_ONBOARDING': {
      const { isOnboarding } = action;

      return {
        ...state,
        isOnboarding,
      };
    }
    default:
      return state
  }
};

export default user;

import { functions } from 'appFirebase';
import {
  searchClearIsFetching,
  searchUpdateSearchInput,
  searchSetIsFetching,
  searchSetIsLoadingMore,
  searchSetVideos,
  searchSetIsFetchingKeywordThumbnails,
  searchSetKeywordThumbnails,
} from '../search';
import {
  videoPeeksSetIsFetchingLatestVideos,
  videoPeeksSetLatestVideos,
  videoPeeksSetIsFetchingWeeklyHotVideos,
  videoPeeksSetWeeklyHotVideos,
} from '../videoPeeks';
import {
  isTouchDevice,
  secureUrlInfo,
} from 'misc/appHelperFunctions';

const _SEARCH_VIDEOS_COUNT = 30;
const _LATEST_VIDEOS_COUNT = 15;
const _KEYWORD_FULL_MOVIE = 'full movie';

/*
 * Search videos.
 */
export const apiSearchVideos = (isRefresh) => {
  return async (dispatch, getState) => {
    // Do not fetch again if it is actively fetching.
    if (getState().search.isFetching) {
      return;
    }

    const isSortByDate =
        getState().search.searchInput.filters.orderBy === 'date';

    // For api searchVideos.
    let startAfter = getState().search.searchInput.startAfter;
    // For api searchVideosByRandomWords.
    let externalSearchPage =
        getState().search.searchInput.externalSearchPage;
    if (isRefresh) {
      startAfter = isSortByDate
          // For 'date'.
          ? new Date().getTime()
          // For 'view'.
          : Number.MAX_VALUE;
      externalSearchPage = 1;
      // Reset the redux states on refresh.
      dispatch(searchUpdateSearchInput({
        startAfter,
        externalSearchPage,
      }));
    }

    let availableKeywords = getState().search.searchInput.availableKeywords || {};
    // When user refresh a search results page, availableKeywords will not be
    // loaded asynchronous in time. So we need to explictly import the constants
    // before proceeding forward.
    if (!Object.keys(availableKeywords).length) {
      const constants = await import("misc/dataConstants/SEARCH_KEYWORDS");
      const { INTERNAL_SEARCH_KEYWORDS } = constants.SEARCH_KEYWORDS;
      INTERNAL_SEARCH_KEYWORDS.forEach((keyword) => {
        availableKeywords[keyword] = true;
      });
      dispatch(searchUpdateSearchInput({
        availableKeywords,
      }));
    }
    const keyword = (getState().search.searchInput.keyword || '').toLowerCase();
    const isKeywordAvailable = !keyword || availableKeywords[keyword];
    const isSearchFullMovie = keyword.includes(_KEYWORD_FULL_MOVIE);
    let shouldSearchExternally = !(isKeywordAvailable || isSearchFullMovie);
    // Search externally if internal search has reached the end.
    if (!shouldSearchExternally && startAfter === -1) {
      shouldSearchExternally = true;
    }
    // If there are no more external videos to load, return directly.
    if (shouldSearchExternally && externalSearchPage === -1) {
      return;
    }

    // Show loading sign to indicate that it is actively fetching related videos.
    if (isRefresh) {
      dispatch(searchSetIsFetching(true));
    } else {
      dispatch(searchSetIsLoadingMore(true));
    }

    const isMobile = isTouchDevice(/*isMobileOS*/true);

    // Call cloud function to get search results (videos).
    const searchVideos = shouldSearchExternally
        // External search.
        ? functions.httpsCallable('searchVideosByRandomWords')
        // Internal search.
        : functions.httpsCallable('searchVideos');

    let requestData = {
      keyword,
      filters: {
        ...getState().search.searchInput.filters,
      },
      isMobile,
    };
    if (!shouldSearchExternally && isSearchFullMovie) {
      requestData.keyword = keyword.replace(_KEYWORD_FULL_MOVIE, '').trim();
      requestData.filters.videoType = 'film';
    }
    if (shouldSearchExternally) {
      // For api searchVideosByRandomWords.
      requestData.filters.page = externalSearchPage;
    } else {
      // For api searchVideos.
      requestData = {
        ...requestData,
        startAfter,
        count: _SEARCH_VIDEOS_COUNT,
      };
    }

    // Get response.
    let hasError = false;
    const res = await searchVideos(requestData)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error("Error searching feed videos: ", error);
          hasError = true;
        });

    if (hasError) {
      // Hide the loading indicator.
      dispatch(searchClearIsFetching());
      return;
    }

    // Update UI to display the feed videos.
    // Always update UI, even if the result is empty.
    // TODO: remove res when backend is updated to return { videos, actorInfo }.
    const resultVideos = (res || {}).videos || res || [];
    for (let i = 0; i < resultVideos.length; i++) {
      resultVideos[i] = secureUrlInfo(resultVideos[i]);
    }
    const nextVideos = isRefresh
        ? resultVideos
        : getState().search.videos.concat(resultVideos);
    dispatch(searchSetVideos(nextVideos));

    if (shouldSearchExternally) {
      let nextExternalSearchPage = -1;
      // Search next external page if current search result is not empty.
      if (resultVideos.length) {
        nextExternalSearchPage = externalSearchPage + 1;
      }
      dispatch(searchUpdateSearchInput({
        externalSearchPage: nextExternalSearchPage,
      }));
    } else {
      let nextStartAfter = -1;
      if (resultVideos.length === _SEARCH_VIDEOS_COUNT) {
        for (let i = (resultVideos.length - 1); i >= 0; i-- ) {
          const {
            createdTimestampMs,
            baseClickCnt,
          } = resultVideos[i] || {};

          // Use the last video's createdTimestampMs or baseClickCnt as the next
          // startAfter, for sorting by 'date' and 'view' respectively.
          const nextStartAfterCandidate =
              isSortByDate ? createdTimestampMs : baseClickCnt;

          if (nextStartAfterCandidate) {
            nextStartAfter = nextStartAfterCandidate;
            break;
          }
        }
      }
      dispatch(searchUpdateSearchInput({
        startAfter: nextStartAfter,
      }));

      // Keep going with external search if internal search results do not
      // have enough data.
      if (resultVideos.length < _SEARCH_VIDEOS_COUNT) {
        dispatch(apiSearchVideos(/*isRefresh*/false));
      }
    }
  };
};

export const apiGetLatestVideos = () => {
  return async (dispatch, getState) => {
    // Do not fetch again if it is actively fetching.
    if (getState().videoPeeks.isFetchingLatestVideos) {
      return;
    }
    dispatch(videoPeeksSetIsFetchingLatestVideos(true));

    const isMobile = isTouchDevice(/*isMobileOS*/true);
    const searchVideos = functions.httpsCallable('searchVideos');

    let requestData = {
      filters: {
        orderBy: 'date',
        targetUser: 'asian',
      },
      isMobile,
      count: _LATEST_VIDEOS_COUNT,
    };

    let hasError = false;
    const res = await searchVideos(requestData)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.error("Error searching feed videos: ", error);
        hasError = true;
      });

    if (hasError) {
      // Hide the loading indicator.
      dispatch(videoPeeksSetIsFetchingLatestVideos(false));
      return;
    }
    // Update UI to display the latest videos.
    const resultVideos = (res || {}).videos || [];
    for (let i = 0; i < resultVideos.length; i++) {
      resultVideos[i] = secureUrlInfo(resultVideos[i]);
    }

    dispatch(videoPeeksSetLatestVideos(resultVideos));
  };
};

export const apiGetWeeklyHotVideos = () => {
  return async (dispatch, getState) => {
    // Do not fetch again if it is actively fetching.
    if (getState().videoPeeks.isFetchingWeeklyHotVideos) {
      return;
    }
    dispatch(videoPeeksSetIsFetchingWeeklyHotVideos(true));

    const isMobile = isTouchDevice(/*isMobileOS*/true);
    const searchVideos = functions.httpsCallable('searchVideos');

    let requestData = {
      filters: {
        orderBy: 'view',
        videoage: 'week',
        targetUser: 'asian',
      },
      isMobile,
      count: _LATEST_VIDEOS_COUNT,
    };

    let hasError = false;
    const res = await searchVideos(requestData)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.error("Error searching feed videos: ", error);
        hasError = true;
      });

    if (hasError) {
      // Hide the loading indicator.
      dispatch(videoPeeksSetIsFetchingWeeklyHotVideos(false));
      return;
    }
    // Update UI to display the latest videos.
    const resultVideos = (res || {}).videos || [];
    for (let i = 0; i < resultVideos.length; i++) {
      resultVideos[i] = secureUrlInfo(resultVideos[i]);
    }

    dispatch(videoPeeksSetWeeklyHotVideos(resultVideos));
  };
};

//--------------------
// Deprecated
// TODO: remove the deprecated codes.
//--------------------
/*
 * Search videos.
 */
export const apiFetchKeywordThumbnails = (keywords) => {
  return async (dispatch, getState) => {
    if (!keywords || !keywords.length) {
      return;
    }

    // Do not fetch again if it is actively fetching.
    if (getState().search.isFetchingShortcutThumbnails) {
      return;
    }

    dispatch(searchSetIsFetchingKeywordThumbnails(true));

    // Call cloud function to get keyword thumbnails.
    const getThumbsByKeywords = functions.httpsCallable('getThumbsByKeywords');

        // Get response.
    let hasError = false;
    const res = await getThumbsByKeywords({
          keywords,
          count: 5,
        })
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error("Error fetching keyword thumbnails: ", error);
          hasError = true;
        });

    if (hasError) {
      dispatch(searchSetIsFetchingKeywordThumbnails(false));
      return;
    }

    dispatch(searchSetKeywordThumbnails(res));
  };
};
import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  IconX,
} from 'components/icons';
// Third party.
import React from 'react';

function TagButton(props) {
  const { tag, tagSelected, setTagSelected, onClickTag } = props;

  const isTagSelected = tagSelected[tag];

  const classNameTag = isTagSelected
        ? "mr-2 my-1 tag-primary cursor-default"
        : "mr-2 my-1 tag-light cursor-default";

  const classNameIcon = isTagSelected ? "icon-white" : null;

  function handleClickTag() {
    const nextTagSelected = {
      ...tagSelected,
      [tag]: !isTagSelected,
    };
    setTagSelected(nextTagSelected);

    if (onClickTag) {
      onClickTag();
    }

    analyticsLogEvent('click_tags_editor_tag_button');
  }

  function handleClickX(e) {
    // Prevent click event propagate to parent.
    e.stopPropagation();

    const { tags, setTags } = props;

    // Remove the tag.
    const nextTags = tags.filter((t) => {
      return (t !== tag);
    });
    setTags(nextTags);

    // Clear the removed tag.
    setTagSelected({
      ...tagSelected,
      [tag]: false,
    });

    if (onClickTag) {
      onClickTag();
    }

    analyticsLogEvent('click_tags_editor_remove_tag_button');
  }

  return (
    <span className={classNameTag} onClick={handleClickTag}>
      {tag}

      <span className="ml-2 cursor-pointer" onClick={handleClickX}>
        <IconX size={0.9} className={classNameIcon} />
      </span>
    </span>
  );
}

function Tags(props) {
  const { tags } = props;
  if (!tags) {
    return null;
  }

  const tagBadges = tags.map((tag) => {
    return (
      <TagButton key={tag} {...props} tag={tag} />
    );
  });

  return tagBadges;
}

export default Tags;
import {
  apiGetFeedVideos,
  apiSearchVideos,
  searchReset,
} from 'appRedux/actions';
import {
  BaseBottomNavbar,
} from '../navbar-components';
import FeedTab from './FeedTab';
import HomeTab from './HomeTab';
import PornstarsTab from './PornstarsTab';
import SavedTab from './SavedTab';
import SitesTab from './SitesTab';
// Third party.
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

function AppBottomNavbar(props) {
  const history = useHistory();
  const currentPathName = history.location.pathname || '';

  const baseTextClassName = "mt-1 mb-0 text-xs no-link-effect ";

  const classNames = {
    inactiveIconClassName: 'icon-black-50',
    activeIconClassName: 'icon-primary',
    inactiveTextClassName: baseTextClassName + ' text-black-50',
    activeTextClassName: baseTextClassName + ' text-primary',
  };

  return (
    <BaseBottomNavbar className="d-flex py-1">
      <HomeTab
        currentPathName={currentPathName}
        classNames={classNames}
        searchInput={props.searchInput}
        actionApiSearchVideos={props.actionApiSearchVideos}
        actionSearchReset={props.actionSearchReset}
      />

      <FeedTab
        currentPathName={currentPathName}
        classNames={classNames}
        actionApiGetFeedVideos={props.actionApiGetFeedVideos}
      />

      <PornstarsTab
        currentPathName={currentPathName}
        classNames={classNames}
      />

      <SitesTab
        currentPathName={currentPathName}
        classNames={classNames}
      />

      <SavedTab
        currentPathName={currentPathName}
        classNames={classNames}
      />
    </BaseBottomNavbar>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  searchInput: state.search.searchInput,
});

const mapDispatchToProps = (dispatch) => ({
  actionApiGetFeedVideos: (isRefresh) => {
    dispatch(apiGetFeedVideos(isRefresh));
  },
  actionApiSearchVideos: (isRefresh) => {
    dispatch(apiSearchVideos(isRefresh));
  },
  actionSearchReset: () => {
    dispatch(searchReset());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppBottomNavbar);

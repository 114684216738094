// Third party.
import { useEffect, useState } from 'react';

const _MIN_VALID_TITLE_LENGTH = 30;

function useSavedItems(collection) {
  const [savedUrls, setSavedUrls] = useState({});
  const [savedTitles, setSavedTitles] = useState({});

  // Compose an object containg all the urls in current collection.
  useEffect(() => {
    const safeCollection = collection || {};
    const safeCurrentCollectionInfo = safeCollection.currentCollectionInfo || {};
    const currentCollectionInfoId = safeCurrentCollectionInfo.id;

    const safeItemIds = safeCollection.itemIds || {};
    const safeCurrentCollectionItemIds = safeItemIds[currentCollectionInfoId] || [];

    const safeItems = safeCollection.items || {};

    const nextSavedUrls = {};
    const nextSavedTitles = {};
    for (let i = 0; i < safeCurrentCollectionItemIds.length; i++) {
      const itemId = safeCurrentCollectionItemIds[i];
      const itemData = safeItems[itemId];
      if (itemData && itemData.urlInfo) {
        const urlInfo = itemData.urlInfo;
        if (urlInfo.url) {
          nextSavedUrls[urlInfo.url] = true;
        }
        if (urlInfo.absoluteUrl) {
          nextSavedUrls[urlInfo.absoluteUrl] = true;
        }
        if (urlInfo.originalUrl) {
          nextSavedUrls[urlInfo.originalUrl] = true;
        }
        if ((urlInfo.title || "").length > _MIN_VALID_TITLE_LENGTH) {
          nextSavedTitles[urlInfo.title] = true;
        }
        if (((urlInfo.backupUrlInfo || {}).title || "").length > _MIN_VALID_TITLE_LENGTH) {
          nextSavedTitles[urlInfo.backupUrlInfo.title] = true;
        }
      }
    }
    setSavedUrls(nextSavedUrls);
    setSavedTitles(nextSavedTitles);
  }, [collection]);

  return {
    urls: savedUrls,
    titles: savedTitles,
  };
}

export default useSavedItems;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  IconPlus,
} from 'components/icons';
import TooltipContainer from 'components/TooltipContainer';
// Third party.
import React from 'react';
import Button from 'react-bootstrap/Button';

function SaveButton(props) {
  const {
    className="",
    iconClassName,
    iconSize=1.5,
    onClick,
    clickEventName="click_save_button",
    tooltipContent="Save",
    hoverEventName="hover_save_button",
  } = props;

  function handleClick() {
    analyticsLogEvent(clickEventName);

    if (onClick) {
      onClick();
    }
  }

  return (
    <TooltipContainer
      placement="top"
      content={tooltipContent}
      hoverEventName={hoverEventName}
    >
      <Button
        variant="light"
        className={"round-button " + className}
        onClick={handleClick}
      >
        <IconPlus size={iconSize} className={iconClassName} />
      </Button>
    </TooltipContainer>
  );
}

export default SaveButton;

import {
  P_HOME,
  P_SAVED,
} from 'misc/appConstants';
import {
  isTouchDevice,
} from 'misc/appHelperFunctions';
import useScrollDirection from 'misc/useScrollDirection';
import LogoButton from 'components/buttons/LogoButton';
import SavedButton from 'components/buttons/SavedButton';
import ToolbarPlaylist from './appTopNavbar/toolbars/ToolbarPlaylist';
import ToolbarPornstars from './appTopNavbar/toolbars/ToolbarPornstars';
import ToolbarSearch from './appTopNavbar/toolbars/ToolbarSearch';
import ToolbarSites from './appTopNavbar/toolbars/ToolbarSites';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

const SCROLL_UP = "up";
const SCROLL_DOWN = "down";

export function TopNavbarScrollable(props) {
  const scrollDir = useScrollDirection({
    initialDirection: SCROLL_UP,
    thresholdPixels: 20,
    off: false,
  });

  const {
    className="",
    children,
  } = props;

  let finalClassName = "px-content-container border-bottom transition-top ";
  if (scrollDir === SCROLL_DOWN) {
    finalClassName += "top-n-navbar-height ";
  }
  finalClassName += className;

  return (
     <Navbar
      fixed="top"
      bg="white"
      className={finalClassName}
    >
      { children }
     </Navbar>
  );
}

export function TopNavbarFixed(props) {
  const {
    className="",
    children,
  } = props;

  // The BottomNavbar will be displayed at the top on desktop. So the 'top'
  // needs to be adjusted to make space for the BottomNavbar positioned at the
  // top.
  const finalClassName =
      "px-content-container border-bottom top-navbar-height-bottom " + className;

  return (
     <Navbar
      fixed="top"
      bg="white"
      className={finalClassName}
    >
      { children }
     </Navbar>
  );
}

export function DummyNavbar(props) {
  const { children } = props;

  // The BottomNavbar will be displayed at the top on desktop. So the dummy
  // navbar need extra top margin to make space for the BottomNavbar positioned
  // at the top.
  const spaceForChildren = children ? "round-button " : "";
  const spaceForBottomNavbar = isTouchDevice() ? "" : "mt-navbar-height-bottom";
  const className = spaceForChildren + spaceForBottomNavbar;

  return (
    <Navbar bg="white">
      <div className={className} />
    </Navbar>
  );
}

export function BaseTopNavbar(props) {
  const { isHidden, children } = props;

  if (isHidden) {
    return (
      <div>
        { children }
        {/* Dummy Navbar used as page top padding. */}
        <DummyNavbar />
      </div>
    );
  }

  if (isTouchDevice()) {
    return (
      <div>
        {
          children &&
          <TopNavbarScrollable {...props} />
        }
        {/* Dummy Navbar used as page top padding since Navbar is fixed-top. */}
        <DummyNavbar children={children} />
      </div>
    );
  }
  return (
    <div>
      {
        children &&
        <TopNavbarFixed {...props} />
      }
      {/* Dummy Navbar used as page top padding since Navbar is fixed-top. */}
      <DummyNavbar children={children} />
    </div>
  );
}

export function BaseBottomNavbar(props) {
  const {
    className="",
    children,
  } = props;

  // The BottomNavbar will be displayed at the top on desktop.
  const fixed = isTouchDevice() ? 'bottom' : 'top';
  const baseClassName = isTouchDevice() ? "border-top pb-safe-area-inset " : "border-bottom ";

  return (
     <Navbar
      fixed={fixed}
      bg="white"
      className={ baseClassName + className }
    >
      { children }
     </Navbar>
  );
}

export function NavbarBrandButton(props) {
  const { shouldHideName } = props;

  return (
    <Navbar.Brand as={Link} to={P_HOME} className="p-0 mr-2 ml-n2">
      <LogoButton shouldHideName={shouldHideName} />
    </Navbar.Brand>
  );
}

export function NavbarSavedButton(props) {
  // Always hidden on touch devices, since it's already in the bottom navbar.
  if (isTouchDevice()) {
    return null;
  }

  const { eventNamePrefix="" } = props;

  return (
    <Link to={P_SAVED} className="pl-2 ml-1 mr-n2">
      <SavedButton
        clickEventName={eventNamePrefix + "-navbar-click-saved_button"}
        hoverEventName={eventNamePrefix + "-navbar-hover-saved_button"}
      />
    </Link>
  );
}

export function NavbarHome(props) {
  return (
    <BaseTopNavbar>
      <ToolbarSearch {...props} />
    </BaseTopNavbar>
  );
}

export function NavbarPlaylist(props) {
  return (
    <BaseTopNavbar isHidden>
      <ToolbarPlaylist {...props} />
    </BaseTopNavbar>
  );
}

export function NavbarPornstars(props) {
  return (
    <BaseTopNavbar>
      <ToolbarPornstars {...props} />
    </BaseTopNavbar>
  );
}

export function NavbarSites(props) {
  return (
    <BaseTopNavbar>
      <ToolbarSites {...props} />
    </BaseTopNavbar>
  );
}

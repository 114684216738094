import React from "react";

function FeedTab(props) {
  const {
    isSelected,
    keyword,
    // Redux actions.
    actionRecommendationSetSelectedCategory,
    actionApiGetFeedVideos
  } = props;

  function handleOnClick() {
    actionRecommendationSetSelectedCategory(keyword);
    window.scrollTo(0, 0);
    actionApiGetFeedVideos(/*isRefresh*/true);
  }

  const className = isSelected ? 'tag-primary' : 'tag-light';

  return (
    <h5
      className={"p-2 m-0 flex-shrink-0 text-dark mr-2 no-hover-effect rounded-3 " + className}
      onClick={handleOnClick}
    >
      { keyword }
    </h5>
  )
}

function RecommendFeedFilters(props) {
  const {
    // Redux states.
    recommendation,
    // Redux actions.
    actionApiGetFeedVideos,
    actionRecommendationSetSelectedCategory,
  } = props;
  const { selectedCategory, recommendKeywords } = recommendation;

  let i = 0;
  const items = recommendKeywords.map(keyword => {
    const isSelected = (selectedCategory === keyword);

    return (
      <FeedTab
        key={i++}
        isSelected={isSelected}
        keyword={keyword}
        actionRecommendationSetSelectedCategory={actionRecommendationSetSelectedCategory}
        actionApiGetFeedVideos={actionApiGetFeedVideos}
      />
    )
    // return (<div></div>)
  });

  return (
    <div className="mb-4 d-flex flex-nowrap overflow-auto hide-scrollbar">
      { items }
    </div>
  );
}

export default RecommendFeedFilters;
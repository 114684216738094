import {
  IconArrowRight,
} from 'components/icons';
// Third party.
import React from 'react';
import Button from 'react-bootstrap/Button';

function CollectionPreview(props) {
  const { collectionInfo={}, handleClickCollection } = props;
  const { name } = collectionInfo;

  function handleClick() {
    if (handleClickCollection) {
      handleClickCollection(collectionInfo);
    }
  }

  return (
    <Button
      variant="light"
      block
      onClick={handleClick}
      className="text-left text-nowrap"
    >
      <div className="d-flex align-items-center justify-content-between m-1">
        <div className="overflow-hidden mr-1">
          <h4 className="m-0 w-100 truncate">
             {name}
          </h4>
        </div>

        <IconArrowRight size={1} className="flex-shrink-0 icon-black-50" />
      </div>
    </Button>
  );
}

export default CollectionPreview;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import { APP_NAME } from 'misc/appConstants';
import SignUpButton from 'components/buttons/SignUpButton';
import InfoSection from '../InfoSection';
import SectionWrapper from '../SectionWrapper';
import Footer from '../Footer';
import BannerContent from './BannerContent';
import SocialProof from './SocialProof';
// Third party.
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';

function Save(props) {
   useEffect(() => {
    analyticsLogEvent('screen_view', { screen_name: 'welcome' });
  }, []);

  const { eventNamePrefix="save" } = props;

  function eventNameForClickSignUp(sectionNumber) {
    const eventName = eventNamePrefix + "_click_sign_up_button_" + sectionNumber;
    return eventName;
  }

  return (
    <Container fluid className="p-0">
      <InfoSection
        className="bg-primary"
        imageSrc="https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2FYour%20private%20lists.png?alt=media&token=3ce02d29-0607-4354-b567-7daaeb781c71"
        imageAlt="Your Private Playlists for Porn"
      >
        <BannerContent
          eventNameForClickSignUp={eventNameForClickSignUp(1)}
        />
      </InfoSection>

      <InfoSection
        className="bg-secondary"
        contentTitle="Save porn videos from everywhere"
        contentDescription={[
          "Save porn video links from any site you like.",
          "Watch saved videos from 100+ different sites directly in " + APP_NAME + ".",
          "Curate your own space filled with everything you love to watch.",
        ]}
        imageSrc="https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2FSave%20content%20from%20any%20where.png?alt=media&token=3787878b-bb24-405c-a395-0402daf7deba"
        isReverseOrder
        showSignUpButton
        eventNameForClickSignUp={eventNameForClickSignUp(2)}
      />

      <InfoSection
        className="bg-white"
        contentTitle="Explore the best porn from all over the net"
        contentDescription={[
          "We collect links to the best porn videos from all over the internet everyday.",
          "Our powerful recommendation engine will present you the best porn videos you may like.",
          "The more porn videos you save, the better recommendation you will get.",
          ]}
        imageSrc="https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Frelated-videos.png?alt=media&token=0409383e-d639-48e0-af84-3226bc6ec4ae"
        showSignUpButton
        eventNameForClickSignUp={eventNameForClickSignUp(3)}
      />

      <InfoSection
        className="bg-secondary"
        contentTitle="Search and organize videos easily"
        contentDescription={[
          "Use our powerful search to find everything you want quickly.",
          "Organize your videos easily with smart tags.",
        ]}
        imageSrc="https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2FOrganize%20and%20find%20videos%20easily.png?alt=media&token=40e8a751-9bac-437b-8abc-a72923be19ae"
        isReverseOrder
        showSignUpButton
        eventNameForClickSignUp={eventNameForClickSignUp(4)}
      />

    {/*
      <InfoSection
        className="bg-white"
        contentTitle="Secure and private"
        contentDescription={[
          "Enter password to access the content everytime.",
          "Browers won't autofill or remember your password.",
          ]}
        imageSrc="https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2FSecure%20and%20private.png?alt=media&token=7e9bc581-b78b-4403-a28c-8f7724eb4dda"
        showSignUpButton
        eventNameForClickSignUp={eventNameForClickSignUp(5)}
      />

      <InfoSection
        className="bg-white"
        contentTitle="Sign up to see the best porn sites of 2020"
        contentDescription={[
          "We reviewed thousands of porn sites of 2020 and handpicked the best ones that are completely safe and free for you.",
          "Sign up now to see the best porn sites for free!",
        ]}
        imageSrc="https://firebasestorage.googleapis.com/v0/b/videomarx-dev.appspot.com/o/public%2Fbest%20sites.png?alt=media&token=7f324460-fd44-479f-939b-bd0b54e2f801"
        isReverseOrder
        showSignUpButton
        buttonTitle="Sign Up Now!"
      />
    */}

      <SocialProof />

      <SectionWrapper className="bg-primary" innerClassName="p-4 text-center">
        <h1 className="text-white">Get started with { APP_NAME }</h1>
        <SignUpButton
          title="Sign Up for Free"
          className="text-primary mt-5 mobile-block"
          size="lg"
          variant="light"
          clickEventName={eventNameForClickSignUp(5)}
        />
      </SectionWrapper>

      <Footer eventNameSuffix={eventNamePrefix} />
    </Container>
  );
}

export default Save;

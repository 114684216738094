import LoadingIndicator from 'components/LoadingIndicator';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function Header(props) {
  const {
    title,
    titleComponent,
    titleFetching,
    isFetchingVideos,
  } = props;

  if (isFetchingVideos) {
    return (
      <div className="text-center">
        <LoadingIndicator className="mt-3" />

        {
          titleFetching &&
           <h4 className="mt-3 text-dark">
            { titleFetching }
          </h4>
        }
      </div>
    );
  }

  if (titleComponent) {
    return React.createElement(titleComponent);
  }

  if (title) {
    return (
      <Container className="px-0 pt-3 pb-1 mw-md text-center">
        <h4 className="text-dark">
          { title }
        </h4>
      </Container>
    );
  }

  return null;
}

export default Header;

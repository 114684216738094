import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  DEFAULT_SEARCH_INPUT,
} from 'appRedux/reducers/stateConstants';
import {
  P_PLAYLIST,
} from 'misc/appConstants';
import {
  QUERY_COLLECTION_ID,
  composeQuery,
  searchInputToQuery,
} from 'misc/queryHelpers';
import {randomlyPickFromList} from "misc/appHelperFunctions";
import PremiumBadge from 'components/PremiumBadge';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

const _ITEMS = [
  {
    thumbnail: "https://img-l3.xvideos-cdn.com/videos/thumbs169ll/d4/89/78/d489780b2f0d9f2c39898664c5e33a7d/d489780b2f0d9f2c39898664c5e33a7d.18.jpg",
    keyword: "Asian Big Tits",
    displayName: "Asian Big Tits",
    descriptions: ["Asian Big Tits", "亚洲大胸美乳合集"],
    collectionId: 'MDI6DoIqyEXv0fDEER30',
    thumbnailPool:[
      'https://img-l3.xvideos-cdn.com/videos/thumbs169ll/d4/89/78/d489780b2f0d9f2c39898664c5e33a7d/d489780b2f0d9f2c39898664c5e33a7d.18.jpg',
      'https://img-l3.xvideos-cdn.com/videos/thumbs169ll/d4/89/78/d489780b2f0d9f2c39898664c5e33a7d/d489780b2f0d9f2c39898664c5e33a7d.18.jpg',
      'https://ci.phncdn.com/videos/201912/28/271939511/original/(m=eafTGgaaaa)(mh=2PWZKCEHLVNqCUJQ)2.jpg',
      'https://static-ca-cdn.eporner.com/thumbs/static4/1/16/168/1689127/8_240.jpg',
    ],
  },
  {
    thumbnail: "https://ci.phncdn.com/videos/202008/11/341490031/original/(m=eaAaGwObaaaa)(mh=_63fOCIjVXUhAA0g)8.jpg",
    keyword: "千人斩探花",
    displayName: "千人斩探花",
    descriptions: ["The Best Chinese Amateur Selections", "国产大神系列 千人斩探花"],
    collectionId: 'ywqVD5mfHLIIqK9cSZTj',
    thumbnailPool:[
      'https://di.phncdn.com/videos/202007/28/337188151/original/(m=eaAaGwObaaaa)(mh=uulZHQrE1RuJ5EM8)8.jpg',
      'https://di.phncdn.com/videos/202008/11/341304341/original/(m=eafTGgaaaa)(mh=m_Q_XwGuvMPMy-mj)16.jpg',
      'https://ci.phncdn.com/videos/202008/11/341490031/original/(m=eaAaGwObaaaa)(mh=_63fOCIjVXUhAA0g)8.jpg',
      'https://di.phncdn.com/videos/202007/29/337406661/original/(m=eafTGgaaaa)(mh=tztzrbVLIMR7S5KK)9.jpg',
    ],
  },
  {
    thumbnail: "https://en.paradisehill.cc/images/pirates_ii_stagnettis_revenge/2(19).jpg",
    keyword: "Pirates",
    displayName: "Pirates II: Stagnetti’s Revenge",
    descriptions: ["Pirates porn version", "H版 加勒比海盗2"],
    collectionId: '25hX8pxMk4zhuC8rjnDl',
    thumbnailPool:[
      'https://en.paradisehill.cc/images/pirates_ii_stagnettis_revenge/2(19).jpg',
    ],
  },
  {
    thumbnail: "https://ci.phncdn.com/videos/201908/26/244275321/original/(m=eaAaGwObaaaa)(mh=nMBO-NCaTgJxXDjk)8.jpg",
    keyword: "嫖客",
    displayName: "嫖客与妓女实拍",
    descriptions: ["", "嫖客与妓女实拍系列"],
    collectionId: 'ugwEIhEUnHRlzHve5mqA',
    thumbnailPool:[
      'https://ci.phncdn.com/videos/201908/26/244275321/original/(m=eaAaGwObaaaa)(mh=nMBO-NCaTgJxXDjk)8.jpg',
      'https://ci.phncdn.com/videos/201908/27/244339411/original/(m=eaAaGwObaaaa)(mh=aQ9U7A8lO_r-ZKec)12.jpg',
      'https://ci.phncdn.com/videos/201908/26/244281421/original/(m=eaAaGwObaaaa)(mh=DJhR7UR5Q5AD2MqK)8.jpg',
      'https://di.phncdn.com/videos/201908/27/244336171/original/(m=eaAaGwObaaaa)(mh=X1co09wTwW_Yhcye)4.jpg',
      'https://di.phncdn.com/videos/201908/26/244285382/original/(m=eaAaGwObaaaa)(mh=9xOxH1enY5QaPj2l)2.jpg',
    ],
  },
  {
    thumbnail: "https://img-hw.xvideos-cdn.com/videos/thumbs169ll/cb/5b/22/cb5b22efbc4fe6cd6c1459aff794038e/cb5b22efbc4fe6cd6c1459aff794038e.10.jpg",
    keyword: "Trip For Fuck",
    displayName: "Trip For Fuck",
    descriptions: ["An Asian guy's sex journey in the world", "亚洲大神的世界旅行"],
    collectionId: 'prkzSczhTRNRKgUSYajR',
    thumbnailPool:[
      'https://ci.phncdn.com/videos/202008/08/340554561/original/(m=eaAaGwObaaaa)(mh=TPtf3py52URFruO1)16.jpg',
      'https://img-hw.xvideos-cdn.com/videos/thumbs169ll/cb/5b/22/cb5b22efbc4fe6cd6c1459aff794038e/cb5b22efbc4fe6cd6c1459aff794038e.10.jpg',
      'https://ci.phncdn.com/videos/202006/08/321752081/original/(m=eaAaGwObaaaa)(mh=ZBMbQnfFqbe0AqSL)15.jpg',
      'https://tb2.sb-cd.com/w:1280/6/7/6775216-t5-enh/chaowestfall.jpg',
      'https://img-hw.xvideos-cdn.com/videos/thumbs169ll/6d/0b/e3/6d0be3dbd2deb20734eea70e7f3b6138/6d0be3dbd2deb20734eea70e7f3b6138.14.jpg',
    ],
  },
  {
    thumbnail: "https://cdn77-pic.xvideos-cdn.com/videos/thumbs169ll/d9/9e/6f/d99e6f313faa077df3e6a1011794d545/d99e6f313faa077df3e6a1011794d545.4.jpg",
    keyword: "Korean Movie",
    displayName: "Korean Movie Collection 2",
    descriptions: ["Selected korean movie 2", "精选韩国三集片合集2"],
    collectionId: 'OnVvK5OsWhot41JjZST4',
    thumbnailPool:[
      'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169ll/d9/9e/6f/d99e6f313faa077df3e6a1011794d545/d99e6f313faa077df3e6a1011794d545.4.jpg',
      'http://img-l3.xvideos-cdn.com/videos/thumbs169ll/c1/04/6d/c1046da5b9dd4e20311f538a8c32bf1f/c1046da5b9dd4e20311f538a8c32bf1f.25.jpg',
      'http://img-hw.xvideos-cdn.com/videos/thumbs169ll/71/fe/5f/71fe5fbf3e9c9a143ee11d789faaacd4/71fe5fbf3e9c9a143ee11d789faaacd4.11.jpg',
    ],
  },
  {
    thumbnail: "https://xoohiod77z.ent-cdn.com/contents/videos_screenshots/29000/29712/preview_720p.mp4.jpg",
    keyword: "big tits",
    displayName: "Western Big Tits collection",
    descriptions: ["Western Big Tits collection", "西方美女，大胸美乳合集"],
    collectionId: 'ny9LFf5KEK8QxQdyxSJk',
    thumbnailPool:[
      'https://xoohiod77z.ent-cdn.com/contents/videos_screenshots/29000/29712/preview_720p.mp4.jpg',
      'https://ci.phncdn.com/videos/201910/31/258390352/original/(m=qYXQ17UbeaAaGwObaaaa)(mh=Qej9XVSgT_BL9FBT)0.jpg',
      'https://cdn1-thumbnails.porntube.com/8/0/1/2/3/9/0/9/1/930x523/266.jpeg',
    ],
  },
  {
    thumbnail: "https://ci.phncdn.com/videos/202008/03/338853481/original/(m=eaAaGwObaaaa)(mh=ZQeSOGS4iY7L1n1Z)1.jpg",
    keyword: "极品探花",
    displayName: "极品探花精选",
    descriptions: ["The Best Chinese Amateur Selections", "国产大神系列 极品探花"],
    collectionId: 'cBdF87bnEbyEFLb0gWME',
    thumbnailPool:[
      'https://ci.phncdn.com/videos/202008/03/338853481/original/(m=eaAaGwObaaaa)(mh=ZQeSOGS4iY7L1n1Z)1.jpg',
      'https://ci.phncdn.com/videos/202008/11/341304341/original/(m=eaAaGwObaaaa)(mh=EEewkFGjixD8pSNA)16.jpg',
      'https://ci.phncdn.com/videos/202008/03/339109301/original/(m=eaAaGwObaaaa)(mh=rvFpx3TNNtARQ1-p)13.jpg',
    ],
  },
  {
    thumbnail: "https://tb1.sb-cd.com/w:1280/4/9/4970535-t6-enh/member+s+video+dating+with+you.jpg",
    keyword: "Korean model scandal",
    displayName: "Korean model scandal",
    descriptions: ["Korean model scandal", "韩国娱乐圈淫媒系列"],
    collectionId: 'vq8LxvaivsXoUB8gNxsD',
    thumbnailPool:[
      'https://tb1.sb-cd.com/w:1280/4/9/4970535-t6-enh/member+s+video+dating+with+you.jpg',
      'http://img-l3.xvideos-cdn.com/videos/thumbs169ll/42/aa/30/42aa30b6eb87777e5cb031e6909a77eb/42aa30b6eb87777e5cb031e6909a77eb.18.jpg',
      'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169ll/be/d7/f7/bed7f73a251fe7b7c00210ba9fbaadb3/bed7f73a251fe7b7c00210ba9fbaadb3.21.jpg',
      'http://img-hw.xvideos-cdn.com/videos/thumbs169ll/fb/fd/60/fbfd60eb30d88a3d394e241e67b31f9c/fbfd60eb30d88a3d394e241e67b31f9c.15.jpg',
    ],
  },
  {
    thumbnail: "https://di.phncdn.com/videos/202005/04/310068101/original/(m=eaAaGwObaaaa)(mh=PjYqibC2KU2yOnHy)12.jpg",
    keyword: "Swag",
    displayName: "Swag",
    descriptions: ["Videos from Asia's largest adult platform", "亞洲最狂射交平台"],
    thumbnailPool:[
      'https://di.phncdn.com/videos/202005/04/310068101/original/(m=eaAaGwObaaaa)(mh=PjYqibC2KU2yOnHy)12.jpg',
      'https://di.phncdn.com/videos/202007/09/331550592/original/(m=eaAaGwObaaaa)(mh=3HynZQyUeGFYqaIm)6.jpg',
      'https://ci.phncdn.com/videos/202002/08/283046332/original/(m=eaAaGwObaaaa)(mh=JtkcevhbEmNRYm1N)1.jpg',
      'https://di.phncdn.com/videos/202007/20/334591772/original/(m=eaAaGwObaaaa)(mh=bncHgGGmMivOsIMs)12.jpg',
    ],
  },
  {
    thumbnail: "https://ci.phncdn.com/videos/202002/08/282837722/original/(m=eaAaGwObaaaa)(mh=CsF-7bC9uyIrInG-)9.jpg",
    keyword: "shoplifter",
    displayName: "ShopLifter",
    descriptions: ["", "商场偷窃被抓，无奈出卖自己"],
    collectionId: 'oeX6HAwLKhCfTdnYqRkc',
    thumbnailPool:[
      'https://ci.phncdn.com/videos/202002/08/282837722/original/(m=eaAaGwObaaaa)(mh=CsF-7bC9uyIrInG-)9.jpg',
      'http://img-hw.xvideos-cdn.com/videos/thumbs169ll/af/98/69/af98694a3e7f55783c093a06582cc331/af98694a3e7f55783c093a06582cc331.15.jpg',
      'http://img-hw.xvideos-cdn.com/videos/thumbs169ll/8f/0a/b4/8f0ab446c92c2a4a01c38c24ab566e10/8f0ab446c92c2a4a01c38c24ab566e10.12.jpg',
      'http://img-hw.xvideos-cdn.com/videos/thumbs169ll/eb/f4/69/ebf4693b0301b09acbe059c04ef1d957/ebf4693b0301b09acbe059c04ef1d957.16.jpg',
      "https://static-ca-cdn.eporner.com/thumbs/static4/3/37/377/3770747/5_240.jpg",
    ],
  },
  {
    thumbnail: "https://ci.phncdn.com/videos/202006/17/324561181/original/(m=eaAaGwObaaaa)(mh=PXB9kTYdUbuteg7u)1.jpg",
    keyword: "双飞",
    displayName: "国产双飞精选",
    descriptions: ["Chinese threesome", "一王二凤，国产双飞精选系列"],
    collectionId: 'd9hDfegvKdSVCZVPO0u2',
    thumbnailPool:[
      'https://ci.phncdn.com/videos/202006/17/324561181/original/(m=eaAaGwObaaaa)(mh=PXB9kTYdUbuteg7u)1.jpg',
      'https://ci.phncdn.com/videos/202007/27/336758531/original/(m=eaAaGwObaaaa)(mh=eYzHRkvL1gw9Qqnj)9.jpg',
      'https://ci.phncdn.com/videos/202007/29/337406661/original/(m=eaAaGwObaaaa)(mh=M_WjvlaXsC5AdMsR)9.jpg',
    ],
  },
  {
    thumbnail: "http://img-l3.xvideos-cdn.com/videos/thumbs169ll/d9/67/f8/d967f80c4196e6d5ff443591c54b6831/d967f80c4196e6d5ff443591c54b6831.9.jpg",
    keyword: "Korean Movie",
    displayName: "Korean Movie Collection 1",
    descriptions: ["Selected korean movie 1", "精选韩国三集片合集1"],
    collectionId: 'uz3kJVB1t732YgyixKLO',
    thumbnailPool:[
      'http://img-l3.xvideos-cdn.com/videos/thumbs169ll/d9/67/f8/d967f80c4196e6d5ff443591c54b6831/d967f80c4196e6d5ff443591c54b6831.9.jpg',
      'http://img-hw.xvideos-cdn.com/videos/thumbs169ll/c6/d1/d7/c6d1d7810fefce7e68b1599601cc4f9d/c6d1d7810fefce7e68b1599601cc4f9d.4.jpg',
      'http://img-l3.xvideos-cdn.com/videos/thumbs169ll/ef/03/47/ef03477c9bc46adb29bc512db0616f17/ef03477c9bc46adb29bc512db0616f17.30.jpg',
    ],
  },
  {
    thumbnail: "https://ci.phncdn.com/videos/201903/06/211492461/original/(m=eaAaGwObaaaa)(mh=omhV9sKRz4-YEGrE)15.jpg",
    keyword: "S-Cute",
    displayName: "S-Cute Series",
    descriptions: ["Selected S-Cute JAV series", "甜美素人S-Cute精选系列"],
    collectionId: '9hQy2n9YivPr7n4BXHEv',
    thumbnailPool:[
      'http://img-l3.xvideos-cdn.com/videos/thumbs169ll/d2/70/d1/d270d1bd3ca789ae6e89194fb990236c/d270d1bd3ca789ae6e89194fb990236c.25.jpg',
      'https://ci.phncdn.com/videos/201903/06/211492461/original/(m=eaAaGwObaaaa)(mh=omhV9sKRz4-YEGrE)15.jpg',
      'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169ll/51/2c/45/512c45f28be7892c3f8c2219d2c82b4a/512c45f28be7892c3f8c2219d2c82b4a.4.jpg',
      'https://ci.phncdn.com/videos/201905/28/226104172/original/(m=eaAaGwObaaaa)(mh=V7ZJczCLfvTWGB1s)15.jpg',
    ],
  },
  {
    thumbnail: "https://di.phncdn.com/videos/201805/06/164945031/original/(m=eaAaGwObaaaa)(mh=GQDgxmojlvs9t-qO)15.jpg",
    keyword: "bananafever",
    displayName: "Banana Fever",
    descriptions: ["The best Asian male fetishization content", "汝工作室 华人之光"],
    collectionId: 'QYLqrATynyGy7FHly7De',
    thumbnailPool:[
      'https://di.phncdn.com/videos/201805/06/164945031/original/(m=eaAaGwObaaaa)(mh=GQDgxmojlvs9t-qO)15.jpg',
      'https://ci.phncdn.com/videos/201909/21/249930321/original/(m=eaAaGwObaaaa)(mh=g9fCnt4sIOHxC7mr)7.jpg',
      'https://di.phncdn.com/videos/201807/09/173724701/original/(m=eaAaGwObaaaa)(mh=yWA1qHw6Ar31zHZF)16.jpg',
      'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169ll/d7/a1/06/d7a106c499c4549745bb4f4359fd5763/d7a106c499c4549745bb4f4359fd5763.10.jpg',
    ],
  },
  {
    thumbnail: "http://img-l3.xnxx-cdn.com/videos/thumbs169xnxxll/e8/40/2b/e8402bac5d2423c1f67819f7dc67062d/e8402bac5d2423c1f67819f7dc67062d.27.jpg",
    keyword: "Korean Movie",
    displayName: "Korean Movie Collection 3",
    descriptions: ["Selected korean movie 3", "精选韩国三集片合集3"],
    collectionId: 'vYaJXoisdNA1LcHPgq7i',
    thumbnailPool:[
      'http://img-l3.xnxx-cdn.com/videos/thumbs169xnxxll/e8/40/2b/e8402bac5d2423c1f67819f7dc67062d/e8402bac5d2423c1f67819f7dc67062d.27.jpg',
      'http://img-hw.xnxx-cdn.com/videos/thumbs169xnxxll/68/51/05/6851058115cef89781dd6e19a46fb0d0/6851058115cef89781dd6e19a46fb0d0.23.jpg',
      'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169ll/0b/fa/2b/0bfa2bd4594fd8eaf6ba1cb5e93ce0b0/0bfa2bd4594fd8eaf6ba1cb5e93ce0b0.3.jpg',
    ],
  },
  {
    thumbnail: "https://img-hw.xvideos-cdn.com/videos/thumbs169poster/ee/6b/75/ee6b75456abbdcc7affa709aec574d6b/ee6b75456abbdcc7affa709aec574d6b.3.jpg",
    keyword: "麻豆",
    displayName: "麻豆",
    descriptions: ["The rising star of Chinese porn movies", "两岸三地专业打造 原创中文剧情"],
    collectionId: 'KKYkMtQEpc6TLa5r6xVn',
    thumbnailPool:[
      'https://img-hw.xvideos-cdn.com/videos/thumbs169poster/ee/6b/75/ee6b75456abbdcc7affa709aec574d6b/ee6b75456abbdcc7affa709aec574d6b.3.jpg',
      'https://ci.phncdn.com/videos/202007/18/334141532/original/(m=eaAaGwObaaaa)(mh=q5zO7Ob75wXVyyHG)6.jpg',
      'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169ll/2e/f7/e0/2ef7e032ba2813d34ee711307e44061a/2ef7e032ba2813d34ee711307e44061a.10.jpg',
      'http://img-hw.xvideos-cdn.com/videos/thumbs169ll/77/e1/be/77e1bec648e945cde14eb229a6a60fda/77e1bec648e945cde14eb229a6a60fda.25.jpg',
    ],
  },
  {
    thumbnail: "https://cdn.javfree.sh/uploads/chn-111.jpg",
    keyword: "Chinese Sub",
    displayName: "Chinese Sub",
    descriptions: ["Japanese movies with Chinese subtitles", "中文字幕 日本剧情"],
    thumbnailPool:[
      'https://cdn.javfree.sh/uploads/chn-111.jpg',
      'https://cdn.javfree.sh/uploads/hnd-424.jpg',
      'https://cdn.javfree.sh/uploads/84mkmp00113.jpg',
      'https://cdn.javfree.sh/uploads/kawd-851.jpg',
    ],
  },
  //   thumbnail: "https://tb1.sb-cd.com/w:1280/4/1/4176625-t9-enh/sr+315.jpg",
  //   keyword: "Snow White",
  //   displayName: "Snow White",
  //   descriptions: ["Snow White Porn Version", "白雪公主，h版童话故事"],
  //   collectionId: '8hdyFGAUBkdlm4qXgH48',
  //   thumbnailPool:[
  //     'https://tb1.sb-cd.com/w:1280/4/1/4176625-t9-enh/sr+315.jpg',
  //   ],
  // },
  // {
  //   thumbnail: "https://ci.phncdn.com/videos/202006/28/327873292/original/(m=eaAaGwObaaaa)(mh=AMfufF8ox8wWdZxy)12.jpg",
  //   keyword: "真人性爱教学系列",
  //   displayName: "真人性愛教學",
  //   descriptions: ["", "美女老師，真人性愛教學"],
  //   collectionId: 'NFTuZ1dBbtvkADsaOEIh',
  //   thumbnailPool:[
  //     'https://di.phncdn.com/videos/202006/28/327876242/original/(m=eaAaGwObaaaa)(mh=hji-oGnyxoUrvp5W)6.jpg',
  //     'https://ci.phncdn.com/videos/202007/01/328918482/original/(m=eaAaGwObaaaa)(mh=L6cR_rf2UvgejltS)1.jpg',
  //     'https://ci.phncdn.com/videos/202006/28/327873292/original/(m=eaAaGwObaaaa)(mh=AMfufF8ox8wWdZxy)12.jpg',
  //   ],
  // },
  // {
  //   thumbnail: "https://i.ytimg.com/vi/vq3ZJKngpZ4/maxresdefault.jpg",
  //   keyword: "深夜保健室",
  //   displayName: "深夜保健室",
  //   descriptions: ["", "深夜保健室，告訴你不知道的性秘密"],
  //   collectionId: 'T5aYGdkneG6rEZWBHNBg',
  //   thumbnailPool:[
  //     'https://i.ytimg.com/vi/vq3ZJKngpZ4/maxresdefault.jpg',
  //     'https://i.ytimg.com/vi/tDEAdh85JVQ/maxresdefault.jpg',
  //     'https://i.ytimg.com/vi/lsFDAvAQ7K0/maxresdefault.jpg',
  //     'https://i.ytimg.com/vi/WFpCEHPDZWc/maxresdefault.jpg',
  //   ],
  // },
  // {
  //   thumbnail: "https://i.ytimg.com/vi/W3hmdm-8QTo/maxresdefault.jpg",
  //   keyword: "Penis Extension",
  //   displayName: "Penis Extension",
  //   descriptions: ["Penis Extension Instruction", "小弟弟增大增粗不是夢"],
  //   collectionId: 'dcdimSTZz7m5aBzJiNc9',
  //   thumbnailPool:[
  //     'https://i.ytimg.com/vi/W3hmdm-8QTo/maxresdefault.jpg',
  //     'https://i.ytimg.com/vi/OKHBUr6zQE8/hqdefault.jpg',
  //   ],
  // },
  // {
];
const _CHANGE_FREQUENCY = 2;
const _THUMBNAIL_CHANGE_FREQUENCY = 2;
const _SKIP_INTERVAL = 1;
const _RESULT_COUNT = 4;

function Collections(props) {
  let i = 0;

  const collections = randomlyPickFromList(
    _ITEMS,
    _CHANGE_FREQUENCY,
    _SKIP_INTERVAL,
    _RESULT_COUNT
  );

  const items = collections.map((item) => {
    i++;
    const { thumbnail, keyword, displayName, descriptions, collectionId, thumbnailPool } = item;

    let chosenThumbnail = thumbnail;
    if (thumbnailPool && thumbnailPool.length > 0) {
      chosenThumbnail = randomlyPickFromList(
        thumbnailPool,
        _THUMBNAIL_CHANGE_FREQUENCY,
        1,
        1,
      );
    }

    const nextSearchInput ={
      keyword,
    };
    if (keyword.toLowerCase() === 'chinese sub') {
      nextSearchInput.filters = {
        ...DEFAULT_SEARCH_INPUT.filters,
        duration: 'long',
      };
    }
    let searchQuery = searchInputToQuery(nextSearchInput);
    if (collectionId) {
      searchQuery = P_PLAYLIST + composeQuery({ [QUERY_COLLECTION_ID]: collectionId });
    }

    function handleOnClick() {
      analyticsLogEvent('home-click-top_collections');
    }

    return (
      <Carousel.Item
        key={i}
        as={Link}
        to={searchQuery}
        className="overflow-hidden cursor-pointer no-hover-effect"
        onClick={handleOnClick}
      >
        <div
          // Display the image in sqaure on mobile vertical screens.
          className="thumbnail-image-container mobile-padding-top-100"
          style={{ backgroundImage:`url("${chosenThumbnail}")` }}
        >
          {
            collectionId &&
            <div className="position-absolute top-2 right-3">
              <PremiumBadge />
            </div>
          }
        </div>
        <div className="px-3 pt-3 pb-5 w-100 position-absolute bottom-0 bg-white-gradient">
          <h1><b>{ displayName }</b></h1>
          {/* This is a hacky solution to enforce all items to be the same height */}
          <div>
            <p className="m-0"><b>{ descriptions[0] }</b></p>
            <p className="m-0"><b>{ descriptions[1] }</b></p>
          </div>
        </div>
      </Carousel.Item>
    );
  });

  return (
    <Carousel controls={false} interval={3000}>
      { items }
    </Carousel>
  );
}

export default Collections;

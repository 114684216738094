import {
  getPreviewImageUrls,
  getPreviewVideoUrl,
  getRandomBackgroundColorClassName,
  isTouchDevice,
  onImageLoaded,
} from 'misc/appHelperFunctions';
import LoadingIndicator from 'components/LoadingIndicator';
import Badges from './Badges';
import Preview from './Preview';
// Third party.
import React, { useEffect, useState } from 'react';

function ThumbnailImage(props) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [previewVideoHasError, setPreviewVideoHasError] = useState(false);

  const urlInfo = props.urlInfo || {};
  const { url, title, isFetching } = urlInfo;
  const thumbnail = urlInfo.thumbnail || {};
  const backupUrlInfo = urlInfo.backupUrlInfo || {};
  // Try to use backupUrlInfo.thumbnail.url first since it's usually the correct
  // image. The only downside is that the image size may be relatively small.
  // But the size should be large enough for thumbnail image anymore.
  const thumbnailUrl = (backupUrlInfo.thumbnail || {}).url || thumbnail.url;

  useEffect(() => {
    // This flag is needed to avoid react waring
    // "Can't perform a React state update on an unmounted component."
    let isMounted = true;
    onImageLoaded(thumbnailUrl, () => {
      if (isMounted) {
        setIsImageLoaded(true);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [thumbnailUrl, setIsImageLoaded]);

  const {
    className="",
    // Preview control.
    activePreviewVideoItemUrl,
    setActivePreviewVideoItemUrl,
  } = props;

  // Use light grey as background color when fetching.
  let bgColorClassName = "bg-secondary";
  // Use different background colors to indicate image is missing once image
  // is loaded.
  if (!isFetching && isImageLoaded) {
    bgColorClassName = getRandomBackgroundColorClassName(title ? title : url);
  }

  //--------------------
  // Begin preview control
  //--------------------
  const previewVideoUrl = getPreviewVideoUrl(urlInfo);
  const previewImageUrls = getPreviewImageUrls(urlInfo);
  const canShowPreview = (previewVideoUrl && !previewVideoHasError) ||
      (previewImageUrls.length > 0);
  const showPreview = url && (url === activePreviewVideoItemUrl);

  function handlePreviewVideoError() {
    setPreviewVideoHasError(true);
  }

  function showPreivew() {
    // Play the preview video if it is not already playing.
    if (canShowPreview && url !== activePreviewVideoItemUrl) {
      setActivePreviewVideoItemUrl(url);
    }
  }

  function hidePreview() {
    // Stop playing the preview video if it is already playing.
    if (showPreview) {
      setActivePreviewVideoItemUrl(null);
    }
  }

  // Preview control on touch devices.
  function handleTouchStart() {
    if (!isTouchDevice()) {
      return;
    }
    showPreivew();
  }

  function handleClick() {
    if (!isTouchDevice()) {
      return;
    }
    hidePreview();
  }

  // Preview control on non-touch devices.
  function handleMouseOver() {
    if (isTouchDevice()) {
      return;
    }
    showPreivew();
  }

  function handleMouseLeave() {
    if (isTouchDevice()) {
      return;
    }
    hidePreview();
  }

  //--------------------
  // End preview control
  //--------------------

  return (
    <div
      className={bgColorClassName + " thumbnail-image-container rounded-2 " + className}
      style={{ backgroundImage:`url("${thumbnailUrl}")` }}
      onTouchStart={handleTouchStart}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {
        isFetching &&
        <div className="center-absolute-self h-10">
          <LoadingIndicator className="mt-n3"/>
        </div>
      }

      <Preview
        canShowPreview={canShowPreview}
        showPreview={showPreview}
        previewVideoUrl={previewVideoUrl}
        previewImageUrls={previewImageUrls}
        previewVideoHasError={previewVideoHasError}
        handlePreviewVideoError={handlePreviewVideoError}
      />

      <Badges {...props} canShowPreview={canShowPreview} />
    </div>
  );
}

export default ThumbnailImage;

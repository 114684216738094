import { analyticsLogEvent } from 'appFirebase/helperApi';
import { IconTagPlus } from 'components/icons';
import TooltipContainer from 'components/TooltipContainer';
// Third party.
import React from 'react';

function EditTagsButton(props) {
  const {
    clickEventName="click_edit_tags_button",
    tooltipContent="Tag",
    hoverEventName="hover_edit_tags_button",
  } = props;

  function handleClick() {
    props.onClick && props.onClick();

    analyticsLogEvent(clickEventName);
  }

  return (
    <TooltipContainer content={tooltipContent} hoverEventName={hoverEventName}>
      <div className="round-button-container" onClick={handleClick}>
        <IconTagPlus size={1.65} />
      </div>
    </TooltipContainer>
  );
}

export default EditTagsButton;

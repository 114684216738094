import {
  apiAddCollectionItem,
  apiDeleteCollectionItem,
  apiUpdateCollectionItemTags,
  modalClose,
} from 'appRedux/actions';
import {
  delay,
} from 'misc/appHelperFunctions';
import {
  apiUpdateCollectionItemDetails,
  apiUpdateCollectionItemPrice,
} from "appRedux/actions/api/collectionApi";
import DetailsEditor from "./detailsEditor";
import DeleteItem from './deleteItem';
import TagsEditor from './tagsEditor';
import SaveItem from './saveItem';
import PriceEditor from './priceEditor';
// Third party.
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

function ModalBody(props) {
  const { modal } = props;

  if (modal.tagsEditor.show) {
    return (
      <TagsEditor {...props} />
    );
  }

  if (modal.deleteItem.show) {
    return (
      <DeleteItem {...props} />
    );
  }

  if (modal.saveItem.show) {
    return (
      <SaveItem {...props} />
    );
  }

  if (modal.detailsEditor.show) {
    return (
      <DetailsEditor {...props} />
    );
  }

  if (modal.priceEditor.show) {
    return (
      <PriceEditor {...props} />
    );
  }

  return null;
}

function ModalItemEditor(props) {
  const { closeModal, modal } = props;
  const show = (modal.deleteItem.show || modal.tagsEditor.show ||
    modal.saveItem.show || modal.detailsEditor.show || modal.priceEditor.show);

  return (
    <Modal
      show={show}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      scrollable={!modal.tagsEditor.show}
    >
      <ModalBody {...props} />
    </Modal>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  collection: state.collection,
  modal: state.modal,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  actionApiAddCollectionItem: (urlInfo, collectionInfo) => {
    dispatch(apiAddCollectionItem(urlInfo, collectionInfo));
  },
  closeModal: (delayInSec) => {
    if (!delayInSec) {
      dispatch(modalClose());
    } else {
      delay(delayInSec).then(() => {
        dispatch(modalClose());
      });
    }
  },
  deleteCollectionItem: (itemId) => {
    dispatch(apiDeleteCollectionItem(itemId));
  },
  updateCollectionItemTags: (itemId, tags) => {
    dispatch(apiUpdateCollectionItemTags(itemId, tags));
  },
  updateCollectionItemDetails: (itemId, title, description) => {
    dispatch(apiUpdateCollectionItemDetails(itemId, title, description));
  },
  updateCollectionItemPrice: (itemId, price) => {
    dispatch(apiUpdateCollectionItemPrice(itemId, price));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalItemEditor);

import {
  isCollectionFilterActive,
} from 'misc/appHelperFunctions';
import { IconPlus } from 'components/icons';
import Filter from './Filter';
import Header from './Header';
import Item from './Item';
// Third party.
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function CollectionCol(props) {
  return (
    <Col
      xs={6} sm={6} md={4} lg={3} xl={3}
      className="col-xxl-2d4 col-xxxl-2 col-xxxxl-1d5 mobile-px-gutter"
    >
      { props.children }
    </Col>
  );
}

function EmptyStateItemCard(props) {
  function handleClick() {
    const { updateNavbarSaveBox } = props;
    updateNavbarSaveBox && updateNavbarSaveBox(true);
  }

  return (
    <CollectionCol>
      <div className="pt-4">
        <div className="cursor-pointer" onClick={handleClick}>
          <div className="my-3 bg-secondary thumbnail-image-container rounded-2">
            <div className="bg-primary round-container-md center-absolute-self">
              <IconPlus size={1.5} className="icon-white" />
            </div>
          </div>
          <h6 className="line-clamp-2">Add video link</h6>
        </div>
      </div>
    </CollectionCol>
  );
}

function CollectionContent(props) {
  const [activePreviewVideoItemUrl, setActivePreviewVideoItemUrl] = useState(null);

  const {
    collection,
    hideNavbarInputBox,
    setCollectionFilterToTags,
    showModalDeleteItem,
    showModalTagsEditor,
    showModalDetailsEditor,
    showModalPriceEditor,
    actionModalShowSaveItem,
    updateCollectionCurrentItemId,
    updateCollectionFilter,
    updateNavbarSaveBox,
    isAdmin,
  } = props;
  const {
    currentCollectionInfo,
    items,
    filter,
    filteredCurrentCollection,
  } = props.collection;

  if (!currentCollectionInfo) {
    return null;
  }

  const collectionItemIds = filteredCurrentCollection.itemIds || [];
  const collectionTags = filteredCurrentCollection.tags || {};

  const collectionItems = collectionItemIds.map((itemId) => {
    const itemData = items[itemId];
    return (
      <CollectionCol key={itemId}>
        <Item
          itemData={itemData}
          itemId={itemId}
          showModalDeleteItem={showModalDeleteItem}
          showModalTagsEditor={showModalTagsEditor}
          showModalDetailsEditor={showModalDetailsEditor}
          showModalPriceEditor={showModalPriceEditor}
          actionModalShowSaveItem={actionModalShowSaveItem}
          setCollectionFilterToTags={setCollectionFilterToTags}
          updateCollectionCurrentItemId={updateCollectionCurrentItemId}
          activePreviewVideoItemUrl={activePreviewVideoItemUrl}
          setActivePreviewVideoItemUrl={setActivePreviewVideoItemUrl}
          isAdmin={isAdmin}
        />
      </CollectionCol>
    );
  });


  // Note this is the entire current collection, not just the filtered collection.
  const currentCollectionItemIds =
      collection.itemIds[currentCollectionInfo.id] || [];
  // Display empty state card if current collection is empty.
  if (!currentCollectionItemIds.length) {
    collectionItems.push(
      <EmptyStateItemCard key={'placeholder'} updateNavbarSaveBox={updateNavbarSaveBox} />
    );
  }

  return (
    <Container fluid className="px-0">
      <Filter
        collectionTags={collectionTags}
        filter={filter}
        updateCollectionFilter={updateCollectionFilter}
      />

      <Header
        titleHidden={isCollectionFilterActive(filter)}
        currentCollectionInfo={currentCollectionInfo}
        collectionItemIds={collectionItemIds}
        collectionTags={collectionTags}
        hideNavbarInputBox={hideNavbarInputBox}
      />

      <Row>
        {collectionItems}
      </Row>
    </Container>
  );
}

export default CollectionContent;

import {
  apiSearchVideos,
  apiGetLatestVideos,
  apiGetWeeklyHotVideos,
  apiUpdateUserPreferredCategories,
  modalShowVideoPlayer,
} from 'appRedux/actions';
import {
  isAnyModalActive,
} from 'misc/appHelperFunctions';
import useScrollToLastPosition from 'misc/useScrollToLastPosition';
import Shortcuts from './shortcuts';
import SearchResults from './searchResults';
// Third party.
import React from 'react';
import { connect } from 'react-redux';
import ModalVideoPlayer from 'components/modalVideoPlayer';


function Home(props) {
  useScrollToLastPosition({ positionTarget: 'search' });

  const {
    // Redux states.
    modal,
    search,
  } = props;

  const { keyword, forceSearchOn } = search.searchInput;
  const showSearchResults = keyword || forceSearchOn;
  // Prevents the page from scrolling when any modal is active. This is
  // especially necessary when video is playing in full screen mode, and user
  // drags the video progress bar on mobile device horizontally.
  const containerClassName = isAnyModalActive(modal) ? "overflow-hidden" : "";

  return (
    <div className={"h-100 " + containerClassName}>
      {
        showSearchResults
        ? <SearchResults {...props} />
        : <Shortcuts {...props} />
      }

      <ModalVideoPlayer eventPrefix={'home'} />

    </div>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  isAdmin: state.admin.isAdmin,
  modal: state.modal,
  search: state.search,
  user: state.user,
  videoPeeks: state.videoPeeks,
});

const mapDispatchToProps = (dispatch) => ({
  actionApiSearchVideos: (isRefresh) => {
    dispatch(apiSearchVideos(isRefresh));
  },
  actionApiGetLatestVideos: () => {
    dispatch(apiGetLatestVideos());
  },
  actionApiGetWeeklyHotVideos: () => {
    dispatch(apiGetWeeklyHotVideos());
  },
  actionModalShowVideoPlayer: (show, urlInfo) => {
    dispatch(modalShowVideoPlayer(show, urlInfo));
  },
  actionApiUpdateUserPreferredCategories: (categoryList, incAmount) => {
    dispatch(apiUpdateUserPreferredCategories(categoryList, incAmount));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);

const modal = (state = {
  deleteItem: {
    show: false,
    itemId: null,
    redirectPath: null, // Redirect path after deletion.
  },
  tagsEditor: {
    show: false,
    itemId: null,
  },
  saveItem: {
    show: false,
    urlInfo: null,
  },
  videoPlayer: {
    show: false,
    urlInfo: null,
  },
  loginRequest: {
    show: false,
    urlInfo: null,
  },
  detailsEditor: {
    show: false,
    itemId: null,
  },
  priceEditor: {
    show: false,
    itemId: null,
  },
}, action) => {
  switch (action.type) {
    case 'MODAL_SHOW_DELETE_ITEM': {
      const { show, itemId, redirectPath } = action;
      const nextDeleteITem = {
        show,
        itemId: show ? itemId : null,
        redirectPath: show ? redirectPath : null,
      };

      return {
        ...state,
        deleteItem: nextDeleteITem,
      };
    }
    case 'MODAL_SHOW_TAGS_EDITOR': {
      const { show, itemId } = action;
      const nextTagsEditor = {
        show,
        itemId: show ? itemId : null,
      };

      return {
        ...state,
        tagsEditor: nextTagsEditor,
      };
    }
    case 'MODAL_SHOW_SAVE_ITEM': {
      const { show, urlInfo } = action;
      const nextSaveItem = {
        show,
        urlInfo: show ? urlInfo : null,
      };

      return {
        ...state,
        saveItem: nextSaveItem,
      };
    }
    case 'MODAL_SHOW_DETAILS_EDITOR': {
      const { show, itemId } = action;
      const nextDetailsEditor = {
        show,
        itemId: show ? itemId : null,
      };

      return {
        ...state,
        detailsEditor: nextDetailsEditor,
      };
    }
    case 'MODAL_SHOW_PRICE_EDITOR': {
      const { show, itemId } = action;
      const nextPriceEditor = {
        show,
        itemId: show ? itemId : null,
      };

      return {
        ...state,
        priceEditor: nextPriceEditor,
      };
    }
    case 'MODAL_SHOW_VIDEO_PLAYER': {
      const { show, urlInfo } = action;
      const nextVideoPlayer = {
        show,
        urlInfo: show ? urlInfo : null,
      };

      return {
        ...state,
        videoPlayer: nextVideoPlayer,
      };
    }
    case 'MODAL_SHOW_LOGIN_REQUEST': {
      const { show } = action;
      const nextLoginRequest = {
        show,
      };

      return {
        ...state,
        loginRequest: nextLoginRequest,
      };
    }
    default:
      return state
  }
};

export default modal;

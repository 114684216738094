const toast = (state = {
  showMessage: {
    /**
     * $id: string,
     */
  }
}, action) => {
  switch (action.type) {
    case 'TOAST_SET_SHOW_MESSAGE': {
      const { id, message } = action;

      if (!id) {
        return state;
      }

      const nextShowMessage = {
        ...state.showMessage,
        [id]: message,
      };

      return {
        ...state,
        showMessage: nextShowMessage,
      };
    }
    default:
      return state;
  }
};

export default toast;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  modalClose,
} from 'appRedux/actions';
import MediaContent from 'components/mediaContent';
import VideoInformation from 'components/VideoInformation';
// Third party.
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import ModalFooter from 'react-bootstrap/ModalFooter';

function ModalBody(props) {
  const { eventPrefix } = props;

  useEffect(() => {
    analyticsLogEvent(eventPrefix + '-modal_video_play-show');
  }, [eventPrefix]);

  const {
    // Redux states.
    modal,
  } = props;

  const urlInfo = modal.videoPlayer.urlInfo;

  return (
    <div className="bg-light pb-3 overflow-auto">
      <MediaContent urlInfo={urlInfo} />
      <div className="overflow-auto" style={{ maxHeight: '200px' }}>
        <VideoInformation urlInfo={urlInfo} />
      </div>
    </div>
  );
}

function ModalVideoPlayer(props) {
  const {
    // Redux states.
    modal,
    // Redux actions.
    actionModalClose,
  } = props;
  const show = modal.videoPlayer.show;

  return (
    <Modal
      size="lg"
      show={show}
      onHide={actionModalClose}
      aria-labelledby="video-player-modal"
      centered
      backdropClassName="backdrop-dark"
      scrollable={true}
    >
      <ModalBody {...props} />
      <ModalFooter
        className="border-top cursor-pointer bg-light-hover"
        onClick={actionModalClose}
      >
        <div className="w-100">
          <h5 className="mb-0 text-center text-black-50">Close</h5>
        </div>
      </ModalFooter>
    </Modal>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  modal: state.modal,
});

const mapDispatchToProps = (dispatch) => ({
  actionModalClose: () => {
    dispatch(modalClose());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalVideoPlayer);

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_COLLECTION,
  P_SAVED,
  P_VMARK,
} from 'misc/appConstants';
import {
  IconProfile,
} from 'components/icons';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';

function SavedTab(props) {
  const {
    currentPathName,
    classNames,
  } = props;

  const isTargetPageCurrent = currentPathName.includes(P_SAVED) ||
      currentPathName.includes(P_COLLECTION) || currentPathName.includes(P_VMARK);

  const {
    inactiveIconClassName,
    activeIconClassName,
    inactiveTextClassName,
    activeTextClassName,
  } = classNames;

  const iconClassName =
      isTargetPageCurrent ? activeIconClassName : inactiveIconClassName;
  const textClassName =
      isTargetPageCurrent ? activeTextClassName : inactiveTextClassName;


  function handleClick() {
    analyticsLogEvent("bottom_navbar-click-saved");
  }

  return (
    <div className="flex-grow-1 text-center">
      <Link
        to={P_SAVED}
        className="no-hover-effect"
        onClick={handleClick}
      >
        <IconProfile size={1.7} className={ iconClassName + " mb-n1" } />
        <p className={textClassName}>
          Saved
        </p>
      </Link>
    </div>
  );
}

export default SavedTab;

import {
  SEARCH_FILTER_DISPLAY_NAME,
} from 'appRedux/reducers/stateConstants';
import {
  toTitleCase,
} from 'misc/appHelperFunctions';
import VideosGrid from 'components/videosGrid';
import Filter from './Filter';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function SearchResults(props) {
  const {
    // Redux states.
    search,
    // Redux actions.
    actionApiSearchVideos,
  } = props;

  const {
    // Redux states.
    isAdmin,
    // Redux actions.
    actionNavbarUpdateSearchBox,
  } = props;

  const {
    isFetching: isFetchingVideos,
    isLoadingMore: isLoadingMoreVideos,
    videos,
  } = search;

  const {
    keyword:searchKeyword,
  } = search.searchInput;

  const titleCaseSearchKeyword = toTitleCase(searchKeyword);
  const titleFetching = searchKeyword
      ? "We are adding " + titleCaseSearchKeyword + " videos."
      : null;
  const hasMoreSearchResults = !searchKeyword ||
      (search.searchInput.externalSearchPage !== -1);
  const footerTitle = hasMoreSearchResults ? null : "No More Videos";

  function VideosGridHeader() {
    let titleKeywordInHeader;
    if (titleCaseSearchKeyword) {
      titleKeywordInHeader = titleCaseSearchKeyword;
    } else if (search.searchInput.forceSearchOn) {
      titleKeywordInHeader =
          SEARCH_FILTER_DISPLAY_NAME.orderBy[search.searchInput.filters.orderBy];
    }

    return (
      <Container className="px-0 pb-3 text-center mw-md">
        <h5 className="mb-0 text-dark">
          { titleKeywordInHeader } <span className="text-primary">Free HD Porn Videos</span>
        </h5>
      </Container>
    );
  }

  function onEndReached() {
    actionApiSearchVideos(/*isRefresh*/false);
  }

  return (
    <div className="page-container">
      <Filter
        search={search}
        actionNavbarUpdateSearchBox={actionNavbarUpdateSearchBox}
      />

      <VideosGrid
        eventPrefix="feed"
        isFetchingVideos={isFetchingVideos}
        isLoadingMoreVideos={isLoadingMoreVideos}
        videos={videos}
        titleFetching={titleFetching}
        titleComponent={VideosGridHeader}
        footerTitle={footerTitle}
        className="mobile-mt-2 desktop-mt-3"
        onEndReached={onEndReached}
        shouldUpdateClickCnt
        enableAdminButton={isAdmin}
      />
    </div>
  );
}

export default SearchResults;

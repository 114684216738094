import {
  apiCreatePrivateCollection,
  apiFetchCollection,
  apiFetchPrivateCollections,
  positionsUpdate,
} from 'appRedux/actions';
import CollectionList from './collectionList';
// Third party.
import React from 'react';
import { connect } from 'react-redux';

function PrivateCollections(props) {
  return (
    <CollectionList {...props} />
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  collection: state.collection,
});

const mapDispatchToProps = (dispatch) => ({
  createPrivateCollection: (name) => {
    dispatch(apiCreatePrivateCollection(name));
  },
  fetchCollection: (collectionInfo) => {
    dispatch(apiFetchCollection(collectionInfo));
  },
  fetchPrivateCollections: () => {
    dispatch(apiFetchPrivateCollections());
  },
  actionPositionsUpdateCollection: (scorllY) => {
    dispatch(positionsUpdate('collection', scorllY));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateCollections);

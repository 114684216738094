// Third party.
import { useEffect, useState } from 'react';

// Asynchronously import constants.
export function useImportConstants(constantsFileName) {
  const [data, setData] = useState([]);

  useEffect(() => {
    import("./" + constantsFileName).then((constants) => {
      const constantsData = constants[constantsFileName];
      setData(constantsData);
    });
  }, [constantsFileName]);

  return data;
}

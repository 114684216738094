import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  QUERY_COLLECTION_ID,
  composeQuery,
  searchInputToQuery,
} from 'misc/queryHelpers';
import {
  useImportConstants,
} from 'misc/dataConstants/dataConstantsHelpers';
import {
  P_PLAYLIST,
} from 'misc/appConstants';
import {
  isPremiumVideo,
  randomlyPickFromList,
} from 'misc/appHelperFunctions';
import PremiumBadge from 'components/PremiumBadge';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';

const _NON_BREAKING_SPACE = "\u00a0";
const _CHANGE_FREQUENCY = 4;
const _THUMBNAIL_CHANGE_FREQUENCY = 3;
const _SKIP_INTERVAL = 1;
const _RESULT_COUNT = 10;

function Item(props) {
  const { thumbnail, keyword, displayNames, collectionId, price, thumbnailPool } = props.keyword;
  const eventName = props.eventName;

  let chosenThumbnail = thumbnail;
  if (thumbnailPool && thumbnailPool.length > 1) {
    chosenThumbnail = randomlyPickFromList(
      thumbnailPool,
      _THUMBNAIL_CHANGE_FREQUENCY,
      1,
      1,
    );
  }

  let searchQuery = searchInputToQuery({
    keyword,
  });

  if (collectionId) {
    searchQuery = P_PLAYLIST + composeQuery({ [QUERY_COLLECTION_ID]: collectionId });
  }

  const namesToDisplay = [];
  if (displayNames && displayNames.length) {
    for (let i = 0; i < displayNames.length; i++) {
      const nameToDisplay = displayNames[i].replace(' ', _NON_BREAKING_SPACE);
      namesToDisplay.push(nameToDisplay);
    }
  } else {
    const nameToDisplay = keyword.replace(' ', _NON_BREAKING_SPACE);
    namesToDisplay.push(nameToDisplay);
  }

  function handleOnClick() {
    analyticsLogEvent('home-click-' + eventName);
  }

  return (
    <Link
      to={searchQuery}
      className="flex-shrink-0 text-center cursor-pointer mr-2 mobile-mb-2 desktop-mb-4 no-hover-effect"
      style={{width: '140px'}}
      onClick={handleOnClick}
    >
      <div
        className="bg-secondary thumbnail-image-container-100 rounded-2"
        style={{backgroundImage: `url("${chosenThumbnail}")`}}
      >
        {
          isPremiumVideo({ price }) &&
          <div className="position-absolute top-0 right-1">
            <PremiumBadge />
          </div>
        }
      </div>
      <div className="mt-1">
        {
          namesToDisplay.map((nameToDisplay) => {
            return (
              <p key={nameToDisplay}
                 className="m-0 text-primary line-clamp-1">
                {nameToDisplay}
              </p>
            );
          })
        }
      </div>
    </Link>
  );
}

function Categories(props) {
  const popularCategories = useImportConstants("POPULAR_CATEGORIES");

  let i = 0;
  const items = popularCategories.map((item) => {
    const { title, keywords } = item;

    const eventName = title ? title.toLowerCase().replace(/ /g, '_') : null;

    let pickedKeywords = randomlyPickFromList(
      keywords,
      _CHANGE_FREQUENCY,
      _SKIP_INTERVAL,
      Math.min(_RESULT_COUNT, keywords.length)
    );

    const subItems = pickedKeywords.map((keyword) => {
      return (
        <Item
          {...props}
          key={i++}
          keyword={keyword}
          eventName={eventName}
        />
      );
    });

    return (
      <div key={i++}>
        <h5>{ title }</h5>
        <div className="d-flex flex-nowrap overflow-auto hide-scrollbar">
          { subItems }
        </div>
      </div>
    );
  });

  return (
    <div className="mt-4 mb-n5">
      { items }
    </div>
  );
}

export default Categories;

import {
  IconPlus,
  IconX,
} from 'components/icons';
import CreateCollectionForm from './CreateCollectionForm';
// Third party.
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalBody(props) {
  const { closeModal, createPrivateCollection } = props;

  function onSubmit(name) {
    if (createPrivateCollection) {
      createPrivateCollection(name);
    }
    if (closeModal) {
      closeModal();
    }
  }

  return (
    <div className="px-3 pb-3">
      <div className="d-flex align-items-center justify-content-between my-1">
        <h5 className="mb-0">Create playlist</h5>

        <div className="round-button-container mr-n2" onClick={closeModal}>
          <IconX size={1.5} />
        </div>
      </div>

      <CreateCollectionForm onSubmit={onSubmit} />
    </div>
  );
}

function CreateCollectionButton(props) {
  const [show, setShow] = useState(false);

  function closeModal() {
    setShow(false);
  }

  function showModal() {
    setShow(true);
  }

  const {
    className,
    createPrivateCollection,
    isRoundButton,
    onClick,
  } = props;

  function handleClick() {
    if (onClick) {
      onClick();
    } else {
      showModal();
    }
  }

  return (
    <div className={className}>
      {
        isRoundButton
        ? <Button variant="light" className="round-button" onClick={handleClick}>
            <IconPlus size={1.5} />
          </Button>
        : <Button variant="light" block onClick={handleClick}>
            <div className="d-flex align-items-center justify-content-center m-1">
              <div className="bg-primary mr-2 round-container-sm">
                <IconPlus size={1} className="icon-white" />
              </div>
              <h4 className="m-0">
                Create playlist
              </h4>
            </div>
          </Button>
      }

      <Modal
        show={show}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <ModalBody
          closeModal={closeModal}
          createPrivateCollection={createPrivateCollection}
        />
      </Modal>
    </div>
  );
}

export default CreateCollectionButton;

// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function Header(props) {
  const { collectionTags } = props;
  const tagCount = collectionTags ? Object.keys(collectionTags).length : 0;
  const tagCountSummary = (tagCount > 1)
      ? (tagCount + ' tags')
      : (tagCount + ' tag');

  return (
    <Container className="pt-5 px-0 mw-md">
      <h4>{tagCountSummary}</h4>
    </Container>
  );
}

export default Header;

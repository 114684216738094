import {
  IconCopy,
  IconTagPlus,
  IconPencil,
  IconThreeDots,
  IconTrash,
} from 'components/icons';

import CustomToggleButton from 'components/buttons/CustomToggleButton';
// Third party.
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

function ItemDropDown(props) {
  const {
    showModalDeleteItem,
    showModalTagsEditor,
    showModalDetailsEditor,
    showModalPriceEditor,
    actionModalShowSaveItem,
    isAdmin,
  } = props;

  // Note that alignRight and drop="up" cannot be used here since Dropdown.Menu
  // will still occupy bottom right space, thus force page extends on right and
  // bottom on mobile (which is a visual bug).
  return (
    <Dropdown className="mr-n2">
      <Dropdown.Toggle as={CustomToggleButton}>
        <IconThreeDots size={1} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-up">
        <Dropdown.Item
          as="div"
          className="d-flex align-items-center"
          onClick={showModalDeleteItem}
        >
          <IconTrash size={1.45} className="mr-2 icon-dark" />
          <span>Delete</span>
        </Dropdown.Item>
        <Dropdown.Item
          as="div"
          className="d-flex align-items-center"
          onClick={actionModalShowSaveItem}
        >
          <IconCopy size={1.45} className="mr-2 icon-dark" />
          <span>Copy</span>
        </Dropdown.Item>
        <Dropdown.Item
          as="div"
          className="d-flex align-items-center"
          onClick={showModalTagsEditor}
        >
          <IconTagPlus size={1.5} className="mr-2 icon-dark" />
          <span>Tag</span>
        </Dropdown.Item>
        <Dropdown.Item
          as="div"
          className="d-flex align-items-center"
          onClick={showModalDetailsEditor}
        >
          <IconPencil size={1.5} className="mr-2 icon-dark" />
          <span>Details</span>
        </Dropdown.Item>
        {
          isAdmin && <Dropdown.Item
            as="div"
            className="d-flex align-items-center"
            onClick={showModalPriceEditor}
          >
            <IconPencil size={1.5} className="mr-2 icon-dark" />
            <span>Price</span>
          </Dropdown.Item>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ItemDropDown;

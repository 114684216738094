import {
  IconX,
} from 'components/icons';
// Third party.
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

function EditBaseClickCnt(props) {
  const {
    // Redux states.
    admin,
    // Redux actions.
    actionAdminCloseModals,
    actionAdminApiUpdateVideoUrlInfo,
  } = props;

  const [baseClickCnt, setBaseClickCnt] =
      useState(admin.modalEditBaseClickCnt.urlInfo.baseClickCnt);

  function closeModal() {
    actionAdminCloseModals();
  }

  function handleInputChange(event) {
    const nextBaseClicCnt = parseInt(event.target.value, 10);
    setBaseClickCnt(nextBaseClicCnt);
  }

  function handleSave() {
    if (actionAdminApiUpdateVideoUrlInfo) {
      actionAdminApiUpdateVideoUrlInfo({
        ...admin.modalEditBaseClickCnt.urlInfo,
        baseClickCnt,
      });
    }
    closeModal();
  }

  return (
    <div className="px-3 pb-3">
      <div className="d-flex align-items-center justify-content-between my-1">
        <h5 className="mb-0">Edit base click</h5>

        <div className="round-button-container mr-n2" onClick={closeModal}>
          <IconX size={1.5} />
        </div>
      </div>

      <div className="mt-3">
        <h6 className="mb-1 text-black-50">New base click count</h6>
        <input
          type={'number'}
          placeholder={'Base click count'}
          value={baseClickCnt}
          onChange={handleInputChange}
          className="rounded w-100 form-control"
          autoComplete="off"
        />
      </div>

      <Button variant='primary' block className="mt-4" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
}

export default EditBaseClickCnt;

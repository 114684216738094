import admin from './admin';
import collection from './collection';
import modal from './modal';
import navbar from './navbar';
import persist from './persist';
import playlist from './playlist';
import positions from './positions';
import recommendation from './recommendation';
import search from './search';
import videoPeeks from './videoPeeks';
import tags from './tags';
import toast from './toast';
import user from './user';
// Third party
import { combineReducers } from 'redux';

export default combineReducers({
  admin,
  collection,
  modal,
  navbar,
  persist,
  playlist,
  positions,
  recommendation,
  search,
  tags,
  toast,
  user,
  videoPeeks,
});

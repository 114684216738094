import { IconLoading } from 'components/icons';
// Third party.
import React from 'react';
import Button from 'react-bootstrap/Button';

function ButtonWithLoadingState(props) {
  const { isLoading, onClick, children } = props;

  const propsForButton = {...props};
  delete propsForButton.isLoading;

  return (
    <Button
      {...propsForButton}
      disabled={isLoading}
      onClick={!isLoading ? onClick : null}
    >
      {
        isLoading
        ?
          <IconLoading
            size={1.2}
            className="spin-icon"
            pathClassName={"icon-white"}
          />
        : children
      }
    </Button>
  );
}

export default ButtonWithLoadingState;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import CollectionPreview from 'routes/privateCollections/collectionList/CollectionPreview';
import CreateCollectionButton from 'components/buttons/createCollectionButton';
// Third party.
import React from 'react';

function ChoosePlayList(props) {
  if (props.hidden) {
    return null;
  }

  const {
    eventPrefix="",
    sortedPrivateCollections,
    setShowCreatePlaylist,
    handleSaveCollectionItem,
  } = props;

  const privateCollectionItems = sortedPrivateCollections
      .map((collectionInfo) => {
        function handleClickCollection() {
          if (handleSaveCollectionItem) {
            handleSaveCollectionItem(collectionInfo);
          }
          analyticsLogEvent(eventPrefix + '_modal-save-item_click-playlist');
        }

        return (
          <div key={collectionInfo.id} className="mb-4">
            <CollectionPreview
              collectionInfo={collectionInfo}
              handleClickCollection={handleClickCollection}
            />
          </div>
        );
      });

  function handleClickCreateCollectionButton() {
    if (setShowCreatePlaylist) {
      setShowCreatePlaylist(true);
    }
    analyticsLogEvent(eventPrefix + '_modal-save-item_click-create-playlist');
  }

  return (
    <div>
      <div className="overflow-auto mh-50vh">
        <h5 className="mt-2 mb-3 text-center">
          Choose playlist
        </h5>

        { privateCollectionItems }
      </div>

      <div className="border-top mx-n3 py-2" />

      <CreateCollectionButton
        onClick={handleClickCreateCollectionButton}
      />
    </div>
  );
}

export default ChoosePlayList;

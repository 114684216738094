import {
  P_COLLECTION,
  P_HOME,
  P_FEED,
  P_PLAYLIST,
  P_PORNSTARS,
  P_SAVED,
  P_SITES,
  P_TAGS,
  P_VMARK,
} from 'misc/appConstants';
import {
  BaseTopNavbar,
  NavbarHome,
  NavbarPlaylist,
  NavbarPornstars,
  NavbarSites,
} from '../navbar-components';
import ToolbarCollection from './toolbars/toolbarCollection';
import ToolbarCollectionTags from './toolbars/ToolbarCollectionTags';
import ToolbarVmark from './toolbars/ToolbarVmark';
import ToolbarSaved from './toolbars/ToolbarSaved';
// Third party.
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

function NavbarVmark(props) {
  return (
    <BaseTopNavbar>
      <ToolbarVmark {...props} />
    </BaseTopNavbar>
  );
}

function NavbarCollection(props) {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}${P_TAGS}`} render={() => {
        return (
          <BaseTopNavbar>
            <ToolbarCollectionTags {...props} />
          </BaseTopNavbar>
        );
      }} />
      <Route path={match.path} render={() => {
        return (
          <BaseTopNavbar className="d-flex justify-content-between">
            <ToolbarCollection {...props} />
          </BaseTopNavbar>
        );
      }} />
    </Switch>
  );
}

function NavbarSaved(props) {
  return (
    <BaseTopNavbar>
      <ToolbarSaved {...props} />
    </BaseTopNavbar>
  );
}

function NavbarDefault() {
  return (
    <BaseTopNavbar />
  );
}

// Navbar to display when user has signed in.
function NavbarWithAuth(props) {
  return (
    <Switch>
      <Route exact path={P_HOME} render={() => {
        return (<NavbarHome {...props} />);
      }} />
      <Route path={P_PLAYLIST} render={() => {
        return (<NavbarPlaylist {...props} />);
      }} />
      <Route path={P_FEED} render={() => {
        return (<NavbarDefault />);
      }} />
      <Route path={P_PORNSTARS} render={() => {
        return (<NavbarPornstars {...props} />);
      }} />
      <Route path={P_SITES} render={() => {
        return (
          <NavbarSites {...props} />
        );
      }} />
      <Route path={P_SAVED} render={() => {
        return (<NavbarSaved {...props} />);
      }} />
      <Route path={P_VMARK} render={() => {
        return (<NavbarVmark {...props} />);
      }} />
      <Route path={P_COLLECTION} render={() => {
        return (<NavbarCollection {...props} />);
      }} />
      <Route path={P_HOME} render={() => {
        return (<NavbarDefault />);
      }} />
    </Switch>
  );
}

export default NavbarWithAuth;

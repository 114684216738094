import {
  isNullOrUndefined,
} from 'misc/appHelperFunctions';

const persist = (state = {
  feedPreference: {
    /**
     * show: boolean,
     * categories: {
     *   $category: boolean,
     * },
     */
  },
  lastFeedTimestampMs: {
    guestUser: 0,
    logInUser: 0,
  },
}, action) => {
  switch (action.type) {
    case 'PERSIST_SET_LAST_FEED_TIMESTAMP_MS': {
      const { lastFeedTimestampMs } = action;

      const nextLastFeedTimestampMs = {
        ...state.lastFeedTimestampMs,
        ...lastFeedTimestampMs,
      };

      if (isNullOrUndefined(nextLastFeedTimestampMs.guestUser)) {
        nextLastFeedTimestampMs.guestUser = 0;
      }

      if (isNullOrUndefined(nextLastFeedTimestampMs.logInUser)) {
        nextLastFeedTimestampMs.logInUser = 0;
      }

      return {
        ...state,
        lastFeedTimestampMs: nextLastFeedTimestampMs,
      };
    }
    case 'PERSIST_SET_FEED_PREFERENCE': {
      const { feedPreference } = action;

      return {
        ...state,
        feedPreference,
      };
    }
    case 'PERSIST_SET_FEED_PREFERENCE_CATEGORIES': {
      const { categories } = action;

      const nextFeedPreference = {
        ...state.feedPreference,
        categories: categories || {},
      }

      return {
        ...state,
        feedPreference: nextFeedPreference,
      };
    }
    default:
      return state
  }
};

export default persist;

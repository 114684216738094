import {
  SEARCH_FILTER_DISPLAY_NAME,
} from 'appRedux/reducers/stateConstants';
import {
  searchInputToQuery,
} from 'misc/queryHelpers';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

const _FILTER_SECTION_CONTAINER_CLASS_NAME = 'd-flex align-items-center mt-3';
const _FILTER_NAME_CLASS_NAME = 'mr-2 mb-0 text-black-50 flex-shrink-0';
const _FILTER_ITEM_CONTAINER_CLASS_NAME = 'd-flex flex-nowrap overflow-auto hide-scrollbar';
const _FILTER_BASE_CLASS_NAME = 'mx-1 mb-0 flex-shrink-0';
const _FILTER_SELECTED_CLASS_NAME =  ' tag-primary bg-primary-transparent-80';
const _FILTER_UNSELECTED_CLASS_NAME = ' tag-light';

function Filter(props) {
  const {
    // Redux state.
    search,
  } = props;

  const searchFilters = search.searchInput.filters;

  function FilterItem(props) {
    const { filterName, filterValue } = props;

    const className = (searchFilters[filterName] === filterValue)
        ? _FILTER_BASE_CLASS_NAME + _FILTER_SELECTED_CLASS_NAME
        : _FILTER_BASE_CLASS_NAME + _FILTER_UNSELECTED_CLASS_NAME;

    const searchQuery = searchInputToQuery({
      ...search.searchInput,
      filters: {
        ...search.searchInput.filters,
        [filterName]: filterValue,
      }
    });

    const displayName = SEARCH_FILTER_DISPLAY_NAME[filterName][filterValue];

    return (
      <Link to={searchQuery} className="no-hover-effect">
        <h6 className={className}>
          <div className="px-2 py-1 text-dark font-weight-normal">
            { displayName }
          </div>
        </h6>
      </Link>
    );
  }

  return (
    <Container className="px-0 mw-md pt-2 pb-3">
      <div className={_FILTER_SECTION_CONTAINER_CLASS_NAME}>
        <p className={_FILTER_NAME_CLASS_NAME}>Sort by</p>
        <div className={_FILTER_ITEM_CONTAINER_CLASS_NAME}>
          <FilterItem
            filterName="orderBy"
            filterValue="view"
          />
          <FilterItem
            filterName="orderBy"
            filterValue="date"
          />
        </div>
      </div>

      <div className={_FILTER_SECTION_CONTAINER_CLASS_NAME}>
        <p className={_FILTER_NAME_CLASS_NAME}>Date</p>
        <div className={_FILTER_ITEM_CONTAINER_CLASS_NAME}>
          <FilterItem
            filterName="videoage"
            filterValue=""
          />
          <FilterItem
            filterName="videoage"
            filterValue="week"
          />
          <FilterItem
            filterName="videoage"
            filterValue="month"
          />
        </div>
      </div>

      <div className={_FILTER_SECTION_CONTAINER_CLASS_NAME}>
        <p className={_FILTER_NAME_CLASS_NAME}>Duration</p>
        <div className={_FILTER_ITEM_CONTAINER_CLASS_NAME}>
          <FilterItem
            filterName="duration"
            filterValue=""
          />
          <FilterItem
            filterName="duration"
            filterValue="short"
          />
          <FilterItem
            filterName="duration"
            filterValue="median"
          />
          <FilterItem
            filterName="duration"
            filterValue="long"
          />
        </div>
      </div>
    </Container>
  );
}

export default Filter;

import {
  DEFAULT_SEARCH_INPUT,
} from './stateConstants';

const search = (state = {
  isFetching: false,
  isLoadingMore: false,
  videos: [
    /**
     * $urlInfo,
     * ...
     */
  ],
  searchInput: DEFAULT_SEARCH_INPUT,
  // Search keyword shortcuts.
  isFetchingKeywordThumbnails: false,
  keywordThumbnails: {
    /**
     * $keyword: [ $thumbnailUrl, ... ],
     */
  },
}, action) => {
  switch (action.type) {
    case 'SEARCH_SET_IS_FETCHING': {
      const { isFetching } = action;

      return {
        ...state,
        isFetching,
      };
    }
    case 'SEARCH_SET_IS_FETCHING_KEYWORD_THUMBNAILS': {
      const { isFetching } = action;

      return {
        ...state,
        isFetchingKeywordThumbnails: isFetching,
      };
    }
    case 'SEARCH_SET_IS_LOADING_MORE': {
      const { isLoadingMore } = action;

      return {
        ...state,
        isLoadingMore,
      };
    }
    case 'SEARCH_SET_VIDEOS': {
      const { videos } = action;

      return {
        ...state,
        isFetching: false,
        isLoadingMore: false,
        videos: [ ...videos ],
      };
    }
    case 'SEARCH_UPDATE_SEARCH_INPUT': {
      const { searchInput } = action;

      const nextSearchInput = {
        ...DEFAULT_SEARCH_INPUT,
        ...state.searchInput,
        ...searchInput,
      };

      return {
        ...state,
        searchInput: nextSearchInput,
      };
    }
    case 'SEARCH_SET_KEYWORD_THUMBNAILS': {
      const { thumbnails } = action;

      return {
        ...state,
        keywordThumbnails: thumbnails || {},
        isFetchingKeywordThumbnails: false,
      };
    }
    default:
      return state
  }
};

export default search;

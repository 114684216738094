import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  IconPencil,
  IconX,
} from 'components/icons';
import TooltipContainer from 'components/TooltipContainer';
// Third party.
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalBody(props) {
  const {
    currentCollectionInfo={},
    updateCollectionInfo,
    closeModal,
  } = props;

  const [name, setName] = useState(currentCollectionInfo.name);

  function handleNameInputChange(event) {
    const nextValue = event.target.value;

    if (!name && nextValue) {
      // Only log for the first character to avoid duplicate loggings.
      analyticsLogEvent('collection_change_name');
    }

    setName(nextValue);
  }

  function handleSave() {
    if (updateCollectionInfo &&
        name && name !== currentCollectionInfo.name) {
      updateCollectionInfo({
        id: currentCollectionInfo.id,
        name,
      });
    }

    if (closeModal) {
      closeModal();
    }
  }

  return (
    <div className="px-3 pb-3">
      <div className="d-flex align-items-center justify-content-between my-1">
        <h5 className="mb-0">Edit your playlist</h5>

        <div className="round-button-container mr-n2" onClick={closeModal}>
          <IconX size={1.5} />
        </div>
      </div>

      <div className="mt-3">
        <h6 className="mb-1 text-black-50">Name</h6>
        <input
          type={'text'}
          placeholder={'Playlist name'}
          value={name}
          onChange={handleNameInputChange}
          className="rounded w-100 form-control"
          autoComplete="off"
        />
      </div>

      <Button variant='primary' block className="mt-4" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
}

function EditCollectionButton(props) {
  const [show, setShow] = useState(false);

  function closeModal() {
    setShow(false);
  }

  function showModal() {
    setShow(true);
  }

  function handleClick() {
    analyticsLogEvent('collection_click_edit_collection');
    showModal();
  }

  return (
    <span>
      <TooltipContainer content="Edit list" hoverEventName="collection_hover_edit_list_button">
        <Button variant="light" className="ml-1 round-button" onClick={handleClick}>
          <IconPencil size={1.5} />
        </Button>
      </TooltipContainer>

      <Modal
        show={show}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <ModalBody
          {...props}
          closeModal={closeModal}
        />
      </Modal>
    </span>
  );
}

export default EditCollectionButton;

export const recommendationClearIsLoadingFeed = () => {
  return  (dispatch, getState) => {
    dispatch(recommendationSetIsFetchingFeedVideos(false));
    dispatch(recommendationSetIsLoadingMoreFeedVideos(false));
  };
};

export const recommendationUpdateIsFetchingUrlRelatedVideos = (url, isFetching) => ({
  type: 'RECOMMENDATION_UPDATE_IS_FETCHING_URL_RELATED_VIDEOS',
  url,
  isFetching,
});

export const recommendationUpdateUrlRelatedVideos = (url, videos) => ({
  type: 'RECOMMENDATION_UPDATE_URL_RELATED_VIDEOS',
  url,
  videos,
});

export const recommendationSetIsFetchingFeedVideos = (isFetching) => ({
  type: 'RECOMMENDATION_SET_IS_FETCHING_FEED_VIDEOS',
  isFetching,
});

export const recommendationSetIsLoadingMoreFeedVideos = (isFetching) => ({
  type: 'RECOMMENDATION_SET_IS_LOADING_MORE_FEED_VIDEOS',
  isFetching,
});

export const recommendationSetFeedVideos = (videos) => ({
  type: 'RECOMMENDATION_SET_FEED_VIDEOS',
  videos,
});

export const recommendationSetSelectedCategory = (selectedCategory) => ({
  type: 'RECOMMENDATION_SET_SELECTED_CATEGORY',
  selectedCategory,
});

export const recommendationSetRecommendKeywords = (recommendKeywords) => ({
  type: 'RECOMMENDATION_SET_RECOMMEND_KEYWORDS',
  recommendKeywords,
});

import {
  delay,
  isTouchDevice,
} from 'misc/appHelperFunctions';
// Third party.
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Toast from 'react-bootstrap/Toast';

function ToastAutoHide(props, ref) {
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');

  useImperativeHandle(ref, () => ({
    show: (message) => {
      setMessage(message);
      setShowToast(true);
    }
  }));

  const bgClassName = props.isWarning ? "bg-info" : "bg-primary";
  const positionClassName = props.positionClassName ||
      ((isTouchDevice() ? "bottom-80px" : "bottom-30px") + " left-30px");
  const baseClassName = bgClassName + " mr-4 text-white px-1 " + positionClassName + " ";
  // Default is 'position-fixed'.
  const className = props.isPositionAbsolute
      ? (baseClassName + "position-absolute")
      : (baseClassName + "position-fixed")

  function handleClose() {
    setShowToast(false);

    // Clear the message.
    //
    // If the message is not cleared, it may be positioned above some
    // clickable components and prevents the on click events being triggered.
    // This is because hiding the toast is simply setting the opacity to 0
    // instead of setting display to none.
    //
    // Need a little bit of delay here because the toast has a fading effetct
    // when closing so it helps to avoid shrinking the toast card before it is
    // fully closed.
    delay(0.5).then(() => {
      setMessage('');

      if (props.onClose) {
        props.onClose();
      }
    })
  }

  return (
      <Toast
        onClose={handleClose}
        show={showToast}
        delay={1000}
        autohide
        className={className}
      >
        <Toast.Body>
          { message }
        </Toast.Body>
      </Toast>
  );
}

export default forwardRef(ToastAutoHide);

import { APP_NAME } from 'misc/appConstants';
import { IconLogo } from 'components/icons';
// Third party.
import React from 'react';

function Header(props) {
  return (
    <div className="my-3">
      <div className="d-flex align-items-center justify-content-center">
        <h1>
          <IconLogo size={0.9} />
        </h1>
        <h1 className="mb-0 ml-2">{APP_NAME}</h1>
      </div>
      <h5 className="text-center text-black-50">
        The best porn videos loved by Asian users
      </h5>
    </div>
  );
}

export default Header;

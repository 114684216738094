import VideosGrid from 'components/videosGrid';
// Third party.
import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';

function Playlist(props) {
  const {
    // Redux states.
    playlist,
  } = props;

  const {
    isFetching,
    isLoadingMore,
    currentCollectionId,
    collectionVideos,
  } = playlist;

  const videos = collectionVideos[currentCollectionId];

  let collectionName = "";
  let collectionDescription = "";
  switch (currentCollectionId) {
    case 'QYLqrATynyGy7FHly7De':
      collectionName = "Banana Fever";
      collectionDescription = "汝工作室 – Women Who Love Asian Men. The best studio for AMXF (Asian Male, Non-Asian Female) content.";
      break;
    case 'KKYkMtQEpc6TLa5r6xVn':
      collectionName = "麻豆";
      collectionDescription ="麻豆传媒映画由两岸三地专业摄影团队打造，以国产原创剧情为主线，搭配原声中文对白，发展出专属于国人自制的成人影视。";
      break;
    default:
      console.log('Playlist information does not exists.');
  }

  const titleFetching = isFetching
      ? "We are adding " + collectionName + " videos."
      : null;
  const footerTitle = isFetching ? null : "No More Videos";

  function VideosGridHeader() {
    return (
      <Container className="px-0 pb-3 mw-md">
        <h4 className="text-center text-dark">
          { collectionName }
        </h4>
        <h5 className="mb-0 text-black-50">
          { collectionDescription }
        </h5>
      </Container>
    );
  }

  return (
    <div className="page-container">
      <VideosGrid
        eventPrefix="feed"
        isFetchingVideos={isFetching}
        isLoadingMoreVideos={isLoadingMore}
        videos={videos}
        titleFetching={titleFetching}
        titleComponent={VideosGridHeader}
        footerTitle={footerTitle}
        className="mobile-mt-2 desktop-mt-3"
        //onEndReached={onEndReached}
        shouldUpdateClickCnt
        //enableAdminButton={isAdmin}
      />
    </div>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  playlist: state.playlist,
});

const mapDispatchToProps = (dispatch) => ({
  // actionApiFetchCollectionVidoes: (collectionId) => {
  //   dispatch(apiFetchCollectionVidoes(collectionId));
  // },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Playlist);

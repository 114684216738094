
import TooltipContainer from 'components/TooltipContainer';
import NavDropdownButton from 'components/buttons/NavDropdownButton';
import CreateCollectionButton from 'components/buttons/createCollectionButton';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

const _PRIVATE_PLAYLISTS_TOOLTIP_CONTENT =
    (<p className="text-left m-0">All the lists are private. Only you can view them.</p>);

function ToolbarSaved(props) {
  const {
    // Redux actions.
    actionApiCreatePrivateCollection,
  } = props;

  return (
    <Container
      className="mw-sm min-h-round-button-size"
    >
      <div className="w-100 d-flex justify-content-between align-items-center px-content-container">
        <TooltipContainer
          placement="bottom"
          delay={{ show: 100, hide: 100 }}
          content={_PRIVATE_PLAYLISTS_TOOLTIP_CONTENT}
          hoverEventName="my_private_playlists_hover_title_button"
        >
          <h4 className="text-dark my-0 mr-1">My Playlists</h4>
        </TooltipContainer>

        <div className="d-flex align-items-center mr-n2">
          <CreateCollectionButton
            createPrivateCollection={actionApiCreatePrivateCollection}
            isRoundButton
          />
          <NavDropdownButton className="ml-1" />
        </div>
      </div>
    </Container>
  );
}

export default ToolbarSaved;

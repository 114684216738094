import CreateCollectionForm from 'components/buttons/createCollectionButton/CreateCollectionForm';
// Third party.
import React from 'react';

function CardCreatePlaylist(props) {
  const { onSubmit } = props;

  return (
    <div className="mw-sm mx-auto">
      <h2 className="text-dark py-3">
        Create your first playlist
      </h2>

      <CreateCollectionForm onSubmit={onSubmit} />
    </div>
  );
}

export default CardCreatePlaylist;

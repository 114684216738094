import {
  P_HOME,
  P_FEED,
  P_LOGIN,
  P_PLAYLIST,
  P_PORNSTARS,
  P_SIGNUP,
  P_SITES,
} from 'misc/appConstants';
import LogInButton from 'components/buttons/LogInButton';
import SignUpButton from 'components/buttons/SignUpButton';
import {
  BaseTopNavbar,
  NavbarHome,
  NavbarPlaylist,
  NavbarPornstars,
  NavbarSites,
} from '../navbar-components';
// Third party.
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

function NavbarWithLoginAndSignUpButtons(props) {
  const location = useLocation();

  const {
    isCurrentUserFresh,
  } = props;

  const eventNamePrefix = (location || {}).pathname || "";

  const eventNameClickLogIn = eventNamePrefix + "_click_log_in_button_0";
  const eventNameClickSignUp = eventNamePrefix + "_click_sign_up_button_0";

  return (
    <BaseTopNavbar className="d-flex justify-content-end">
      {
        isCurrentUserFresh &&
        <Nav>
          <LogInButton
            className="mr-3"
            clickEventName={eventNameClickLogIn}
          />
          <SignUpButton
            clickEventName={eventNameClickSignUp}
          />
        </Nav>
      }
    </BaseTopNavbar>
  );
}

// Navbar to display when no user signed in.
function NavbarWithoutAuth(props) {
  return (
    <Switch>
      <Route exact path={P_HOME} render={() => {
        return (<NavbarHome {...props} eventNamePrefix={"home-guest_user"} />);
      }} />
      <Route path={P_PLAYLIST} render={() => {
        return (<NavbarPlaylist {...props} />);
      }} />
      <Route path={P_FEED} render={() => {
        return (
          <NavbarWithLoginAndSignUpButtons {...props} />
        );
      }} />
      <Route path={P_PORNSTARS} render={() => {
        return (
          <NavbarPornstars {...props} />
        );
      }} />
      <Route path={P_SITES} render={() => {
        return (
          <NavbarSites {...props} />
        );
      }} />
      <Route exact path={P_LOGIN} render={() => {
        return (
          <NavbarWithLoginAndSignUpButtons {...props} />
        );
      }} />
      <Route exact path={P_SIGNUP} render={() => {
        return (
          <NavbarWithLoginAndSignUpButtons {...props} />
        );
      }} />
      <Route path={P_HOME} render={() => {
        return (
          <NavbarWithLoginAndSignUpButtons {...props} />
        );
      }} />
    </Switch>
  );
}

export default NavbarWithoutAuth;

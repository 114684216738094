import PageFooter from 'components/PageFooter';
import BestSites from './BestSites';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function Sites(props) {
  return (
    <div className="page-container">
      <Container className="px-0 pt-4 mw-md">
        <h4 className="text-center pb-3">Best Porn Sites</h4>
        <BestSites />
        <PageFooter />
      </Container>
    </div>
  );
}

export default Sites;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_VMARK,
} from 'misc/appConstants';
import DomainInfo from 'components/DomainInfo';
import Tags from 'components/Tags';
import ThumbnailImage from 'components/thumbnailImage';
import ItemDropDown from './ItemDropDown';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';

function Item(props) {
  const {
    itemData,
    itemId,
    activePreviewVideoItemUrl,
    setActivePreviewVideoItemUrl,
    isAdmin,
  } = props;
  if (!itemData || !itemData.urlInfo || !itemData.urlInfo.url) {
    return null;
  }

  const { tags } = itemData;
  const { url, domain, title, customizedTitle } = itemData.urlInfo;

  function setCollectionCurrentItemId() {
    if (props.updateCollectionCurrentItemId) {
      props.updateCollectionCurrentItemId(itemId);
    }
    analyticsLogEvent('collection_click_view_item_details');
  }

  function handleClickTag(tag) {
    if (props.setCollectionFilterToTags) {
      // Reset filter, set filter tags, and hide navbar input box.
      props.setCollectionFilterToTags([tag]);
    }
  }

  function showModalTagsEditor() {
    if (props.showModalTagsEditor) {
      props.showModalTagsEditor(true, itemId);
    }
    analyticsLogEvent('collection_click_edit_item_tags');
  }

  function showModalDetailsEditor() {
    if (props.showModalDetailsEditor) {
      props.showModalDetailsEditor(true, itemId);
    }
    analyticsLogEvent('collection_click_edit_item_details');
  }

  function showModalPriceEditor() {
    if (props.showModalPriceEditor) {
      props.showModalPriceEditor(true, itemId);
    }
    analyticsLogEvent('collection_click_edit_item_details');
  }

  function showModalDeleteItem() {
    if (props.showModalDeleteItem) {
      props.showModalDeleteItem(true, itemId);
    }
    analyticsLogEvent('collection_click_delete_item');
  }

  function actionModalShowSaveItem() {
    if (props.actionModalShowSaveItem) {
      props.actionModalShowSaveItem(true, itemData.urlInfo);
    }
    analyticsLogEvent('collection_click_copy_item');
  }

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div className="pt-2">
        <Link
          to={P_VMARK}
          className="no-hover-effect"
          onClick={setCollectionCurrentItemId}
        >
          <ThumbnailImage
            className="my-3"
            urlInfo={itemData.urlInfo}
            activePreviewVideoItemUrl={activePreviewVideoItemUrl}
            setActivePreviewVideoItemUrl={setActivePreviewVideoItemUrl}
          />
        </Link>

        <small className="desktop-line-clamp-2 mobile-line-clamp-1">
          { customizedTitle || title || url }
        </small>

        <div className="d-flex justify-content-between align-items-center mt-n2">
          <DomainInfo
            url={url}
            domain={domain}
            clickEventName="collection_click_item_domain"
            isSmall
          />

          <ItemDropDown
            showModalDeleteItem={showModalDeleteItem}
            showModalTagsEditor={showModalTagsEditor}
            actionModalShowSaveItem={actionModalShowSaveItem}
            showModalDetailsEditor={showModalDetailsEditor}
            showModalPriceEditor={showModalPriceEditor}
            isAdmin={isAdmin}
          />
        </div>

        <Tags
          tags={tags}
          handleClickTag={handleClickTag}
          clickEventName="collection_click_item_tag"
        />
      </div>
    </div>
  );
}

export default Item;

import {
  isAndroidDevice,
  isIOSDevice,
} from 'misc/appHelperFunctions';
// Third party.
import React from "react";

const title = 'Faster, more fluid app experience';
const addition = 'We also recommend opening the App from your homescreen and Logging in or Signing up for an account for additional features such as Save videos and personal recommendations!';

const iOSBestPractise = {
  phoneType: 'iOS',
  steps: [
    '1. Load videoMarx.com in the Safari Web Browser.(* Installation will not work from Chrome for iOS and this is only for the first step.)',
    '2. Tap the "Share" icon in the middle of the bottom bar.',
    '3. Select "Add to Home screen" from the available options.',
    '4. Safari will close and the app icon will be added to your home screen. Launch the app once to ensure the install completes successfully.',
    '5. (Optional): Go back to Safari and ensure you close the videoMarx.com tab for added Privacy.'
  ]
};

const AndroidBestPractise = {
  phoneType: 'Android',
  steps: [
    '1. Load videomarx.com in the Chrome Web Browser.',
    '2. Tap the 3 dots from the top right corner.',
    '3. Select "Add to Home screen" from the available options.',
    '4. Chrome will open a prompt and you will be able to add Videomarx app icon to your home screen. Launch the app once to ensure the install completes successfully.',
    '5. (Optional): Go back to Chrome and ensure you close the VideoMarx.com tab for added Privacy.'
  ]
};

function BestPractise() {
  let item;
  if (isIOSDevice()) {
    item = iOSBestPractise;
  } else if (isAndroidDevice()) {
    item = AndroidBestPractise;
  }

  if (!item) {
    return null;
  }

  const steps = item.steps.map((step) => {
    return (
      <h5 className="mb-3 text-black-50">
        { step }
      </h5>
    )
  });

  return (
    <div className="mt-5">
      <div className="p-3 border border-primary rounded-2">
        <h3> { title } on { item.phoneType } </h3>
        <h3 className="mb-4"> 最佳看片体验 </h3>

        { steps }

        <h5 className="mb-0 text-black-50">
          { addition }
        </h5>
      </div>
    </div>
  )
}

export default BestPractise;
const videoPeeks = (state = {
  isFetchingLatestVideos: false,
  latestVideos: [
    /**
     * $urlInfo,
     * ...
     */
  ],
  isFetchingWeeklyHotVideos: false,
  weeklyHotVideos: [
    /**
     * $urlInfo,
     * ...
     */
  ],
}, action) => {
  switch (action.type) {
    case 'VIDEO_PEEKS_IS_FETCHING_LATEST_VIDEOS': {
      const { isFetchingLatestVideos } = action;

      return {
        ...state,
        isFetchingLatestVideos,
      };
    }
    case 'VIDEO_PEEKS_SET_LATEST_VIDEOS': {
      const { latestVideos } = action;

      return {
        ...state,
        isFetchingLatestVideos: false,
        latestVideos: [ ...latestVideos ],
      };
    }
    case 'VIDEO_PEEKS_IS_FETCHING_WEEKLY_HOT_VIDEOS': {
      const { isFetchingWeeklyHotVideos } = action;

      return {
        ...state,
        isFetchingWeeklyHotVideos,
      };
    }
    case 'VIDEO_PEEKS_SET_WEEKLY_HOT_VIDEOS': {
      const { weeklyHotVideos } = action;

      return {
        ...state,
        isFetchingWeeklyHotVideos: false,
        weeklyHotVideos: [ ...weeklyHotVideos ],
      };
    }
    default:
      return state
  }
};

export default videoPeeks;

import { IconTagPlus } from 'components/icons';
import Header from './Header';
import TagItem from './TagItem';
// Third party.
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function getTagImageUrls(collection) {
  const result = {};

  if (!collection
      || !collection.currentCollectionInfo
      || !collection.currentCollectionInfo.id
  ) {
    return result;
  }

  const collectionId = collection.currentCollectionInfo.id;
  const collectionItemIds = collection.itemIds[collectionId] || [];

  for (let i = 0; i < collectionItemIds.length; i++) {
    const itemId = collectionItemIds[i];
    const itemData = collection.items[itemId];
    const { thumbnail={} } = itemData.urlInfo || {};
    if (!thumbnail.url) {
      continue;
    }

    const itemTags = itemData.tags || [];
    for (let j = 0; j < itemTags.length; j++) {
      const tag = itemTags[j];

      if (!result[tag]) {
        result[tag] = [];
      }

      result[tag].push(thumbnail.url);
    }
  }

  return result;
}

function EmptyStateTips(props) {
  return (
    <div className="py-4 text-center">
      <h5 className="text-black-50">
        You haven't tagged anything yet.
      </h5>
      <h5 className="text-black-50">
        Click <IconTagPlus size={1} className="mx-2 mt-n1"/> in a video mark to add tags.
      </h5>
    </div>
  );
}

function CollectionTags(props) {
  const [tagImageUrls, setTagImageUrls] = useState({});

  useEffect(() => {
    // Only need to extract tagImageUrls once. This helps to avoid
    // unnecessary calculations during tagsFilter changes.
    setTagImageUrls(getTagImageUrls(props.collection));
  }, [setTagImageUrls, props.collection]);

  const currentCollectionInfo = props.collection.currentCollectionInfo;
  if (!currentCollectionInfo || !currentCollectionInfo.id) {
    return null;
  }

  const { setCollectionFilterToTags } = props;
  const tagsFilter = props.tags.filter || '';
  const collectionTags = props.collection.tags[currentCollectionInfo.id] || {};
  const filteredTags =
      Object.keys(collectionTags).filter(tag => tag.includes(tagsFilter));

  const tagsItems = filteredTags.sort().map((tag) => {
    return (
      <Col
        key={tag}
        xs={6} sm={6} md={4} lg={3} xl={3}
        className="col-xxl-2d4 col-xxxl-2 col-xxxxl-1d5 mobile-px-gutter"
      >
        <TagItem
          tag={tag}
          imageUrls={tagImageUrls[tag]}
          marksCount={collectionTags[tag]}
          setCollectionFilterToTags={setCollectionFilterToTags}
        />
      </Col>
    );
  });

  return (
    <div>
      <Header collectionTags={collectionTags} />

      <Container fluid className="px-0">
        {
          tagsItems.length
          ? <Row>
              {tagsItems}
            </Row>
          : <EmptyStateTips />
        }
      </Container>
    </div>
  );
}

export default CollectionTags;

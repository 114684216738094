import {
  DEFAULT_FEED_CATEGORY,
} from 'misc/appConstants';

const recommendation = (state = {
  isFetchingUrlRelatedVideos: {
    /**
     * $url: boolean,
     */
  },
  urlRelatedVideos: {
    /**
     * $url: [
     *   {
     *     url: item.videoLink,
     *     title: item.title,
     *     thumbnail: {
     *       url: item.image,
     *     },
     *     video : {
     *       durationInSec: item.duration,
     *     },
     *   },
     *   ...
     * ]
     */
  },
  isFetchingFeedVideos: false,
  isLoadingMoreFeedVideos: false,
  feedVideos: [
    /**
     * {
     *   url: item.videoLink,
     *   title: item.title,
     *   thumbnail: {
     *     url: item.image,
     *   },
     *   video : {
     *     durationInSec: item.duration,
     *   },
     * },
     * ...
     */
  ],
  selectedCategory: DEFAULT_FEED_CATEGORY,
  recommendKeywords: [],
}, action) => {
  switch (action.type) {
    case 'RECOMMENDATION_UPDATE_IS_FETCHING_URL_RELATED_VIDEOS': {
      const { url, isFetching } = action;

      const nextIsFetchingUrlRelatedVideos = {
        ...state.isFetchingUrlRelatedVideos,
        [url]: isFetching,
      };

      return {
        ...state,
        isFetchingUrlRelatedVideos: nextIsFetchingUrlRelatedVideos,
      };
    }
    case 'RECOMMENDATION_UPDATE_URL_RELATED_VIDEOS': {
      const { url, videos } = action;

      const nextIsFetchingUrlRelatedVideos = {
        ...state.isFetchingUrlRelatedVideos,
        [url]: false,
      };

      const nextUrlRelatedVideos = {
        ...state.urlRelatedVideos,
        [url]: videos,
      };

      return {
        ...state,
        isFetchingUrlRelatedVideos: nextIsFetchingUrlRelatedVideos,
        urlRelatedVideos: nextUrlRelatedVideos,
      };
    }
    case 'RECOMMENDATION_SET_IS_FETCHING_FEED_VIDEOS': {
      const { isFetching } = action;

      return {
        ...state,
        isFetchingFeedVideos: isFetching,
      };
    }
    case 'RECOMMENDATION_SET_IS_LOADING_MORE_FEED_VIDEOS': {
      const { isFetching } = action;

      return {
        ...state,
        isLoadingMoreFeedVideos: isFetching,
      };
    }
    case 'RECOMMENDATION_SET_FEED_VIDEOS': {
      const { videos } = action;

      const nextFeedVideos = [
        ...videos,
      ];

      return {
        ...state,
        isFetchingFeedVideos: false,
        isLoadingMoreFeedVideos: false,
        feedVideos: nextFeedVideos,
      };
    }
    case 'RECOMMENDATION_SET_SELECTED_CATEGORY': {
      const { selectedCategory } = action;

      return {
        ...state,
        selectedCategory: selectedCategory || DEFAULT_FEED_CATEGORY,
      };
    }
    case 'RECOMMENDATION_SET_RECOMMEND_KEYWORDS': {
      const { recommendKeywords } = action;

      return {
        ...state,
        recommendKeywords: recommendKeywords,
      };
    }
    default:
      return state
  }
};

export default recommendation;

import { auth, db } from 'appFirebase';
import {
  ADMIN_ACCOUNTS,
} from 'misc/appConstants';
import {
  generateUrlId,
} from '../../../appFirebase/helperApi';
import {
  recommendationSetFeedVideos,
} from '../recommendation';
import {
  searchSetVideos,
} from '../search';

/*
 * Delete url collection item.
 */
export const adminApiDeleteFeedVideo = (urlInfo) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser || !ADMIN_ACCOUNTS[auth.currentUser.email] ||
        !urlInfo || !urlInfo.url) {
      return;
    }

    const urlId = generateUrlId(urlInfo.url);
    if (!urlId) {
      return;
    }

    const isSuccessful = await db
        .collection('urlCollection').doc(urlId)
        .delete()
        .then(() => true)
        .catch((error) => { console.error("Error deleting urlCollection item: ", error); });

    if (isSuccessful) {
      // Update search results in redux states.
      const nextSearchVideos =
          getState().search.videos.filter((videoUrlInfo) => {
            return (videoUrlInfo.url !== urlInfo.url);
          });
      dispatch(searchSetVideos(nextSearchVideos));
      // Update feed results in Redux states.
      const nextRecommendVideos =
          getState().recommendation.feedVideos.filter((videoUrlInfo) => {
            return (videoUrlInfo.url !== urlInfo.url);
          });
      dispatch(recommendationSetFeedVideos(nextRecommendVideos));
    }
  };
};

export const adminApiUpdateVideoUrlInfo = (urlInfo) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser || !ADMIN_ACCOUNTS[auth.currentUser.email] ||
        !urlInfo || !urlInfo.url) {
      return;
    }

    const urlId = generateUrlId(urlInfo.url);
    if (!urlId) {
      return;
    }

    const isSuccessful = await db
        .collection('urlCollection').doc(urlId)
        .update({
          baseClickCnt: urlInfo.baseClickCnt,
        })
        .then(() => true)
        .catch((error) => { console.error("Error updating urlCollection item: ", error); });

    if (isSuccessful) {
      // Update search results in redux states.
      const nextSearchVideos =
          getState().search.videos.map((videoUrlInfo) => {
            return (videoUrlInfo.url === urlInfo.url) ? urlInfo : videoUrlInfo;
          });
      dispatch(searchSetVideos(nextSearchVideos));
      // Update feed results in Redux states.
      const nextRecommendVideos =
          getState().recommendation.feedVideos.map((videoUrlInfo) => {
            return (videoUrlInfo.url === urlInfo.url) ? urlInfo : videoUrlInfo;
          });
      dispatch(recommendationSetFeedVideos(nextRecommendVideos));
    }
  };
};

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  isTouchDevice,
} from 'misc/appHelperFunctions';
import LoadingIndicator from 'components/LoadingIndicator';
// Third party.
import React, { useEffect, useRef, useState } from 'react';

function PageFooter(props) {
  const [disableOnEndReached, setDisableOnEndReached] = useState(false);
  const ref = useRef();

  const {
    scrollTrackingEnabled,
    showLoadingIndicator,
    eventPrefix,
    onEndReached,
    title,
  } = props;

  useEffect(() => {
    // Log sroll to bottom event.
    function handleScroll() {
      // Only log scroll to bottom event if valid related vidieos exist.
      if (!scrollTrackingEnabled) {
        return;
      }
      let isInViewport = false;
      const offset = 0;
      const bound = ref && ref.current ? ref.current.getBoundingClientRect() : null;
      if (bound) {
        const top = bound.top;
        isInViewport = (top + offset) >= 0 && (top - offset) <= window.innerHeight;
      }
      if (isInViewport) {
        if (onEndReached) {
          // Avoid calling onEndReached multiple times when it is still roughly
          // at the bottom.
          if (!disableOnEndReached) {
            setDisableOnEndReached(true);
            onEndReached();
            analyticsLogEvent(eventPrefix + '-scroll_to_bottom');
          }
        } else {
          // Only log scroll to bottom once if no valid onEndReached effect.
          analyticsLogEvent(eventPrefix + '-scroll_to_bottom');
          window.removeEventListener('scroll', handleScroll);
        }
      } else {
        // Clear flag to enable onEndReached again.
        setDisableOnEndReached(false);
      }
    }

    // Listen to scroll events.
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollTrackingEnabled, eventPrefix, onEndReached, disableOnEndReached]);

  // Need more footer height for the bottom navbar on touch devices.
  const containerClassName = isTouchDevice() ? "h-150px" : "h-100px";

  return (
    <div ref={ref} className={containerClassName}>
      {
        showLoadingIndicator &&
        <LoadingIndicator className="mt-3" delayInSeconds={0.5} />
      }
      {
        title &&
        <h5 className="mt-3 text-secondary text-center">
          { title }
        </h5>
      }
    </div>
  );
}

export default PageFooter;
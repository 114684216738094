import {
  apiFetchRelatedVideos,
  combinedSetCollectionFilterToTags,
  modalClose,
  toastSetShowMessage,
} from 'appRedux/actions';
import {
  ESCAPE_KEY_CODE,
  P_COLLECTION,
  P_SAVED,
  P_VMARK,
} from 'misc/appConstants';
import {
  navBack,
} from 'misc/appHelperFunctions';
import Delay from 'components/Delay';
import ToastAutoHide from 'components/toastAutoHide';
import Fallback from 'routes/fallback';
import VmarkContent from './vmarkContent';
// Third party.
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";

function Vmark(props) {
  const history = useHistory();
  const match = useRouteMatch();

  const { modal, closeModal } = props;

  useEffect(() => {
    // Always scroll to top as initial position.
    window.scrollTo(0, 0);

    function HandleKeyDown(event) {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        if (modal.tagsEditor.show || modal.deleteItem.show) {
          closeModal();
          return;
        }

        navBack(history);
      }
    }

    // Listen to key events.
    window.addEventListener('keydown', HandleKeyDown);

    return () => {
      window.removeEventListener('keydown', HandleKeyDown);
    };
  }, [history, modal, closeModal]);

  const {
    currentCollectionInfo,
    currentItemId,
  } = props.collection;

  // Only redirect if matched url is just '/vmark' instead of something
  // like '/vmark/itemId'.
  if (!currentCollectionInfo && (match.url === P_VMARK)) {
    return (
      <Redirect to={P_SAVED} />
    );
  }

  // This may only happen briefly when navigating from collection page, due
  // to a race condition between navigation and setting currentItemId.
  // So a small delay is added to the fallback page to avoid flashing the
  // fallback page during the navigation.
  //
  // Give above, it's actually ok to simply return null here. But still
  // returns a fallback page just in case there are other situations where
  // currentItemId=null in the future.
  if (!currentItemId) {
    return (
      <Delay delayInSeconds={1}>
        <Fallback
          title={"This video mark isn't available."}
          hideSubtitle={true}
        />
      </Delay>
    );
  }

  // Reset filter, set filter tags, hide navbar input box,
  // and navigate to collection page.
  function handleClickTag(tag) {
    const {
      setCollectionFilterToTags,
    } = props;

    // Reset filter, set filter tags, hide navbar input box.
    if (tag) {
      setCollectionFilterToTags([tag]);
    }

    // Navigate to collection page.
    history.push(P_COLLECTION);
  }

  return (
    <div>
      <VmarkContent
        {...props}
        handleClickTag={handleClickTag}
      />

      <ToastAutoHide
        id={P_VMARK}
      />
    </div>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  collection: state.collection,
  modal: state.modal,
  recommendation: state.recommendation,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(modalClose());
  },
  fetchRelatedVideos: (urlInfo) => {
    dispatch(apiFetchRelatedVideos(urlInfo));
  },
  setCollectionFilterToTags: (tags) => {
    dispatch(combinedSetCollectionFilterToTags(tags));
  },
  toastShowMessage: (message) => {
    dispatch(toastSetShowMessage(P_VMARK, message));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Vmark);

export const DEFAULT_COLLECTION_FILTER = {
  search: '',           // String to search.
  tags: [],             // [$tag] Applied tags in the filter.
};

// Search filters.
export const DEFAULT_SEARCH_INPUT = {
  availableKeywords: {},
  externalKeywordsList: [],
  keyword: '',
  filters: {
    orderBy: 'view',  // 'date' or 'view'
    videoage: '',     // 'week', 'month', 'year', ''
    duration: '',     // 'short', 'median', 'long', ''
    targetUser: 'asian',
    // This field will be filled on the fly if search keyword contains 'full movie' substring.
    //videoType: '',    // 'video', 'film'
  },
  forceSearchOn: false, // Show search results with an empty keyword.
  // For api searchVideos.
  startAfter: 0,
  // For api searchVideosByRandomWords.
  externalSearchPage: 1,
};

export const SEARCH_FILTER_DISPLAY_NAME = {
  orderBy: {
    view: 'Most Viewed',
    date: 'Newest',
  },
  videoage: {
    '': 'Anytime',
    week: 'This Week',
    month: 'This Month',
    year: 'This Year',
  },
  duration: {
    '': 'All',
    short: 'Short (1-10min)',
    median: 'Medium (10-20min)',
    long: 'Long (>20min)',
  },
};

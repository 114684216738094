export const adminCloseModals = () => {
  return  (dispatch, getState) => {
    dispatch(adminShowModalDeleteVideo(false));
    dispatch(adminShowModalEditBaseClickCnt(false));
  };
};

export const adminSetIsAdmin = (isAdmin) => ({
  type: 'ADMIN_SET_IS_ADMIN',
  isAdmin,
});

export const adminShowModalDeleteVideo = (show, urlInfo) => ({
  type: 'ADMIN_SHOW_MODAL_DELETE_VIDEO',
  show,
  urlInfo,
});

export const adminShowModalEditBaseClickCnt = (show, urlInfo) => ({
  type: 'ADMIN_SHOW_MODAL_EDIT_BASE_CLICK_CNT',
  show,
  urlInfo,
});

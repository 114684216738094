import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  searchInputToQuery,
} from 'misc/queryHelpers';
import {randomlyPickFromList} from "misc/appHelperFunctions";
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';

const _ITEMS = [
  "AFWF",
  "高端",
  "学妹",
  "Teen",
  "18",
  "Japanese wife",
  "Chinese Amateur",
  "Japanese uncensored",
  "对白",
  "奶水",
  "Asian",
  "露脸",
  "双飞",
  "Japanese teacher",
  "奶",
  "探花",
  "Chinese cam girl",
  "91大神",
  "性感",
  "Step sister",
  "Bang Real Teen",
  "Amateur",
  "会所",
  "AFXF",
  "换脸",
  "Softcore",
  "嫩模",
  "Fake Driving School",
  "Shoplift",
  "Big Tits",
  "骚",
  "Thai teen",
  "生活",
  "Uzbek",
  "新疆",
  "Japanese",
  "Chinese",
  "小果酱",
  "清流出品",
  "Kansai Enko",
  "Japanese MILF",
  "Japanese anal",
  "Lesbian",
  "Fake Agent",
];
const _CHANGE_FREQUENCY = 3;
const _SKIP_INTERVAL = 2;
const _RESULT_COUNT = 20;

function Item(props) {
  const {
    keyword,
    rank,
  } = props;

  const searchQuery = searchInputToQuery({
    keyword,
  });

  const suffix = (rank < 4) ? '🔥' : '';

  function handleOnClick() {
    analyticsLogEvent('home-click-top_searches');
  }

  return (
    <Link
      to={searchQuery}
      className="no-hover-effect tag-light my-1 mr-2"
      onClick={handleOnClick}
    >
      <p className="m-0 flex-shrink-0 text-dark">
        <span className="text-primary mr-1">{rank}</span> { keyword } {suffix}
      </p>
    </Link>
  );
}

function TopSearches(props) {
  const {
    showAll,
    // Redux states.
    search,
  } = props;

  const title = showAll ? "More Popular Searches" : "Top Searches This Week";

  // Change the keywords every week
  let keywords = randomlyPickFromList(
    _ITEMS,
    _CHANGE_FREQUENCY,
    _SKIP_INTERVAL,
    _RESULT_COUNT
  );

  let i = 0;
  if (showAll) {
    const englishRegex = /^[A-Za-z0-9\s]*$/;
    const nonEnglishKeywords = [];
    const englishKeywords = [];
    const allKeywords = Object.keys(search.searchInput.availableKeywords)
        .concat(search.searchInput.externalKeywordsList);
    for (let i = 0; i < allKeywords.length; i++) {
      const keyword = allKeywords[i];
      if (englishRegex.test(keyword)) {
        englishKeywords.push(keyword);
      } else {
        nonEnglishKeywords.push(keyword);
      }
    }
    nonEnglishKeywords.sort();
    englishKeywords.sort();
    keywords = nonEnglishKeywords.concat(englishKeywords);
  }

  const items = keywords.map((keyword) => {
    i++;
    return (
      <Item
        key={i}
        {...props}
        keyword={keyword}
        rank={i}
      />
    );
  });

  return (
    <div className="mt-4">
      <h5>{ title }</h5>
      {/*
      <div className="d-flex flex-wrap p-3 border border-primary rounded-2">
      */}
      <div className="p-3 border border-primary rounded-2">

        { items }
      </div>
    </div>

  );
}

export default TopSearches;

// Third party.
import React from 'react';
import Button from 'react-bootstrap/Button';

const _CONFIRM_DELETE =
    'Are you sure you want to delete this video? This cannot be undone.';
const _BUTTON_TITLE_CANCEL = 'Cancel';
const _BUTTON_TITLE_DELETE = 'Delete';

function DeleteVideo(props) {
  const {
    // Redux states.
    admin,
    // Redux actions.
    actionAdminCloseModals,
    actionAdminApiDeleteFeedVideo,
  } = props;

  function closeModal() {
    actionAdminCloseModals();
  }

  function handleDelete() {
    if (actionAdminApiDeleteFeedVideo) {
      actionAdminApiDeleteFeedVideo(admin.modalDeleteVideo.urlInfo);
    }
    closeModal();
  }

  return (
    <div className="p-3">
      <h5>Delete video</h5>

      <p className="text-black-50">
        { _CONFIRM_DELETE }
      </p>

      <div className="d-flex justify-content-end">
        <Button variant='light' onClick={closeModal}>
          { _BUTTON_TITLE_CANCEL }
        </Button>

        <Button variant='info' className="ml-2" onClick={handleDelete}>
          { _BUTTON_TITLE_DELETE }
        </Button>
      </div>
    </div>
  );
}

export default DeleteVideo;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  canShowVideoPlayer,
  isPremiumVideo,
  openUrlInNewTab,
} from 'misc/appHelperFunctions';
import VideoItem from './VideoItem';
import useSavedItems from './useSavedItems';
// Third party.
import React, { useState } from 'react';

function VideoItems(props) {
  const [activePreviewVideoItemUrl, setActivePreviewVideoItemUrl] = useState(null);

  const {
    eventPrefix,
    shouldUpdateClickCnt,
    // Derived Redux states.
    enableAdminButton,
    videos=[],
    // Redux states.
    collection,
    user,
    recommendation,
    feedPreference,
    // Redux actions.
    actionApiAddCollectionItem,
    actionApiUpdateUserPreferredCategories,
    actionModalShowSaveItem,
    actionModalShowVideoPlayer,
    actionModalLoginRequest,
    toastShowMessage,
    actionSetRecommendKeywords,
  } = props;

  // Compose an object containg all the urls and titles in the current collection.
  const savedItems = useSavedItems(collection);

  function handleSaveVideo(urlInfo) {
    if (actionApiAddCollectionItem && urlInfo && urlInfo.url) {
      const safeCurrentCollectionInfo = collection.currentCollectionInfo || {};
      const currentCollectionId = safeCurrentCollectionInfo.id;
      const collectionName = safeCurrentCollectionInfo.name;
      const toastMessage = 'Saved' + (collectionName ? (' to ' + collectionName) : '!');

      if (currentCollectionId) {
        const collectionInfo = {
          id: currentCollectionId,
        };
        actionApiAddCollectionItem(urlInfo, collectionInfo);
        toastShowMessage(toastMessage);
      } else if (actionModalShowSaveItem) {
        actionModalShowSaveItem(true, urlInfo);
      }
    }
  }

  function handleClickThumbnail(urlInfo) {
    analyticsLogEvent(eventPrefix + '-click_video_thumbnail');

    const isLoginRequired = !user.currentUser && isPremiumVideo(urlInfo);

    if (isLoginRequired) {
      actionModalLoginRequest(true, urlInfo);
    } else {
      const canShowVideoPlayerModal =
        canShowVideoPlayer(urlInfo) && actionModalShowVideoPlayer;

      if (canShowVideoPlayerModal) {
        actionModalShowVideoPlayer(true, urlInfo);
      } else {
        const {url} = urlInfo || {};
        openUrlInNewTab(url, eventPrefix + '-click_video_thumbnail-and-open_new_tab');
      }
    }

    if (actionApiUpdateUserPreferredCategories) {
      actionApiUpdateUserPreferredCategories(urlInfo.keywords, /*incAmount*/1);
    }
  }

  let iKey = 0;
  const videoItems = videos.map((videoUrlInfo) => {
    iKey++;

    return (
      <VideoItem
        key={iKey.toString()}
        urlInfo={videoUrlInfo}
        eventPrefix={eventPrefix}
        onClickSaveVideo={handleSaveVideo}
        onClickThumbnail={handleClickThumbnail}
        isSaved={
          savedItems.urls[videoUrlInfo.url] ||
          savedItems.titles[videoUrlInfo.title]
        }
        shouldUpdateClickCnt={shouldUpdateClickCnt}
        activePreviewVideoItemUrl={activePreviewVideoItemUrl}
        setActivePreviewVideoItemUrl={setActivePreviewVideoItemUrl}
        enableAdminButton={enableAdminButton}
        actionSetRecommendKeywords={actionSetRecommendKeywords}
        recommendation={recommendation}
        feedPreference={feedPreference}
      />
    );
  });

  return videoItems;
}

export default VideoItems;

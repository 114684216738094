export const collectionAddItem = (collectionId, itemId, itemData) => ({
  type: 'COLLECTION_ADD_ITEM',
  collectionId,
  itemId,
  itemData,
});

export const collectionBringItemToFront = (collectionId, itemId, createdTimestampMs) => ({
  type: 'COLLECTION_BRING_ITEM_TO_FRONT',
  collectionId,
  itemId,
  createdTimestampMs,
});

export const collectionDeleteItem = (collectionId, itemId) => ({
  type: 'COLLECTION_DELETE_ITEM',
  collectionId,
  itemId,
});

export const collectionSetisFetchingCurrentCollectionInfo = (isFetching) => ({
  type: 'COLLECTION_SET_IS_FETCHING_CURRENT_COLLECTION_INFO',
  isFetching,
});

export const collectionSetIsFetchingItems = (isFetching) => ({
  type: 'COLLECTION_SET_IS_FETCHING_ITEMS',
  isFetching,
});

export const collectionSetIsFetchingPrivateCollections = (isFetching) => ({
  type: 'COLLECTION_SET_IS_FETCHING_PRIVATE_COLLECTIONS',
  isFetching,
});

export const collectionSetPrivateCollections = (privateCollections) => ({
  type: 'COLLECTION_SET_PRIVATE_COLLECTIONS',
  privateCollections,
});

// Update current collection.
export const collectionUpdateCurrentCollectionInfo = (info) => ({
  type: 'COLLECTION_UPDATE_CURRENT_COLLECTION_INFO',
  info,
});

export const collectionUpdateCurrentItemId = (itemId) => ({
  type: 'COLLECTION_UPDATE_CURRENT_ITEM_ID',
  itemId,
});

export const collectionUpdateFilter = (filter) => ({
  type: 'COLLECTION_UPDATE_FILTER',
  filter,
});

// Note: `items` should be all items in the collection.
export const collectionUpdateItems = (collectionId, items) => ({
  type: 'COLLECTION_UPDATE_ITEMS',
  collectionId,
  items,
});

export const collectionUpdateItemTags = (collectionId, itemId, tags) => ({
  type: 'COLLECTION_UPDATE_ITEM_TAGS',
  collectionId,
  itemId,
  tags,
});

export const collectionUpdateItemDetails = (collectionId, itemId, customizedTitle, customizedDescription) => ({
  type: 'COLLECTION_UPDATE_ITEM_DETAILS',
  collectionId,
  itemId,
  customizedTitle,
  customizedDescription,
});

export const collectionUpdateItemPrice = (collectionId, itemId, price) => ({
  type: 'COLLECTION_UPDATE_ITEM_PRICE',
  collectionId,
  itemId,
  price,
});

export const collectionUpdateItemUrlInfo = (collectionId, itemId, urlInfo) => ({
  type: 'COLLECTION_UPDATE_ITEM_URL_INFO',
  collectionId,
  itemId,
  urlInfo,
});

// Update private collection meta data. e.g. collection name.
export const collectionUpdatePrivateCollectionInfo = (info) => ({
  type: 'COLLECTION_UPDATE_PRIVATE_COLLECTION_INFO',
  info,
});

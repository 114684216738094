import LoadingCover from './LoadingCover';
// Third party.
import React, { useState } from 'react';

function Frame(props) {
  const [loaded, setLoaded] = useState(false);

  const { src, backgroundImageUrl } = props;

  const backgroundImageStyle =
      loaded ? { backgroundImage:`url("${backgroundImageUrl}")` } : null;

  function handleLoad(e) {
    setLoaded(true);
  }

  // TODO: remove Chrome fallback html on loading error.
  return (
    <div
      className="embed-responsive embed-responsive-16by9 background-image"
      style={backgroundImageStyle}
    >
      <iframe
        title="Web content"
        className="embed-responsive-item"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        sandbox="allow-same-origin allow-scripts allow-presentation"
        onLoad={handleLoad}
      />

      <LoadingCover hidden={loaded} />
    </div>
  );
}

export default Frame;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  useSearchQuery,
  searchInputToQuery,
} from 'misc/queryHelpers';
import {
  P_HOME,
} from 'misc/appConstants';
import {
  goTo,
} from 'misc/appHelperFunctions';
import AutoSuggest from 'components/AutoSuggest';
import InputBox from 'components/InputBox';
// Third party.
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

const _SEARCH_PLACEHOLDER = 'Search videos...';
const _BUTTON_TITLE_SEARCH = 'Search';

function SearchBox(props) {
  const history = useHistory();

  const [initialValue, setInitialValue] = useState('');
  const [targetKeyword, setTargetKeyword] = useState('');
  const [highlightedCandidateKeyword, setHighlightedCandidateKeyword] = useState('');
  const [availableKeywordsList, setAvailableKeywordsList] = useState([]);

  const {
    // Redux states.
    navbar,
    searchInput,
    // Redux actions.
    actionSearchReset,
    actionSearchUpdateSearchInput,
  } = props;
  const searchKeyWord = searchInput.keyword;

  // Monitor search query changes here instead of in the navbar home button,
  // so that the tracking is only active when home page the current page.
  useSearchQuery(actionSearchUpdateSearchInput);

  useEffect(() => {
    import("misc/dataConstants/SEARCH_KEYWORDS").then((constants) => {
      const {
        INTERNAL_SEARCH_KEYWORDS,
        EXTERNAL_SEARCH_KEYWORDS,
      } = constants.SEARCH_KEYWORDS;

      // Auto suggest should include both internal and external keywords, while
      // only internal keywords will be searched in internal database first.
      setAvailableKeywordsList(INTERNAL_SEARCH_KEYWORDS.concat(EXTERNAL_SEARCH_KEYWORDS));

      const availableKeywords = {};
      INTERNAL_SEARCH_KEYWORDS.forEach((keyword) => {
        availableKeywords[keyword] = true;
      });
      actionSearchUpdateSearchInput({
        availableKeywords,
        externalKeywordsList: EXTERNAL_SEARCH_KEYWORDS,
      });
    });
  }, [actionSearchUpdateSearchInput]);

  // Update search box when search keyword is updated elsewhere.
  // e.g. Tap home button to clear search keyword.
  useEffect(() => {
    // Make input box inputString the same as the search keyword in redux state.
    setInitialValue(searchKeyWord);
    // Hide AutoSuggest.
    setTargetKeyword('');
  }, [searchKeyWord, setInitialValue, setTargetKeyword]);

  function handleSearch(searchString) {
    const rawKeyword = searchString || '';

    if (rawKeyword) {
      const searchQuery = searchInputToQuery({
        keyword: rawKeyword,
      });
      goTo(history, searchQuery);
    } else {
      goTo(history, P_HOME);
      actionSearchReset();
    }

    // Hide AutoSuggest.
    // When user taps on or search the same keyword as the current one,
    // auto suggest still needs to be dismissed.
    setTargetKeyword('');

    analyticsLogEvent('feed-search-keyword=' + searchString);
  }

  function handleSubmit(inputString) {
    handleSearch(highlightedCandidateKeyword || inputString);

    const eventName = highlightedCandidateKeyword
        ? ('feed-search-submit-suggested=' + highlightedCandidateKeyword)
        : ('feed-search-submit-original=' + inputString);
    analyticsLogEvent(eventName);
  }

  function handleClickCandidateTag(tag) {
    handleSearch(tag);

    analyticsLogEvent('feed-search-click-suggested=' + tag);
  }

  return (
    <div className="w-100 position-relative">
      <InputBox
        initialValue={initialValue}
        autoFocus={navbar.searchBox.autoFocus}
        placeholder={_SEARCH_PLACEHOLDER}
        buttonTitle={_BUTTON_TITLE_SEARCH}
        hideCancelButton
        submitOnClick={handleSubmit}
        onChange={setTargetKeyword}
      />

      <AutoSuggest
        className="position-absolute mt-1"
        targetTag={targetKeyword}
        candidateTags={availableKeywordsList}
        onClickCandidateTag={handleClickCandidateTag}
        onHighlightedCandidateTagChange={setHighlightedCandidateKeyword}
        enableKeyEvents={true}
      />
    </div>
  );
}

function ToolbarSearch(props) {
  return (
    <Container
      className="mw-md min-h-round-button-size"
    >
      <SearchBox {...props} />
    </Container>
  );
}

export default ToolbarSearch;

import SignUpButton from 'components/buttons/SignUpButton';
import {
  IconBalloons,
  IconHeartFill,
  IconVideo,
} from 'components/icons';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function IconText(props) {
  const { className="", iconType, text } = props;
  const iconClassName = "icon-white mr-3";

  let icon;
  switch(iconType) {
    case 'heart':
      icon = (<IconHeartFill size={1.4} className="icon-warning mr-3" />);
      break;
    case 'balloons':
      icon = (<IconBalloons size={1.4} className={iconClassName} />);
      break;
    case 'video':
      icon = (<IconVideo size={1.4} className={iconClassName} />);
      break;
    default:
      break;
  }

  return (
    <div className={"d-flex align-items-center py-2 " + className}>
      <h5>
        { icon }
      </h5>
      <h5 className="text-white">
        { text }
      </h5>
    </div>
  );
}

function BannerContent(props) {
  return (
    <Container fluid className="px-0 d-flex justify-content-center align-items-center mobile-pb-4">
      <div className="w-100">
        <h2 className="text-white pb-3">
          Popular Free Porn Videos.
        </h2>
        <h2 className="text-white pb-3">
          From the Best Porn Sites.
        </h2>
        <h2 className="text-white pb-3">
          Recommended Based On Your Tastes.
        </h2>

        <IconText
          iconType="video"
          text="10 million popular videos."
        />
        <IconText
          iconType="balloons"
          text="We refresh our database everyday."
        />
        <IconText
          iconType="heart"
          text="Personalized recommendations."
        />

        <SignUpButton
          title="Sign Up for Free"
          className="text-primary mt-4 mobile-block"
          size="lg"
          variant="light"
          clickEventName={props.eventNameForClickSignUp}
        />
      </div>
    </Container>
  );
}

export default BannerContent;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  CONFIGS_DISPLAY_MEDIA,
  P_HOME,
} from 'misc/appConstants';
// Third party.
import normalizeUrl from 'normalize-url';
import isUrl from 'is-url';

const specialCategories = {
  'asian': ['asian', 'japanese', 'korean'],
  'japanese': ['asian', 'japanese', 'korean'],
  'gay': ['gay'],
  'hentai': ['hentai'],
};

const hotCategories = ['teen', 'big tits'];

export function abbreviateNumber(value=0) {
  const suffixes = ["", "K", "M", "B","T"];
  let i = 0;
  for (; i < 5; i++) {
    if (value < 1000) {
      break;
    }
    value /= 1000;
  }

  const decimalDigits = (value % 1 > 0.1) ? 1 : 0;
  const candidateResult = value.toFixed(decimalDigits);
  value = (candidateResult % 1) ? candidateResult : value.toFixed(0);

  return value + suffixes[i];
}

export function areArraysEqual(a, b) {
  if (!a && !b) {
    return true;
  }

  if (!a || !b || (a.length !== b.length)) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

export function areObjectsShallowEqual(a, b) {
  if (!a || !b) {
    return (!a && !b);
  }

  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
      return false;
  }

  for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
          return false;
      }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}

export function canShowEmbedVideo(urlInfo={}) {
  const {
    domain="",
    video,
  } = urlInfo;

  const embedUrl = (video || {}).embedUrl;

  // Embed is displayed by default.
  const embedFlag = (CONFIGS_DISPLAY_MEDIA[domain.toLowerCase()] || {}).embed;
  const shouldSuppressEmbed = (embedFlag === 'no') ||
      (isTouchDevice(/*isMobileOS*/true) && embedFlag === 'no-mobile');

  return (embedUrl && !shouldSuppressEmbed);
}

export function canShowVideoContent(urlInfo={}) {
  const {
    domain="",
    video,
  } = urlInfo;

  const contentUrl = (video || {}).contentUrl;

  // Video is suppressed by default.
  const canShowVideo =
      (CONFIGS_DISPLAY_MEDIA[domain.toLowerCase()] || {}).video === 'yes';

  return (canShowVideo && contentUrl);
}

export function canShowVideoPlayer(urlInfo) {
  const canShowEmbedVideoFlag = canShowEmbedVideo(urlInfo);
  const canShowVideoContentFlag = canShowVideoContent(urlInfo);
  return (canShowEmbedVideoFlag || canShowVideoContentFlag);
}

export function delay(timeInSeconds = 1, value) {
   return new Promise((resolve) => {
       setTimeout(resolve.bind(null, value), timeInSeconds * 1000);
   });
}

export function feedCategoriesToSortedArray(feedCategories) {
  const categories = feedCategories || {};

  const sortedCategories = [];
  for (let keyName in categories) {
    if (keyName && categories[keyName]) {
      if (specialCategories[keyName]) {
        return specialCategories[keyName];
      }
      sortedCategories.push(keyName);
    }
  }

  // Sort categories starting from the largest value.
  sortedCategories.sort((a, b) => {
    return feedCategories[b] - feedCategories[a];
  });

  if (sortedCategories.length < 4) {
    sortedCategories.push(...hotCategories);
  }

  return sortedCategories;
}

export function getAbsoluteUrl(string) {
  let absoluteUrl = null;

  try {
    absoluteUrl = normalizeUrl(string, {
      defaultProtocol: 'https:',
      stripAuthentication: false,
      stripWWW: false,
      removeTrailingSlash: false,
      sortQueryParameters: false,
    });
  } catch (error) {
    console.log("Get absolute url error:", error);
  }

  return isUrl(absoluteUrl) && absoluteUrl;
}

export function getDomain(url) {
  const hostName = getHostName(url);
  let domain = hostName;

  if (hostName != null) {
    const parts = hostName.split('.').reverse();

    if (parts != null && parts.length > 1) {
      domain = parts[1] + '.' + parts[0];

      if (hostName.toLowerCase().indexOf('.co.uk') !== -1 && parts.length > 2) {
        domain = parts[2] + '.' + domain;
      }
    }
  }

  return domain;
}

export function getMaskedEmail(email) {
  if (!email) {
    return '';
  }

  const emailParts = email.split('@');
  const maskedEmailParts = emailParts.map((str) => {
    if (!str || str.length < 2) {
      return str;
    }
    const stars = '*'.repeat(str.length - 1);
    return str[0] + stars;
  });
  maskedEmailParts.splice(1, 0, '@');
  const maskedEmail = ''.concat(...maskedEmailParts);

  return maskedEmail;
}

export function getPreviewImageUrls(urlInfo) {
  return ((urlInfo || {}).preview || {}).imageUrls || [];
}

export function getPreviewVideoUrl(urlInfo) {
  return ((urlInfo || {}).preview || {}).videoUrl;
}

export function getRandomBackgroundColorClassName(seedString) {
  if (!seedString || !seedString.length) {
    return "bg-0";
  }
  const midIndex = Math.floor(seedString.length / 2) % seedString.length;
  const backgroundColorIndex = seedString.charCodeAt(midIndex) % 10
  return ("bg-" + backgroundColorIndex);
}

export function getSortedPrivateCollections(privateCollections) {
  if (!privateCollections) {
    return [];
  }

  // Sort by collection name alphabetically.
  const sortedCollectionItems = Object.values(privateCollections);
  sortedCollectionItems.sort((a, b) => {
    const aName = (a.name || '').toLowerCase();
    const bName = (b.name || '').toLowerCase();
    if(aName < bName) { return -1; }
    if(aName > bName) { return 1; }
    return 0;
  });

  return sortedCollectionItems;
}

export function isAnyModalActive(modalState) {
  let isAnyModalShow = false;
  const allModalControlKeys = Object.keys(modalState);
  for (let i = 0; i < allModalControlKeys; i++) {
    if (modalState[allModalControlKeys[i]].show) {
      isAnyModalShow = true;
      break;
    }
  }
  return isAnyModalShow;
}

export function isNullOrUndefined(x) {
  return (x === null || x === 'undefined');
}

export function isAndroidDevice() {
  if (/Android/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
}

export function isIOSDevice() {
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    return true;
  }

  // iPad Pro (10.5 inch) has the same userAgent as Mac laptop. So need to
  // explictly check if it's touch device for iPad Pro (10.5 inch).
  if (/Mac/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
    return true;
  }

  return false;
}

export function isPremiumVideo(urlInfo) {
  return (urlInfo && urlInfo.price && urlInfo.price !== 'free');
}

export function isTouchDevice(isMobileOS=false) {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|Phone/i.test(navigator.userAgent)) {
    return true;
  }

  if (!isMobileOS) {
    // iPad Pro (10.5 inch) has the same userAgent as Mac laptop. So need to
    // explictly check if it's touch device for iPad Pro (10.5 inch).
    if (/Mac/i.test(navigator.userAgent) &&
        navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      return true;
    }
  }

  return false;
}

export function logScreenView(screenName) {
  analyticsLogEvent('screen_view', { screen_name: screenName });
  analyticsLogEvent('screen_view_' + screenName);
}

export function minusItemTags(allTags, itemData) {
  if (!itemData || !itemData.tags) {
    return allTags;
  }

  const tags = { ...allTags };
  const itemTags = itemData.tags;

  for (let i = 0; i < itemTags.length; i++) {
    const itemTag = itemTags[i];
    tags[itemTag] = (tags[itemTag] || 0) - 1;
    if (tags[itemTag] <= 0) {
      delete tags[itemTag];
    }
  }

  return tags;
}

// https://stackoverflow.com/a/2901298
// Note that Safari doesn't support lookbehind yet (?<=\/)
export function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function onImageLoaded(imageUrl, callbackOnLoad) {
  if (!imageUrl) {
    callbackOnLoad()
    return;
  }
  const img = new Image();
  img.onload = () => {
    callbackOnLoad()
  };
  img.onerror = () => {
    callbackOnLoad()
  };
  img.src = imageUrl;
}

export function openUrlInNewTab(url, eventName) {
  window.open(url, '_blank');
  if (eventName) {
    analyticsLogEvent(eventName);
  }
}

export function parseDurationInSec(durationInSec) {
  if (!durationInSec) {
    return null;
  }

  const d = Number(durationInSec);
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);
  const s = Math.floor(d % 3600 % 60);

  return { h, m, s };
}

export function parseDurationInSecToClockFormat(durationInSec) {
  const hms = parseDurationInSec(durationInSec);
  if (!hms) {
    return null;
  }
  const { h, m, s } = hms;

  const hDisplay = (h > 0) ? (h + ':') : '';
  const mDisplay = m + ':';
  const sDisplay = (s > 9) ? s : ('0' + s);

  return (hDisplay + mDisplay + sDisplay);
}

export function parseDurationInSecToTextFormat(durationInSec) {
  const hms = parseDurationInSec(durationInSec);
  if (!hms) {
    return null;
  }
  const { h, m, s } = hms;

  const hDisplay = (h > 0) ? (h + 'h ') : '';
  const mDisplay = (m > 0) ? (m + 'm ') : '';
  const sDisplay = (s > 0) ? (s + 's') : '';

  return (hDisplay + mDisplay + sDisplay).trim();
}

export function plusItemTags(allTags, itemData) {
  if (!itemData || !itemData.tags) {
    return allTags;
  }

  const tags = { ...allTags };
  const itemTags = itemData.tags;

  for (let i = 0; i < itemTags.length; i++) {
    const itemTag = itemTags[i];
    tags[itemTag] = (tags[itemTag] || 0) + 1;
  }

  return tags;
}

// Ensure all medai urls are secured so browser will not indicate
// "Your connection is not fully secure" due to "mixed content"
// https://www.godaddy.com/community/SSL-And-Security/quot-Your-connection-is-not-fully-secure-quot-Google-Chrome/td-p/50372
export function secureUrlInfo(urlInfo) {
  if (!urlInfo) {
    return null;
  }

  if (urlInfo.thumbnail && urlInfo.thumbnail.url) {
    urlInfo.thumbnail.url =
        getSecureUrl(urlInfo.thumbnail.url);
  }
  if (urlInfo.video) {
    if (urlInfo.video.embedUrl) {
      urlInfo.video.embedUrl =
          getSecureUrl(urlInfo.video.embedUrl);
    }
    if (urlInfo.video.contentUrl) {
      urlInfo.video.contentUrl =
          getSecureUrl(urlInfo.video.contentUrl);
    }
  }
  if (urlInfo.backupUrlInfo) {
    urlInfo.backupUrlInfo = secureUrlInfo(urlInfo.backupUrlInfo);
  }

  return urlInfo;
}

// https://stackoverflow.com/a/32589289
export function toTitleCase(str) {
  if (!str) {
    return "";
  }

  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
     // You do not need to check if i is larger than splitStr length, as your for does that for you.
     // Assign it back to the array
     splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

//--------------------
// React Router
//--------------------
export function navBack(history) {
  if (!history) {
    return null;
  }

  if (history.length > 2) {
    history.goBack();
  } else {
    history.replace(P_HOME);
  }
}

export function goTo(history, path) {
  if (!history || !path) {
    return null;
  }

  history.push(path);
}

export function redirectTo(history, path) {
  if (!history || !path) {
    return null;
  }

  history.replace(path);
}

//--------------------
// Collection Filter
//--------------------
// Note: a few assumptions need to be met here at this point
// (1) Filter is supposed to be active.
// (2) Item tags and filter tags should be sorted from small to large.
function isItemInFilter(item, parsedFilter) {
  if (!item) {
    return false;
  }

  const itemTags = item.tags || [];
  const { filterKeyWords, filterTags } = parsedFilter || {};

  // Check if item includes search string.
  //
  // filterKeyWords !== null indicates there are at least one valid filter keywords.
  if (filterKeyWords) {
    const { url, name, description } = item.urlInfo || {}
    const info = [url, name, description, ...itemTags].join(' ').toLowerCase();

    for (let i = 0; i < filterKeyWords.length; i++) {
      if (!info.includes(filterKeyWords[i])) {
        return false;
      }
    }
  }

  // Check if itemTags includes all filterTags.
  //
  // At this point, either search is NOT active, or item is in search results.
  // filterTags !== null indicates there are at least one valid filter tags.
  let itemHasAllFilterTags = !filterTags;
  if (filterTags) {
    const itemTagsCount = itemTags.length;
    const filterTagsCount = filterTags.length;
    // Use two pointers to check if itemTags includes all filterTags.
    // Assume itemTags is sorted.
    if (itemTagsCount >= filterTagsCount) {
      // Filter tag index.
      let fIndex = 0;

      for (let iIndex = 0; iIndex < itemTagsCount; iIndex++) {
        const itemTag = itemTags[iIndex];
        const filterTag = filterTags[fIndex];
        if (itemTag < filterTag) {
          continue;
        } else if (itemTag === filterTag) {
          fIndex++;
          // fIndex === filterTagsCount indicates all filterTags are found in itemTags.
          if (fIndex === filterTagsCount) {
            itemHasAllFilterTags = true;
            break;
          }
        } else {
          break;
        }
      }
    }
  }

  return itemHasAllFilterTags;
}

function parseCollectionFilter(filter) {
  const results = {
    filterKeyWords: null,   // [searchKeyWord]
    filterTags: null,       // [tag]
  };

  if (!filter) {
    return results;
  }

  if (filter.search && filter.search.length > 0) {
    // Split search string by space(s).
    const filterKeyWords = filter.search.toLowerCase().split(/\s+/);
    if (filterKeyWords.length > 0) {
      results.filterKeyWords = filterKeyWords;
    }
  }

  if (filter.tags && (filter.tags.length > 0)) {
    results.filterTags = filter.tags;
  }

  return results;
}

export function filterCollection(collectionId, items, itemIds, tags, filter) {
  const filteredCollection = {
    itemIds: [],
    tags: {},
  };
  if (!collectionId || !items || !itemIds || !itemIds[collectionId]) {
    return filteredCollection;
  }
  const collectionItemIds = itemIds[collectionId];

  if (!isCollectionFilterActive(filter)) {
    // Use collectionItemIds and collectionTags as filtered results when
    // filter is NOT active.
    filteredCollection.itemIds = collectionItemIds;
    filteredCollection.tags = (tags || {})[collectionId] || {};
    return filteredCollection;
  }

  const parsedFilter = parseCollectionFilter(filter);

  for (let i = 0; i < collectionItemIds.length; i++) {
    const itemId = collectionItemIds[i];
    const itemData = items[itemId];

    if (!isItemInFilter(itemData, parsedFilter)) {
      continue;
    }

    filteredCollection.itemIds.push(itemId);
    // Merge all item tags into results as available tags in filtered items.
    filteredCollection.tags = plusItemTags(filteredCollection.tags, itemData);
  }

  return filteredCollection;
}

export function isCollectionFilterActive(filter) {
  const { filterKeyWords, filterTags } = parseCollectionFilter(filter);
  return (filterKeyWords || filterTags);
}

export function isNavbarInputBoxEnabled(navbar) {
  if (!navbar) {
    return false;
  }
  return (navbar.saveBox.enabled || navbar.searchBox.enabled);
}

// Randomly pick from list,
// list: the original list
// frequency: change frequency
// skip: interval between two picks.
export function randomlyPickFromList(list, frequency, skip, count) {

  let date = new Date().getDate();
  let start = Math.floor(date / frequency) * frequency;

  if (!list || list.length === 0 || list.length < count) {
    return list;
  }
  const res = [];
  start = start % list.length;
  skip = skip % list.length;

  while (res.length < count) {
    if (!res.includes(list[start])) {
      res.push(list[start]);
    } else {
      // Find the next avaiable item in sequence
      let i = start;
      while (res.includes(list[i])) {
        i++;
        if (i >= list.length - 1) {
          i = 0;
        }
      }
      res.push(list[i]);
      start = i;
    }
    start += skip;
    if (start >= list.length) {
      start = start % list.length;
    }
  }

  return res;
}

//--------------------
// More private methods
//--------------------
// primaryobjects.com/2012/11/19/parsing-hostname-and-domain-from-a-url-with-javascript
function getHostName(url) {
  if (!url) {
    return null;
  }

  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2];
  }

  return null;
}

function getSecureUrl(string) {
  let secureUrl = null;

  try {
    secureUrl = normalizeUrl(string, {
      defaultProtocol: 'https:',
      forceHttps: true,
      stripAuthentication: false,
      stripWWW: false,
      removeTrailingSlash: false,
      sortQueryParameters: false,
    });
  } catch (error) {
    console.log("Get secure url error:", error);
  }

  return isUrl(secureUrl) && secureUrl;
}

//--------------------
// Unused
//--------------------
/*
const _IPHONE_USER_AGENT = 'Mozilla/5.0 (iPhone; CPU iPhone OS 12_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1 Mobile/15E148 Safari/604.1';
function setUserAgentMobile(window, userAgent=_IPHONE_USER_AGENT) {
  if (window.navigator.userAgent !== userAgent) {
    const userAgentProp = { get: function () { return userAgent; } };
    try {
      Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
    } catch (e) {
      window.navigator = Object.create(navigator, {
        userAgent: userAgentProp
      });
    }
  }
}
*/

import {
  parseDurationInSecToTextFormat,
} from 'misc/appHelperFunctions';
import CopyButton from 'components/buttons/CopyButton';
import DomainInfo from 'components/DomainInfo';
import Tags from 'components/Tags';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function TextsRow(props) {
  const { title, texts=[] } = props;

  let content = "";
  for (let i = 0; i < texts.length; i++) {
    if (content) {
      content += ", "
    }
    content += texts[i];
  }

  if (!content) {
    return null;
  }

  const titleItem = title
      ? (<span className="text-black-50 mr-1">{ title }:</span>)
      : null;

  return (
    <p className="mt-3 mb-0 text-dark">
      { titleItem } { content }
    </p>
  );
}

function VideoInformation(props) {
  const {
    tags,
    urlInfo,
    onCopyLink,
  } = props;

  const {
    url,
    domain,
    title,
    customizedTitle,
    description,
    customizedDescription,
    video={},
    actors,
    categories,
  } = urlInfo || {};
  const formattedDuration = parseDurationInSecToTextFormat(video.durationInSec);
  const nameSuffix = formattedDuration ? (' · ' + formattedDuration) : '';

  function handleClickTag(tag) {
    props.handleClickTag && props.handleClickTag(tag);
  }

  return (
    <div className="px-content-container">
      <Container className="pt-4 pb-1 px-0 mw-md">
        <h6 className="mt-1">
          { customizedTitle || title || url }
          <span className="text-black-50">{nameSuffix}</span>
        </h6>
        {
          onCopyLink &&
          <div className="mt-3 d-flex justify-content-between">
            <DomainInfo
              url={url}
              domain={domain}
              showArrow={true}
              clickEventName="video_info-click_item_domain"
            />

            <CopyButton
              className="my-n3" text={url}
              clickEventName="video_info-click-copy_domain_button"
              hoverEventName="video_info-hover-copy_domain_button"
              onCopy={onCopyLink}
            />
          </div>
        }

        <TextsRow
          title="Pornstars"
          texts={actors}
        />

        <TextsRow
          title="Categories"
          texts={categories}
        />

        <Tags
          className="mt-3"
          tags={tags}
          handleClickTag={handleClickTag}
          clickEventName="video_info-click_item_tag"
        />

        {
          description &&
          <p className="mt-3 mb-0 text-dont-break-out text-display-line-break">
            { customizedDescription || description }
          </p>
        }
      </Container>
    </div>
  );
}

export default VideoInformation;

// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function SectionWrapper(props) {
  const {
    className="",
    innerClassName="",
    children,
  } = props;

  return (
    <Container fluid className={"px-0 py-5 " + className}>
      <Container className={innerClassName}>
        { children }
      </Container>
    </Container>
  );
}

export default SectionWrapper;

// Third party.
import React, { forwardRef } from 'react';
import Button from 'react-bootstrap/Button';

function ToggleButton({ children, onClick }, ref) {
  function handleClick(e) {
    e.preventDefault();
    if (onClick) {
      onClick(e);
    }
  }

  return (
    <Button
      ref={ref}
      variant="light"
      className="round-button"
      onClick={handleClick}
    >
      {children}
    </Button>
  );
}

const CustomToggleButton = forwardRef(ToggleButton);

export default CustomToggleButton;
import {
  P_HOME,
} from 'misc/appConstants';
import {
  areObjectsShallowEqual,
  goTo,
} from 'misc/appHelperFunctions';
import CategoriesGrid from './CategoriesGrid';
import FooterButtons from './FooterButtons';
// Third party.
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function FeedPreference(props) {
  const {
    // Redux states.
    feedPreference,
    // Redux actions.
    actionPersistSetFeedPreference,
  } = props;

  const [selectedCategories, setSelectedCategories] = useState(feedPreference.categories || {});

  const history = useHistory();

  function handleClickDone() {
    const categoriesChanged =
        !areObjectsShallowEqual(selectedCategories, feedPreference.categories);

    const nextFeedPreference = {
      ...feedPreference,
      show: false,
    };
    if (categoriesChanged) {
      nextFeedPreference.categories = selectedCategories;
    }
    actionPersistSetFeedPreference(nextFeedPreference);

    // Note that goTo need to execute after actionPersistSetFeedPreference.
    if (categoriesChanged) {
      goTo(history, P_HOME);
    }
  }

  return (
    <div className="bg-white w-100 position-fixed left-0 top-navbar-height h-body overflow-auto">
      <h4 className="text-center my-4">
        Porn Categories
      </h4>

      <CategoriesGrid
        {...props}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />

      <FooterButtons
        {...props}
        handleClickDone={handleClickDone}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
    </div>
  );
}

export default FeedPreference;

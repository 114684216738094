import {
  apiAddCollectionItem,
  apiUpdateUserPreferredCategories,
  modalShowSaveItem,
  modalShowVideoPlayer,
  modalShowLoginRequest,
  toastSetShowMessage,
} from 'appRedux/actions';
import {recommendationSetRecommendKeywords} from "appRedux/actions/recommendation";
import AdminModal from 'components/admin/adminModal';
import ToastAutoHide from 'components/toastAutoHide';
import ModalItemEditor from 'components/modalItemEditor';
import ModalLoginRequest from 'components/modalLoginRequest';
import ModalVideoPlayer from 'components/modalVideoPlayer';
import PageFooter from 'components/PageFooter';
import Header from './Header';
import VideoItems from './videoItems';
// Third party.
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const _DEFAULT_EVENT_PREFIX = 'videos-grid';
const _TOAST_ID = 'videos-grid';

function VideosGrid(props) {
  const {
    // Derived Redux actions.
    fetchVideos,
  } = props;

  useEffect(() => {
    if (fetchVideos) {
      fetchVideos();
    }
  }, [fetchVideos]);

  const {
    eventPrefix=_DEFAULT_EVENT_PREFIX,
    title,
    titleComponent,
    titleFetching,
    footerTitle,
    className="",
    onEndReached,
    // Derived Redux states.
    enableAdminButton,
    isFetchingVideos,
    isLoadingMoreVideos,
    videos=[],
  } = props;

  return (
    <Container fluid className={"px-0 " + className}>
      <Header
        title={title}
        titleComponent={titleComponent}
        titleFetching={titleFetching}
        isFetchingVideos={isFetchingVideos}
      />
      <Row>
        <VideoItems {...props} />
      </Row>
      <PageFooter
        scrollTrackingEnabled={videos.length}
        showLoadingIndicator={isLoadingMoreVideos}
        eventPrefix={eventPrefix}
        onEndReached={onEndReached}
        title={footerTitle}
      />

      <ToastAutoHide
        id={_TOAST_ID}
        positionClassName={props.toastPositionClassName}
      />

      <ModalItemEditor eventPrefix={eventPrefix} />

      <ModalVideoPlayer eventPrefix={eventPrefix} />

      <ModalLoginRequest eventPrefix={eventPrefix} />

      {
        enableAdminButton &&
        <AdminModal />
      }
    </Container>
  );
}

//--------------------
// Container
//--------------------
const mapStateToProps = (state, ownProps) => ({
  collection: state.collection,
  user: state.user,
  recommendation: state.recommendation,
  feedPreference: state.persist.feedPreference,
});

const mapDispatchToProps = (dispatch) => ({
  actionApiAddCollectionItem: (urlInfo, collectionInfo) => {
    dispatch(apiAddCollectionItem(urlInfo, collectionInfo));
  },
  actionApiUpdateUserPreferredCategories: (categoryList, incAmount) => {
    dispatch(apiUpdateUserPreferredCategories(categoryList, incAmount));
  },
  actionModalShowSaveItem: (show, urlInfo) => {
   dispatch(modalShowSaveItem(show, urlInfo));
  },
  actionModalShowVideoPlayer: (show, urlInfo) => {
   dispatch(modalShowVideoPlayer(show, urlInfo));
  },
  actionModalLoginRequest: (show) => {
    dispatch(modalShowLoginRequest(show));
  },
  toastShowMessage: (message) => {
    dispatch(toastSetShowMessage(_TOAST_ID, message));
  },
  actionSetRecommendKeywords: (recommendKeywords) => {
    dispatch(recommendationSetRecommendKeywords(recommendKeywords));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideosGrid);

import {
  DEFAULT_SEARCH_INPUT,
} from 'appRedux/reducers/stateConstants';
// Third party.
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function composeQuery(queryItems) {
  let query = '?';
  for (let key in queryItems) {
    if (key && queryItems[key]) {
      if (query.length > 1) {
        query += '&';
      }
      query += key + '=' + queryItems[key];
    }
  }
  if (query === '?') {
    query = '';
  }
  return query;
}

export function searchInputToQuery(rawSearchInput) {
  const searchInput = {
    ...DEFAULT_SEARCH_INPUT,
    ...rawSearchInput,
  };
  const keyword = searchInput.keyword;
  const filters = searchInput.filters;

  const searchFilterItems = {};
  for (let queryKey in _QUERY_SEARCH_FILTERS) {
    const filterName = _QUERY_SEARCH_FILTERS[queryKey].filterName;

    for (let queryValue in _QUERY_SEARCH_FILTERS[queryKey].filterValue) {
      const filterValue = _QUERY_SEARCH_FILTERS[queryKey].filterValue[queryValue];

      if (filters[filterName] === filterValue) {
        searchFilterItems[queryKey] = queryValue;
      }
    }
  }

  const queryItems = {
    [_QUERY_SEARCH]: keyword,
    ...searchFilterItems,
  }

  const searchQuery = composeQuery(queryItems);

  return searchQuery;
}

// A custom hook that builds on useLocation to parse the query string for you.
// https://reacttraining.com/react-router/web/example/query-parameters
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// A custom hook that converts search query to playlist information, and trigger
// the given callback function.
export function usePlaylistQuery(onQueryChange) {
  const query = useQuery();

  // Convert query to a single string to so that React Hook can detect the changes.
  // Since queryString is used for tracking query changes, query is not listed
  // in the hook dependencies, and eslint check is disabled for exception.
  const queryString = query.toString();

  useEffect(() => {
    const queryCollectionId = query.get(QUERY_COLLECTION_ID);

    // Update feed search keyword and filter to trigger search if current url
    // contains valid query params.
    onQueryChange({
      collectionId: queryCollectionId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, onQueryChange]);
}

// A custom hook that converts search query to search input, and trigger the
// given callback function.
export function useSearchQuery(onSearchQueryChange) {
  const query = useQuery();

  // Convert query to a single string to so that React Hook can detect the changes.
  // Since queryString is used for tracking query changes, query is not listed
  // in the hook dependencies, and eslint check is disabled for exception.
  const queryString = query.toString();

  useEffect(() => {
    const queryKeyword = query.get(_QUERY_SEARCH);
    // Extract search filters from the search query.
    const queryFilters = {
      ...DEFAULT_SEARCH_INPUT.filters
    };
    let forceSearchOn = false;
    for (let queryKey in _QUERY_SEARCH_FILTERS) {
      const queryValue = query.get(queryKey);
      const filterName = _QUERY_SEARCH_FILTERS[queryKey].filterName;
      const filterValue = _QUERY_SEARCH_FILTERS[queryKey].filterValue[queryValue];
      if (filterName && filterValue) {
        queryFilters[filterName] = filterValue;
        forceSearchOn = true
      }
    }
    // Update feed search keyword and filter to trigger search if current url
    // contains valid query params.
    onSearchQueryChange({
      keyword: queryKeyword,
      filters: queryFilters,
      forceSearchOn,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, onSearchQueryChange]);
}

export const QUERY_ROUTE = 'route';

export const QUERY_COLLECTION_ID = 'list';

//--------------------
// Private constants
//--------------------
const _QUERY_SEARCH = 'search';

const _QUERY_SEARCH_FILTERS = {
  sort: {
    filterName: 'orderBy',
    filterValue: {
      v: 'view',
      n: 'date',
    },
  },
  date: {
    filterName: 'videoage',
    filterValue: {
      w: 'week',
      m: 'month',
    },
  },
  duration: {
    filterName: 'duration',
    filterValue: {
      s: 'short',
      m: 'median',
      l: 'long',
    },
  },
};

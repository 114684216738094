import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_SEARCH,
} from 'misc/appConstants';
import {
  goTo,
} from 'misc/appHelperFunctions';
import {
  searchInputToQuery,
} from 'misc/queryHelpers';
import {
  useImportConstants,
} from 'misc/dataConstants/dataConstantsHelpers';
import AutoSuggest from 'components/AutoSuggest';
import InputBox from 'components/InputBox';
// Third party.
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

const _SEARCH_PLACEHOLDER = 'Search pornstars...';
const _BUTTON_TITLE_SEARCH = 'Search';

function SearchBox(props) {
  const history = useHistory();

  const availableKeywordsList = useImportConstants("ACTOR_NAMES");

  const [targetKeyword, setTargetKeyword] = useState('');
  const [highlightedCandidateKeyword, setHighlightedCandidateKeyword] = useState('');

  function handleSearch(searchString) {
    const rawKeyword = searchString || '';

    // Reset search input and results if target keyword is empty.
    if (rawKeyword) {
      const searchQuery = searchInputToQuery({
        keyword: rawKeyword,
      });
      goTo(history, P_SEARCH + searchQuery);
    }

    // Hide AutoSuggest.
    // When user taps on or search the same keyword as the current one,
    // auto suggest still needs to be dismissed.
    setTargetKeyword('');

    analyticsLogEvent('pornstars-search=' + searchString);
  }

  function handleSubmit(inputString) {
    handleSearch(highlightedCandidateKeyword || inputString);

    const eventName = highlightedCandidateKeyword
        ? ('pornstars-search-submit-suggested=' + highlightedCandidateKeyword)
        : ('pornstars-search-submit-original=' + inputString);
    analyticsLogEvent(eventName);
  }

  function handleClickCandidateTag(tag) {
    handleSearch(tag);

    analyticsLogEvent('pornstars-search-click-suggested=' + tag);
  }

  return (
    <div className="w-100 position-relative">
      <InputBox
        initialValue=""
        placeholder={_SEARCH_PLACEHOLDER}
        buttonTitle={_BUTTON_TITLE_SEARCH}
        hideCancelButton
        submitOnClick={handleSubmit}
        onChange={setTargetKeyword}
      />

      <AutoSuggest
        className="position-absolute mt-1"
        targetTag={targetKeyword}
        candidateTags={availableKeywordsList}
        onClickCandidateTag={handleClickCandidateTag}
        onHighlightedCandidateTagChange={setHighlightedCandidateKeyword}
        enableKeyEvents={true}
      />
    </div>
  );
}

function ToolbarPornstars(props) {
  return (
    <Container
      className="mw-md min-h-round-button-size"
    >
      <SearchBox {...props} />
    </Container>
  );
}

export default ToolbarPornstars;

import SignUpButton from 'components/buttons/SignUpButton';
import SectionWrapper from './SectionWrapper';
// Third party.
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Content(props) {
  const { contentDescription } = props;

  if (!contentDescription) {
    return null;
  }

  if (Array.isArray(contentDescription)) {
    const lines = contentDescription.map((line) => (
      <p key={line} className="mt-3 mb-0">{ "• " + line }</p>
    ));

    return (
      <div className="mt-3">
        { lines }
      </div>
    );
  }

  return (
    <p className="mt-3">{ contentDescription }</p>
  );
}

function InfoSection(props) {
  const {
    contentTitle,
    contentDescription,
    imageSrc,
    imageAlt,
    children,
    className="",
    isReverseOrder,
    showSignUpButton,
    buttonTitle="Sign Up for Free",
    eventNameForClickSignUp,
  } = props;

  const textOrder = isReverseOrder ? 12 : 1;
  const imageOrder = isReverseOrder ? 1 : 12;

  return (
    <SectionWrapper className={className} innerClassName="p-0">
      <Row className="no-gutters">
        <Col
          sm={{ order: textOrder }}
          className="d-flex flex-column justify-content-center px-4 my-4"
        >
          { children
            ? children
            : <div className="my-4">
                <h2>{ contentTitle }</h2>
                <Content contentDescription={contentDescription} />
                { showSignUpButton &&
                  <SignUpButton
                    title={buttonTitle}
                    className="mt-4 mobile-block"
                    size="lg"
                    variant="primary"
                    clickEventName={eventNameForClickSignUp}
                  />
                }
              </div>
          }
        </Col>
        <Col
          sm={{ order: imageOrder }}
          className="d-flex flex-column justify-content-center px-4 desktop-my-4 mobile-mt-3 mobile-mb-5"
        >
          <div>
            <img
              src={imageSrc}
              alt={imageAlt || contentTitle}
              className="w-100 h-100 img-cover"
            />
          </div>
        </Col>
      </Row>
    </SectionWrapper>
  );
}

export default InfoSection;

// Third party.
import React, { useEffect, useState } from 'react';

function PreviewImages(props) {
  const [visibleImageIndex, setVisibleImageIndex] = useState(0);
  const { previewImageUrls=[] } = props;
  const imagesCnt = previewImageUrls.length;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleImageIndex((index) => {
        let nextIndex = index + 1;
        if (nextIndex >= imagesCnt) {
          nextIndex = 0;
        }
        return nextIndex;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [imagesCnt, setVisibleImageIndex]);

  return (
    <img
      src={previewImageUrls[visibleImageIndex]}
      alt="Preview"
      className="center-absolute-self w-100 h-100 rounded-2 img-cover"
    />
  );
}

function Preview(props) {
  const {
    canShowPreview,
    showPreview,
    previewVideoUrl,
    previewImageUrls=[],
    previewVideoHasError,
    handlePreviewVideoError,
  } = props;

  if (!canShowPreview || !showPreview) {
    return null;
  }

  if (previewVideoUrl && !previewVideoHasError) {
    return (
      <video
        src={previewVideoUrl}
        playsInline="playsinline"
        autoPlay="autoplay"
        muted="muted"
        loop="loop"
        className="center-absolute-self w-100 h-100 rounded-2 img-cover"
        onError={handlePreviewVideoError}
      />
    );
  }

  if (previewImageUrls.length) {
    return (
      <PreviewImages previewImageUrls={previewImageUrls} />
    );
  }

  return null;
}

export default Preview;

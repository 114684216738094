export const modalClose = () => {
  return  (dispatch, getState) => {
    dispatch(modalShowDeleteItem(false));
    dispatch(modalShowTagsEditor(false));
    dispatch(modalShowSaveItem(false));
    dispatch(modalShowVideoPlayer(false));
    dispatch(modalShowLoginRequest(false));
    dispatch(modalShowDetailsEditor(false));
    dispatch(modalShowPriceEditor(false));
  };
};

export const modalShowDeleteItem = (show, itemId, redirectPath) => ({
  type: 'MODAL_SHOW_DELETE_ITEM',
  show,
  itemId,
  redirectPath,
});

export const modalShowTagsEditor = (show, itemId) => ({
  type: 'MODAL_SHOW_TAGS_EDITOR',
  show,
  itemId,
});

export const modalShowSaveItem = (show, urlInfo) => ({
  type: 'MODAL_SHOW_SAVE_ITEM',
  show,
  urlInfo,
});

export const modalShowVideoPlayer = (show, urlInfo) => ({
  type: 'MODAL_SHOW_VIDEO_PLAYER',
  show,
  urlInfo,
});

export const modalShowLoginRequest = (show) => ({
  type: 'MODAL_SHOW_LOGIN_REQUEST',
  show,
});

export const modalShowDetailsEditor = (show, itemId) => ({
  type: 'MODAL_SHOW_DETAILS_EDITOR',
  show,
  itemId,
});

  export const modalShowPriceEditor = (show, itemId) => ({
  type: 'MODAL_SHOW_PRICE_EDITOR',
  show,
  itemId,
});

import {
  IconPlus,
} from 'components/icons';
import InputBox from 'components/InputBox';
// Third party.
import React from 'react';

function CardSummary() {
  return (
    <div className="mw-sm mx-auto">
      <h5 className="text-dark py-4 text-display-line-break">
        You can always save more videos by tapping <IconPlus size={1} className="icon-black-50 mx-1 mt-n0p5"/>, or paste any external video links to save them.
      </h5>

      <InputBox
        placeholder={'Save a URL https://...'}
        autoFocus={false}
        autoComplete="off"
        buttonTitle={'Save'}
        disabled={true}
      />

      <h5 className="text-dark py-5 text-display-line-break">
        Remember that the more videos you save, the better recommendations you will get.
      </h5>
    </div>
  );
}

export default CardSummary;

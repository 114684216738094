import {
  isCollectionFilterActive,
} from 'misc/appHelperFunctions';
import {
  DEFAULT_COLLECTION_FILTER,
} from '../reducers/stateConstants';

import {
  adminSetIsAdmin,
} from './admin';
import {
  collectionSetPrivateCollections,
  collectionUpdateCurrentCollectionInfo,
  collectionUpdateCurrentItemId,
  collectionUpdateFilter,
} from './collection';
import {
  navbarHideInputBox,
  navbarUpdateSaveBox,
  navbarUpdateSearchBox,
} from './navbar';
import {
  positionsUpdate,
} from './positions';
import {
  userUpdateCurrentUser,
} from './user';

/*
 * Update collection filter.
 * Scroll to top if filter is active.
 * Show / hide search input box based on filter active status.
 */
export const combinedUpdateCollectionFilter = (filter) => {
  return (dispatch, getState) => {
    // Scroll to top if the collection filter is updated to an active value.
    if (isCollectionFilterActive(filter)) {
      window.scrollTo(0, 0);

      // Show search box.
      dispatch(navbarUpdateSearchBox(true));
      // Hide save box in case it's currently enabled.
      dispatch(navbarUpdateSaveBox(false));
    } else {
      // Hide search box.
      dispatch(navbarUpdateSearchBox(false));
    }

    dispatch(collectionUpdateFilter(filter));
  }
};

/*
 * Reset filter, set filter tags, hide navbar input box.
 */
export const combinedSetCollectionFilterToTags = (tags) => {
  return (dispatch, getState) => {
    // Reset filter, set filter tags.
    if (tags) {
      const nextFilter = {
        ...DEFAULT_COLLECTION_FILTER,
        tags,
      }
      dispatch(combinedUpdateCollectionFilter(nextFilter));
    }
  }
};

export const combinedClearCollection = () => {
  return (dispatch, getState) => {
    dispatch(collectionUpdateCurrentCollectionInfo(null));
    dispatch(collectionUpdateCurrentItemId(null));
    dispatch(combinedUpdateCollectionFilter(null));
    dispatch(navbarHideInputBox());
    dispatch(positionsUpdate('collection', 0));
  };
};

export const combinedLogOut = () => {
  return (dispatch, getState) => {
    dispatch(userUpdateCurrentUser());
    dispatch(combinedClearCollection());
    dispatch(collectionSetPrivateCollections(null));
    // Always clear isAdmin flag on log out.
    dispatch(adminSetIsAdmin(false));
  };
};

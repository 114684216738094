import { analyticsLogEvent } from 'appFirebase/helperApi';
import { apiUpdateFeedVideoClickCnt } from 'appFirebase/helperApi';
import {
  canShowVideoPlayer,
  openUrlInNewTab,
} from 'misc/appHelperFunctions';
import {
  searchInputToQuery,
} from 'misc/queryHelpers';
import {
  IconArrowRight,
} from 'components/icons';
import ThumbnailImage from 'components/thumbnailImage';
// Third party.
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function Item(props) {
  const {
    urlInfo,
    shouldUpdateClickCnt,
    activePreviewVideoItemUrl,
    setActivePreviewVideoItemUrl,
    actionModalShowVideoPlayer,
    actionApiUpdateUserPreferredCategories,
  } = props;

  function onClickThumbnail(urlInfo) {

    const canShowVideoPlayerModal =
      canShowVideoPlayer(urlInfo) && actionModalShowVideoPlayer;

    if (canShowVideoPlayerModal) {
      actionModalShowVideoPlayer(true, urlInfo);
    } else {
      const {url} = urlInfo || {};
      openUrlInNewTab(url, 'home-click-latest_video_in_new_tab');
    }

    if (actionApiUpdateUserPreferredCategories) {
      actionApiUpdateUserPreferredCategories(urlInfo.keywords, /*incAmount*/1);
    }
  }

  function updateClickCnt() {
    if (shouldUpdateClickCnt) {
      apiUpdateFeedVideoClickCnt(urlInfo);
    }
  }

  function handleClickThumbnail() {
    if (onClickThumbnail) {
      onClickThumbnail(urlInfo);
    }

    updateClickCnt();
    analyticsLogEvent('home-click-latest_videos');
  }

  return (
    <div
      className="mr-2 cursor-pointer mobile-mb-2 desktop-mb-4"
      style={{width: '220px'}}
      onClick={handleClickThumbnail}
    >
      <ThumbnailImage
        urlInfo={urlInfo}
        isHD={true}
        activePreviewVideoItemUrl={activePreviewVideoItemUrl}
        setActivePreviewVideoItemUrl={setActivePreviewVideoItemUrl}
      />
      <p className="line-clamp-2 text-primary">
        {urlInfo.title}
      </p>
    </div>
  );
}

function LatestVideos(props) {
  const [activePreviewVideoItemUrl, setActivePreviewVideoItemUrl] = useState(null);

  const {
    videoPeeks,
    actionApiGetLatestVideos,
    actionModalShowVideoPlayer,
    actionApiUpdateUserPreferredCategories,
  } = props;

  const {
    latestVideos,
  } = videoPeeks;

  let searchQuery = searchInputToQuery({
    keyword: null,
    filters: {
      orderBy: 'date',
    }
  });

  useEffect(() => {
    if (!latestVideos || latestVideos.length === 0) {
      actionApiGetLatestVideos();
    }
  }, [latestVideos, actionApiGetLatestVideos]);

  function handleOnClick() {
    analyticsLogEvent('home-click-latest_videos_see_more');
  }

  let i = 0;
  const items = latestVideos.map((item) => {
    return (
      <div key={i++}>
      <Item
        {...props}
        key={i++}
        urlInfo={item}
        shouldUpdateClickCnt={true}
        activePreviewVideoItemUrl={activePreviewVideoItemUrl}
        setActivePreviewVideoItemUrl={setActivePreviewVideoItemUrl}
        actionModalShowVideoPlayer={actionModalShowVideoPlayer}
        actionApiUpdateUserPreferredCategories={actionApiUpdateUserPreferredCategories}
      />
      </div>
    );
  });

  return (
    <div className="mt-4 mb-0">
      <div className="d-flex justify-content-between align-items-center">
        <h5>Newest Free Porn Videos</h5>
        <Link
          to={searchQuery}
          onClick={handleOnClick}
          className="no-hover-effect"
        >
          <h6>
            View More <IconArrowRight size={1} className="flex-shrink-0 icon-primary" />
          </h6>
        </Link>
      </div>
      <div className="d-flex flex-nowrap overflow-auto hide-scrollbar">
        { items }
      </div>
    </div>
  );
}

export default LatestVideos;

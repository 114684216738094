import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  IconCopy,
} from 'components/icons';
import ToastAutoHide from 'components/toastAutoHide';
import TooltipContainer from 'components/TooltipContainer';
// Third party.
import React, { useRef } from 'react';

const _COPY_CONFIRMATION = 'Link copied';

function CopyButton(props) {
  const textAreaRef = useRef(null);
  const toastRef = useRef(null);

  const {
    className,
    text,
    iconSize=1.5,
    clickEventName="click_copy_button",
    tooltipContent="Copy",
    hoverEventName="hover_copy_button",
    onCopy,
  } = props;

  function copyTextToClipboard() {
    textAreaRef.current.select();
    document.execCommand("copy");

    if (onCopy) {
      onCopy();
    } else if (toastRef && toastRef.current) {
      toastRef.current.show(_COPY_CONFIRMATION);
    }

    analyticsLogEvent(clickEventName);
  }

  return (
    <TooltipContainer content={tooltipContent} hoverEventName={hoverEventName}>
      <div
        className={"flex-shrink-0 round-button-container " + className}
        onClick={copyTextToClipboard}
      >
        <IconCopy size={iconSize}/>

        <textarea
          ref={textAreaRef}
          value={text}
          readOnly
          // Note that display: none cannot be used here. Else
          // document.execCommand("copy") will not have any valid copied text.
          className="fake-hidden"
        />

        <ToastAutoHide
          ref={toastRef}
        />
      </div>
    </TooltipContainer>
  );
}

export default CopyButton;

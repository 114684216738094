const tags = (state = {
  filter: '',
}, action) => {
  switch (action.type) {
    case 'TAGS_UPDATE_FILTER': {
      const { filter } = action;

      return {
        ...state,
        filter: (filter || ''),
      };
    }
    default:
      return state
  }
};

export default tags;

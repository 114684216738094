import Delay from 'components/Delay';
import { IconLoading } from 'components/icons';
// Third party.
import React from 'react';

function LoadingIndicator(props) {
  const { delayInSeconds, className } = props;

  return (
    <Delay delayInSeconds={delayInSeconds}>
      <p className={"m-0 text-center " + className}>
        <IconLoading
          size={3}
          className="spin-icon"
          pathClassName="icon-primary"
        />
      </p>
    </Delay>
  );
}

export default LoadingIndicator;

import PageFooter from 'components/PageFooter';
import Stars from './Stars';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function Pornstars(props) {
  return (
    <div className="page-container">
      <Container className="px-0 mw-md pt-4">
        <h4 className="text-center">Hot Pornstars This Week</h4>
        <Stars />
        <PageFooter />
      </Container>
    </div>
  );
}

export default Pornstars;

import {
  ONBOARDNIG_URL_INFO,
} from 'misc/appConstants';
import {
  IconPlus,
} from 'components/icons';
import VideosGrid from 'components/videosGrid';
// Third party.
import React from 'react';

function CardSaveVideos(props) {
  const {
    minRequiredSavedVideos=3,
    // Redux states
    collection,
    recommendation,
    // Redux actions
    fetchRelatedVideos,
  } = props;
  const currentCollectionInfo = collection.currentCollectionInfo || {};
  const collectionName = currentCollectionInfo.name
      ? ('"' + currentCollectionInfo.name + '"')
      : 'your first playlist';

  const isFetchingVideos =
      recommendation.isFetchingUrlRelatedVideos[ONBOARDNIG_URL_INFO.url];
  const videos = recommendation.urlRelatedVideos[ONBOARDNIG_URL_INFO.url];

  function fetchVideos() {
    if (fetchRelatedVideos) {
      fetchRelatedVideos(ONBOARDNIG_URL_INFO);
    }
  }

  return (
    <div>
      <div className="mw-sm mx-auto">
        <h2 className="text-dark py-3">
          Now tap <IconPlus size={1} className="icon-black-50 mt-n1 mx-1"/> to save {minRequiredSavedVideos} (or more!) videos you like to { collectionName }
        </h2>
      </div>

      <VideosGrid
        eventPrefix="onboarding-save-videos"
        fetchVideos={fetchVideos}
        isFetchingVideos={isFetchingVideos}
        videos={videos}
        toastPositionClassName="bottom-120px left-30px"
      />
    </div>
  );
}

export default CardSaveVideos;

import {
  redirectTo,
} from 'misc/appHelperFunctions';
// Third party.
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const _CONFIRM_DELETE =
    'Are you sure you want to delete this item? This cannot be undone.';
const _BUTTON_TITLE_CANCEL = 'Cancel';
const _BUTTON_TITLE_DELETE = 'Delete';

function DeleteItem(props) {
  const history = useHistory();

  function handleClose() {
    const { closeModal } = props;

    if (closeModal) {
      closeModal();
    }
  }

  function handleDelete() {
    const { deleteCollectionItem } = props;
    const { itemId, redirectPath } = props.modal.deleteItem;

    if (deleteCollectionItem) {
      deleteCollectionItem(itemId);

      if (redirectPath && redirectPath.length > 0) {
        redirectTo(history, redirectPath);
      }
    }

    handleClose();
  }

  return (
    <div className="p-3">
      <h5>Delete item</h5>

      <p className="text-black-50">
        { _CONFIRM_DELETE }
      </p>

      <div className="d-flex justify-content-end">
        <Button variant='light' onClick={handleClose}>
          { _BUTTON_TITLE_CANCEL }
        </Button>

        <Button variant='info' className="ml-2" onClick={handleDelete}>
          { _BUTTON_TITLE_DELETE }
        </Button>
      </div>
    </div>
  );
}

export default DeleteItem;

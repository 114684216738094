import {
  ARROW_DOWN_KEY_CODE,
  ARROW_UP_KEY_CODE,
} from 'misc/appConstants';

// Third party.
import React, { useEffect, useState } from 'react';

const _MAX_AUTO_SUGGEST_NUM = 8;
const _DEFAULT_SELECTED_INDEX = -1;

function getFilteredCandidateTags(targetTag, candidateTags) {
  let filteredCandidateTags = [];
  if (targetTag && candidateTags && candidateTags.length) {
    const lowerCasetargetTag = targetTag.toLowerCase();
    filteredCandidateTags = candidateTags.filter((tag) => {
      return tag.includes(lowerCasetargetTag);
    });
    if (filteredCandidateTags.length > _MAX_AUTO_SUGGEST_NUM) {
      filteredCandidateTags = filteredCandidateTags.slice(0, _MAX_AUTO_SUGGEST_NUM);
    }
  }
  return filteredCandidateTags;
}

function TagItem(props) {
  const { tag, onClickCandidateTag, isSelected } = props;

  if (!tag) {
    return null;
  }

  function handleClick() {
    if (onClickCandidateTag) {
      onClickCandidateTag(tag);
    }
  }

  const className = "m-0 py-1 px-3 cursor-pointer bg-light-hover " +
      (isSelected ? "bg-grey" : "");

  return (
    <p className={className} onClick={handleClick}>
      {tag}
    </p>
  );
}

function AutoSuggest(props) {
  const [selectedIndex, setSelectedIndex] = useState(_DEFAULT_SELECTED_INDEX);
  const [filteredCandidateTags, setFilteredCandidateTags] = useState([]);

  const {
    targetTag,
    candidateTags,
    onHighlightedCandidateTagChange,
    enableKeyEvents,
  } = props;

  // Update autoSuggestSelectedTag whenever selectedIndex changes.
  useEffect(() => {
    if (!onHighlightedCandidateTagChange) {
      return;
    }

    if (selectedIndex >= 0 && selectedIndex < filteredCandidateTags.length) {
      onHighlightedCandidateTagChange(filteredCandidateTags[selectedIndex]);
    } else {
      onHighlightedCandidateTagChange(null);
    }
  }, [selectedIndex, filteredCandidateTags, onHighlightedCandidateTagChange]);

  // whenever targetTag changes, clear selectedIndex and recalculate
  // filtered list.
  useEffect(() => {
    setSelectedIndex(_DEFAULT_SELECTED_INDEX);
    setFilteredCandidateTags(getFilteredCandidateTags(targetTag, candidateTags));
  }, [targetTag, candidateTags, setSelectedIndex, setFilteredCandidateTags]);

  // Respond to arrow up and arrow down key events.
  useEffect(() => {
    function HandleKeyDown(event) {
      // enableKeyEvents is expected to be "input focused".
      // Only handle key events if input is focused and filteredCandidateTags has tags.
      if (!enableKeyEvents || !filteredCandidateTags.length) {
        return;
      }

      if (event.keyCode === ARROW_DOWN_KEY_CODE) {
        setSelectedIndex((index) => {
          let nextIndex = (index < 0) ? 0 : (index + 1);
          nextIndex %= filteredCandidateTags.length;
          return nextIndex;
        });
      } else if (event.keyCode === ARROW_UP_KEY_CODE) {
        setSelectedIndex((index) => {
          let nextIndex = (index < 0)
              ? (filteredCandidateTags.length - 1)
              : (index - 1);
          if (nextIndex < 0) {
            nextIndex += filteredCandidateTags.length
          }
          return nextIndex;
        });
      }
    }

    // Listen to key events.
    window.addEventListener('keydown', HandleKeyDown);

    return () => {
      window.removeEventListener('keydown', HandleKeyDown);
    };
  }, [enableKeyEvents, filteredCandidateTags, setSelectedIndex]);

  if (!filteredCandidateTags || !filteredCandidateTags.length) {
    return null;
  }

  const { className = "", onClickCandidateTag } = props;

  const tagItems = filteredCandidateTags.map((tag, index) => {
    return (
      <TagItem
        key={tag}
        tag={tag}
        onClickCandidateTag={onClickCandidateTag}
        isSelected={(index === selectedIndex)}
      />
    );
  });

  return (
    <div className={"bg-white border w-100 overflow-auto " + className}>
      <div className="py-2">
        {tagItems}
      </div>
    </div>
  );
}

export default AutoSuggest;

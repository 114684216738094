import {
  areArraysEqual,
  plusItemTags,
  minusItemTags,
  filterCollection,
  secureUrlInfo,
} from 'misc/appHelperFunctions';

import {
  DEFAULT_COLLECTION_FILTER,
} from './stateConstants';

const collection = (state = {
  // Current user's all private collections information.
  isFetchingPrivateCollections: false,
  privateCollections: {
    /**
     * $collectionId: {
     *   id: string,         // collectionId
     *   name: string,
     *   createdTimestampMs: number,
     * }
     */
  },
  // For current collection.
  isFetchingCurrentCollectionInfo: false,
  isFetchingItems: false,
  /*
   * `currentCollectionInfo = null` indicates there's no collection to display.
   * `id == null` indicates the collection is empty and does not exist in
   * database yet.
   */
  currentCollectionInfo: null,  // { id, name }
  /*
   * `currentItemId = null` indicates there's no item to display in vmark page.
   */
  currentItemId: null,          // $itemId
  // Items information. It may contain items outside the CURRENT collection.
  items: {
    /*
     * $itemId: {
     *   urlInfo: {
     *     id: string,
     *     url: string,
     *     domain: string,
     *     absoluteUrl: string,
     *     title: string,
     *     description: string,
     *     isFamilyFriendly: boolean,
     *     video : {
     *       embedUrl: string,
     *       contentUrl: string,
     *       width: number,
     *       height: number,
     *       durationInSec: number,
     *       uploadDate: string,
     *       genre: string,
     *       tags: [],
     *     },
     *     thumbnail: {
     *       url: string,
     *       width: number,
     *       height: number,
     *     },
     *   //--------------------
     *   // Optional properties.
     *   //--------------------
     *   tags: [],
     * }
     */
  },
  // Item ids in the collection.
  itemIds: {
    /*
     * $collectionId: [$itemId],
     */
  },
  tags: {
    /*
     * $collectionId: {
     *   [$tag]: number,  // itemCount.
     * },
     */
  },
  filter: DEFAULT_COLLECTION_FILTER,
  // Filtered results for the CURRENT collection.
  filteredCurrentCollection: {
    itemIds: [],          // [$itemId] Filtered itemIds.
    tags: {               // Available tags in filtered items.
      /*
       * [$tag]: number,  // itemCount.
       */
    },
  },
}, action) => {
  switch (action.type) {
    case 'COLLECTION_SET_IS_FETCHING_PRIVATE_COLLECTIONS': {
      const { isFetching } = action;

      return {
        ...state,
        isFetchingPrivateCollections: isFetching,
      };
    }
    case 'COLLECTION_SET_PRIVATE_COLLECTIONS': {
      const { privateCollections } = action;

      const nextPrivateCollections = privateCollections || [];

      return {
        ...state,
        isFetchingPrivateCollections: false,
        privateCollections: nextPrivateCollections,
      };
    }
    case 'COLLECTION_ADD_ITEM': {
      const { collectionId, itemId, itemData } = action;
      if (!collectionId || !itemId || !itemData) {
        return state;
      }

      const nextItems = {
        ...state.items,
        [itemId]: itemData,
      };

      const nextCollectionItemIds = [itemId, ...(state.itemIds[collectionId] || [])];
      const nextItemIds = {
        ...state.itemIds,
        [collectionId]: nextCollectionItemIds,
      };

      // Add tags count from the next item.
      const nextCollectionTags = plusItemTags(state.tags[collectionId], itemData);
      const nextTags = {
        ...state.tags,
        [collectionId]: nextCollectionTags,
      };

      const nextState = {
        ...state,
        items: nextItems,
        itemIds: nextItemIds,
        tags: nextTags,
      };

      if (collectionId === (state.currentCollectionInfo || {}).id) {
        nextState.filteredCurrentCollection = filterCollection(
          collectionId,
          nextItems,
          nextItemIds,
          nextTags,
          state.filter,
        );
      }

      return nextState;
    }
    case 'COLLECTION_BRING_ITEM_TO_FRONT': {
      const { collectionId, itemId, createdTimestampMs } = action;
      if (!collectionId || !itemId || !createdTimestampMs) {
        return state;
      }

      // Check if itemId exists in current collectionItemIds.
      const currentCollectionItemIds = state.itemIds[collectionId] || [];
      const foundItemId = currentCollectionItemIds.includes(itemId);
      if (!foundItemId) {
        return state;
      }

      // Bring itemId to the first in itemIds.
      const nextCollectionItemIds =
          [itemId, ...(currentCollectionItemIds.filter(id => (id !== itemId)))];
      const nextItemIds = {
        ...state.itemIds,
        [collectionId]: nextCollectionItemIds,
      };

      const nextState = {
        ...state,
        itemIds: nextItemIds,
      };

      if (collectionId === (state.currentCollectionInfo || {}).id) {
        nextState.filteredCurrentCollection = filterCollection(
          collectionId,
          state.items,
          nextItemIds,
          state.tags,
          state.filter,
        );
      }

      return nextState;
    }
    case 'COLLECTION_DELETE_ITEM': {
      const { collectionId, itemId } = action;
      if (!collectionId || !itemId) {
        return state;
      }

      const nextCurrentItemId =
          (state.currentItemId === itemId) ? null : state.currentItemId;

      const nextItems = { ...state.items };
      delete nextItems[itemId];

      const nextCollectionItemIds =
          (state.itemIds[collectionId] || []).filter((id) => {
            return (id !== itemId);
          });
      const nextItemIds = {
        ...state.itemIds,
        [collectionId]: nextCollectionItemIds,
      }

      const nextCollectionTags =
          minusItemTags(state.tags[collectionId], state.items[itemId]);
      const nextTags = {
        ...state.tags,
        [collectionId]: nextCollectionTags,
      };

      const nextState = {
        ...state,
        currentItemId: nextCurrentItemId,
        items: nextItems,
        itemIds: nextItemIds,
        tags: nextTags,
      };

      if (collectionId === (state.currentCollectionInfo || {}).id) {
        nextState.filteredCurrentCollection = filterCollection(
          collectionId,
          nextItems,
          nextItemIds,
          nextTags,
          state.filter,
        );
      }

      return nextState;
    }
    case 'COLLECTION_SET_IS_FETCHING_CURRENT_COLLECTION_INFO': {
      const { isFetching } = action;

      return {
        ...state,
        isFetchingCurrentCollectionInfo: isFetching,
      };
    }
    case 'COLLECTION_SET_IS_FETCHING_ITEMS': {
      const { isFetching } = action;

      return {
        ...state,
        isFetchingItems: isFetching,
      };
    }
    case 'COLLECTION_UPDATE_CURRENT_COLLECTION_INFO': {
      const { info } = action;

      const nextCurrentCollectionInfo = info ? { ...info } : null;

      const nextfilteredCurrentCollection = filterCollection(
        (nextCurrentCollectionInfo || {}).id,
        state.items,
        state.itemIds,
        state.tags,
        state.filter,
      );

      return {
        ...state,
        currentCollectionInfo: nextCurrentCollectionInfo,
        filteredCurrentCollection: nextfilteredCurrentCollection,
        isFetchingCurrentCollectionInfo: false,
      };
    }
    case 'COLLECTION_UPDATE_CURRENT_ITEM_ID': {
      const { itemId } = action;

      return {
        ...state,
        currentItemId: itemId,
      };
    }
    case 'COLLECTION_UPDATE_FILTER': {
      const { filter } = action;

      const nextFilter = filter ? { ...filter } : DEFAULT_COLLECTION_FILTER;
      if (nextFilter.tags) {
        nextFilter.tags.sort();
      }

      const nextfilteredCurrentCollection = filterCollection(
        (state.currentCollectionInfo || {}).id,
        state.items,
        state.itemIds,
        state.tags,
        nextFilter,
      );

      return {
        ...state,
        filter: nextFilter,
        filteredCurrentCollection: nextfilteredCurrentCollection,
      };
    }
    case 'COLLECTION_UPDATE_ITEMS': {
      const { collectionId, items } = action;

      // Extract collection itemIds and tags.
      const itemIds = [];
      let tags = {};
      for (let keyItemId in items) {
        itemIds.push(keyItemId);
        const itemData = items[keyItemId];
        if (itemData && itemData.urlInfo) {
          itemData.urlInfo = secureUrlInfo(itemData.urlInfo);
        }
        tags = plusItemTags(tags, itemData);
      }

      const nextItems = {
        ...state.items,
        ...items,
      };

      const nextItemIds = {
        ...state.itemIds,
        [collectionId]: itemIds
      };

      const nextTags = {
        ...state.tags,
        [collectionId]: tags,
      };

      const nextState = {
        ...state,
        items: nextItems,
        itemIds: nextItemIds,
        tags: nextTags,
        isFetchingItems: false,
      };

      if (collectionId === (state.currentCollectionInfo || {}).id) {
        nextState.filteredCurrentCollection = filterCollection(
          collectionId,
          nextItems,
          nextItemIds,
          nextTags,
          state.filter,
        );
      }

      return nextState;
    }
    case 'COLLECTION_UPDATE_ITEM_TAGS': {
      const { collectionId, itemId, tags } = action;

      if (!collectionId || !itemId
          || areArraysEqual(tags, (state.items[itemId] || {}).tags)) {
        return state;
      }

      const nextItem = {
        ...state.items[itemId],
        tags,
      };
      const nextItems = {
        ...state.items,
        [itemId]: nextItem,
      };

      // Remove tags count from the existing item.
      let nextCollectionTags =
          minusItemTags(state.tags[collectionId], state.items[itemId]);
      // Add tags count from the next item.
      nextCollectionTags = plusItemTags(nextCollectionTags, nextItem);
      const nextTags = {
        ...state.tags,
        [collectionId]: nextCollectionTags,
      };

      const nextState = {
        ...state,
        items: nextItems,
        tags: nextTags,
      };

      if (collectionId === (state.currentCollectionInfo || {}).id) {
        nextState.filteredCurrentCollection = filterCollection(
          collectionId,
          nextItems,
          state.itemIds,
          nextTags,
          state.filter,
        );
      }

      return nextState;
    }
    case 'COLLECTION_UPDATE_ITEM_DETAILS': {
      const { collectionId, itemId, customizedTitle, customizedDescription } = action;

      if (!collectionId || !itemId
        || (customizedTitle === (state.items[itemId] || {}).urlInfo.customizedTitle
          && customizedDescription === (state.items[itemId] || {}).urlInfo.customizedDescription)) {
        return state;
      }

      const nextUrlInfo = {
        ...state.items[itemId].urlInfo,
        customizedTitle,
        customizedDescription,
      };

      const nextItem = {
        ...state.items[itemId],
        urlInfo: nextUrlInfo,
      };

      const nextItems = {
        ...state.items,
        [itemId]: nextItem,
      };

      const nextState = {
        ...state,
        items: nextItems,
      };

      if (collectionId === (state.currentCollectionInfo || {}).id) {
        nextState.filteredCurrentCollection = filterCollection(
          collectionId,
          nextItems,
          state.itemIds,
          state.tags,
          state.filter,
        );
      }

      return nextState;
    }
    case 'COLLECTION_UPDATE_ITEM_PRICE': {
      const { collectionId, itemId, price } = action;

      if (!collectionId || !itemId
        || (price === (state.items[itemId] || {}).urlInfo.price)) {
        return state;
      }

      const nextUrlInfo = {
        ...state.items[itemId].urlInfo,
        price,
      };

      const nextItem = {
        ...state.items[itemId],
        urlInfo: nextUrlInfo,
      };

      const nextItems = {
        ...state.items,
        [itemId]: nextItem,
      };

      const nextState = {
        ...state,
        items: nextItems,
      };

      if (collectionId === (state.currentCollectionInfo || {}).id) {
        nextState.filteredCurrentCollection = filterCollection(
          collectionId,
          nextItems,
          state.itemIds,
          state.tags,
          state.filter,
        );
      }

      return nextState;
    }
    case 'COLLECTION_UPDATE_ITEM_URL_INFO': {
      const { collectionId, itemId, urlInfo } = action;

      if (!collectionId || !itemId || !urlInfo) {
        return state;
      }

      const nextItem = {
        ...state.items[itemId],
        urlInfo: secureUrlInfo(urlInfo),
      };
      const nextItems = {
        ...state.items,
        [itemId]: nextItem,
      };

      const nextState = {
        ...state,
        items: nextItems,
      };

      if (collectionId === (state.currentCollectionInfo || {}).id) {
        nextState.filteredCurrentCollection = filterCollection(
          collectionId,
          nextItems,
          state.itemIds,
          state.tags,
          state.filter,
        );
      }

      return nextState;
    }
    case 'COLLECTION_UPDATE_PRIVATE_COLLECTION_INFO': {
      const { info } = action;
      if (!info || !info.id) {
        return state;
      }

      const collectionId = info.id;

      const nextPrivateCollections = { ...state.privateCollections };
      if (nextPrivateCollections[collectionId]) {
        nextPrivateCollections[collectionId] = {
          ...nextPrivateCollections[collectionId],
          ...info,
        };
      }

      const nextState = {
        ...state,
        privateCollections: nextPrivateCollections,
      };

      if (collectionId === (state.currentCollectionInfo || {}).id) {
        nextState.currentCollectionInfo = {
          ...state.currentCollectionInfo,
          ...info,
        };
      }

      return nextState;
    }
    default:
      return state
  }
};

export default collection;

// the value from combineReducers.
import rootReducer from '../reducers';
// Third party.
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

//export const store = createStore(rootReducer, applyMiddleware(thunk));

// Begin Redux Persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['persist'] // only persist states will be persisted.
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)
// End Redux Persist

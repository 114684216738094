import { P_COLLECTION } from 'misc/appConstants';
import BackButton from 'components/buttons/BackButton';
import DeleteButton from 'components/buttons/DeleteButton';
import EditTagsButton from 'components/buttons/EditTagsButton';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function ToolbarVmark(props) {
  function showModalTagsEditor() {
    props.showModalTagsEditor &&
        props.showModalTagsEditor(true, props.collectionCurrentItemId);
  }

  function showModalDeleteItem() {
    // Redirect to P_COLLECTION after deletion.
    props.showModalDeleteItem &&
        props.showModalDeleteItem(true, props.collectionCurrentItemId, P_COLLECTION);
  }

  return (
    <Container className="d-flex justify-content-between min-h-round-button-size mw-md">
      <BackButton
        className="ml-n2"
        clickEventName="vmark_click_back_button"
        tooltipContent="Back to collection"
        hoverEventName="vmark_hover_back_button"
      />

      <div>
        <EditTagsButton
          onClick={showModalTagsEditor}
          clickEventName="vmark_click_edit_tags_button"
          hoverEventName="vmark_hover_edit_tags_button"
        />
        <DeleteButton
          onClick={showModalDeleteItem}
          clickEventName="vmark_click_delete_button"
          hoverEventName="vmark_hover_delete_button"
        />
      </div>
    </Container>
  );
}

export default ToolbarVmark;

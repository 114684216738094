import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_COLLECTION,
} from 'misc/appConstants';
import ThumbnailMultipleImages from 'components/ThumbnailMultipleImages';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';

function TagPreview(props) {
  const {
    tag,
    imageUrls,
    marksCount,
    setCollectionFilterToTags,
  } = props;

  const marksCountSummary = (marksCount > 1)
    ? (marksCount + ' videos')
    : (marksCount + ' video');

  function handleClick() {
    // Reset filter, set filter tags, hide navbar input box.
    if (setCollectionFilterToTags) {
      setCollectionFilterToTags([tag]);
    }
    analyticsLogEvent('collection_tags_click_tag_item');
  }

  return (
    <div className="py-4">
      <Link
        to={P_COLLECTION}
        className="no-hover-effect cursor-pointer"
        onClick={handleClick}
      >
        <ThumbnailMultipleImages
          className="my-3"
          imageUrls={imageUrls}
          maxFallbackColoredItems={marksCount}
        />

        <h5 className="text-dont-break-out">{ tag }</h5>

        <span className={"text-truncate text-black-50"}>
          { marksCountSummary }
        </span>
      </Link>
    </div>
  );
}

export default TagPreview;

import { analytics, auth, db, firestore } from 'appFirebase';
import {
  DISABLE_LOG_EVENT_ACCOUNTS,
} from 'misc/appConstants';

export function analyticsLogEvent(eventName, eventParams, options) {
  // Excludes some accounts from events logging.
  if (auth.currentUser &&
      auth.currentUser.email &&
      DISABLE_LOG_EVENT_ACCOUNTS[auth.currentUser.email]
  ) {
    return;
  }
  analytics.logEvent(eventName, eventParams, options);
}

// TODO: move this logic to server side.
export async function apiUpdateFeedVideoClickCnt(urlInfo) {
  const urlId = generateUrlId((urlInfo || {}).url);

  if (!urlId) {
    return;
  }

  await db.collection('urlCollection').doc(urlId)
      .update({
        baseClickCnt: firestore.FieldValue.increment(1),
        clickCnt: firestore.FieldValue.increment(1),
      })
      .catch(err => {
        console.log('Error updating view counts: ', err);
      });
}

//--------------------
// Helper functions
//--------------------
export function generateUrlId(url) {
  if (!url) {
    return null;
  }

  url = url.replace(/\//g, '');
  url = url.replace(/\./g, '');

  return url;
};

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  IconLogo,
} from 'components/icons';
import TooltipContainer from 'components/TooltipContainer';
// Third party.
import React from 'react';

function SavedButton(props) {
  const {
    className="",
    iconClassName="flex-shrink-0 icon-primary",
    iconSize=1.45,
    onClick,
    clickEventName="click_Saved_button",
    tooltipContent="Saved",
    hoverEventName="hover_Saved_button",
  } = props;

  function handleClick() {
    analyticsLogEvent(clickEventName);

    if (onClick) {
      onClick();
    }
  }

  return (
    <TooltipContainer
      placement="bottom"
      content={tooltipContent}
      hoverEventName={hoverEventName}
    >
      <div className={"round-button-container " + className} onClick={handleClick}>
        <IconLogo size={iconSize} className={iconClassName} />
      </div>
    </TooltipContainer>
  );
}

export default SavedButton;

import {
  IconX,
} from 'components/icons';
// Third party.
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

const _BUTTON_TITLE = 'Save';

function Header(props) {
  const { handleClose } = props;

  return (
    <div className="d-flex align-items-center justify-content-between my-1">
      <h5 className="mb-0">Set Price</h5>

      <div className="round-button-container mr-n2" onClick={handleClose}>
        <IconX size={1.5} />
      </div>
    </div>
  );
}

function PriceEditor(props) {
  const { itemId } = props.modal.priceEditor;

  const itemData = props.collection.items[itemId] || {};
  const [price, setPrice] = useState(itemData.urlInfo.price);

  function handlePriceInputChange(event) {
    const nextValue = event.target.value;

    setPrice(nextValue);
  }

  function handleClose() {
    const { closeModal } = props;

    if (closeModal) {
      closeModal();
    }
  }

  function handleSave() {
    const { updateCollectionItemPrice } = props;

    handleClose();

    if (updateCollectionItemPrice) {
      updateCollectionItemPrice(itemId, price);
    }
  }

  return (
    <div className="px-3 pb-3">
      <Header handleClose={handleClose} />
      <div className="mt-3">
        <h6 className="mb-1 text-black-50">Price</h6>s
        <select className="rounded w-100 form-control" onChange={handlePriceInputChange}>
          <option value={price}>{price} (current)</option>
          <option value="free">free</option>
          <option value="premium">premium</option>
        </select>
      </div>

      <Button variant='primary' block className="mt-3" onClick={handleSave}>
        { _BUTTON_TITLE }
      </Button>
    </div>
  );
}

export default PriceEditor;

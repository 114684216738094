import SignUpButton from 'components/buttons/SignUpButton';
import {
  IconExplore,
  IconSearch,
  IconStar,
} from 'components/icons';
// Third party.
import React from 'react';
import Container from 'react-bootstrap/Container';

function IconText(props) {
  const { className="", iconType, text } = props;
  const iconClassName = "icon-white mr-3";

  let icon;
  switch(iconType) {
    case 'star':
      icon = (<IconStar size={1.4} className={iconClassName} />);
      break;
    case 'explore':
      icon = (<IconExplore size={1.4} className={iconClassName} />);
      break;
    case 'search':
      icon = (<IconSearch size={1.35} className={iconClassName} />);
      break;
    default:
      break;
  }

  return (
    <div className={"d-flex align-items-center py-2 " + className}>
      <h5>
        { icon }
      </h5>
      <h5 className="text-white">
        { text }
      </h5>
    </div>
  );
}

function BannerContent(props) {
  return (
    <Container fluid className="px-0 d-flex justify-content-center align-items-center mobile-pb-4">
      <div className="w-100">
        <h1 className="text-white pb-4">
          Your Private Porn Playlists.
        </h1>

        <IconText
          iconType="star"
          text="Save porn videos from anywhere."
        />
        <IconText
          iconType="explore"
          text="Explore the best porn for you."
        />
        <IconText
          iconType="search"
          text="Search everything quickly."
        />

        <SignUpButton
          title="Sign Up for Free"
          className="text-primary mt-4 mobile-block"
          size="lg"
          variant="light"
          clickEventName={props.eventNameForClickSignUp}
        />
      </div>
    </Container>
  );
}

export default BannerContent;

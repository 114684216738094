import { auth, db } from 'appFirebase';
import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  ADMIN_ACCOUNTS,
} from 'misc/appConstants';
import {
  adminSetIsAdmin,
} from '../admin';
import {
  userUpdateCurrentUser,
} from '../user';
import {
  persistSetFeedPreferenceCategories,
} from '../persist';
import {
  apiFetchPrivateCollections,
} from './collectionApi';

export const apiPostSignUp = () => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser) {
      return;
    }

    // Send email verifycation.
    auth.currentUser.sendEmailVerification()
        .catch((error) => {
          analyticsLogEvent('exception', { description: ('[sendEmailVerification]: ' + error.toString()) });
          console.log(error.message);
        });

    // Add account information in the database.
    db.collection('users').doc(auth.currentUser.uid)
      .set({ createdTimestampMs: new Date().getTime() }, { merge: true })
      .catch((error) => {
        analyticsLogEvent('exception', { description: ('[add account info]: ' + error.toString()) });
        console.error("Error adding account information: ", error)
      });

    // Set isOnboarding flag to true to trigger onboarding flow.
    // dispatch(userSetIsOnboarding(true));
  };
};

export const apiPostLogIn = () => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser) {
      return;
    }

    // const { displayName, email, emailVerified, photoURL, isAnonymous, uid, providerData} = user;
    dispatch(userUpdateCurrentUser(auth.currentUser));

    // Fetch user's private collections.
    dispatch(apiFetchPrivateCollections());

    // Flip isAdmin flag for admin accounts.
    if (ADMIN_ACCOUNTS[auth.currentUser.email]) {
      dispatch(adminSetIsAdmin(true));
    } else {
      dispatch(adminSetIsAdmin(false));
    }

    // Record the user's last log in time in the database.
    db.collection('users').doc(auth.currentUser.uid)
      .set({ lastLogInTimestampMs: new Date().getTime() }, { merge: true })
      .catch((error) => {
        analyticsLogEvent('exception', { description: ('[last log in time]: ' + error.toString()) });
        console.error("Error adding last log in time information: ", error)
      });

    // Fetch the user preferred categories from the database on login.
    db.collection('users').doc(auth.currentUser.uid)
      .get()
      .then((querySnapshot) => {
        const userData = querySnapshot.data() || {};
        const { preferredCategories } = userData;
        if (preferredCategories) {
          // Update the persisted redux state.
          dispatch(persistSetFeedPreferenceCategories(preferredCategories));
        }
      })
      .catch((error) => {
        analyticsLogEvent('exception', { description: ('[Get user preferred categories]: ' + error.toString()) });
        console.error("Error fetching user preferred categories: ", error)
      });
  };
};

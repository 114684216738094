import {
  APP_NAME,
  P_PRIVACY,
  P_TERMS,
} from 'misc/appConstants';
import ButtonWithLoadingState from 'components/buttons/ButtonWithLoadingState';
// Third party.
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';

const _LOG_IN_TITLE = 'Log In';
const _SIGN_UP_TITLE = 'Sign Up';

const _EMAIL = 'email';
const _EMAIL_PLACEHOLDER = 'Email';
const _PASSWORD = 'password';
const _PASSWORD_PLACEHOLDER = 'Password';

const _BUTTON_TITLE_LOG_IN = 'Log In';
const _BUTTON_TITLE_SIGN_UP = 'Sign Up';

const _HINT_GO_TO_RESET_PASSWORD = 'Forgot your password?';
const _HINT_GO_TO_LOG_IN = 'Already a member?';
const _HINT_GO_TO_SIGN_UP = 'Not on ' + APP_NAME + ' yet?';

function TermsAndPrivacy() {
  const Terms = (
    <Link to={P_TERMS} target="_blank" className="no-hover-effect">
      <u className="text-dark">Terms of Service</u>
    </Link>
  );

  const Privacy = (
    <Link to={P_PRIVACY} target="_blank" className="no-hover-effect">
      <u className="text-dark">Privacy Policy</u>
    </Link>
  );

  return (
    <Form.Text className='text-center text-black-50'>
      By continuing, you agree to our { Terms } and { Privacy }.
    </Form.Text>
  );
}

function Footer(props) {
  function toggleIsLogIn() {
    if (props.toggleIsLogIn) {
      props.toggleIsLogIn();
    }
  }

  return (
    <div>
      <hr className="my-5" />

      <div className="d-flex justify-content-center">
        <h6 className="text-black-50 mr-2">
          {props.isLogIn ? _HINT_GO_TO_SIGN_UP : _HINT_GO_TO_LOG_IN}
        </h6>
        <h6
          className="text-dark cursor-pointer form-bottom-link"
          onClick={toggleIsLogIn}
        >
          {props.isLogIn ? _BUTTON_TITLE_SIGN_UP : _BUTTON_TITLE_LOG_IN}
        </h6>
      </div>
    </div>
  );
}

function EmailAndPasswordForm(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { isLoading } = props;

  function handleChange(event) {
    // Do not change if it's already loading.
    if (isLoading) {
      return;
    }

    switch (event.target.name) {
      case _EMAIL:
        setEmail(event.target.value);
        break;
      case _PASSWORD:
        setPassword(event.target.value);
        break;
      default:
        console.log('Input name is invalid.');
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    // Do not submit again if it's already loading.
    if (isLoading) {
      return;
    }

    if (props.handleSubmit) {
      props.handleSubmit({ email, password });
    }
  }

  return (
    <div>
      <h2 className="text-dark text-center mb-4">
        { props.isLogIn ? _LOG_IN_TITLE : _SIGN_UP_TITLE }
      </h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId='formBasicEmail'>
          <Form.Control
            type={_EMAIL}
            placeholder={_EMAIL_PLACEHOLDER}
            name={_EMAIL}
            value={email}
            onChange={handleChange}
            autoFocus={true}
          />
        </Form.Group>

        <Form.Group controlId='formBasicPassword'>
          <Form.Control
            type={_PASSWORD}
            placeholder={_PASSWORD_PLACEHOLDER}
            name={_PASSWORD}
            value={password}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId='formBasicSubmitButton'>
          <ButtonWithLoadingState
            variant='primary'
            type='submit'
            block
            isLoading={isLoading}
          >
            { props.isLogIn ? _BUTTON_TITLE_LOG_IN : _BUTTON_TITLE_SIGN_UP }
          </ButtonWithLoadingState>
        </Form.Group>

        {
          props.isLogIn
          ? <Form.Text className='text-black-50 text-center cursor-pointer' onClick={props.enableForgotPassword}>
              <u>{_HINT_GO_TO_RESET_PASSWORD}</u>
            </Form.Text>
          : <TermsAndPrivacy />
        }
      </Form>

      <Footer {...props} />
    </div>
  );
}

export default EmailAndPasswordForm;

import {
  getDomain,
  openUrlInNewTab,
} from 'misc/appHelperFunctions';
import {
  useImportConstants,
} from 'misc/dataConstants/dataConstantsHelpers';
// Third party.
import React from 'react';

function Item(props) {
  const { rank } = props;
  const { url, description } = props.site;
  const domain = getDomain(url);

  function handleClick() {
    openUrlInNewTab(url, 'sites-click-' + domain);
  }

  const faviconUrl = 'https://www.google.com/s2/favicons?domain=' + domain;

  return (
    <div className="d-flex px-3 py-2 cursor-pointer bg-light-hover" onClick={handleClick}>
      <p className="mb-0 mr-2">{rank}.</p>
      <div>
        <p className="m-0">
          <img src={faviconUrl} alt="" className="mr-2" />
          { description || domain }
        </p>
      </div>
      <p className="mb-0 mr-2 transparent">{rank}.</p>
    </div>
  );
}

function BestSites(props) {
  const bestSites = useImportConstants("BEST_SITES");

  let i = 0;
  const items = bestSites.map((item) => {
    const { title, sites } = item;

    let j = 1;
    const subItems = sites.map((site) => {
      return (
        <Item
          key={i++}
          {...props}
          site={site}
          rank={j++}
        />
      );
    });

    return (
      <div key={i++} className="bg-light mb-5">
        <div className="text-center bg-primary py-2">
          <h5 className="mb-0">{ title }</h5>
        </div>
        <div className="overflow-auto">
          { subItems }
        </div>
      {/*
        <div className="bg-secondary py-2">
          <h5 className="mb-0">See All Sites</h5>
        </div>
      */}
      </div>
    );
  });

  return items;
}

export default BestSites;

import {
  getRandomBackgroundColorClassName,
} from 'misc/appHelperFunctions';
// Third party.
import React, { useEffect, useState } from 'react';

const _NUM_COL = 12;
const _ITEM_W_RATIO = (1 / _NUM_COL);
const _ITEM_W_5 = _ITEM_W_RATIO * 500 + '%';
const _ITEM_W_7 = _ITEM_W_RATIO * 700 + '%';
const _NUM_ITEMS = 3;

function SafeImage(props) {
  const [imgSrc, setImgSrc] = useState(null);
  const [bgColorClassName, setBgColorClassName] = useState("bg-secondary");

  const {
    src,
    backupSrcs,
    containerClassName = "",
    bodyClassName = "",
    width,
  } = props;

  const imgClassName = "w-100 h-100 img-cover";

  useEffect(() => {
    setImgSrc(src);
  }, [src])

  function handleLoad(event) {
    event.target.className = imgClassName;
  }

  function handleError(event) {
    if (backupSrcs.length) {
      setImgSrc(backupSrcs.pop());
    } else {
      event.target.className += ' transparent';
      // If image cannot be loaded successfully, use a colored background
      // to indicate an item exists.
      setBgColorClassName(getRandomBackgroundColorClassName(imgSrc));
    }
  }

  return (
    <div
      className={"d-inline-block align-top " + containerClassName}
      style={{ width }}
    >
      <div className={bgColorClassName + " h-100 " + bodyClassName}>
      {
        imgSrc &&
        <img
          src={imgSrc}
          alt=""
          className={imgClassName}
          onLoad={handleLoad}
          onError={handleError}
        />
      }
      </div>
    </div>
  );
}

function ThreeImages(props) {
  const { imageUrls, maxFallbackColoredItems } = props;
  const urlCount = imageUrls ? imageUrls.length : 0;

  const srcs = [];
  for (let i = 0; i < _NUM_ITEMS; i++) {
    const imageUrl = (i < urlCount)
        ? imageUrls[i]
        // Use a colored background to indicate an item exists if no
        // thumbnail is available for the item.
        //
        // e.g. `maxFallbackColoredItems` is expected to be the total number of
        // vmarks included in a tag, if ThumbnailMultipleImages is used for
        // display multiple thumbnail images for a tag.
        //
        // Use a semi-random seed string to generate fallback background color.
        // `i < maxFallbackColoredItems` will happen if some vmarks does not
        // have thumbnail, and total_imageUrls_for_tag < _NUM_ITEMS.
        : ((i < maxFallbackColoredItems) ? ((i + maxFallbackColoredItems).toString()) : null);
    srcs.push(imageUrl);
  }
  const backupSrcs = [];
  for (let i = _NUM_ITEMS; i < urlCount; i++) {
    backupSrcs.push(imageUrls[i]);
  }

  return (
    <div className="h-100 position-relative">
      <SafeImage
        src={srcs[0]}
        backupSrcs={backupSrcs}
        width={_ITEM_W_7}
        containerClassName="h-50 position-absolute top-0 left-0"
      />

      <SafeImage
        src={srcs[1]}
        backupSrcs={backupSrcs}
        width={_ITEM_W_7}
        containerClassName="h-50 position-absolute bottom-0 left-0"
        bodyClassName="mt-0p5"
      />

      <SafeImage
        src={srcs[2]}
        backupSrcs={backupSrcs}
        width={_ITEM_W_5}
        containerClassName="h-100 position-absolute top-0 right-0"
        bodyClassName="ml-0p5"
      />
    </div>
  );
}

function ThumbnailMultipleImages(props) {
  const { className = "" } = props;

  return (
    <div className={"embed-responsive embed-responsive-16by9 rounded-2 " + className}>
      <div className="embed-responsive-item">
        <ThreeImages {...props} />
      </div>
    </div>
  );
}

export default ThumbnailMultipleImages;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_COLLECTION,
} from 'misc/appConstants';
import {
  getSortedPrivateCollections,
} from 'misc/appHelperFunctions';
import LoadingIndicator from 'components/LoadingIndicator';
import PageFooter from 'components/PageFooter';
import CreateCollectionButton from 'components/buttons/createCollectionButton';
import CollectionPreview from './CollectionPreview';
// Third party.
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

function CollectionList(props) {
  const [collectionItems, setCollectionItems] = useState([]);

  const { fetchPrivateCollections } = props;
  const privateCollections = props.collection.privateCollections;

  useEffect(() => {
    analyticsLogEvent('screen_view', { screen_name: 'my_private_playlists' });
    fetchPrivateCollections();
  }, [fetchPrivateCollections]);

  useEffect(() => {
    // Sort by collection name alphabetically.
    const sortedPrivateCollections = getSortedPrivateCollections(privateCollections);
    setCollectionItems(sortedPrivateCollections);
  }, [privateCollections]);

  if (props.collection.isFetchingPrivateCollections) {
    return (
      <LoadingIndicator delayInSeconds={1} className="mt-5" />
    );
  }

  if (props.collection.currentCollectionInfo) {
    return (
      <Redirect push to={P_COLLECTION} />
    );
  }

  function handleClickCollection(collectionInfo) {
    // Do not fetch again if it's already loading.
    if (props.collection.isFetchingCurrentCollectionInfo) {
      return;
    }

    // Clear collection's scroll position. Although it's already done in
    // combinedClearCollection, this one is still neccessary because
    // combinedClearCollection is called before collection's last scroll position
    // is recored in collectionContent/Header.
    props.actionPositionsUpdateCollection(0);

    if (props.fetchCollection) {
      props.fetchCollection(collectionInfo);
    }
  }

  const privateCollectionItems = collectionItems
      .map((collectionInfo) => {
        return (
          <div key={collectionInfo.id} className="mb-4">
            <CollectionPreview
              collectionInfo={collectionInfo}
              handleClickCollection={handleClickCollection}
            />
          </div>
        );
      });

  return (
    <div>
      <Container className="pt-5 page-container mw-sm">

        { privateCollectionItems }

        <CreateCollectionButton
          createPrivateCollection={props.createPrivateCollection}
        />
      </Container>

      <PageFooter />
    </div>
  );
}

export default CollectionList;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  searchInputToQuery,
} from 'misc/queryHelpers';
import {
  useImportConstants,
} from 'misc/dataConstants/dataConstantsHelpers';
import {randomlyPickFromList} from "misc/appHelperFunctions";
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';

const _NON_BREAKING_SPACE = "\u00a0";
const _CHANGE_FREQUENCY = 4;
const _SKIP_INTERVAL = 2;
const _RESULT_COUNT = 13;

function Item(props) {
  const { thumbnail, keyword } = props.item;

  const searchQuery = searchInputToQuery({
    keyword,
  });

  const displayName = keyword.replace(' ', _NON_BREAKING_SPACE);

  function handleOnClick() {
    analyticsLogEvent('home-click-popular_stars');
  }

  return (
    <Link
      to={searchQuery}
      className="flex-shrink-0 text-center cursor-pointer mr-2 no-hover-effect"
      style={{ width: '100px' }}
      onClick={handleOnClick}
    >
      <div
        className="bg-secondary thumbnail-image-container-100 rounded-corner"
        style={{ backgroundImage:`url("${thumbnail}")` }}
      />
      <p className="mt-1 mb-0 text-primary line-clamp-1">
        { displayName }
      </p>
    </Link>
  );
}

function Stars(props) {
  const popularPornstars = useImportConstants("POPULAR_PORNSTARS");

  let pickedPornstars = randomlyPickFromList(
    popularPornstars,
    _CHANGE_FREQUENCY,
    _SKIP_INTERVAL,
    _RESULT_COUNT
  );

  let i = 0;
  const items = pickedPornstars.map((item) => {
    i++;

    return (
      <Item key={i} item={item} {...props} />
    );
  });

  return (
    <div className="mt-3">
      <h5>Popular Pornstars This Week</h5>
      <div className="d-flex flex-nowrap overflow-auto hide-scrollbar">
        { items }
      </div>
    </div>
  );
}

export default Stars;

import { auth, db, functions } from 'appFirebase';
import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  AVAIABLE_RECOMMEND_KEYWORDS,
  FEED_CATEGORIES,
} from 'misc/appConstants';
import {
  recommendationClearIsLoadingFeed,
  recommendationUpdateIsFetchingUrlRelatedVideos,
  recommendationUpdateUrlRelatedVideos,
  recommendationSetIsFetchingFeedVideos,
  recommendationSetIsLoadingMoreFeedVideos,
  recommendationSetFeedVideos,
} from '../recommendation';
import {
  persistSetFeedPreferenceCategories,
  persistSetLastFeedTimestampMs,
} from '../persist';
import {
  feedCategoriesToSortedArray,
  isTouchDevice,
  secureUrlInfo,
} from 'misc/appHelperFunctions';

/*
 * Fetch related videos to urlInfo.
 */
export const apiFetchRelatedVideos = (urlInfo) => {
  return async (dispatch, getState) => {
    if (!auth || !auth.currentUser ||
        !urlInfo || !urlInfo.url) {
      return;
    }

    // Do not fetch again if it is actively fetching or already fetched.
    if (getState().recommendation.isFetchingUrlRelatedVideos[urlInfo.url] ||
        getState().recommendation.urlRelatedVideos[urlInfo.url]) {
      return;
    }

    // Show loading sign to indicate that it is actively fetching related videos.
    dispatch(recommendationUpdateIsFetchingUrlRelatedVideos(urlInfo.url, true));

    // Call cloud function to get recommendated related videos.
    const recommendVideos = functions.httpsCallable('recommendVideos');
    // Get response.
    const res = await recommendVideos({
          // Nonnull.
          urlInfo,
        })
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error("Error fetching related videos: ", error)
          // Hide the loading indicator.
          dispatch(recommendationUpdateIsFetchingUrlRelatedVideos(urlInfo.url, false));
        });

    // Update UI to display the recommended videos.
    if (res && res.videos) {
      dispatch(recommendationUpdateUrlRelatedVideos(urlInfo.url, res.videos));
    }
  };
};

/*
 * Fetch feed videos.
 */
export const apiGetFeedVideos = (isRefresh) => {
  return async (dispatch, getState) => {
    // Do not fetch again if it is actively fetching.
    if (getState().recommendation.isFetchingFeedVideos) {
      return;
    }

    // Show loading sign to indicate that it is actively fetching related videos.
    if (isRefresh) {
      dispatch(recommendationSetIsFetchingFeedVideos(true));
    } else {
      dispatch(recommendationSetIsLoadingMoreFeedVideos(true));
    }

    let lastFeedTimestampMs;
    if (auth && auth.currentUser) {
      lastFeedTimestampMs = getState().persist.lastFeedTimestampMs.logInUser;
      // Try to get the lastFeedTimestampMs for the login user if not done yet.
      if (lastFeedTimestampMs === 0) {
        await db
            .collection('users').doc(auth.currentUser.uid)
            .get()
            .then((doc) => {
              const userInfo = doc.data() || {};
              const userLastFeedTimestampMs = userInfo.lastFeedTimestampMs;
              if (userLastFeedTimestampMs) {
                lastFeedTimestampMs = userLastFeedTimestampMs;
              }
            })
            .catch((error) => {
              console.error("Error fetching user info: ", error);
            });
      }
    } else {
      lastFeedTimestampMs = getState().persist.lastFeedTimestampMs.guestUser;
    }
    if (lastFeedTimestampMs === 0) {
      // Get videos from a random lastTimestampMs.
      lastFeedTimestampMs = -1;
    }

    const userPreferredCategories =
        feedCategoriesToSortedArray(getState().persist.feedPreference.categories)
            .map((name) => {
              // Database only recognize lower case categories.
              return name.toLowerCase();
            })
            // Only use the top 5 preferred categories.
            .slice(0, 5);

    const enabledCategories = (userPreferredCategories.length >= 1)
        ? userPreferredCategories
        : [];

    // Compose feed keywords according to selected category.
    const selectedCategoryItem = FEED_CATEGORIES.find((categoryItem) => {
      return (categoryItem.name === getState().recommendation.selectedCategory);
    });
    const keywords = (selectedCategoryItem || {}).keywords || [];

    const isMobile = isTouchDevice(/*isMobileOS*/true);

    // Call cloud function to get recommendated videos.
    const getFeedVideos = functions.httpsCallable('getFeedVideos');
    // Get response.
    let hasError = false;
    const res = await getFeedVideos({
          enabledCategories,
          filters: {
            keywords,
            targetUser: 'asian',
            lastTimestampMs: lastFeedTimestampMs,
            isMobile,
          },
          count: 30,
          isMobile,
        })
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          console.error("Error fetching feed videos: ", error);
          hasError = true;
        });

    if (hasError) {
      // Hide the loading indicator.
      dispatch(recommendationClearIsLoadingFeed());
      return;
    }

    // Update UI to display the feed videos.
    // Do not update UI if the result is empty.
    if (res && res.videos) {
      const resultVideos = (res || {}).videos || [];
      for (let i = 0; i < resultVideos.length; i++) {
        resultVideos[i] = secureUrlInfo(resultVideos[i]);
      }
      const nextVideos = isRefresh
          ? resultVideos
          : getState().recommendation.feedVideos.concat(resultVideos);
      dispatch(recommendationSetFeedVideos(nextVideos));

      const nextLastFeedTimestampMs = {};
      if (auth && auth.currentUser) {
        nextLastFeedTimestampMs.logInUser = res.lastTimestamp;
      } else {
        nextLastFeedTimestampMs.guestUser = res.lastTimestamp;
      }
      dispatch(persistSetLastFeedTimestampMs(nextLastFeedTimestampMs));
    }
  };
};

/*
 * Update user preferred categories.
 */
export const apiUpdateUserPreferredCategories = (categoryList, incAmount) => {
  return async (dispatch, getState) => {
    if (!categoryList || !categoryList.length || !incAmount) {
      return;
    }

    // Compose the next preferredCategories.
    const nextPreferredCategories = {
      ...getState().persist.feedPreference.categories,
    };
    categoryList.forEach((category) => {
      if (AVAIABLE_RECOMMEND_KEYWORDS.includes(category)) {
        nextPreferredCategories[category] =
          (nextPreferredCategories[category] || 0) + incAmount;
      }
    });

    // Update the persisted redux state.
    dispatch(persistSetFeedPreferenceCategories(nextPreferredCategories));

    // Update the database for login users.
    if (!auth || !auth.currentUser) {
      return;
    }

    await db
      .collection('users').doc(auth.currentUser.uid)
      .set({ preferredCategories: nextPreferredCategories }, { merge: true })
      .catch((error) => {
        analyticsLogEvent('exception', { description: ('[update user preferred categories]: ' + error.toString()) });
        console.error("Error updating user preferred categories: ", error)
      });
  };
};
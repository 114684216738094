import { analyticsLogEvent } from 'appFirebase/helperApi';
// Third party.
import React from 'react';

function TagButton(props) {
  const { tag, clickEventName } = props;

  function handleClick() {
    if (props.handleClick) {
      // Reset filter, set filter tags, and hide navbar input box.
      props.handleClick(tag);
    }
    analyticsLogEvent(clickEventName || 'click_tag_button');
  }

  return (
    <span className="mr-2 mt-2 tag-light" onClick={handleClick}>
      {tag}
    </span>
  );
}

function Tags(props) {
  const { className, tags, handleClickTag, clickEventName } = props;
  if (!tags || tags.length === 0) {
    return null;
  }

  const tagBadges = tags.map((tag) => {
    return (
      <TagButton
        key={tag}
        tag={tag}
        handleClick={handleClickTag}
        clickEventName={clickEventName}
      />
    );
  });

  const finalClassName = "overflow-hidden" +
    (className ? (" " + className) : '');

  return (
    <div className={finalClassName}>
      <small>
        { tagBadges }
      </small>
    </div>
  );
}

export default Tags;

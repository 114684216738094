import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_FEED,
} from 'misc/appConstants';
import {
  IconHeart,
} from 'components/icons';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';

function FeedTab(props) {
  const {
    currentPathName,
    classNames,
    // Redux actions.
    actionApiGetFeedVideos,
  } = props;

  const isTargetPageCurrent = currentPathName.includes(P_FEED);

  const {
    inactiveIconClassName,
    activeIconClassName,
    inactiveTextClassName,
    activeTextClassName,
  } = classNames;

  const iconClassName =
      isTargetPageCurrent ? activeIconClassName : inactiveIconClassName;
  const textClassName =
      isTargetPageCurrent ? activeTextClassName : inactiveTextClassName;


  function handleClick() {
    analyticsLogEvent("bottom_navbar-click-for_you");

    // If already on the target page, scroll to top and refresh the feed content.
    if (isTargetPageCurrent) {
      window.scrollTo(0, 0);
      actionApiGetFeedVideos(/*isRefresh*/true);
    }
  }

  return (
    <div className="flex-grow-1 text-center">
      <Link
        to={P_FEED}
        className="no-hover-effect"
        onClick={handleClick}
      >
        <IconHeart size={1.2} className={iconClassName} />
        <p className={textClassName}>
          For You
        </p>
      </Link>
    </div>
  );
}

export default FeedTab;

import LazyContainer from 'components/LazyContainer';
// Third party.
import React from 'react';

function Help(props) {
  return (
    <LazyContainer importContent={() => import('./Content')} />
  );
}

export default Help;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import {
  P_SITES,
} from 'misc/appConstants';
import {
  IconWebsite,
} from 'components/icons';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';

function SitesTab(props) {
  const {
    currentPathName,
    classNames,
  } = props;

  const isTargetPageCurrent = currentPathName.includes(P_SITES);

  const {
    inactiveIconClassName,
    activeIconClassName,
    inactiveTextClassName,
    activeTextClassName,
  } = classNames;

  const iconClassName =
      isTargetPageCurrent ? activeIconClassName : inactiveIconClassName;
  const textClassName =
      isTargetPageCurrent ? activeTextClassName : inactiveTextClassName;

  function handleClick() {
    analyticsLogEvent("bottom_navbar-click-pornstars");
  }

  return (
    <div className="flex-grow-1 text-center">
      <Link
        to={P_SITES}
        className="no-hover-effect"
        onClick={handleClick}
      >
        <IconWebsite size={1.2} className={iconClassName} />
        <p className={textClassName}>
          Best Sites
        </p>
      </Link>
    </div>
  );
}

export default SitesTab;

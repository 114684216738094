// Third party.
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const _INPUT_NAME = 'input';
const _BUTTON_TITLE = 'Create';

function CreateCollectionForm(props) {
  const [name, setName] = useState('');

  const { onSubmit } = props;

  function handleChange(event) {
    switch (event.target.name) {
      case _INPUT_NAME:
        setName(event.target.value);
        break;
      default:
        console.log('Playlist name is invalid.');
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!name) {
      return;
    }

    if (onSubmit) {
      onSubmit(name);
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="form-inline w-100">
      <Form.Control
        type={'text'}
        placeholder={"Name your playlist"}
        name={_INPUT_NAME}
        value={name}
        onChange={handleChange}
        autoFocus={false}
        className="rounded w-100"
        autoComplete="off"
      />

      <Button variant='primary' block className="mt-3" type="submit" disabled={!name}>
        { _BUTTON_TITLE }
      </Button>
    </Form>
  );
}

export default CreateCollectionForm;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import { IconTrash } from 'components/icons';
import TooltipContainer from 'components/TooltipContainer';
// Third party.
import React from 'react';

function DeleteButton(props) {
  const {
    clickEventName="click_delete_button",
    tooltipContent="Delete",
    hoverEventName="hover_delete_button",
  } = props;

  function handleClick() {
    props.onClick && props.onClick();

    analyticsLogEvent(clickEventName);
  }

  return (
    <TooltipContainer content={tooltipContent} hoverEventName={hoverEventName}>
      <div className="round-button-container" onClick={handleClick}>
        <IconTrash size={1.5} />
      </div>
    </TooltipContainer>
  );
}

export default DeleteButton;

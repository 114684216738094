import { apiUpdateFeedVideoClickCnt } from 'appFirebase/helperApi';
import {
  abbreviateNumber,
  feedCategoriesToSortedArray,
} from 'misc/appHelperFunctions';
import {
  IconCheck,
} from 'components/icons';
import ThumbnailImage from 'components/thumbnailImage';
import SaveButton from 'components/buttons/SaveButton';
import AdminDropDownButton from 'components/admin/AdminDropDownButton';
// Third party.
import React from 'react';
import Col from 'react-bootstrap/Col';
import { AVAIABLE_RECOMMEND_KEYWORDS } from 'misc/appConstants';

const _NON_BREAKING_SPACE = "\u00a0";
const _MIN_STATS_TO_SHOW = 30;

function Stats(props) {
  const { clickCnt, savedCnt } = props;

  if (clickCnt < _MIN_STATS_TO_SHOW && savedCnt < _MIN_STATS_TO_SHOW) {
    return null;
  }

  const clickCntCopy = (clickCnt >= _MIN_STATS_TO_SHOW) ? (abbreviateNumber(clickCnt) + ' views') : '';
  const savedCntCopy = (savedCnt >= _MIN_STATS_TO_SHOW) ? (abbreviateNumber(savedCnt) + ' saved') : '';
  const spaces = (clickCntCopy && savedCntCopy) ? _NON_BREAKING_SPACE.repeat(3) : '';
  const statsCopy = clickCntCopy + spaces + savedCntCopy

  return (
    <small className="text-black-50 line-clamp-1 transparent-80">
      { statsCopy }
    </small>
  );
}

function SavedSign() {
  return (
    <div className="flex-shrink-0 bg-transparent round-container-md">
      <div className="bg-primary round-container-sm">
        <IconCheck size={0.8} className="icon-white" />
      </div>
    </div>
  );
}

function ItemSaveButton(props) {
  const {
    className,
    isSaved,
    handleClickSaveVideo,
    eventPrefix,
  } = props;

  if (isSaved) {
    return (<SavedSign />);
  }

  return (
    <SaveButton
      className={"flex-shrink-0 " + className}
      iconClassName="icon-black-50 mt-n0p5"
      iconSize={1}
      onClick={handleClickSaveVideo}
      clickEventName={eventPrefix + "_click_save_video_button"}
      hoverEventName={eventPrefix + "_hover_save_video_button"}
    />
  );
}

function VideoItem(props) {
  const {
    enableAdminButton,
    eventPrefix,
    onClickSaveVideo,
    onClickThumbnail,
    isSaved,
    shouldUpdateClickCnt,
    feedPreference,
    recommendation,
    // Preview control.
    activePreviewVideoItemUrl,
    setActivePreviewVideoItemUrl,
    actionSetRecommendKeywords,
    // Redux states.
  } = props;
  const urlInfo = props.urlInfo || {};
  const {
    title,
    customizedTitle,
    url,
    baseClickCnt=0,
    clickCnt=0,
    savedCnt=0,
    keywords,
  } = urlInfo;

  const { selectedCategory, recommendKeywords } = recommendation;

  function updateClickCnt() {
    if (shouldUpdateClickCnt) {
      apiUpdateFeedVideoClickCnt(urlInfo);
    }
  }

  function updateRecommendKeywords() {
    if (!keywords || keywords.length === 0) {
      return;
    }

    const newKeywords = [];
    for (let keyword of keywords) {
      if (AVAIABLE_RECOMMEND_KEYWORDS.includes(keyword) &&
        !recommendKeywords.includes(keyword)) {
        newKeywords.push(keyword);
      }
    }
    console.log(newKeywords);

    if (newKeywords.length === 0) {
      return;
    }

    const newRecommendKeywords =
      (recommendKeywords.includes(selectedCategory)) ?  [selectedCategory] : [];

    // Add keywords from most recent visited videos
    newRecommendKeywords.push(...newKeywords);

    // Add keywords from top viewed keywords
    const userPreferredKeywords =
      feedCategoriesToSortedArray(feedPreference.categories)
        .map((name) => {
          // Database only recognize lower case categories.
          return name.toLowerCase();
        })
        // Only use the top 5 preferred categories.
        .slice(0, 5);

    newRecommendKeywords.push(...userPreferredKeywords);

    const dedupRecommendKeywords = [...new Set(newRecommendKeywords)];

    actionSetRecommendKeywords(dedupRecommendKeywords);
  }

  function handleClickThumbnail() {
    if (onClickThumbnail) {
      onClickThumbnail(urlInfo);
    }

    updateClickCnt();
    updateRecommendKeywords();
  }

  function handleClickSaveVideo() {
    if (onClickSaveVideo) {
      onClickSaveVideo(urlInfo);
    }
  }

  return (
    <Col
      xs={12} sm={6} md={4} lg={3} xl={3}
      className="col-xxl-2d4 col-xxxl-2 col-xxxxl-1d5 mobile-px-gutter mb-3"
    >
      <div
        className="cursor-pointer"
        onClick={handleClickThumbnail}
      >
        <ThumbnailImage
          className="my-3"
          urlInfo={urlInfo}
          isHD
          activePreviewVideoItemUrl={activePreviewVideoItemUrl}
          setActivePreviewVideoItemUrl={setActivePreviewVideoItemUrl}
        />
      </div>

      <div className="d-flex justify-content-between align-items-start">
        <p className="mb-0 line-clamp-2">
          { customizedTitle || title || url }
        </p>

        <div className="my-n3">
          <ItemSaveButton
            className="mt-0p5"
            isSaved={isSaved}
            handleClickSaveVideo={handleClickSaveVideo}
            eventPrefix={eventPrefix}
          />
        </div>
      </div>

      <Stats
        clickCnt={ baseClickCnt }
        savedCnt={ clickCnt + savedCnt }
      />

      {
        enableAdminButton &&
        <div className="d-flex justify-content-end align-items-center mt-n2">
          <AdminDropDownButton urlInfo={urlInfo} />
        </div>
      }
    </Col>
  );
}

export default VideoItem;

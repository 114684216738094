import {
  ARROW_DOWN_KEY_CODE,
  ARROW_UP_KEY_CODE,
  BACK_SPACE_KEY_CODE,
  ENTER_KEY_CODE,
} from 'misc/appConstants';
import AutoSuggest from 'components/AutoSuggest';
import AutosizeInput from './AutosizeInput';
import Tags from './Tags';
// Third party.
import React, { useEffect, useRef, useState } from 'react';

const _TAG_WATCH_LATER = 'watch later';

function TagsInput(props) {
  const [isFocused, setIsFocused] = useState(false);
  const [sortedTagList, setSortedTagList] = useState([]);
  const [autoSuggestSelectedTag, setAutoSuggestSelectedTag] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    function HandleKeyDown(event) {
      // Only handle key events if input is focused without pending new tag.
      if (!isFocused) {
        return;
      }

      const { newTag, tags, setTags, tagSelected, setTagSelected } = props;

      if (!newTag.length && event.keyCode === BACK_SPACE_KEY_CODE) {
        const isAnyTagSelected = Object.values(tagSelected).includes(true);

        if (isAnyTagSelected) {
          // Remove all selected tags.
          const nextTags = tags.filter((tag) => {
            return !tagSelected[tag];
          });
          setTags(nextTags);
          // Clear tagSelected.
          setTagSelected({});
        } else {
          // Select the last tag.
          const lastTag = tags[tags.length - 1];
          const nextTagSelected = {
            ...tagSelected,
            [lastTag]: true,
          };
          setTagSelected(nextTagSelected);
        }
      } else if (!newTag.length && event.keyCode === ENTER_KEY_CODE) {
        if (props.handleSave) {
          // Explictly bluring the currently focused input.
          //
          // Safari will try to auto scroll to any input that is focused on.
          // If not explictly bluring the currently focused input, Safari will
          // scroll to the collection bottom after modal is dismissed because
          // the input is still focused in the shrinked modal at the bottom.
          inputRef && inputRef.current.blur();
          props.handleSave && props.handleSave();
        }
      } else {
        const isAnyTagSelected = Object.values(tagSelected).includes(true);
        if (isAnyTagSelected) {
          // Clear tagSelected.
          setTagSelected({});
        }
      }
    }

    // Listen to key events.
    window.addEventListener('keydown', HandleKeyDown);

    return () => {
      window.removeEventListener('keydown', HandleKeyDown);
    };
  }, [props, isFocused, inputRef]);

  const { collection } = props;
  useEffect(() => {
    const collectionId = (collection.currentCollectionInfo || {}).id;
    if (!collectionId) {
      return;
    }
    const collectionTags = {
      ...collection.tags[collectionId],
    };
    // Always include 'watch later' tag in auto suggested tags.
    if (!collectionTags[_TAG_WATCH_LATER]) {
      collectionTags[_TAG_WATCH_LATER] = 1;
    }

    // Sort array in descending order - more frequent tags come first.
    const tagList = (collectionId ? Object.keys(collectionTags) : [])
        .sort((a, b) => {
          return (collectionTags[b] || 0) - (collectionTags[a] || 0);
        });
    setSortedTagList(tagList);
  }, [collection, setSortedTagList]);

  const { newTag, setNewTag, addNewTagToTags } = props;

  function addNewTag(optNewTag) {
    if (addNewTagToTags) {
      // optNewTag always has the highest priority.
      // autoSuggestSelectedTag has the second heighest priority.
      addNewTagToTags(optNewTag || autoSuggestSelectedTag);
    }
  }

  function handleChange(event) {
    setNewTag(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    addNewTag();
  }

  function focusInput() {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  function onInputFocus() {
    setIsFocused(true);
  }

  function onInputBlur() {
    setIsFocused(false);
  }

  function handleKeyDown(event) {
    // Save arrow-up and arrow-down key events for AutoSuggest.
    if (event.keyCode === ARROW_DOWN_KEY_CODE ||
        event.keyCode === ARROW_UP_KEY_CODE) {
      event.preventDefault();
    }
  }

  function handleAutoSuggestClick(tag) {
    addNewTag(tag);
    focusInput();
  }

  return (
    <div className="position-relative">
      <form onSubmit={handleSubmit} className="border rounded input-group p-2">
        <Tags {...props} onClickTag={focusInput} />
        <AutosizeInput
          ref={inputRef}
          value={newTag}
          onChange={handleChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onKeyDown={handleKeyDown}
        />
      </form>

      <AutoSuggest
        className="position-absolute"
        targetTag={newTag}
        candidateTags={sortedTagList}
        onClickCandidateTag={handleAutoSuggestClick}
        onHighlightedCandidateTagChange={setAutoSuggestSelectedTag}
        enableKeyEvents={isFocused}
      />
    </div>
  );
}

export default TagsInput;

import {
  P_ACCOUNT,
  P_CONTACT,
//  P_HELP,
  P_HOME,
} from 'misc/appConstants';
import {
  delay,
  redirectTo,
} from 'misc/appHelperFunctions';
import {
//  IconArrowUpRight,
  IconThreeDots,
} from 'components/icons';
import { logOut } from 'components/auth/authHelperFunctions';
import CustomToggleButton from './CustomToggleButton';
// Third party.
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

function NavDropdownButton(props) {
  const history = useHistory();

  function handleClickLogOut() {
    logOut();

    // Note that this is a hacky solution to redirect to home page after user
    // logs out. It's waiting 0.1 second until redirecting, so that auth has
    // enough time to change.
    //
    // A more reliable solution is to redirct after auth changed
    // from valid to null. However, this requires `useAuthState` have access
    // to previous auth and history, which will introduce unnecessary engineering
    // complexity.
    delay(0.1).then(() => {
      redirectTo(history, P_HOME);
    });
  }

  const { className="" } = props;

  return (
    <Dropdown alignRight className={"clean-dropdown " + className}>
      <Dropdown.Toggle as={CustomToggleButton}>
        <IconThreeDots size={1.2} className="icon-black-50" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="right-2">
    {/*
        <Dropdown.Item as={Link} to={P_HELP} target="_blank">
          <div className="d-flex justify-content-between align-items-center">
            Help <IconArrowUpRight size={0.75} />
          </div>
        </Dropdown.Item>
    */}
        <Dropdown.Item as={Link} to={P_ACCOUNT}>Account</Dropdown.Item>
        <Dropdown.Item as={Link} to={P_CONTACT}>Contact</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleClickLogOut}>Log out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default NavDropdownButton;
// Third party.
import React, { useState, useEffect } from 'react';

function Delay(props) {
  const [hideContent, setHideContent] = useState(true);

  const { delayInSeconds } = props;
  useEffect(() => {
    let timeout = null;

    if (delayInSeconds > 0) {
      timeout = setTimeout(() => {
        setHideContent(false);
      }, delayInSeconds * 1000);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [delayInSeconds]);

  if ((delayInSeconds > 0) && hideContent) {
    return null;
  }

  return (
    <div>
      {props.children}
    </div>
  );
}

export default Delay;

import {
  IconX,
} from 'components/icons';
// Third party.
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const _BUTTON_TITLE = 'Save';

function Header(props) {
  const { handleClose } = props;

  return (
    <div className="d-flex align-items-center justify-content-between my-1">
      <h5 className="mb-0">Edit Details</h5>

      <div className="round-button-container mr-n2" onClick={handleClose}>
        <IconX size={1.5} />
      </div>
    </div>
  );
}

function DetailsEditor(props) {
  const { itemId } = props.modal.detailsEditor;

  const itemData = props.collection.items[itemId] || {};
  const [customizedTitle, setCustomizedTitle] = useState(itemData.urlInfo.customizedTitle);
  const [customizedDescription, setCustomizedDescription] = useState(itemData.urlInfo.customizedDescription);
  const [title] = useState(itemData.urlInfo.title);
  const [description] = useState(itemData.urlInfo.description);

  function handleTitleInputChange(event) {
    const nextValue = event.target.value;

    setCustomizedTitle(nextValue);
  }

  function handleDescriptionInputChange(event) {
    const nextValue = event.target.value;

    setCustomizedDescription(nextValue);
  }

  function handleClose() {
    const { closeModal } = props;

    if (closeModal) {
      closeModal();
    }
  }

  function handleSave() {
    const { updateCollectionItemDetails } = props;

    handleClose();

    if (updateCollectionItemDetails) {
      updateCollectionItemDetails(itemId, customizedTitle, customizedDescription);
    }
  }

  return (
    <div className="px-3 pb-3">
      <Header handleClose={handleClose} />
      <Form onSubmit={handleSave}>
        <Form.Group controlId='formTitle'>
          <h6 className="mb-1 text-black-50">Title</h6>
          <Form.Control
            type={'title'}
            placeholder={'Title'}
            name={'title'}
            value={customizedTitle || title}
            onChange={handleTitleInputChange}
            autoFocus={true}
          />
        </Form.Group>

        <Form.Group controlId='formDesc'>
          <h6 className="mb-1 text-black-50">Description</h6>
          <Form.Control
            type={'description'}
            placeholder={'Description'}
            name={'description'}
            value={customizedDescription || description}
            onChange={handleDescriptionInputChange}
            autoFocus={true}
          />
        </Form.Group>
      </Form>

      <Button variant='primary' block className="mt-3" onClick={handleSave}>
        { _BUTTON_TITLE }
      </Button>
    </div>
  );
}

export default DetailsEditor;

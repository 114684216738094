import {
  P_SEARCH,
} from 'misc/appConstants';
import {
  searchInputToQuery,
} from 'misc/queryHelpers';
import {
  useImportConstants,
} from 'misc/dataConstants/dataConstantsHelpers';
// Third party.
import React from 'react';
import { Link } from 'react-router-dom';

function Item(props) {
  const { rank } = props;
  const { thumbnail, keyword } = props.keyword;

  const searchQuery = searchInputToQuery({
    keyword,
  });

  const displayName = keyword;

  return (
    <Link
      to={P_SEARCH + searchQuery}
      className="flex-shrink-0 text-center cursor-pointer mr-2 no-hover-effect"
      style={{ width: '120px' }}
    >
      <div
        className="bg-secondary thumbnail-image-container-100 rounded-corner"
        style={{ backgroundImage:`url("${thumbnail}")`, backgroundPosition: 'center top' }}
      >
        <div className="position-absolute bottom-0 bg-primary round-container-sm">
          <span className="m-0 text-dark">{rank}</span>
        </div>
      </div>
      <p className="mt-1 mb-0 text-primary">
        { displayName }
      </p>
    </Link>
  );
}

function Stars(props) {
  const pornstars = useImportConstants("HOT_PORNSTARS");

  let i = 0;
  const items = pornstars.map((item) => {
    const { title, keywords } = item;

    let j = 1;
    const subItems = keywords.map((keyword) => {
      return (
        <Item
          {...props}
          key={i++}
          keyword={keyword}
          rank={j++}
        />
      );
    });

    return (
      <div key={i++} className="mb-5">
        <h5>{ title }</h5>
        <div className="d-flex flex-nowrap overflow-auto hide-scrollbar">
          { subItems }
        </div>
      </div>
    );
  });

  return items;
}

export default Stars;

import { analyticsLogEvent } from 'appFirebase/helperApi';
import BackButton from 'components/buttons/BackButton';
// Third party.
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';

const _PLACEHOLDER = 'Filter tags';

function InputBox(props) {
  const [inputString, setInputString] = useState('');

  function handleChange(event) {
    const nextValue = event.target.value.toLowerCase();

    if (!inputString && nextValue) {
      // Only log for the first character to avoid duplicate loggings.
      analyticsLogEvent('collection_tags_filter_tag_items');
    }

    setInputString(nextValue);

    if (props.updateTagsFilter) {
      props.updateTagsFilter(nextValue);
    }
  }

  return (
    <input
      type={'text'}
      placeholder={_PLACEHOLDER}
      value={inputString}
      onChange={handleChange}
      autoFocus={false}
      className="flex-1 ml-3 mr-4 form-control"
      autoComplete="off"
    />
  );
}

function ToolbarCollectionTags(props) {
  const { updateTagsFilter } = props;

  useEffect(() => {
    return () => {
      // Clear tags.filter when exits page.
      updateTagsFilter('');
    };
  }, [ updateTagsFilter ]);

  return (
    <Container className="px-0 min-h-round-button-size mw-md">
      <BackButton
        className="ml-n2"
        clickEventName="collection_tags_click_back_button"
        tooltipContent="Back to collection"
        hoverEventName="collection_tags_hover_back_button"
      />

      <InputBox updateTagsFilter={updateTagsFilter} />
    </Container>
  );
}

export default ToolbarCollectionTags;

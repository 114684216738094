export const persistSetLastFeedTimestampMs = (lastFeedTimestampMs) => ({
  type: 'PERSIST_SET_LAST_FEED_TIMESTAMP_MS',
  lastFeedTimestampMs,
});

export const persistSetFeedPreference = (feedPreference) => ({
  type: 'PERSIST_SET_FEED_PREFERENCE',
  feedPreference,
});

export const persistSetFeedPreferenceCategories = (categories) => ({
  type: 'PERSIST_SET_FEED_PREFERENCE_CATEGORIES',
  categories,
});

const navbar = (state = {
  saveBox: {
    enabled: false,
  },
  searchBox: {
    enabled: false,
    autoFocus: false,
  },
}, action) => {
  switch (action.type) {
    case 'NAVBAR_UPDATE_SAVE_BOX': {
      const { enabled } = action;

      const nextSaveBox = {
        enabled,
      };

      return {
        ...state,
        saveBox: nextSaveBox,
      };
    }
    case 'NAVBAR_UPDATE_SEARCH_BOX': {
      const { enabled, autoFocus } = action;

      const nextSearchBox = {
        enabled,
        autoFocus,
      };

      return {
        ...state,
        searchBox: nextSearchBox,
      };
    }
    default:
      return state
  }
};

export default navbar;

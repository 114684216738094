// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyCGr7kRo0_Bk1eQOlesKLm7RUhqS7S01jE",
    authDomain: "videomarx-app.firebaseapp.com",
    databaseURL: "https://videomarx-app.firebaseio.com",
    projectId: "videomarx-app",
    storageBucket: "videomarx-app.appspot.com",
    messagingSenderId: "859960176893",
    appId: "1:859960176893:web:e004ee968c8a449807ce94",
    measurementId: "G-5M14EQ6LKC"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const firestore = firebase.firestore;
export const functions = firebase.functions();
